import {
    ENDPOINT_DEPRECATE_SORT_PATH,
    ENDPOINT_GET_SORT_PATH_RESOURCES,
    ENDPOINT_GET_SORT_PATHS,
    PROCESS_NODE_RESOURCE, REVERSE_SORT_PATH,
    ENDPOINT_GET_BIN_TYPES,
    SORT_PATH, ENDPOINT_ADD_SORT_PATH
} from "../Constants";
import {createRequest} from "../Utility";
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";


/**
 * Adds sort path by calling the appropriate endpoint.
 *
 * @param sortPath
 * @param sortPathResources
 * @returns {Promise<*>}
 */
export async function addSortPath(sortPath, sortPathResources) {
    const requestBody = {
        sortPath: sortPath,
        sortPathResources: sortPathResources
    };
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_ADD_SORT_PATH, requestBody);
    let response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_ADD_SORT_PATH);
    return response.data;
}

/**
 * Deprecates sort path by calling the appropriate endpoint.
 *
 * @param nodeId
 * @param sortPathId
 * @returns {Promise<*>}
 */
export async function deprecateSortPath(nodeId, sortPathId) {
    const requestBody = {
        stationCode: nodeId,
        sortPathId: sortPathId
    };
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_DEPRECATE_SORT_PATH, requestBody);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_DEPRECATE_SORT_PATH);
    return response.data;
}

/**
 * Fetches sort path resources by calling the appropriate endpoint.
 *
 * @param nodeId
 * @param sortPathId
 * @returns {Promise<string>}
 */
export async function fetchSortPathResources(nodeId, sortPathId) {
    let resources = [];
    let nextPageToken = null;
    do {
        const requestBody = {
            sortPathId: sortPathId,
            nextPageToken: nextPageToken
        };
        let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_SORT_PATH_RESOURCES, requestBody);
        const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_SORT_PATH_RESOURCES);
        resources = resources.concat(response.data.sortPathResources);
        nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);
    resources.sort(compareSortPathResource);
    const labelsInSortedOrder = getLabels(resources);
    return labelsInSortedOrder.toString();
}

/**
 * Fetches sort paths by calling the appropriate endpoint.
 *
 * @param nodeId
 * @param tag
 * @returns {Promise<[]>}
 */
export async function fetchSortPathsByTag(nodeId, tag) {
    const requestBody = {
        stationCode: nodeId,
        tag: tag
    };
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_SORT_PATHS, requestBody);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_SORT_PATHS);
    return parseSortPathsResponse(response.data);
}

export async function fetchSortPaths(nodeId) {
    let sortPaths = [];
    sortPaths = sortPaths.concat(await fetchSortPathsByTag(nodeId, SORT_PATH));
    sortPaths = sortPaths.concat(await fetchSortPathsByTag(nodeId, REVERSE_SORT_PATH));
    return sortPaths;
}

/**
 * Creates flat sort path object from sortPath object.
 *
 * @param sortPath
 * @returns {{}}
 */
function createFlatSortPath(sortPath) {
    const flatSortPath = {};
    if (sortPath.hasOwnProperty("attributes")) {
        flatSortPath.binType = sortPath.attributes.binType ? sortPath.attributes.binType : "";
    }
    flatSortPath.id = sortPath.id ? sortPath.id : "";
    flatSortPath.name = sortPath.name ? sortPath.name : "";
    flatSortPath.stationCode = sortPath.stationCode ? sortPath.stationCode : "";
    flatSortPath.tag = sortPath.tag ? sortPath.tag : SORT_PATH;
    flatSortPath.actions = "";
    return flatSortPath;
}

/**
 * Parses response data to format expected by React component.
 *
 * @param responseData
 * @returns {[]}
 */
function parseSortPathsResponse(responseData) {
    const flatSortPaths = [];
    for (let sortPath of responseData.sortPaths) {
        flatSortPaths.push(createFlatSortPath(sortPath));
    }
    return flatSortPaths;
}

/**
 * Returns the labels from resources array.
 *
 * @param resources
 * @returns {[]}
 */
function getLabels(resources) {
    const labels = [];
    for (let resource of resources) {
        if (resource.hasOwnProperty("resource") && resource.resource.hasOwnProperty("label")) {
            labels.push(resource.resource.label);
        }
    }
    return labels;
}

/**
 * Sort Comparator function to compare two resource objects.
 *
 * @param a
 * @param b
 * @returns {number}
 */
function compareSortPathResource(a, b) {
    // a should come before b in the sorted order
    return a.sequenceNumber - b.sequenceNumber;
}


/**
 * Fetches binTypes by calling appropriate end point.
 *
 * @returns {Promise<{binTypes: []}>}
 */
export async function fetchBinTypes() {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_BIN_TYPES, null);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_BIN_TYPES);
    return parseGetBinTypesResponse(response.data);
}


/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{binTypes: []}}
 */
function parseGetBinTypesResponse(responseData) {
    let flatBinTypes = [];
    for (let binType of responseData.binTypes) {
        const flatBinType = createFlatBinTypeObject(binType);
        flatBinTypes.push(flatBinType);
    }
    return {
        binTypes: flatBinTypes
    }
}

/**
 * Creates a flat structure binType object expected by React component.
 *
 * @param binType binType object.
 * @returns flat structure binType object
 */
function createFlatBinTypeObject(binType) {
    let flatBinType = {};
    flatBinType.type = binType.type;
    flatBinType.scope = binType.scope;
    flatBinType.weight = binType.maximumWeight;
    if (binType.hasOwnProperty("dimension")) {
        flatBinType.length = binType.dimension.length;
        flatBinType.width = binType.dimension.width;
        flatBinType.height = binType.dimension.height;
    } else {
        flatBinType.length = "";
        flatBinType.width = "";
        flatBinType.height = "";
    }
    return flatBinType;
}
