import React, {useEffect, useContext} from 'react';
import Row from "@meridian/components/row";
import Column from "@meridian/components/column";
import Box from "@meridian/components/box";
import Button from "@meridian/components/button"
import {
    getStationData,
    updateStationFilters,
    setPageNumber,
    setSelectedStation,
    getStationDataCallBegin,
    getStationDataSuccess,
    getStationDataCallFailure
} from "../resources/StationManagementResource";
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import RadioButton from "@meridian/components/radio-button";
import Table, { TableRow, TableCell } from "@meridian/components/table";
import Text from "@meridian/components/text";
import Input from "@meridian/components/input";
import '../css/StationManagement.css';
import Pagination from "@meridian/components/pagination";
import Select, { SelectOption } from "@meridian/components/select";
import ErrorPage from '../components/util/ErrorPage';
import Loading from '../components/util/Loading';
import {StationManagementContext} from "../context/StationManagementContext";
import Banner from "./Banner";

const StationManagement = () => {

        const {state, dispatch} = useContext(StationManagementContext);

        useEffect(() => {
            dispatch(getStationDataCallBegin());
            getStationData(state.stationSearchCriteria).then(stationDataJson => {
            dispatch(getStationDataSuccess(stationDataJson));
        }).catch(error => {
            dispatch(getStationDataCallFailure(error.response));
        });
        },[]);

        if (state.error) {
            return <ErrorPage error = {state.error.data && state.error.data.message ? state.error.data.message : "generic.error_try_again"} />
        }

        if (state.isLoading === true) {
            return (
                <div className="StationManagement">
                    <Loading />
                </div>
            );
        }
        let stationData = state.items.stationList;
        let history = useHistory();
        if (stationData) {
            return (
                <div className="StationManagement">
                    <Banner message="banner.station_page"/>
                    <Row
                        widths={["grid-9", "grid-3"]}
                        wrap="down"
                        alignment="top right"
                        spacingInset="large"
                    >
                        <Column spacing="none" alignment="top center">
                            <Box
                                type="outline"
                                spacingInset="small"
                                height={550}
                                className="OverflowYAuto"
                            >
                                <Table
                                    headerRows={2}
                                    showDividers={true}
                                    fixHeaderRows={true}
                                    spacing="small"
                                >
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_name" defaultMessage="Name">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_code" defaultMessage="Code">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_city" defaultMessage="City">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_active" defaultMessage="Active">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_parent" defaultMessage="Parent">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text
                                                alignment="center"
                                                type="h100"
                                            >
                                                <FormattedMessage id="station.station_timezone" defaultMessage="Time Zone">
                                                </FormattedMessage>
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <Input
                                                size="small"
                                                value={state.stationFilters.stationName}
                                                type="text"
                                                onChange={(stationName) => dispatch(updateStationFilters("stationName", stationName))}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                size="small"
                                                value={state.stationFilters.stationCode}
                                                type="text"
                                                onChange={(stationCode) => dispatch(updateStationFilters("stationCode", stationCode))}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                size="small"
                                                value={state.stationFilters.city}
                                                type="text"
                                                onChange={(city) => dispatch(updateStationFilters("city", city))}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                size="small"
                                                popoverPosition="adjacent"
                                                value={state.stationFilters.active}
                                                onChange={(active) => dispatch(updateStationFilters("active", active))}
                                            >
                                                <SelectOption value="" label="All" />
                                                <SelectOption value="true" label="Yes" />
                                                <SelectOption value="false" label="No" />
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                size="small"
                                                popoverPosition="adjacent"
                                                value={state.stationFilters.parentStation}
                                                onChange={(parentStation) => dispatch(updateStationFilters("parentStation", parentStation))}
                                            >
                                                <SelectOption value="" label="All" />
                                                {stationData.map(station => (
                                                    <SelectOption key={station.stationCode} value={station.stationCode} label={station.stationCode} />
                                                ))}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Input
                                                size="small"
                                                value={state.stationFilters.timeZone}
                                                type="text"
                                                onChange={(timeZone) => dispatch(updateStationFilters("timeZone", timeZone))}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {state.items.visibleStationListForPage.map(station => (
                                        <TableRow onClick = {() => {dispatch(setSelectedStation(station.stationCode))}} key={station.stationId} highlightOnHover={true} >
                                            <TableCell>
                                                <RadioButton
                                                    checked={state.selectedStationCode === station.stationCode}
                                                    name="color"
                                                    value="blue"
                                                >
                                                    <Text> </Text>
                                                </RadioButton>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.stationName ? station.stationName : ""}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.stationCode ? station.stationCode : ""}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.city ? station.city : ""}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.active ? "Yes" : "No"}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.parentStation ? station.parentStation : ""}
                                                </Text>
                                            </TableCell>
                                            <TableCell>
                                                <Text
                                                    alignment="center"
                                                    type="b100"
                                                >{station.timeZone ? station.timeZone : ""}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </Box>
                            <Pagination
                                numberOfPages={Math.ceil(state.items.updatedStationListWithFilters.length / state.pageSize)}
                                currentPage={state.currentPageNumber}
                                locale="en-US"
                                onChange={(pageNumber) => { dispatch(setPageNumber(pageNumber)) }}
                            />
                        </Column>
                        <Box
                            type="outline"
                            spacingInset="small"
                            height={550}
                        >
                            <Row
                                widths={"fill"}
                                wrap="down"
                                alignment="center left"
                                spacingInset="medium"
                            >
                                <Button disabled = {state.selectedStationCode === ""} onClick = {() => {
                                    history.push({
                                        pathname: '/stationView',
                                        state: { detail: state.selectedStationCode }
                                    });
                                }} >
                                    <FormattedMessage id="station.view_station" defaultMessage="View Station">
                                    </FormattedMessage>
                                </Button>
                            </Row>
                        </Box>
                    </Row>
                </div>

            );
        }
};

export default StationManagement