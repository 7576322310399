/**
 * Use this class whenever there is a requirement whenever a feature context variable
 * is required to be used for both components and other objects.
 */
export class FeatureConfigUtils {
    static isSSD3Enabled = false;

    static getIsSSD3EnabledStation() {
        return FeatureConfigUtils.isSSD3Enabled;
    }

    static setIsSSD3EnabledStation(isEnabled) {
        FeatureConfigUtils.isSSD3Enabled = isEnabled;
    }
}
