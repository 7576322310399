import {
    commaSeperatedAisleRangeStringToArray,
    commaSeperatedLocationRangeStringToArray,
    commaSeperatedStringToArray,
    getNodeId
} from "../../../Utility";
import {
    INCLUDED_LOCATION_OPTIONS,
    SORT_PATH_FILL_ALGORITHMS,
    SORT_PATH_PICK_ALGORITHMS, SORT_PATH_SETTING_STATUS,
    StationCycleState
} from "../../../Constants";
import {addToObject, chainWalk} from "../CommonUtils";
import {isDynamicCycle} from "./CommonUtils";
import {FeatureConfigUtils} from "../FeatureConfigUtils";

export const buildSortPathConfigurationDataObject = (formData) => {
    const { commonFormData, fillFormData, pickFormData } = formData;

    return {
        settingId: chainWalk(() => commonFormData.gridAllocationId,  null),
        name: chainWalk(() => commonFormData.name,  "").trim(),
        cycle: chainWalk(() => commonFormData.cycle,  null),
        nodeId: chainWalk(() => commonFormData.nodeId,  null),
        binType: chainWalk(() => commonFormData.binType,  null),
        cycleList: chainWalk(() => commonFormData.cycleList,  null),
        status: chainWalk(() => commonFormData.status, SORT_PATH_SETTING_STATUS.UPDATED),
        excludedLocations: {
            excludedClusters: commaSeperatedStringToArray(chainWalk(() => commonFormData.excludedLocations.excludedClusters, null)),
            excludedAisles: commaSeperatedStringToArray(chainWalk(() => commonFormData.excludedLocations.excludedAisles, null)),
            excludedBins: commaSeperatedStringToArray(chainWalk(() => commonFormData.excludedLocations.excludedBins, null)),
        },
        includedLocations: {
            includedAisles: commaSeperatedAisleRangeStringToArray(chainWalk(() => commonFormData.includedLocations.aislesRange[1], null)),
            includedColumns: commaSeperatedStringToArray(chainWalk(() => commonFormData.includedLocations.columns[1], null)),
            includedSortZones: commaSeperatedStringToArray(chainWalk(() => commonFormData.includedLocations.includedSortZones, null)),
            includedSortLocations: commaSeperatedLocationRangeStringToArray(chainWalk(() => commonFormData.includedLocations.sortLocationsRange[1], null)),
        },
        fillPathSetting: buildFillPathSetting(fillFormData),
        pickPathSetting: buildPickPathSetting(pickFormData)
    };
}

const buildFillPathSetting = (fillFormData) => {
    return {
        algorithm: chainWalk(() => fillFormData.algorithm, null),
        additionalFields: buildFillPathAdditionalFields(fillFormData),
    };
}

const buildFillPathAdditionalFields = (fillFormData) => {
    const algorithm = chainWalk(() => fillFormData.algorithm, null);
    switch (algorithm) {
        case SORT_PATH_FILL_ALGORITHMS.COMBINATRIX:
            return {
                fingerOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.fingerSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.fingerSequence[1], null)),
                },
                aisleOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.aisleSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.aisleSequence[1], null)),
                },
                adtaPrioritizationOrder: chainWalk(() => fillFormData.resourceSequences.adtaPrioritySequence[0], null),
                deprioritizedTiers: chainWalk(() => fillFormData.commonParameters.deprioritizedTiers, []),
                deprioritizedTiersForADTAClusters: chainWalk(() => fillFormData.commonParameters.deprioritizedTiersForADTAClusters, []),
                scienceModelOptimizationParameter: {
                    balancingSortZonesInClusters: chainWalk(() => fillFormData.commonParameters.balancingSortZonesInClusters, false),
                    optimiseClusterUsage: chainWalk(() => fillFormData.commonParameters.optimiseClusterUsage, true),
                },
                maxClusterCapacityForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.maxClusterCapacityForNode, [])),
                adtaClusterIdentifierForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() =>fillFormData.commonParameters.adtaClusterIdentifierForNode, [])),
                blockLength: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.blockLength, [])),
                splitPercentage: chainWalk(() => fillFormData.parameters.splitPercentage, null),
            };
        case SORT_PATH_FILL_ALGORITHMS.STANDARD:
        case SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK:
            return {
                clusterOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.clusterSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.clusterSequence[1], null)),
                },
                aisleOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.aisleSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.aisleSequence[1], null)),
                },
                adtaPrioritizationOrder: chainWalk(() => fillFormData.resourceSequences.adtaPrioritySequence[0], null),
                deprioritizedTiers: chainWalk(() => fillFormData.commonParameters.deprioritizedTiers, []),
                deprioritizedTiersForADTAClusters: chainWalk(() => fillFormData.commonParameters.deprioritizedTiersForADTAClusters, []),
                scienceModelOptimizationParameter: {
                    balancingSortZonesInClusters: chainWalk(() => fillFormData.commonParameters.balancingSortZonesInClusters, false),
                    optimiseClusterUsage: chainWalk(() => fillFormData.commonParameters.optimiseClusterUsage, true),
                },
                maxClusterCapacityForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.maxClusterCapacityForNode, [])),
                adtaClusterIdentifierForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() =>fillFormData.commonParameters.adtaClusterIdentifierForNode, [])),
                blockLength: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.blockLength, [])),
            };
        case SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD:
            return {
                clusterOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.clusterSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.clusterSequence[1], null)),
                },
                aisleOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.aisleSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.aisleSequence[1], null)),
                },
                adtaPrioritizationOrder: chainWalk(() => fillFormData.resourceSequences.adtaPrioritySequence[0], null),
                deprioritizedTiers: chainWalk(() => fillFormData.commonParameters.deprioritizedTiers, []),
                deprioritizedTiersForADTAClusters: chainWalk(() => fillFormData.commonParameters.deprioritizedTiersForADTAClusters, []),
                scienceModelOptimizationParameter: {
                    balancingSortZonesInClusters: chainWalk(() => fillFormData.commonParameters.balancingSortZonesInClusters, false),
                    optimiseClusterUsage: chainWalk(() => fillFormData.commonParameters.optimiseClusterUsage, true),
                },
                maxClusterCapacityForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.maxClusterCapacityForNode, [])),
                adtaClusterIdentifierForNode: buildCusterParameterModelForSortPathSettings(chainWalk(() =>fillFormData.commonParameters.adtaClusterIdentifierForNode, [])),
                blockLength: buildCusterParameterModelForSortPathSettings(chainWalk(() => fillFormData.commonParameters.blockLength, [])),
            };
        case SORT_PATH_FILL_ALGORITHMS.SAME_DAY_STANDARD_FILL:
            return {
                sortLocationOrder: {
                    type: chainWalk(() => fillFormData.resourceSequences.sortLocationSequence[0], null),
                    customSequence: commaSeperatedStringToArray(chainWalk(() => fillFormData.resourceSequences.sortLocationSequence[1], null)),
                } };
        default:
            return {};
    };
}

const buildPickPathSetting = (pickFormData) => {
    return {
        algorithm: chainWalk(() => pickFormData.algorithm, null),
        additionalFields: FeatureConfigUtils.getIsSSD3EnabledStation() ? buildPickPathAdditionalFieldsSSD3(pickFormData) :
            buildPickPathAdditionalFields(pickFormData),
    };
}

const buildPickPathAdditionalFields = (pickFormData) => {
    return {
        clusterOrder: {
            type: chainWalk(() => pickFormData.resourceSequences.clusterSequence[0], null),
            customSequence: commaSeperatedStringToArray(chainWalk(() => pickFormData.resourceSequences.clusterSequence[1], null)),
        },
        aisleOrder: {
            type: chainWalk(() => pickFormData.resourceSequences.aisleSequence[0], null),
            customSequence: commaSeperatedStringToArray(chainWalk(() => pickFormData.resourceSequences.aisleSequence[1], null)),
        },
        columnLengthForZebraPath: chainWalk(() => pickFormData.parameters.columnLengthForZebraPath, null),
        columnSplitRatio: "0.5",
    };
};

const buildPickPathAdditionalFieldsSSD3 = (pickFormData) => {
    return {
        clusterOrder: {
            type: chainWalk(() => pickFormData.resourceSequences.clusterSequence[0], null),
            customSequence: commaSeperatedStringToArray(chainWalk(() => pickFormData.resourceSequences.clusterSequence[1], null)),
        },
        sortLocationOrder: {
            type: chainWalk(() => pickFormData.resourceSequences.sortLocationSequence[0], null),
            customSequence: commaSeperatedStringToArray(chainWalk(() => pickFormData.resourceSequences.sortLocationSequence[1], null)),
        }
    };
};

export const getGridAllocationFormData = (sortPathConfiguration, cycleList) => {
    const fillAlgorithm = isDynamicCycle(sortPathConfiguration.cycle, sortPathConfiguration.nodeId, cycleList) ?
        SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD: FeatureConfigUtils.getIsSSD3EnabledStation() ?
            SORT_PATH_FILL_ALGORITHMS.SAME_DAY_STANDARD_FILL: chainWalk(() => sortPathConfiguration.fillPathSetting.algorithm,
                null);
    const nodeId = chainWalk(() => sortPathConfiguration.nodeId, getNodeId());
    const cycleName = chainWalk(() => sortPathConfiguration.cycle, null);
    const pickAlgorithm = FeatureConfigUtils.getIsSSD3EnabledStation() ? SORT_PATH_PICK_ALGORITHMS.SAME_DAY_STANDARD_PATH :
        chainWalk(() => sortPathConfiguration.pickPathSetting.algorithm, null);
    return {
        commonFormData: {
            gridAllocationId: chainWalk(() => sortPathConfiguration.settingId, null),
            cycle: cycleName,
            nodeId: nodeId,
            status: chainWalk(() => sortPathConfiguration.status, SORT_PATH_SETTING_STATUS.UPDATED),
            cycleList: chainWalk(() => sortPathConfiguration.cycleList, [{
                [StationCycleState.STATION_CODE] : nodeId,
                [StationCycleState.CYCLE] : {
                    [StationCycleState.CYCLE_NAME]: cycleName,
                    [StationCycleState.CYCLE_ID]: cycleName
                }
            }]),
            name: chainWalk(() => sortPathConfiguration.name, null),
            binType: chainWalk(() => sortPathConfiguration.binType, null),
            excludedLocations: {
                excludedClusters: chainWalk(() => sortPathConfiguration.excludedLocations.excludedClusters, []).join(", "),
                excludedAisles: chainWalk(() => sortPathConfiguration.excludedLocations.excludedAisles, []).join(", "),
                excludedBins: chainWalk(() => sortPathConfiguration.excludedLocations.excludedBins, []).join(", "),
            },
            includedLocations: {
                ...(chainWalk(() => sortPathConfiguration.includedLocations.includedAisles, []).length) ?
                {
                    aislesRange: [INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM,
                        chainWalk(() => sortPathConfiguration.includedLocations.includedAisles, []).map(x => `${x.startingAisle}:${x.endingAisle}`).join(", ")],
                } : { aislesRange: [INCLUDED_LOCATION_OPTIONS.AISLES.USE_ALL, null] },
                ...(chainWalk(() => sortPathConfiguration.includedLocations.includedColumns, []).length) ? {
                    columns: [INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM, chainWalk(() => sortPathConfiguration.includedLocations.includedColumns, []).join(", ")]
                } : { columns: [INCLUDED_LOCATION_OPTIONS.COLUMNS.ABC, null] },
                ...(chainWalk(() => sortPathConfiguration.includedLocations.includedSortZones, []).length) ? {
                    includedSortZones: chainWalk(() => sortPathConfiguration.includedLocations.includedSortZones, []).join(", ")
                } : { includedSortZones: null },
                ...(FeatureConfigUtils.getIsSSD3EnabledStation()) ? (chainWalk(() => sortPathConfiguration.includedLocations.includedSortLocations, []).length) ? {
                    sortLocationsRange: [INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM,
                        chainWalk(() => sortPathConfiguration.includedLocations.includedSortLocations, [])
                            .map(x => `${x.startingLocation}:${x.endingLocation}`).join(", ")],
                } : { sortLocationsRange: [INCLUDED_LOCATION_OPTIONS.SORT_LOCATIONS.USE_ALL, null] } : {}
            },
            overlapList : chainWalk(() => sortPathConfiguration.overlapList, null),
        },
        fillFormData: {
            algorithm: fillAlgorithm,
            resourceSequences: {
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.COMBINATRIX) &&
                {
                    fingerSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.fingerOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.fingerOrder.customSequence, []).join(", ")
                    ],
                    aisleSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.customSequence, []).join(", ")
                    ],
                    adtaPrioritySequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.adtaPrioritizationOrder, null),
                    ]
                },
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD) &&
                {
                    clusterSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.customSequence, []).join(", ")
                    ],
                    aisleSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.customSequence, []).join(", ")
                    ],
                    adtaPrioritySequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.adtaPrioritizationOrder, null),
                    ]
                },
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD) &&
                {
                    clusterSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.customSequence, []).join(", ")
                    ],
                    aisleSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.customSequence, []).join(", ")
                    ],
                    adtaPrioritySequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.adtaPrioritizationOrder, null),
                    ]
                },
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK) &&
                {
                    clusterSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.clusterOrder.customSequence, []).join(", ")
                    ],
                    aisleSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.aisleOrder.customSequence, []).join(", ")
                    ],
                    adtaPrioritySequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.adtaPrioritizationOrder, null),
                    ]
                },
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.SAME_DAY_STANDARD_FILL) &&
                {
                    sortLocationSequence: [
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.sortLocationOrder.type, null),
                        chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.sortLocationOrder.customSequence, []).join(", ")
                    ]
                }
            },
            parameters: {
                ...(fillAlgorithm === SORT_PATH_FILL_ALGORITHMS.COMBINATRIX) &&
                {
                    splitPercentage: chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.splitPercentage, null)
                }
            },
                commonParameters: {
                    deprioritizedTiers: chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.deprioritizedTiers, []),
                    deprioritizedTiersForADTAClusters:  chainWalk(() => sortPathConfiguration.fillPathSetting.additionalFields.deprioritizedTiersForADTAClusters, []),
                    balancingSortZonesInClusters: getScienceModelOptimizationParameter(sortPathConfiguration.fillPathSetting.additionalFields.scienceModelOptimizationParameter, "balancingSortZonesInClusters"),
                    optimiseClusterUsage: getScienceModelOptimizationParameter(sortPathConfiguration.fillPathSetting.additionalFields.scienceModelOptimizationParameter,"optimiseClusterUsage"),
                    maxClusterCapacityForNode: extractCusterParameterMapFromSortPathSettings(sortPathConfiguration.fillPathSetting.additionalFields.maxClusterCapacityForNode),
                    adtaClusterIdentifierForNode: extractCusterParameterMapFromSortPathSettings(sortPathConfiguration.fillPathSetting.additionalFields.adtaClusterIdentifierForNode),
                    blockLength: extractCusterParameterMapFromSortPathSettings(sortPathConfiguration.fillPathSetting.additionalFields.blockLength),
                },
        },
        pickFormData: {
            algorithm: pickAlgorithm,
            resourceSequences: {
                clusterSequence: [
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.clusterOrder.type, null),
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.clusterOrder.customSequence, []).join(", "),
                ],
                aisleSequence: [
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.aisleOrder.type, null),
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.aisleOrder.customSequence, []).join(", ")
                ],
                sortLocationSequence: [
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.sortLocationOrder.type, null),
                    chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.sortLocationOrder.customSequence, []).join(", ")
                ]
            },
            parameters: {
                columnLengthForZebraPath: chainWalk(() => sortPathConfiguration.pickPathSetting.additionalFields.columnLengthForZebraPath, null)
            }
        },
    };
};

const extractCusterParameterMapFromSortPathSettings = (clusterParameterValuesForNode) => {
    const clusterParameterValues = chainWalk(() => clusterParameterValuesForNode, []);
    let transformedValues = [];
    if (Array.isArray(clusterParameterValues) && clusterParameterValues.length) {
        clusterParameterValues.forEach(parameterValuePerCluster => {
            transformedValues = addToObject(transformedValues, parameterValuePerCluster.clusterName,
                parameterValuePerCluster.clusterParameterValue)
        });
    }
    return transformedValues;
};

const buildCusterParameterModelForSortPathSettings = (clusterParameterValues) => {
    let transformedValues = [];
    if (Object.entries(clusterParameterValues).length) {
        Object.entries(clusterParameterValues).forEach(entry => {
            const [clusterName, value] = entry;
            transformedValues.push({clusterName: clusterName, clusterParameterValue: value})
        });
    }
    return transformedValues;
};

const getScienceModelOptimizationParameter = (scienceModelOptimizationParameter, key) => {
    const defaultValue = (key !== "balancingSortZonesInClusters");
    if (scienceModelOptimizationParameter && Object.keys(scienceModelOptimizationParameter).length !== 0) {
        return chainWalk(() => scienceModelOptimizationParameter[key], defaultValue);
    }
    return defaultValue;
}

