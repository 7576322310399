import React, {useCallback, useEffect, useState} from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Alert from "@amzn/meridian/alert";
import Link from "@amzn/meridian/link";
import Button from "@amzn/meridian/button";
import {FormattedMessage} from "react-intl";
import {buildSortPathConfigurationDataObject} from "../util/sort-paths/SortPathConfigurationData";
import Modal from "@amzn/meridian/modal";
import ModalFooter from "@amzn/meridian/modal/modal-footer";
import {getSortGridOverlap} from "../../resources/SortPathSettingsResource";
import {getNodeId} from "../../Utility";
import {STRINGS} from "../../Constants";

/**
 * React component for showing Sort Grid overlap details
 */
const SortGridOverlapDetails = (props) => {
    const {
        formData, areRequiredFieldsPresent
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [overlappingSortZones, setOverlappingSortZones] = useState("");
    const [overlapList, setOverlapList] = useState([]);
    const [refreshingOverlapData, setRefreshingOverlapData] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [isSuccess, setIsSuccess] = useState(true)


    const onClickCloseButton = useCallback(() => {
        setIsModalOpen(false);
        setOverlappingSortZones("");
    }, []);

    const onClickSortZonesLink = useCallback((sortZones) => {
        setIsModalOpen(true);
        setOverlappingSortZones(sortZones ? sortZones.join(", ") : "");
    }, []);

    useEffect(() => {
        refreshOverlapData(formData)
    }, []);

    const refreshOverlapData = useCallback((formData) => {
        setRefreshingOverlapData(true);
        if(!areRequiredFieldsPresent(formData)) {
            setRefreshingOverlapData(false);
            return;
        }
        const sortPathConfigurationData = buildSortPathConfigurationDataObject(formData);
        const response = getSortGridOverlap(getNodeId(), sortPathConfigurationData);
        response.then(data => {
            setRefreshingOverlapData(false);
            if (data) {
                const { overlapList, errorMessage } = data;
                if( overlapList) {
                    setIsSuccess(true)
                    setOverlapList(data.overlapList);
                } else {
                    setIsSuccess(false)
                    const parsedMessage = JSON.parse(errorMessage || `{"message": "${STRINGS.RETRY_LATER}"`);
                    setErrorMessage(parsedMessage.message);
                }
            } else {
                setIsSuccess(false)
                setErrorMessage("Something went wrong!!")
            }
        }, error => {
            setIsSuccess(false)
            setErrorMessage("Something went wrong!")
        }).finally(() => {
            setRefreshingOverlapData(false)
        });
    }, [])

    function renderModalForViewSortZones() {
        return (
            <Modal open={isModalOpen}
                   scrollContainer="modal"
                   describedById="overlappingsz-modal-description"
                   width="800px"
                   height="600px">
                {overlappingSortZones}
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="primary" size="small" onClick={onClickCloseButton}>
                            <FormattedMessage id="sortPaths.close" defaultMessage="Close"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        );
    }

    return (
            <Row>
                <Column>
                    <Row spacingInset={"medium none none"}>
                        <Button
                            data-testid="check-overlap"
                            disabled={!props.isAuthorized || refreshingOverlapData || !areRequiredFieldsPresent(formData)}
                            onClick={() => refreshOverlapData(formData)}>
                            <FormattedMessage id="sortPaths.checkOverlap" defaultMessage="Check Overlap"/>
                        </Button>
                    </Row>
                    <Alert
                        type={isSuccess ? "informational" : "error"}>
                        <Row> { isSuccess ?
                            <FormattedMessage
                                id="sortPaths.overlapsFound"
                                defaultMessage="{count} Overlap(s) found"
                                values={{count: overlapList.length}} />
                            : errorMessage
                        }
                        </Row>
                        { isSuccess && overlapList.map(overlap => {
                            return (
                                <Row>
                                    <Column><Text type={"h100"}>{overlap.overlappingGridName}</Text></Column>
                                    <Column><Link onClick={() => onClickSortZonesLink(overlap.sortZoneList)}>{overlap.sortZoneCount} SortZones</Link> </Column>
                                </Row>
                            )
                        })}
                    </Alert>
                </Column>
                {renderModalForViewSortZones()}
            </Row>
        );
}

export default SortGridOverlapDetails;