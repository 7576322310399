import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    ENDPOINT_GET_CART_RECIPE,
    PROCESS_NODE_RESOURCE
} from "../Constants";
import {createRequest} from "../Utility";

/**
 * Fetches cart recipe by calling appropriate end point.
 *
 * @param cartType
 * @returns {Promise<*>}
 */
export async function getCartRecipe(cartType) {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_CART_RECIPE, {cartType: cartType});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_CART_RECIPE);
}
