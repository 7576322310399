import React from "react";
import GroupAccordion from "./GroupAccordion";
import Responsive from "@amzn/meridian/responsive";

const RBLConfig = (props) => {
    return (
        <Responsive query="min-width"
                    props={{
                        label: {
                            default: "the default",
                            "500px": "pretty small",
                            "720px": "slightly mediumish",
                            "1024px": "respectably sized",
                            "1280px": "decisively big",
                            "1600px": "very large",
                        },
                    }}>
            {props => <GroupAccordion/>}
        </Responsive>
    );
}

export default RBLConfig;
