import React, {Component} from 'react';
import Row from "@meridian/components/row"
import Column from "@meridian/components/row"
import Box from "@meridian/components/box"
import Modal from "@meridian/components/modal"
import Button from "@meridian/components/button";
import Text from "@meridian/components/text"
import {fetchInductLocationData} from "../resources/StationLayoutResource.js"
import {FormattedMessage} from 'react-intl';
import Table, {TableCell, TableRow} from "@meridian/components/table";
import QRCode from "qrcode.react";
import Link from "@meridian/components/link";
import ReactToPrint from 'react-to-print';
import ErrorPage from './util/ErrorPage.js';
import Loading from './util/Loading.js';

class StationLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isShowQRModal: false,
    };
    this.handleOnClickToggleInductLocationArea = this.handleOnClickToggleInductLocationArea.bind(this);
    this.handleOnClickViewQR = this.handleOnClickViewQR.bind(this);
    this.handleOnClickCloseQR = this.handleOnClickCloseQR.bind(this);
  }

  componentDidMount() {
    let response = this.getInductLocationData();
    this.handleResponse(response);
  }

  handleResponse(response) {
    return response.then(inductLocationDataJson => {
      let _state = {...this.state};
      _state.inductLocationData = inductLocationDataJson;
      _state.isLoading = false;
      this.setState(_state);
    }).catch(error => {
      let _state = {...this.state};
      _state.isLoading = false;
      _state.error = error;
      this.setState(_state);
    })
  }

  getInductLocationData() {
    this.setIsLoading(true);
    return fetchInductLocationData();
  }

  setIsLoading(isLoading) {
    let _state = {...this.state};
    _state.isLoading = isLoading;
    this.setState(_state);
  }

  setIsShowQRModal(isShowQRModal) {
    let _state = {...this.state};
    _state.isShowQRModal = isShowQRModal;
    this.setState(_state);
  }

  handleOnClickToggleInductLocationArea(inductLocation) {
    let _state = {...this.state};
    let _inductLocationData = [...this.state.inductLocationData];
    let index = _inductLocationData.findIndex(location => location.name === inductLocation.name);
    let _inductLocation = {..._inductLocationData[index]};
    _inductLocation.open = _inductLocation.open ? !_inductLocation.open : true;
    _inductLocationData[index] = _inductLocation;
    _state.inductLocationData = _inductLocationData;
    this.setState(_state);
  }

  handleOnClickViewQR(qrCodeData) {
    let _state = {...this.state};
    _state.qrModalData = qrCodeData;
    _state.isShowQRModal = true;
    this.setState(_state);
  }

  handleOnClickCloseQR() {
    this.setIsShowQRModal(false);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage error = {this.state.error.data && this.state.error.data.message ? this.state.error.data.message : "generic.error_try_again"} />
    }

    if (this.state.isLoading === true) {
      return (
        <div className="StationLayout">
           <Loading /> 
        </div>
      );
    }
    let inductLocationData = this.state.inductLocationData;
    if (inductLocationData) {
      return (
        <div className="StationLayout">
          <Modal
            type="dialog"
            id="modal-qrCode"
            closeLabel="Close"
            open={this.state.isShowQRModal}
            onOpen={() => { }}
            describedBy="modal-qrCode_desc"
          >
            <Text id="qrCode-modal_desc"><QRCode size={256} value={this.state.qrModalData} /></Text>
            <Row alignment="center right" widths="fit">

              <Button
                type="primary"
                size="small"
                onClick={() => this.handleOnClickCloseQR()}
              >
                <FormattedMessage id="generic.close" defaultMessage="Close">
                </FormattedMessage>
              </Button>
            </Row>
          </Modal>
          <Row
            widths={"fill"}
            wrap="down"
            alignment="top left"
            spacingInset="small"
          >

            <Box spacingInset="medium">
              <Table spacing="small" headerRows={1} showDividers={true}>
                <TableRow>
                  <TableCell />
                  <TableCell><FormattedMessage id="stationlayout.induct_area" defaultMessage="Induct Area">
                  </FormattedMessage></TableCell>
                  <TableCell><FormattedMessage id="stationlayout.qrcode" defaultMessage="QR Code">
                  </FormattedMessage></TableCell>
                  <TableCell><FormattedMessage id="stationlayout.operation" defaultMessage="Operation">
                  </FormattedMessage></TableCell>
                </TableRow>
                {inductLocationData.map(inductLocations => (
                  <TableRow
                    highlightOnHover={true}
                    key={inductLocations.name}
                    open={inductLocations.open}
                    onClick={() => this.handleOnClickToggleInductLocationArea(inductLocations)}
                  >
                    <TableCell spacing="small" columnSpan={4}><FormattedMessage id={inductLocations.name} defaultMessage="Induct Location">
                    </FormattedMessage></TableCell>
                    {inductLocations.inductLocation.map(inductLocation => (
                      <TableRow highlightOnHover={true} key={inductLocation}>
                        <TableCell />
                        <TableCell  >{inductLocation.locationArea}</TableCell>
                        <TableCell >
                          <Link type="secondary" onClick={() => this.handleOnClickViewQR(inductLocation.scannableId)}>
                            <FormattedMessage id="stationlayout.view" defaultMessage="View">
                            </FormattedMessage>
                          </Link>
                        </TableCell>
                        <TableCell >
                          <div style={{ display: 'none' }}><div ref={el => this[inductLocation.scannableId] = el} ><Column
                            height="400px"
                            alignment="bottom center"
                          ><QRCode size={256} value={inductLocation.scannableId} /></Column></div></div>
                          <ReactToPrint
                            trigger={() => <Link href="#"><FormattedMessage id="stationlayout.print" defaultMessage="Print">
                            </FormattedMessage></Link>}
                            content={() => this[inductLocation.scannableId]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableRow>
                ))}
              </Table>
            </Box>
          </Row>
        </div>

      );
    }
  };
}

export default StationLayout;