import FileSaver from 'file-saver';
import {useState, useEffect} from 'react';

import XLSX from "xlsx";
import {REGION, STAGE, XLSX_FILE_TYPE} from "./Constants";

export const getNodeId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('stationCode');
};

export function createRequest(processName, endPoint, requestBody) {
    return {
        processName: processName,
        endPoint: endPoint,
        request: JSON.stringify(requestBody)
    }
}

export function downloadXLSXFile(data, sheetName, fileName) {
    const workBook = {SheetNames: [], Sheets: {}};
    const fileExtension = '.xlsx';
    const workSheet1 = XLSX.utils.json_to_sheet(data);

    workBook.SheetNames.push(sheetName);
    workBook.Sheets[sheetName] = workSheet1;
    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
    const output = new Blob([excelBuffer], {type: XLSX_FILE_TYPE});
    FileSaver.saveAs(output, fileName + fileExtension);
}

export function getRegion() {
    let location = window.location.href
    let region = REGION.NA
    if (location.includes("ui.na")) {
        region = REGION.NA
    } else if (location.includes("ui.eu")) {
        region = REGION.EU
    } else if (location.includes("ui.jp")) {
        region = REGION.FE
    } else if (location.includes("ui.in")) {
        region = REGION.IN
    }
    return region;
}

export function getStage() {
    let location = window.location.href
    let stage = STAGE.GAMMA
    if (location.includes(".beta.")) {
        stage = STAGE.BETA
    } else if (location.includes(".gamma.")) {
        stage = STAGE.GAMMA
    } else if (location.includes(".prod.")) {
        stage = STAGE.PROD
    }
    return stage;
}

export function useFetch(fetchFunction) {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasError, setErrorLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        fetchFunction()
            .then((data) => {
                setResponse(data);
            })
            .catch((e) => {
                console.error('error calling fetch ',e)
                setErrorLoading(true)
            })
            .finally(() => {
                setLoading(false);
            })
    }, [fetchFunction]);

    return [response, loading, hasError];
}

export function strcmp(a, b) {
    if (a.toString() < b.toString()) return -1;
    if (a.toString() > b.toString()) return 1;
    return 0;
}

export function isBlankString(str) {
    return (!str || /^\s*$/.test(str));
}

export function commaSeperatedStringToArray(str) {
    if(!str || str.trim().length === 0) {
        return [];
    } else {
        return str.split(",").map(x => x.trim());
    }
}

export function commaSeperatedAisleRangeStringToArray(str) {
    return commaSeperatedStringToArray(str)
        .map(range => range.split(":"))
        .map(range => ({
            startingAisle: range[0] ? range[0].trim() : null,
            endingAisle: range[1] ? range[1].trim() : null
        }));
}

export function commaSeperatedLocationRangeStringToArray(str) {
    return commaSeperatedStringToArray(str)
        .map(range => range.split(":"))
        .map(range => ({
            startingLocation: range[0] ? range[0].trim() : null,
            endingLocation: range[1] ? range[1].trim() : null
        }));
}

export function isConfigEnabled(configName, stationConfigs) {
    const configDetails = stationConfigs.find(stationConfig => stationConfig.name === configName);
    return configDetails && configDetails.value === "true";
}
