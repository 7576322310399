import {ACTION_TYPE} from '../Constants'
export const SET_IS_AUTHORIZED_USER = "SET_IS_AUTHORIZED_USER";

export const UserAuthorizationReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE.GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK:
            return {
                ...state,
                isNARCTCPAuthorizationCheckEnabled: action.isNARCTCPAuthorizationCheckEnabled
            }

        case SET_IS_AUTHORIZED_USER:
            return {
                ...state,
                isAuthorized: action.payload.isAuthorized
            };

        default:
            return state
    }
}