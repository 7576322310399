import React, {Component} from "react";
import StationView from "../StationView";
import StationViewManagementContextProvider from "../../context/StationViewManagementContext";

class StationViewManagementManager extends Component {
    render() {
        return (
            <StationViewManagementContextProvider>
                <StationView/>
            </StationViewManagementContextProvider>
        )
    }
}

export default StationViewManagementManager
