import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from "@meridian/components/alert";
import Box from "@meridian/components/box";
import Text from "@meridian/components/text";

class Banner extends Component {
    render() {
        return (
            <Box>
                <Alert
                    type={this.props.type || "warning"}
                    size="medium"
                >
                    <Text>
                        <FormattedMessage id={this.props.message}
                                          values={this.props.values}
                        defaultMessage="This page has been ported from SMC and shows the data basis your COMP active station">
                        </FormattedMessage>
                    </Text>
                </Alert>
            </Box>
        )
    };
}

export default Banner;