import React, {useCallback, useContext, useState} from "react";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import PositionInput from "./PositionInput";
import {getNodeId, useFetch} from "../../Utility";
import {ASSET_TYPES} from "../../Constants";
import {addAsset, getAssetsByMappedResource, setAssetStatus, updateAsset} from "../../resources/AssetResource";
import Loading from "../util/Loading";
import ErrorPage from "../util/ErrorPage";
import {BinTestModalContext} from "../../context/BinTestModalContext";
import {LOAD_SPOTLIGHT_FOR_GROUP} from "../../reducers/BinTestModalReducer";
import InputMask from "@amzn/meridian/input/mask";
import Toggle from "@amzn/meridian/toggle";
import Tag from "@amzn/meridian/tag";

const SPOTLIGHT_DEFAULT_ATTRIBUTES = {
    name: "",
    macAddress: "",
    universe: "0",
    startChannelOffset: "0",
    eulerAngles: "{\"x\":\"0\",\"y\":\"180\",\"z\":\"270\"}",
    enabled: "true"
}

const MAC_MASK = new InputMask(
    '^^:^^:^^:^^:^^:^^',
    {
        formatChars: {
            '^': '[0-9a-f]'
        }
    }
);

function isSpotlightValid(name, mac, universe, startChannelOffset, position, rotation) {
    if(name === "" || mac === "" || universe === "" || startChannelOffset === "") {
        return false;
    }
    if (position.x === "" || position.y === "" || position.z === "" ) {
        return false;
    }
    return !(rotation.x === "" || rotation.y === "" || rotation.z === "");

}

function createSpotLightAsset(spotlightId, mac, startChannelOffset, universe, enabled,
                              positionXyz, rotationXyz, groupLabel, nodeId, spotlightLabel) {
    return {
        "assetDetails": {
            "assetStatus": "ACTIVE",
            "assetType": "SPOTLIGHT",
            "nodeId": nodeId,
            "assetLabel": spotlightLabel,
            "assetAttributes": {
                "macAddress": mac,
                "startChannelOffset": startChannelOffset,
                "universe": universe,
                "name": spotlightId,
                "enabled": enabled.toString(),
                "eulerAngles": JSON.stringify(rotationXyz)
            },
            "relativePositions": [{
                "fromPositionType": "CENTER",
                "toPositionType": "CENTER",
                "sourceResourceLabel": groupLabel,
                "position": {
                    "unit": "INCHES",
                    "x": parseInt(positionXyz.x),
                    "y": parseInt(positionXyz.y),
                    "z": parseInt(positionXyz.z)
                }
            }]
        },
        "assetMappings": [{
            "mappedResourceLabel": groupLabel,
            "mappedResourceType": "AR_GROUP",
            "mappingAttributes": {
                "role": "default"
            }
        }]
    };
}

function Spotlight(props) {
    const spotlightAttributes = props.spotlightDetails.assetDetails.assetAttributes?
        props.spotlightDetails.assetDetails.assetAttributes: SPOTLIGHT_DEFAULT_ATTRIBUTES;
    let [spotlightId, setSpotlightId] = useState(spotlightAttributes.name);
    let [mac, setMac] = useState(spotlightAttributes.macAddress);
    let [universe, setUniverse] = useState(spotlightAttributes.universe);
    let [enabled, setEnabled] = useState(spotlightAttributes.enabled==='true');
    let [startChannelOffset, setStartChannelOffset] = useState(spotlightAttributes.startChannelOffset);
    const [rotationXyz, setRotationXyz] = useState(JSON.parse(spotlightAttributes.eulerAngles));
    const positionXyzInitialValues = props.spotlightDetails.assetDetails.relativePositions.length>0?
        props.spotlightDetails.assetDetails.relativePositions[0].position : {x:"0", y:"0", z:"101"}
    const [positionXyz, setPositionXyz] = useState(
        {
                x: positionXyzInitialValues.x,
                y: positionXyzInitialValues.y,
                z: positionXyzInitialValues.z
            }
        );
    const spotlightActive = props.spotlightDetails.assetDetails.assetStatus==='ACTIVE';
    const areInputsValid = isSpotlightValid(spotlightId, mac, universe, startChannelOffset, positionXyz, rotationXyz);

    const [saveEnabled, setSaveEnabled] = useState(areInputsValid);
    const saveSpotlight = useCallback(() => {
        const previousValue = saveEnabled;
        setSaveEnabled(false);
        const updatedAsset = createSpotLightAsset(spotlightId,mac,startChannelOffset,universe,enabled,positionXyz,
            rotationXyz, props.groupLabel, props.spotlightDetails.assetDetails.nodeId,
            props.spotlightDetails.assetDetails.assetLabel);
        updateAsset(updatedAsset).then(()=>{
            console.log('completed');
        }).catch((error) => {
            console.error(error);
        }).finally(()=>{
            setSaveEnabled(previousValue);
        });
    }, [saveEnabled, spotlightId, mac, startChannelOffset, universe, positionXyz, rotationXyz, enabled]);
    const toggleSpotlightState = useCallback(() => {
        setAssetStatus(props.spotlightDetails.assetDetails.assetLabel, spotlightActive? "INACTIVE":"ACTIVE")
        .then(()=>{
            console.log('completed');
        }).catch((error) => {
            console.error(error);
        }).finally(()=>{
            props.reloadCallBack(true);
        });
    }, [spotlightActive]);
    return <Box type="outline" spacingInset="medium" className="margin-top-10px">
        <Row width="100%" widths={["fill","fit"]} className="margin-top-10px">
            <Box>
                <Tag type={enabled?"success":"error"}>{enabled?"Active":"Inactive"}</Tag>
            </Box>
            <Box>
                <Toggle checked={enabled} onChange={setEnabled}/>
            </Box>
        </Row>
        <Row width="100%" widths={["fill","fill"]} className="margin-top-10px">
            <Input value={spotlightId} onChange={setSpotlightId} label="Id"
                   type="text" placeholder="Enter value..." size="small" width="small"/>
            <Input value={mac} onChange={setMac} label="Mac Address" disabled={true}
                   type="text" placeholder="Enter value..." size="small" width="small"/>
        </Row>
        <Row width="100%" widths={["fill","fill"]} className="margin-top-10px">
            <Input value={universe} onChange={setUniverse} label="Universe"
                   type="number" placeholder="0" size="small" width="small"/>
            <Input value={startChannelOffset} onChange={setStartChannelOffset} label="Start Channel Offset"
                   type="number" placeholder="0" size="small" width="small"/>
        </Row>
        <Row width="100%" widths="fill" className="margin-top-10px">
            <PositionInput label="Position:" xyz={positionXyz} setXyz={setPositionXyz}/>
            <PositionInput label="Rotation:" xyz={rotationXyz} setXyz={setRotationXyz}/>
        </Row>
        <Row className="margin-top-10px" width="100%" widths={["fit","fill","fit"]}>
            <Button type="tertiary" onClick={toggleSpotlightState}>{spotlightActive?"Delete":"Activate"}</Button>
            <Box/>
            <Button type="primary" disabled={!areInputsValid} onClick={saveSpotlight}> Save</Button>
        </Row>
    </Box>;
}

export default function SpotlightGroup(props) {
    const dispatch = useContext(BinTestModalContext).dispatch;
    const nodeId = getNodeId();
    const [newSpotlightMac, setNewSpotlightMac] = useState('');
    const [reload, setReload] = useState(false);
    const fetchSpotlights = useCallback(async () => {
        const assetsResponse = await getAssetsByMappedResource(nodeId, props.groupLabel);
        const assets = assetsResponse.data.mappedAssets
            .filter((asset) => asset.assetDetails.assetType === ASSET_TYPES.SPOTLIGHT);
        const spotlights = assets
            .map((spotlight) => {
                return {
                    id: spotlight.assetDetails.assetLabel.value,
                    name: spotlight.assetDetails.assetAttributes.name
                }
            });
        dispatch({type: LOAD_SPOTLIGHT_FOR_GROUP, payload: {groupLabel: props.groupLabel, spotlights:spotlights}});
        setReload(false);
        return assets;
    },[reload]);

    const onAddSpotlight = useCallback(async (newMac) => {
        const newAsset = createSpotLightAsset(`${props.groupLabel}-S-1`,newMac,"0","0",
                true,{x:0,y:0,z:101},{x:0,y:180,z:270}
                ,props.groupLabel, nodeId, {"type":"MAC_ADDRESS","value":newMac});
        const response = await addAsset(newAsset);
        if(response.status === 500) {
            // asset already exists so trying to update it
            await setAssetStatus(newAsset.assetDetails.assetLabel,'ACTIVE');
            await updateAsset(newAsset);
            setReload(true);
        }
    }, [reload]);
    const [response, loading] = useFetch(fetchSpotlights);
    if (loading) {
        return <Loading/>;
    } else if (response) {
        return (<Box>
            <Row width="100%" widths={["fit","fill","fit"]}>
                <Heading level={5}>Spotlights</Heading>
                <Box/>
                <Input value={newSpotlightMac} onChange={setNewSpotlightMac}
                        mask={MAC_MASK}/>
                <Button type="tertiary" disabled={response.length>=1 || !newSpotlightMac}
                        onClick={()=>onAddSpotlight(newSpotlightMac)}>Add Spotlight</Button>
            </Row>
            {
                response.map((spotlight, index) => {
                   return <Spotlight key={index} groupLabel={props.groupLabel}
                                     spotlightDetails={spotlight} reloadCallBack={setReload}/>;
                })
            }
        </Box>);
    } else {
        return <ErrorPage error = "error.getResourceGroups" />;
    }
}
