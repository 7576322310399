/**
 * Resources file for Employee Management.
 */
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_USER,
    ENDPOINT_REGISTER_EMPLOYEE,
    ENDPOINT_SEARCH_EMPLOYEES_IN_TCP,
    ENDPOINT_GET_TOOLS_LISTS
} from "../Constants";
import {createRequest} from "../Utility";
export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN";
export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS";
export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE";
export const EDIT_REGISTER_EMPLOYEE_ROLE = "EDIT_REGISTER_EMPLOYEE_ROLE";
export const EDIT_REGISTER_EMPLOYEE_LOGIN = "EDIT_REGISTER_EMPLOYEE_LOGIN";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE";
export const SET_REGISTER_EMPLOYEEE_MODAL_STATE = "SET_REGISTER_EMPLOYEEE_MODAL_STATE";
export const GET_TOOLS_LIST_CALL_BEGIN = "GET_TOOLS_LIST_CALL_BEGIN";
export const GET_TOOLS_LIST_CALL_SUCCESS = "GET_TOOLS_LIST_CALL_SUCCESS";
export const GET_TOOLS_LIST_CALL_FAILURE = "GET_TOOLS_LIST_CALL_FAILURE";
export const CLEAR_STATE = "CLEAR_STATE";
const AMAZON_SUFFIX = "@amazon.com";

/**
 * Gets the Employee Details from TCP via backend call and check if user has correct roles from TCP.
 */
export async function getEmployeeDetailsFromTCP(login, tcpRole) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_SEARCH_EMPLOYEES_IN_TCP, login);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_SEARCH_EMPLOYEES_IN_TCP);
    return parseGetEmployeeDetailsFromTCPResponse(response.data, tcpRole);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{flatEmployeeList: []}}
 */
function parseGetEmployeeDetailsFromTCPResponse(responseData, tcpRole) {
    // get list of flat employees
    let employeeDetailsResponse = {};
    employeeDetailsResponse.customerId = responseData.customerId;
    employeeDetailsResponse.name = responseData.name;
    employeeDetailsResponse.roles = flattenUserRoles(responseData.roles);
    employeeDetailsResponse.email = responseData.realm;

    if(tcpRole!=="" && !employeeDetailsResponse.roles.find(role =>role.includes(tcpRole))) {
        return Promise.reject({
            response : {
                data : {
                    message : "register_employee.incorrect_role"
                }
            }
        });
    }
    return employeeDetailsResponse;



}

function flattenUserRoles(userRolesList) {
    let flatUserRolesList = [];
    for (let userRole of userRolesList) {
        flatUserRolesList.push(userRole);
    }
    return flatUserRolesList;
}

/**
 * Registers employee in CMDS.
 */
export async function registerEmployee(registerEmployeeDetails) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_REGISTER_EMPLOYEE, registerEmployeeDetails);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_REGISTER_EMPLOYEE);
    return response.data;
}

/**
 * Register employee in CMDS.
 */
export function registerEmployeeInCMDS(dispatch, registerEmployeeDetails) {

    dispatch(registerEmployeeDataInCMDSCallBegin());
    registerEmployee(registerEmployeeDetails).then(response => {
        if (response === true) {
            dispatch(registerEmployeeDataInCMDSCallSuccess())
        } else {
            dispatch(registerEmployeeDataInCMDSCallFailure('register_employee.user_already_exists_in_comp'))
        }
    });
}

/**
 * Get tools list.
 */
export async function getTools() {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_TOOLS_LISTS, "");
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_TOOLS_LISTS);
    return parseGetToolsResponse(response.data);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{flatEmployeeList: []}}
 */
function parseGetToolsResponse(responseData) {

    const flatToolsList = [];
    for (let tool of responseData) {
        flatToolsList.push(createFlatToolObject(tool));
    }
    return flatToolsList;
}

/**
 * Creates a flat structure station object expected by React component.
 *
 * @param tool object.
 * @returns flat structure object
 */
function createFlatToolObject(tool) {
    let flatTool = {};
    flatTool.toolName = tool.toolName;
    flatTool.role = tool.role;
    flatTool.tcpRole = tool.tcpRole;
    return flatTool;
}

/**
 * Get Tools List.
 */
export function fetchToolsList(dispatch) {
    dispatch(fetchToolsListCallBegin());
    getTools().then(toolToRoleMapping =>
        dispatch(fetchToolsListCallSuccess(toolToRoleMapping))
    ).catch (error =>
        dispatch(fetchToolsListCallFailure(error.response))
    );
}

/**
 * Gets the Employee Details from TCP via backend call and trigger actions based when call is started and
 * when it ended.
 */
export function fetchEmployeeDetailsFromTCP(dispatch, login, tcpRole) {

    if(!login.includes(AMAZON_SUFFIX)) {
        login += AMAZON_SUFFIX;
    }
    dispatch(fetchEmployeeDetailsFromTCPCallBegin());
    getEmployeeDetailsFromTCP(login, tcpRole).then(employeeDetailsFromTCPJson =>
        dispatch(fetchEmployeeDetailsFromTCPSuccess(employeeDetailsFromTCPJson)))
    .catch (error =>
        dispatch(fetchEmployeeDetailsFromTCPFailure('register_employee.user_not_found_in_tcp'))
    );
}

/**
 * Action for  get Employee Details from TCP call begin
 */
export const fetchEmployeeDetailsFromTCPCallBegin = () => ({
    type: GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN
});

/**
 * Action for changing employee role
 */
export const editRegisterEmployeeRole = (value) => ({
    type: EDIT_REGISTER_EMPLOYEE_ROLE,
    value
});

/**
 * Action for changing employee role
 */
export const editRegisterEmployeeLogin = (value) => ({
    type: EDIT_REGISTER_EMPLOYEE_LOGIN,
    value
});

/**
 * Get Tools call begin.
 */
export const fetchToolsListCallBegin = () => ({
    type: GET_TOOLS_LIST_CALL_BEGIN
});

/**
 * Get Tools call success.
 */
export const fetchToolsListCallSuccess = (toolToRoleMapping) => ({
    type: GET_TOOLS_LIST_CALL_SUCCESS,
    payload: {
        toolToRoleMapping
    }
});

/**
 * Get Tools call failure
 */
export const fetchToolsListCallFailure = (error) => ({
    type: GET_TOOLS_LIST_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for get Employee Details from TCP call Success
 */
export const fetchEmployeeDetailsFromTCPSuccess = (employeeDetailsFromTCP) => ({
    type: GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS,
    payload: {
        employeeDetailsFromTCP
    }
});

/**
 * Action for get Employee Details from TCP call failure.
 */
export const fetchEmployeeDetailsFromTCPFailure = error => ({
    type: GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for register employe begin
 */
export const registerEmployeeDataInCMDSCallBegin = () => ({
    type: REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN
});

/**
 * Clears the state for first time load.
 */
export const clearState = () => ({
    type: CLEAR_STATE
});

/**
 * Action for register employe success
 */
export const registerEmployeeDataInCMDSCallSuccess = () => ({
    type: REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS
});

/**
 * Action for register employe failure.
 */
export const registerEmployeeDataInCMDSCallFailure = error => ({
    type: REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for setting register employee modal state
 */
export const setRegisterEmployeeModalState = value => ({
    type: SET_REGISTER_EMPLOYEEE_MODAL_STATE,
    value
});
