import React, {Component} from 'react';
import {publishCount} from "../../MetricUtility";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        const metricProcessName = "SCC_NARC";
        const metricName = "clientError";
        publishCount(metricProcessName, metricName);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage error="narc.error" errorMessage="Some error occurred"/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;