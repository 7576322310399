import React, {useEffect, useContext} from 'react';
import Row from "@meridian/components/row";
import Column from "@meridian/components/column";
import Box from "@meridian/components/box";
import Button from "@meridian/components/button"
import { FormattedMessage } from 'react-intl';
import RadioButton from "@meridian/components/radio-button";
import Table, { TableRow, TableCell } from "@meridian/components/table";
import Text from "@meridian/components/text";
import '../css/StationManagement.css';
import Select, { SelectOption } from "@meridian/components/select";
import { useHistory } from "react-router-dom";
import ErrorPage from '../components/util/ErrorPage';
import Loading from '../components/util/Loading';
import SearchField from "@meridian/components/search-field"
import {EmployeeManagementContext} from "../context/EmployeeManagementContext";
import Banner from "./Banner";
import {
    employeeDetailsByCriteriaCallBegin,
    getAllEmployeeDetails,
    fetchEmployeeDetailsByCriteria,
    fetchAndUpdateEmployeeDetailsByCriteria,
    fetchEmployeeDetailsByCriteriaSuccess,
    fetchEmployeeDetailsByCriteriaFailure,
    getAllChildrenStation,
    fetchAllChildrenStationsSuccess,
    fetchAllChildrenStationsFailure,
    clearState,
    setSelectedEmployeeId,
    updateEmployeeSearchFilter
} from "../resources/EmployeeManagementResource";

const EmployeeManagement = () => {

    const {state, dispatch} = useContext(EmployeeManagementContext);

    useEffect(() => {
        dispatch(clearState());
        dispatch(employeeDetailsByCriteriaCallBegin());
        getAllEmployeeDetails(state.employeeSearchFilters).then(employeeDetailsJson => {
            dispatch(fetchEmployeeDetailsByCriteriaSuccess(employeeDetailsJson));
            getAllChildrenStation({}).then(childStationList => {
                dispatch(fetchAllChildrenStationsSuccess(childStationList));
            })
            .catch(error =>
                dispatch(fetchAllChildrenStationsFailure(error.response))
            );
        }).catch(error => {
            dispatch(fetchEmployeeDetailsByCriteriaFailure(error.response));
        });
    },[]);

    const fetchStationCodeFromStationList = (childStationList, employee) => {
        var station = childStationList.find(station => station.stationId === employee.station);
        return station ? station.stationCode : "";
    };

    if (state.error) {
        return <ErrorPage error={state.error.data && state.error.data.message ? state.error.data.message : "generic.error"} />
    }

    if (state.loading) {
        return (
            <div className="Employee">
                <Loading />
            </div>
        );
    }
    let history = useHistory();
    let allEmployeeDetails = state.items;
    let childStationList = state.childStationList;
    if (allEmployeeDetails && childStationList) {
        return (
            <div>
                <Banner message="banner.employee_page"/>
                <Row widths={["grid-9", "grid-3"]}
                     wrap="down"
                     alignment="top right"
                     spacingInset="large">
                    <Column>
                        <Box
                            type="outline"
                            spacingInset="small"
                            height={550}
                            className="OverflowYAuto"
                        >
                            <Table
                                headerRows={2}
                                showDividers={true}
                                fixHeaderRows={true}
                                spacing="small">
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell><FormattedMessage id="employee.name" defaultMessage="Name" /></TableCell>
                                    <TableCell><FormattedMessage id="employee.station" defaultMessage="Station" /></TableCell>
                                    <TableCell><FormattedMessage id="employee.is_da" defaultMessage="Is DA" /></TableCell>
                                    <TableCell><FormattedMessage id="employee.role" defaultMessage="Role" /></TableCell>
                                    <TableCell><FormattedMessage id="employee.login" defaultMessage="Login" /></TableCell>
                                    {state.realm === "INAmazon" ?
                                        <TableCell><FormattedMessage id="employee.store_id" defaultMessage="Store Id" /></TableCell>
                                        : <div style={{ display: 'none' }}/>}
                                    <TableCell><FormattedMessage id="employee.status" defaultMessage="Status" /></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <SearchField
                                            size="small"
                                            value={state.employeeSearchFilters.employeeName}
                                            onChange={(searchText) => { dispatch(updateEmployeeSearchFilter("employeeName", searchText)) }}
                                            onSubmit={(name) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch, "employeeName", name, state.employeeSearchFilters) }}
                                        >
                                        </SearchField>
                                    </TableCell>

                                    <TableCell>
                                        <Select
                                            size="small"
                                            value={state.employeeSearchFilters.station}
                                            popoverPosition="adjacent"
                                            onChange={(stationCode) => {
                                                fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"station", stationCode,
                                                    state.employeeSearchFilters)
                                            }}
                                        >
                                            {childStationList.map(station =>
                                                <SelectOption value={station.stationCode} key={station.stationCode} label={station.stationCode} />
                                            )}
                                        </Select>
                                    </TableCell>

                                    <TableCell>
                                        <Select
                                            size="small"
                                            value={state.employeeSearchFilters.isDa}
                                            popoverPosition="adjacent"
                                            onChange={(isDa) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"isDa", isDa, state.employeeSearchFilters) }}
                                        >
                                            <SelectOption value="" label="All" />
                                            <SelectOption value="true" label="Yes" />
                                            <SelectOption value="false" label="No" />
                                        </Select>
                                    </TableCell>

                                    <TableCell>
                                        <Select
                                            size="small"
                                            value={state.employeeSearchFilters.role}
                                            popoverPosition="adjacent"
                                            onChange={(role) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"role", role, state.employeeSearchFilters) }}
                                        >
                                            <SelectOption value="" label="All" />
                                            {allEmployeeDetails.roleList.map(role =>
                                                <SelectOption value={role} label={role} />
                                            )}
                                        </Select>
                                    </TableCell>

                                    <TableCell>
                                        <SearchField
                                            size="small"
                                            value={state.employeeSearchFilters.login}
                                            onChange={(searchText) => { dispatch(updateEmployeeSearchFilter("login", searchText)) }}
                                            onSubmit={(login) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"login", login, state.employeeSearchFilters) }}
                                        >
                                        </SearchField>
                                    </TableCell>

                                    {state.realm === "INAmazon" ?
                                        <TableCell>
                                            <SearchField
                                                size="small"
                                                value={state.employeeSearchFilters.storeId}
                                                onChange={(searchText) => { dispatch(updateEmployeeSearchFilter("storeId", searchText)) }}
                                                onSubmit={(storeId) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"storeId", storeId, state.employeeSearchFilters) }}
                                            >
                                            </SearchField>
                                        </TableCell>
                                        : <div style={{ display: 'none' }}/>}

                                    <TableCell>
                                        <Select
                                            size="small"
                                            value={state.employeeSearchFilters.isActive}
                                            popoverPosition="adjacent"
                                            onChange={(isActive) => { fetchAndUpdateEmployeeDetailsByCriteria(dispatch,"isActive", isActive, state.employeeSearchFilters) }}
                                        >
                                            <SelectOption value="" label="All" />
                                            <SelectOption value="true" label="Active" />
                                            <SelectOption value="false" label="InActive" />
                                        </Select>
                                    </TableCell>
                                </TableRow>

                                {allEmployeeDetails.employeeList.map((employee => (
                                    <TableRow onClick={() => {
                                        dispatch(setSelectedEmployeeId(employee.id))
                                    }} key={employee.id}
                                              highlightOnHover={true}>
                                        <TableCell><RadioButton checked={state.selectedEmployeeId === employee.id} name="color"
                                                                value="blue" children="">
                                        </RadioButton></TableCell>
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.name ? employee.name : ""}
                                            </Text></TableCell>
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.station ? fetchStationCodeFromStationList(childStationList, employee) : ""}
                                            </Text></TableCell>
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.deliveryAssociate ? "Yes" : "No"}
                                            </Text></TableCell>
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.userRole ? employee.userRole : ""
                                                }</Text></TableCell>
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.externalCode ? employee.externalCode : ""}
                                            </Text></TableCell>
                                        {state.realm === "INAmazon" ?
                                            <TableCell>
                                                <Text alignment="center" type="b100">
                                                    {employee.storeId ? employee.storeId : ""}
                                                </Text></TableCell>
                                            : <div style={{ display: 'none' }}/>}
                                        <TableCell>
                                            <Text alignment="center" type="b100">
                                                {employee.active ? "Active" : "InActive"}
                                            </Text></TableCell>
                                    </TableRow>
                                )))}
                            </Table>
                        </Box>

                        <Button type="link" disabled={state.employeeSearchFilters &&
                        state.employeeSearchFilters.nextToken === null}
                                onClick={() => { fetchEmployeeDetailsByCriteria(dispatch, state.employeeSearchFilters) }}>
                            <FormattedMessage id="employee.next_page" defaultMessage="Next Page" />
                        </Button>
                    </Column>
                    <Box
                        type="outline"
                        spacingInset="small"
                        height={550}
                    >
                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center left"
                            spacingInset="medium"
                        >

                            <Button disabled={state.selectedEmployeeId === ""} onClick={() => {
                                history.push({
                                    pathname: '/employeeForm',
                                    state: { employeeId: state.selectedEmployeeId,
                                            employeeRoles: state.employeeRoles}
                                });
                            }}>
                                <FormattedMessage id="employee.view_edit_employee" defaultMessage="View/Edit Employee">
                                </FormattedMessage>
                            </Button>
                        </Row>
                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center left"
                            spacingInset="medium"
                        >
                            {state.employeeRoles && state.employeeRoles.includes("ADMIN") ?
                                <Button type="tertiary" onClick={() => {
                                    history.push({pathname: '/employeeRegister'})
                                }}>
                                    <FormattedMessage id="employee.add_employee" defaultMessage="Add Employee">
                                    </FormattedMessage>
                                </Button>
                                :""}
                        </Row>

                    </Box>
                </Row>
            </div>
        )
    }
};

export default EmployeeManagement