import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    ENDPOINT_ADD_ASSET,
    ENDPOINT_UPDATE_ASSET,
    ENDPOINT_GET_ASSETS_BY_MAPPED_RESOURCE,
    ENDPOINT_SET_ASSET_STATUS,
    ENDPOINT_GET_ASSET_BY_ASSET_LABEL,
    PROCESS_NODE_RESOURCE
} from "../Constants";
import {createRequest} from "../Utility";

/**
 * Adds asset by calling appropriate end point.
 *
 * @param asset
 * @returns {Promise<*>}
 */
export async function addAsset(asset) {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_ADD_ASSET, {asset: asset});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_ADD_ASSET);
}

/**
 * Updates asset by calling appropriate end point.
 *
 * @param asset
 * @returns {Promise<*>}
 */
export async function updateAsset(asset) {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_ASSET, {asset: asset});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_ASSET);
}

/**
 * Deprecate asset by calling appropriate end point.
 *
 * @param assetLabel
 * @param status
 * @returns {Promise<*>}
 */
export async function setAssetStatus(assetLabel, status) {
    const requestBody = {
        assetLabel: assetLabel,
        assetStatus: status
    }
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_SET_ASSET_STATUS, requestBody);
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_ADD_ASSET);
}

/**
 * Fetches assets by calling appropriate end point.
 *
 * @param nodeId
 * @param mappedResourceLabel
 * @returns {Promise<*>}
 */
export async function getAssetsByMappedResource(nodeId, mappedResourceLabel) {
    const requestBody = {
        nodeId: nodeId,
        mappedResourceLabel: mappedResourceLabel
    };
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_ASSETS_BY_MAPPED_RESOURCE, requestBody);
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_ASSETS_BY_MAPPED_RESOURCE);
}

/**
 * Fetches an asset by calling appropriate end point.
 *
 * @param assetLabel
 * @returns {Promise<*>}
 */
export async function getAssetByAssetLabel(assetLabel) {
    const requestBody = {
        assetLabel: assetLabel
    };
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_ASSET_BY_ASSET_LABEL, requestBody);
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_ASSET_BY_ASSET_LABEL);
}
