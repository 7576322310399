import React, {createContext, useReducer} from "react"
import {NodeManagerReducer} from "../reducers/NodeManagerReducer";
import {
    DEFAULT_CURRENT_PAGE_INDEX,
    DEFAULT_NUM_PAGES,
    LOADER_TYPE,
    NODE_RESOURCES_TABS,
    STRINGS
} from "../Constants";

export const NodeResourceContext = createContext()

const NodeResourceContextProvider = (props) => {
    const initialState = {
        loading: true,
        resourceList: [],
        displayFields: [],
        resourceSearchCriteria: {},
        resourceTypes: [],
        viewResourceModalState: false,
        currentResource: {},
        resourcesByType : [],
        updateResourceModalState: false,
        deprecateResourceModalState: false,
        currentNodeId: STRINGS.EMPTY,
        countryCode: STRINGS.EMPTY,
        bulkResourceCreationModalState: false,
        uploadBulkResourceSuccess: false,
        resourceDataMap: {},
        resourceNames:[],
        activeTab: "",
        uploadBulkResourceError: STRINGS.EMPTY,
        currentTab: NODE_RESOURCES_TABS.RESOURCES_TAB,
        narc_sls_intergration_enabled: false,
        isNARCTCPAuthorizationCheckEnabled: false,
        childResource: {},
        updateResourceError: false,
        updateType: "",
        uploadType: "",
        isValidEditModal: true,
        isValidEditModalMessage: "",
        isAuthorizedUser: false,
        validateAndDownloadResourceModalState: false,
        validateAndDownloadResourceError: "",
        aisleResources: [],
        aisleToStagingZoneDistanceResources: [],
        aisleToStagingZoneDistanceValidationEnabled: false,
        currentPageIndexInBulkUploadPreview: DEFAULT_CURRENT_PAGE_INDEX,
        numPagesInBulkUploadPreview: DEFAULT_NUM_PAGES,
        loaderType : LOADER_TYPE.PREVIEW,
        isOVDFeatureEnabled : false,
        isPaginationFixEnabled: false,
        isBulkUploadReaderUsingXLSXReaderEnabled: false,
        isResourceRevivalUsingAddBulkResourcesAPIEnabled: false,
        isSSDMMOTEnabled : false
    }

    const [ state, dispatch ] = useReducer(NodeManagerReducer, initialState)
    return (
        <NodeResourceContext.Provider value = {{state, dispatch}}>
            {props.children}
        </NodeResourceContext.Provider>
    )
}

export default NodeResourceContextProvider
