import React, {createContext, useReducer} from "react"
import {RegisterEmployeeDataReducer} from "../reducers/RegisterEmployeeReducer";

export const RegisterEmployeeContext = createContext()

const RegisterEmployeeContextProvider = (props) => {
    /**
     * Initial state of the employee management.
     */
    const initialState = {
        employeeDataFromTCP: {},
        registerEmployeeDetails: {
            login: "",
            role: "",
            customerId:""
        },
        toolToRoleMapping: [],
        selectedRoleIndex:"",
        registerEmployeeModalState: false,
        searchEmployeeSuccess: false,
        loading: false,
        error: null,
        searchEmployeeError: null,
        registerEmployeeError: null
    };
    const [ state, dispatch ] = useReducer(RegisterEmployeeDataReducer, initialState)
    return (
        <RegisterEmployeeContext.Provider value = {{state, dispatch}}>
            {props.children}
        </RegisterEmployeeContext.Provider>
    )
}

export default RegisterEmployeeContextProvider
