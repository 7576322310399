import React, {Component} from "react";
import EmployeeFormManagementContextProvider from "../../context/EmployeeFormManagementContext";
import EmployeeFormManagement from "../EmployeeFormManagement";

class EmployeeFormManager extends Component {
    render() {
        return (
            <EmployeeFormManagementContextProvider>
                <EmployeeFormManagement/>
            </EmployeeFormManagementContextProvider>
        )
    }
}

export default EmployeeFormManager
