import React, {useEffect, useState} from "react";
import { Switch } from 'react-router-dom';

import Tab, {TabGroup} from "@amzn/meridian/tab";
import {FormattedMessage} from "react-intl";
import Column from "@amzn/meridian/column";
import {SORT_PATH_ROUTES} from "../../commons/constants/RouteConstants";
import {NARCRoute} from "../util/NARCRoute";
import UploadedSortPaths from "./UploadedSortPaths";
import SortGridAllocationView from "./SortGridAllocationView";
import Text from "@amzn/meridian/text";
import {FeatureConfigUtils} from "../util/FeatureConfigUtils";

const SortPathTabs = (props) => {
    const [currentTab, setCurrentTab] = useState("sortPathGridAllocation");
    const [isSSD3Station] = useState(FeatureConfigUtils.getIsSSD3EnabledStation());

    const onTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
        props.history.push(SORT_PATH_ROUTES[selectedTab].path);
    };

    useEffect(()=> {
        Object.keys(SORT_PATH_ROUTES).some((key) => {
            if(props.location.pathname.toString() === SORT_PATH_ROUTES[key].path) {
                setCurrentTab(key);
                return true;
            }
            return false;
        });
    },[props.location.pathname]);

    //SSD3 stations will be using system grid allocation UI only for creating/updating sort paths. So, not showing Uploaded
    //paths UI in such cases.
    return (
        <Column spacing={"small"} spacingInset={"medium large"} >
            <TabGroup value={currentTab} onChange={onTabChange} fill="line">
                <Tab value={"sortPathGridAllocation"}>
                    <Text type="b500">
                        <FormattedMessage id="sortPaths.systemGeneratedGridAllocation" defaultMessage="System generated grid allocation"/>
                    </Text>
                </Tab>

                {!isSSD3Station &&
                    <Tab value={"uploadedSortPaths"}>
                        <Text type="b500">
                            <FormattedMessage id="sortPaths.uploadedPaths" defaultMessage="Uploaded paths"/>
                        </Text>
                    </Tab>
                }
            </TabGroup>
            <Switch>
                <NARCRoute exact path={SORT_PATH_ROUTES.sortPathGridAllocation.path} component={SortGridAllocationView} />
                {!isSSD3Station && <NARCRoute exact path={SORT_PATH_ROUTES.uploadedSortPaths.path} component={UploadedSortPaths} />}
            </Switch>
        </Column>
    )
};

export default SortPathTabs;
