/**
 * Resources file for Station Management.
 */
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_USER,
    ENDPOINT_GET_STATION_BY_CRITERIA
} from "../Constants";
import {createRequest} from "../Utility";
export const GET_STATION_DATA_CALL_BEGIN = "GET_STATION_DATA_CALL_BEGIN";
export const GET_STATION_DATA_SUCCESS = "GET_STATION_DATA_SUCCESS";
export const GET_STATION_DATA_CALL_FAILURE = "GET_STATION_DATA_CALL_FAILURE";
export const UPDATE_SEARCH_FILTERS = "UPDATE_SEARCH_FILTERS";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_SELECTED_STATION = "SET_SELECTED_STATION";

/**
 * Gets the Station Data via API Call.
 */
export async function getStationData(stationSearchCriteria) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CRITERIA, stationSearchCriteria);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CRITERIA);
    return parseGetStationDataResponse(response.data);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{binTypes: []}}
 */
function parseGetStationDataResponse(responseData) {
    let flatStations = [];
    for (let station of responseData) {
        const flatStation = createFlatStationObject(station);
        flatStations.push(flatStation);
    }
    return flatStations;
}

/**
 * Creates a flat structure station object expected by React component.
 *
 * @param station binType object.
 * @returns flat structure binType object
 */
function createFlatStationObject(station) {
    let flatStation = {};
    flatStation.stationCode = station.stationCode;
    flatStation.stationName = station.stationName;
    flatStation.city = station.city;
    flatStation.active = station.isActive;
    flatStation.parentStation = station.parentStation;
    flatStation.timeZone = station.timeZone;
    return flatStation;
}

/**
 * Action for Get Station Data call begin
 */
export const getStationDataCallBegin = () => ({
    type: GET_STATION_DATA_CALL_BEGIN
});

/**
 * Action for get station Data call Success
 */
export const getStationDataSuccess = stationData => ({
    type: GET_STATION_DATA_SUCCESS,
    payload: {
        stationData
    }
});

/**
 * Action for get Station Data call failure
 */
export const getStationDataCallFailure = error => ({
    type: GET_STATION_DATA_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for updating Station Management filters.
 */
export const updateStationFilters = (key, value) => ({
    type: UPDATE_SEARCH_FILTERS,
    key,
    value
});

/**
 * Action for updating visible Stations as per page size and current page number.
 */
export const setPageNumber = (pageNumber) => ({
    type: SET_PAGE_NUMBER,
    pageNumber
});

/**
 * Action for updating visible Stations as per page size and current page number.
 */
export const setSelectedStation = (stationCode) => ({
    type: SET_SELECTED_STATION,
    stationCode
});