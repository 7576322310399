import React, {createContext, useReducer} from "react"
import {EmployeeManagementDataReducer} from "../reducers/EmployeeManagementReducer";

export const EmployeeManagementContext = createContext()

const EmployeeManagementContextProvider = (props) => {
    /**
     * Initial state of the employee management.
     */
    const initialState = {
        items: {
            employeeList: [],
            roleList: []
        },
        employeeSearchFilters: {
            employeeName: "",
            station: "",
            isDa: "false",
            role: "",
            login: "",
            storeId: "",
            isActive: "true",
            nextToken: null
        },
        selectedEmployeeId: "",
        childStationList: [],
        loading: false,
        error: null,
        userRoles: [],
        realm: "",
        employeeRoles: []
    };
    const [ state, dispatch ] = useReducer(EmployeeManagementDataReducer, initialState)
    return (
        <EmployeeManagementContext.Provider value = {{state, dispatch}}>
            {props.children}
        </EmployeeManagementContext.Provider>
    )
}

export default EmployeeManagementContextProvider
