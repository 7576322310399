
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const LOAD_SPOTLIGHT_FOR_GROUP = "LOAD_SPOTLIGHT_FOR_GROUP";
export const LOAD_CART_RECIPE_FOR_TYPE = "LOAD_CART_RECIPE_FOR_TYPE";
export const LOAD_TO_CART_CLIPBOARD = "LOAD_TO_CART_CLIPBOARD";

export const BinTestModalReducer = (state, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                openState: true,
                testGroupLabel: action.payload.groupLabel,
                testCartLabel: action.payload.cartLabel,
                testCartType: action.payload.cartType
            };
        case CLOSE_MODAL:
            return {
                ...state,
                openState: false
            };
        case LOAD_SPOTLIGHT_FOR_GROUP:
            const newSpotlightGroup = {};
            newSpotlightGroup[action.payload.groupLabel] = action.payload.spotlights;
            return {
                ...state,
                spotlights: Object.assign({}, state.spotlights, newSpotlightGroup)
            }
        case LOAD_CART_RECIPE_FOR_TYPE:
            const newCartRecipe = {};
            newCartRecipe[action.payload.cartType] = action.payload.cartRecipe;
            return {
                ...state,
                cartRecipes: Object.assign({}, state.cartRecipes, newCartRecipe)
            }
        case LOAD_TO_CART_CLIPBOARD:
            return {
                ...state,
                cartLocationClipboard: action.payload.cartLocation
            }
        default:
            return state
    }
}
