import React, {createContext, useReducer} from "react"
import {BinTestModalReducer} from "../reducers/BinTestModalReducer";

export const BinTestModalContext = createContext()

const BinTestModalContextProvider = (props) => {
    const [ state, dispatch ] = useReducer(BinTestModalReducer,
        {openState: false, testGroupLabel: null, testCartLabel: null, testCartType: null,
            spotlights: {}, cartRecipes: {}, cartLocationClipboard: null})
    return (
        <BinTestModalContext.Provider value = {{state, dispatch}}>
            {props.children}
        </BinTestModalContext.Provider>
    )
}

export default BinTestModalContextProvider
