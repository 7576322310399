export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN";
export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS";
export const GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE = "GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE";
export const EDIT_REGISTER_EMPLOYEE_ROLE = "EDIT_REGISTER_EMPLOYEE_ROLE";
export const EDIT_REGISTER_EMPLOYEE_LOGIN = "EDIT_REGISTER_EMPLOYEE_LOGIN";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS";
export const REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE = "REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE";
export const SET_REGISTER_EMPLOYEEE_MODAL_STATE = "SET_REGISTER_EMPLOYEEE_MODAL_STATE";
export const GET_TOOLS_LIST_CALL_BEGIN = "GET_TOOLS_LIST_CALL_BEGIN";
export const GET_TOOLS_LIST_CALL_SUCCESS = "GET_TOOLS_LIST_CALL_SUCCESS";
export const GET_TOOLS_LIST_CALL_FAILURE = "GET_TOOLS_LIST_CALL_FAILURE";
export const CLEAR_STATE = "CLEAR_STATE";

/**
 * Reducer function that changes state based on the action triggered.
 */
export const RegisterEmployeeDataReducer = (state,action) => {
    switch (action.type) {

        case GET_EMPLOYEE_DATA_FROM_TCP_CALL_BEGIN:
            return {
                ...state,
                loading: true,
                searchEmployeeError: null,
                registerEmployeeError: null
            };

        case GET_TOOLS_LIST_CALL_BEGIN:
            return {
                ...state,
                loading: true,
                searchEmployeeError: null,
                registerEmployeeError: null
            };

        case GET_EMPLOYEE_DATA_FROM_TCP_CALL_SUCCESS:
            return {
                ...state,
                loading: false,
                registerEmployeeDetails: {
                    ...state.registerEmployeeDetails,
                    customerId: action.payload.employeeDetailsFromTCP.customerId
                },
                employeeDataFromTCP: action.payload.employeeDetailsFromTCP,
                searchEmployeeSuccess: true
            };

        case GET_TOOLS_LIST_CALL_SUCCESS:
            let index = 0;
            let toolsList = action.payload.toolToRoleMapping.map(tool=> ({ ...tool, index: index++ }));
            return {
                ...state,
                loading: false,
                toolToRoleMapping: toolsList
            };

        case GET_TOOLS_LIST_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case GET_EMPLOYEE_DATA_FROM_TCP_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                searchEmployeeError: action.payload.error,
                searchEmployeeSuccess: false
            };

        case REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_BEGIN:
            return {
                ...state,
                loading: true,
                searchEmployeeError: null,
                registerEmployeeError: null
            };

        case REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_SUCCESS:
            return {
                ...state,
                loading: false,
                registerEmployeeModalState: true
            };

        case REGISTER_EMPLOYEE_DATA_IN_CMDS_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                registerEmployeeError: action.payload.error,
                registerEmployeeModalState: true
            };

        case EDIT_REGISTER_EMPLOYEE_ROLE:
            return {
                ...state,
                registerEmployeeDetails: {
                    ...state.registerEmployeeDetails,
                    role: state.toolToRoleMapping[action.value].role
                },
                selectedRoleIndex: action.value
            };

        case EDIT_REGISTER_EMPLOYEE_LOGIN:
            return {
                ...state,
                registerEmployeeDetails: {
                    ...state.registerEmployeeDetails,
                    login: action.value
                }
            };

        case SET_REGISTER_EMPLOYEEE_MODAL_STATE:
            return {
                ...state,
                registerEmployeeModalState: action.value
            };
        case CLEAR_STATE:
            let newState = {
                employeeDataFromTCP: {},
                registerEmployeeDetails: {
                    login: "",
                    role: "",
                    customerId:""
                },
                toolToRoleMapping: [],
                selectedRoleIndex:"",
                registerEmployeeModalState: false,
                searchEmployeeSuccess: false,
                loading: false,
                error: null,
                searchEmployeeError: null,
                registerEmployeeError: null
            };
            newState.toolToRoleMapping = state.toolToRoleMapping;
            return newState;
        default:
            return state;
    }
}