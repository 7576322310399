import React, {useEffect, useContext} from 'react';
import Row from "@meridian/components/row";
import Column from "@meridian/components/column";
import Box from "@meridian/components/box";
import Button from "@meridian/components/button"
import { FormattedMessage } from 'react-intl';
import Text from "@meridian/components/text";
import '../css/StationManagement.css';
import Select, { SelectOption } from "@meridian/components/select";
import { useHistory } from "react-router-dom";
import ErrorPage from '../components/util/ErrorPage';
import Loading from '../components/util/Loading';
import {RegisterEmployeeContext} from "../context/RegisterEmployeeContext";
import Banner from "./Banner";
import {
    fetchEmployeeDetailsFromTCP,
    editRegisterEmployeeLogin,
    editRegisterEmployeeRole,
    registerEmployeeInCMDS,
    clearState,
    setRegisterEmployeeModalState,
    fetchToolsList
} from "../resources/RegisterEmployeeResource";
import Alert from "@meridian/components/alert";
import Link from "@meridian/components/link";
import Modal from "@meridian/components/modal";
import Heading from "@meridian/components/heading";
import Input from "@meridian/components/input";

const RegisterEmployee = () => {

    const {state, dispatch} = useContext(RegisterEmployeeContext);

    useEffect(() => {
        dispatch(clearState());
        fetchToolsList(dispatch);
    },[]);

    let history = useHistory();
    const { searchEmployeeError, registerEmployeeError, loading, registerEmployeeDetails, toolToRoleMapping,
        registerEmployeeModalState, selectedRoleIndex, userRoles, searchEmployeeSuccess, error } = state;
    if (loading) {
        return (
            <div className="RegisterEmployee">
                <Loading />
            </div>
        );
    }
    if (error) {
        return <ErrorPage error = {error.data && error.data.message ? error.data.message : "generic.error"} />
    }
    if (userRoles && !userRoles.includes("ADMIN")) {
        return <ErrorPage error = "generic.user_not_have_admin_access" />
    }
    let searchEmployeeAlertMessage = "";
    if(searchEmployeeError) {
        if(searchEmployeeError ==="register_employee.user_not_found_in_tcp") {
            searchEmployeeAlertMessage = <Row
                widths={"fill"}
                wrap="down"
                alignment="center center"
                spacingInset="small"
            >
                <Alert
                    type="warning"
                    size="large"
                >
                    <b><FormattedMessage id={searchEmployeeError} defaultMessage="User not found in TCP. Please follow the below steps">
                    </FormattedMessage></b>
                </Alert>
                <Text type="b200" alignment="left" tag="ul">
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_1" defaultMessage="Go to TCP portal by clicking ">
                        </FormattedMessage>
                        <Link href="/dashboard/manageUsers" target="_blank">
                            <FormattedMessage id="generic.here" defaultMessage=" here">
                            </FormattedMessage>
                        </Link>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_2" defaultMessage="Make sure you are in your Corresponding Station Account. See the top right corner of the Page, it should show your 'userid - StationCode'">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_3" defaultMessage="Click new user">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_4" defaultMessage="Select account as your Corresponding Station Account">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_5" defaultMessage="Enter Login including @amazon.com">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_6" defaultMessage="Add temporary password and share it with the associate">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.user_not_found_in_tcp_step_7" defaultMessage="Click Submit and ">
                        </FormattedMessage>
                        <Link onClick={() => {fetchEmployeeDetailsFromTCP(dispatch, registerEmployeeDetails.login, toolToRoleMapping[selectedRoleIndex].tcpRole)}}>
                            <FormattedMessage id="generic.try_again" defaultMessage=" try again">
                            </FormattedMessage>
                        </Link>
                    </li>
                </Text>f
            </Row>

        } else if(searchEmployeeError.data && searchEmployeeError.data.message && searchEmployeeError.data.message==="register_employee.incorrect_role") {
            searchEmployeeAlertMessage = <Row
                widths={"fill"}
                wrap="down"
                alignment="center center"
                spacingInset="small"
            >
                <Alert
                    type="warning"
                    size="large"
                >
                    <b><FormattedMessage id={searchEmployeeError.data.message} defaultMessage="User do not have correct role for the requested tool. Please follow the below steps">
                    </FormattedMessage></b>
                </Alert>
                <Text type="b200" alignment="left" tag="ul">
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_1" defaultMessage="Go to TCP portal by clicking ">
                        </FormattedMessage>
                        <Link href="/dashboard/manageUsers" target="_blank">
                            <FormattedMessage id="generic.here" defaultMessage=" here">
                            </FormattedMessage>
                        </Link>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_2" defaultMessage="Make sure you are in your Corresponding Station Account. See the top right corner of the Page, it should show your 'userid - StationCode'">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_3" defaultMessage="Click edit user">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_4" defaultMessage="Select account as your Corresponding Station Account">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_5" defaultMessage="Enter Login with including @amazon.com">
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage id="register_employee.incorrect_role_step_6" defaultMessage="Click Submit and try again">
                        </FormattedMessage>
                        <Link onClick={() => {fetchEmployeeDetailsFromTCP(dispatch, registerEmployeeDetails.login, toolToRoleMapping[selectedRoleIndex].tcpRole)}}>
                            <FormattedMessage id="generic.try_again" defaultMessage=" try again">
                            </FormattedMessage>
                        </Link>
                    </li>
                </Text>
            </Row>

        } else {
            searchEmployeeAlertMessage = <Alert
                type="error"
                size="large"
            >
                <FormattedMessage id={searchEmployeeError.data && searchEmployeeError.data.message ? searchEmployeeError.data.message : "generic.error"} defaultMessage="Some Error Occured">
                </FormattedMessage>
            </Alert>;
        }
    }
    if(searchEmployeeSuccess) {
        searchEmployeeAlertMessage = <Alert
            type="success"
            size="large"
        >
            <b><FormattedMessage id="register_employee.search_employee_success" defaultMessage="User found in TCP and has correct roles for the requested tool. Click on register">
            </FormattedMessage></b>
        </Alert>
    }
    return (
        <div className="RegisterEmployee">
            <Banner message="banner.employee_page"/>
            <Modal
                id="modal-registerEmployee"
                closeLabel="Close"
                open={registerEmployeeModalState}
                describedBy="modal-registerEmployee_desc"
            >
                {registerEmployeeError ?
                    <div id="registerEmployee-modal_desc">
                        <Alert
                            type="error"
                            size="large"
                        >
                            <FormattedMessage id={registerEmployeeError ? registerEmployeeError : "generic.error"} defaultMessage="Some Error Occured">
                            </FormattedMessage>
                        </Alert>
                    </div> :
                    <div id="registerEmployee-modal_desc">
                        <Alert
                            type="success"
                            size="large"
                        >
                            <FormattedMessage id="register_employee.set_employee_success" defaultMessage="Employee has been Added successfully">
                            </FormattedMessage>
                        </Alert>
                    </div>
                }
                <Row alignment="center right" widths="fit">
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => dispatch(setRegisterEmployeeModalState(false))}
                    >
                        <FormattedMessage id="generic.close" defaultMessage="Close">
                        </FormattedMessage>
                    </Button>
                </Row>
            </Modal>
            <Row
                widths={["grid-9", "grid-3"]}
                wrap="down"
                alignment="center left"
                spacingInset="large"
            >
                <Box
                    type="outline"
                    spacingInset="medium"
                    minHeight={650}
                >
                    <Column
                        widths={["grid-12"]}
                        wrap="down"
                        alignment="center stretch"
                        spacingInset="small"
                    >
                        <Row
                            widths={["grid-12"]}
                            wrap="down"
                            alignment="center justify"
                            spacingInset="small"
                        >
                            <Heading
                                level={3}
                            >
                                <FormattedMessage id="register_employee.grant_access" defaultMessage="Grant Access to Station Tech Tools">
                                </FormattedMessage>
                            </Heading>
                        </Row>
                        <Row
                            widths={["grid-5","grid-3"]}
                            wrap="down"
                            alignment="center center"
                            spacingInset="small"
                        >
                            <Input
                                value={registerEmployeeDetails.login}
                                disabled={registerEmployeeDetails.customerId !== ''}
                                type="text"
                                placeholder="Enter Login without @amazon.com"
                                onChange={(value) => {dispatch(editRegisterEmployeeLogin(value))}}
                            />
                            <Select
                                placeholder="Select Tool"
                                popoverPosition="adjacent"
                                value={selectedRoleIndex}
                                onChange={(value) => {dispatch(editRegisterEmployeeRole(value))}}
                            >
                                {toolToRoleMapping.map(tool => (
                                    <SelectOption key={tool.toolName} value={tool.index} label={tool.toolName} />
                                ))}
                            </Select>
                        </Row>
                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center center"
                            spacingInset="small"
                        >
                            {searchEmployeeAlertMessage}
                        </Row>

                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="bottom right"
                            spacingInset="large"
                        >
                            <i>
                                <Text type="b200" alignment="right" >
                                    <FormattedMessage id="register_employee.troubleshooting" defaultMessage="For any queries click ">
                                    </FormattedMessage>
                                    <Link href="https://river.amazon.com/workflow/workflows/run/wfss-10a3921e-2fdc-4e4a-8fad-a95d8b4294da/?buildingType=last_mile&q0=Tools%20Access&q1=SMC%20Add%20Employee&q2=&q3=&q4=" target="_blank">
                                        <FormattedMessage id="generic.here" defaultMessage=" here">
                                        </FormattedMessage>
                                    </Link>
                                </Text>
                            </i>
                        </Row>
                    </Column>
                </Box>
                <Box
                    type="outline"
                    spacingInset="medium"
                    minHeight={650}>
                    <Row
                        widths={"fill"}
                        wrap="down"
                        alignment="center center"
                        spacingInset="medium"
                    >
                        <Button onClick={() => {fetchEmployeeDetailsFromTCP(dispatch, registerEmployeeDetails.login, toolToRoleMapping[selectedRoleIndex].tcpRole)}}
                                disabled={registerEmployeeDetails.login === "" || selectedRoleIndex === ""} >
                            <FormattedMessage id="register_employee.search_employee_in_tcp" defaultMessage="Search Employee In TCP">
                            </FormattedMessage>
                        </Button>
                    </Row>
                    <Row
                        widths={"fill"}
                        wrap="down"
                        alignment="center center"
                        spacingInset="medium"
                    >
                        <Button onClick={() => {registerEmployeeInCMDS(dispatch, registerEmployeeDetails)}} disabled={registerEmployeeDetails.login === "" || selectedRoleIndex === "" || registerEmployeeDetails.customerId === ""} type="tertiary">
                            <FormattedMessage id="register_employee.register_employee" defaultMessage="Register Employee">
                            </FormattedMessage>
                        </Button>
                    </Row>
                    <Row
                        widths={"fill"}
                        wrap="down"
                        alignment="center center"
                        spacingInset="medium"
                    >
                        <Button onClick={() => (history.push("/employee"))} type="tertiary">
                            <FormattedMessage id="generic.back" defaultMessage="Back">
                            </FormattedMessage>
                        </Button>
                    </Row>
                    <Row
                        widths={"fill"}
                        wrap="down"
                        alignment="center center"
                        spacingInset="medium"
                    >
                        <Button onClick={() => dispatch(clearState())} type="tertiary">
                            <FormattedMessage id="generic.clear" defaultMessage="Clear">
                            </FormattedMessage>
                        </Button>
                    </Row>
                </Box>
            </Row>
        </div>
    );
};

export default RegisterEmployee