/**
 * Resources file for Employee Management.
 */
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_USER,
    ENDPOINT_GET_EMPLOYEES_BY_CRITERIA,
    ENDPOINT_GET_STATION_BY_CRITERIA
} from "../Constants";
import {createRequest} from "../Utility";
export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN";
export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS";
export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE";
export const GET_ALL_CHILD_STATION_CALL_SUCCESS = "GET_ALL_CHILD_STATION_CALL_SUCCESS";
export const GET_ALL_CHILD_STATION_CALL_FAILURE = "GET_ALL_CHILD_STATION_CALL_FAILURE";
export const SET_SELECTED_EMPLOYEE_ID = "SET_SELECTED_EMPLOYEE_ID";
export const UPDATE_EMPLOYEE_SEARCH_FILTER = "UPDATE_EMPLOYEE_SEARCH_FILTER";
export const CLEAR_STATE = "CLEAR_STATE";

/**
 * Gets the Employee Details via backend call.
 */
export async function getAllEmployeeDetails(employeeSearchFilters) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_EMPLOYEES_BY_CRITERIA, employeeSearchFilters);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_EMPLOYEES_BY_CRITERIA);
    return parseGetAllEmployeeDetailsResponse(response.data);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{flatEmployeeList: []}}
 */
function parseGetAllEmployeeDetailsResponse(responseData) {
    // get list of flat employees
    let employeeDetailsResponse = {};
    employeeDetailsResponse.employeeList = flattenEmployeeList(responseData.employeeList);
    employeeDetailsResponse.nextToken = responseData.nextToken;
    employeeDetailsResponse.realm = responseData.realm;
    employeeDetailsResponse.roleList = flattenUserRoles(responseData.userRoles);
    employeeDetailsResponse.employeeRoles = responseData.employeeRoles;
    return employeeDetailsResponse;
}
function flattenUserRoles(userRolesList) {
    let flatUserRolesList = [];
    for (let userRole of userRolesList) {
        flatUserRolesList.push(userRole);
    }
    return flatUserRolesList;
}


function flattenEmployeeList(listData) {
    let flatEmployeeList = [];
    for (let employee of listData) {
        const flatEmployee = createFlatEmployeeObject(employee);
        flatEmployeeList.push(flatEmployee);
    }
    return flatEmployeeList;
}

/**
 * Creates a flat structure station object expected by React component.
 *
 * @param employee object.
 * @returns flat structure object
 */
function createFlatEmployeeObject(employee) {
    let flatEmployee = {};
    flatEmployee.name = employee.name;
    flatEmployee.id = employee.id;
    flatEmployee.station = employee.station;
    flatEmployee.deliveryAssociate = employee.deliveryAssociate;
    flatEmployee.userRole = employee.userRole;
    flatEmployee.externalCode = employee.externalCode;
    flatEmployee.storeId = employee.storeId;
    flatEmployee.active = employee.active;
    return flatEmployee;
}


/**
 * Gets the Children Stations via backend call.
 */
export async function getAllChildrenStation(stationSearchCriteria) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CRITERIA, stationSearchCriteria);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CRITERIA);
    return parseGetStationDataResponse(response.data);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{binTypes: []}}
 */
function parseGetStationDataResponse(responseData) {
    let flatStations = [];
    for (let station of responseData) {
        const flatStation = createFlatStationObject(station);
        flatStations.push(flatStation);
    }
    return flatStations;
}

/**
 * Creates a flat structure station object expected by React component.
 *
 * @param station binType object.
 * @returns flat structure binType object
 */
function createFlatStationObject(station) {
    let flatStation = {};
    flatStation.stationCode = station.stationCode;
    flatStation.stationId = station.stationId;
    return flatStation;
}


/**
 * Gets the Employee Details via backend call and trigger actions based when call is started and
 * when it ended.
 */
export function fetchEmployeeDetailsByCriteria(dispatch, employeeSearchFilters) {

    dispatch(employeeDetailsByCriteriaCallBegin());
    if (employeeSearchFilters["isDa"] === "") {
        employeeSearchFilters["isDa"] = null;
    }
    if (employeeSearchFilters["isActive"] === "") {
        employeeSearchFilters["isActive"] = null;
    }
    getAllEmployeeDetails(employeeSearchFilters).then(employeeDetailsJson => {
        dispatch(fetchEmployeeDetailsByCriteriaSuccess(employeeDetailsJson));
    }).catch(error => {
        dispatch(fetchEmployeeDetailsByCriteriaFailure(error.response));
    });
}

/**
 * Action to fetch all Employees with a particular field value
 */
export function fetchAndUpdateEmployeeDetailsByCriteria(dispatch, key, value, employeeSearchFilters) {

        let employeeSearchFilterCriteria = employeeSearchFilters;
        employeeSearchFilterCriteria[key] = value;
        employeeSearchFilterCriteria.nextToken = null;
        dispatch(updateEmployeeSearchFilter(key, value));
        fetchEmployeeDetailsByCriteria(dispatch, employeeSearchFilterCriteria);
}


/**
 * Fetches All the child Stations corresponding to the current station
 */

export function fetchAllChildrenStations(stationSearchCriteria) {
    return dispatch => {
        return getAllChildrenStation(stationSearchCriteria).then(childStationList => {
            dispatch(fetchAllChildrenStationsSuccess(childStationList));
        })
            .catch(error =>
                dispatch(fetchAllChildrenStationsFailure(error.response))
            );
    }
}


/**
 * Action for get Employee Data call begin
 */
export const employeeDetailsByCriteriaCallBegin = () => ({
    type: GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN
});

/**
 * Action for get Employee Details call Success
 */
export const fetchEmployeeDetailsByCriteriaSuccess = (employeeDetails) => ({
    type: GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS,
    payload: {
        employeeDetails
    }
});

/**
 * Action for get Employee Details call failure
 */
export const fetchEmployeeDetailsByCriteriaFailure = error => ({
    type: GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for get Station Details call Success
 */
export const fetchAllChildrenStationsSuccess = childStationList => ({
    type: GET_ALL_CHILD_STATION_CALL_SUCCESS,
    payload: {
        childStationList
    }
});

/**
 * Action for get Station Details call failure
 */
export const fetchAllChildrenStationsFailure = error => ({
    type: GET_ALL_CHILD_STATION_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action to update the employee search filters
 */
export const updateEmployeeSearchFilter = (key, value) => ({
    type: UPDATE_EMPLOYEE_SEARCH_FILTER,
    key,
    value
})

/**
 * Action for updating visible Stations as per page size and current page number.
 */
export const setSelectedEmployeeId = (employeeId) => ({
    type: SET_SELECTED_EMPLOYEE_ID,
    employeeId
});

/**
 * Clears the state for first time load.
 */
export const clearState = () => ({
    type: CLEAR_STATE
});
