import React, {useCallback, useEffect, useState, useContext} from "react";

import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@amzn/meridian/button";
import Menu, {MenuItem} from "@amzn/meridian/menu";
import {CSVLink} from "react-csv";
import Popover from "@amzn/meridian/popover";
import Column from "@amzn/meridian/column";
import MenuMeatballTokens from "@amzn/meridian-tokens/base/icon/menu-meatball";
import get from "lodash/get";
import {deleteSortPathSetting, getSortPath} from "../../resources/SortPathSettingsResource";
import {getNodeId, isConfigEnabled} from "../../Utility";
import Loader from "@meridian/components/loader";
import Icon from "@amzn/meridian/icon";
import {UserAuthorizationContext} from "../../context/UserAuthorizationContext";
import {StationConfigContext} from "../../context/StationConfigContext";
import {isDynamicCycle} from "../util/sort-paths/CommonUtils";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Alert from "@amzn/meridian/alert";
import {IS_DELETE_PARTITION_ENABLED} from "../../Constants";


const SortPathConfigurationActions = (props) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const [isDeleteSortPathSettingModalOpen, setIsDeleteSortPathSettingModalOpen] = useState(false);
    const [isSortPathDownloading, setIsSortPathDownloading] = useState(false);
    const [isFillPath, setIsFillPath] = useState(undefined);
    const [sortPathFileName, setSortPathFileName] = useState(undefined);
    const [sortPathCSVData, setSortPathCSVData] = useState([]);
    const [deleteSortPathSettingResponse, setDeleteSortPathSettingResponse] = useState({});

    const { updateDataVersion, actionButtonRef, fillPathCSVRef, pickPathCSVRef, sortPathConfiguration, onClickEditGridAllocation, intl } = props;
    const { userAuthorizationState } = useContext(UserAuthorizationContext);
    const { state } = useContext(StationConfigContext);

    const isDeletePartitionEnabled = isConfigEnabled(IS_DELETE_PARTITION_ENABLED, state.stationConfigs)
    const dynamicCycle = isDynamicCycle(sortPathConfiguration.cycle, sortPathConfiguration.nodeId, state.cycleList);

    const onClickDownloadSortPath = useCallback((sortPathConfiguration, isFillPath) => {
        setIsSortPathDownloading(true);
        setIsFillPath(isFillPath);
        const sortPath = sortPathConfiguration.sortPaths.find(x => x.type === (isFillPath ? "FILL" : "PICK"));
        const sortPathResponse = getSortPath(getNodeId(), sortPath.sortPathId);
        sortPathResponse.then(data => {
            if (data) {
                setSortPathFileName(`${get(data, ["sortPathInfo", "name"], isFillPath ? "FILL" : "PICK")}.csv`);
                setSortPathCSVData([["LABEL"], ...(get(data, ["sortPathLocations", "sortZones"], [])
                    .map(sortZone => [sortZone]))]);
            }
        }).finally(() => setIsSortPathDownloading(false));
    }, []);

    // Delete action selected
    const onClickDeleteSortPath = useCallback((sortPathConfiguration) => {
        // Loading until we get a response
        setDeleteSortPathSettingResponse({loading: true});

        // Call backend for deletion
        deleteSortPathSetting(getNodeId(), sortPathConfiguration.settingId)
            // Update state on API success and failure
            .then(data => setDeleteSortPathSettingResponse({success: !!data}))
            .catch(() => setDeleteSortPathSettingResponse({success: false}))
    }, []);

    const onCloseDeleteSortPathModal = useCallback((deleteSortPathResponse) => {
        // If deletion was successful, fetch fresh list of sort paths
        deleteSortPathResponse.success && updateDataVersion();

        // Close modal and remove previous responses
        setDeleteSortPathSettingResponse({});
        setIsDeleteSortPathSettingModalOpen(false);
    }, []);

    useEffect(() => {
        if (sortPathCSVData && sortPathCSVData.length > 0) {
            isFillPath ? fillPathCSVRef.current.link.click() : pickPathCSVRef.current.link.click();
        }
    }, [sortPathCSVData]);


    return (
        <div>
            <Button data-testid="actionsButton" ref={actionButtonRef} type="icon" size="medium"
                    onClick={() => setIsActionsMenuOpen(true)}>
                <Icon tokens={MenuMeatballTokens} />
            </Button>
            <Menu anchorNode={actionButtonRef.current}
                  open={isActionsMenuOpen}
                  position="right"
                  alignment="top"
                  onClose={() => setIsActionsMenuOpen(false)}>
                <MenuItem disabled={!get(sortPathConfiguration, ["sortPaths"], []).some(x => x.type === "FILL")}
                          onClick={() => onClickDownloadSortPath(sortPathConfiguration, true)}>
                    <FormattedMessage id="sortPaths.downloadFillPath" defaultMessage="Download Fill Path"/>
                </MenuItem>
                <MenuItem disabled={!get(sortPathConfiguration, ["sortPaths"], []).some(x => x.type === "PICK")}
                          onClick={() => onClickDownloadSortPath(sortPathConfiguration, false)}>
                    <FormattedMessage id="sortPaths.downloadPickPath" defaultMessage="Download Pick Path"/>
                </MenuItem>
                {userAuthorizationState.isAuthorized && <MenuItem onClick={() => onClickEditGridAllocation(sortPathConfiguration)}>
                    <FormattedMessage id="sortPaths.editGridAllocation" defaultMessage="Edit"/>
                </MenuItem>}
                {!userAuthorizationState.isAuthorized && <MenuItem onClick={() => onClickEditGridAllocation(sortPathConfiguration)}>
                    <FormattedMessage id="sortPaths.viewGridAllocation" defaultMessage="View"/>
                </MenuItem>}
                {(dynamicCycle || isDeletePartitionEnabled) && <MenuItem onClick={() => setIsDeleteSortPathSettingModalOpen(true)} disabled={!userAuthorizationState.isAuthorized}>
                    <FormattedMessage id="sortPaths.deleteGridAllocation" defaultMessage="Delete"/>
                </MenuItem>}
            </Menu>
            <CSVLink data={sortPathCSVData}
                     data-testid="fillPathCSVLink"
                     ref={fillPathCSVRef}
                     filename={sortPathFileName}
                     target="_blank"
                     fileencoding="UTF-8" />
            <CSVLink data={sortPathCSVData}
                     data-testid="pickPathCSVLink"
                     ref={pickPathCSVRef}
                     filename={sortPathFileName}
                     target="_blank"
                     fileencoding="UTF-8" />

            <Popover anchorNode={actionButtonRef.current}
                     open={isSortPathDownloading}
                     onClose={() => setIsSortPathDownloading(false)}
                     position="right"
                     alignment="top"
                     data-testid="popover" >
                <Column height={"32px"} alignment="center center">
                    <Loader size={"medium"}  type={"circular"}/>
                </Column>
            </Popover>

            <Modal
                title={intl.formatMessage({id: "sortPaths.deleteModalTitle", defaultMessage: "Delete Partition"}, {partition: sortPathConfiguration.name})}
                open={isDeleteSortPathSettingModalOpen}
                onClose={() => onCloseDeleteSortPathModal(deleteSortPathSettingResponse)}
                scrollContainer="viewport"
                closeLabel={intl.formatMessage({id: "sortPaths.close", defaultMessage: "Close"})}>
                {deleteSortPathSettingResponse.success && <Alert type="success" >
                    <FormattedMessage id="sortPaths.deleteSuccessful" defaultMessage="Partition successfully deleted"/>
                </Alert>}
                {deleteSortPathSettingResponse.success === false && <Alert type="error">
                    <FormattedMessage id="sortPaths.deleteFailed" defaultMessage="Failed to delete partition"/>
                </Alert>}
                <Text>
                    <FormattedMessage id="sortPaths.deleteConfirmation"
                                      defaultMessage="Are you sure you want to delete this partition? This action is irreversible, please proceed with caution."/>
                </Text>
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="primary" size="small" onClick={() => onClickDeleteSortPath(sortPathConfiguration)}
                                disabled={deleteSortPathSettingResponse.loading || deleteSortPathSettingResponse.success}>
                            {deleteSortPathSettingResponse.loading ? <Loader type={"circular"} size={"small"}/> :
                                <FormattedMessage id="sortPaths.confirm" defaultMessage="Confirm"/>}
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default injectIntl(SortPathConfigurationActions);