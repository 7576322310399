import React, {createContext, useReducer} from "react"
import {StationConfigReducer} from "../reducers/StationConfigReducer";

export const StationConfigContext = createContext();

const StationConfigContextProvider = (props) => {
    const initialState = {
        cycleList: undefined,
        binTypes: [],
        stationConfigs: []
    }

    const [ state, dispatch ] = useReducer(StationConfigReducer, initialState)
    return (
        <StationConfigContext.Provider value = {{state, dispatch}}>
            {props.children}
        </StationConfigContext.Provider>
    )
}

export default StationConfigContextProvider
