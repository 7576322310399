import React, {useReducer, createContext} from "react"
import {UserAuthorizationReducer} from "../reducers/UserAuthorizationReducer";

export const UserAuthorizationContext = createContext();

const UserAuthorizationContextProvider = (props) => {
    const initialState = {
        isNARCTCPAuthorizationCheckEnabled: false,
        isAuthorized: false
    }

    const [ state, dispatch ] = useReducer(UserAuthorizationReducer, initialState)
    return (
        <UserAuthorizationContext.Provider value = {{userAuthorizationState: state, userAuthorizationDispatch: dispatch}}>
            {props.children}
        </UserAuthorizationContext.Provider>
    )
}

export default UserAuthorizationContextProvider