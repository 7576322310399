import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {ENDPOINT_ADD_BIN_TYPE, ENDPOINT_GET_BIN_TYPES, ENDPOINT_UPDATE_BIN_TYPE, PROCESS_NODE_RESOURCE} from "../Constants";
import {createRequest} from "../Utility";

/**
 * Fetches binTypes by calling appropriate end point.
 *
 * @returns {Promise<{binTypes: []}>}
 */
export async function fetchBinTypes() {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_BIN_TYPES, null);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_BIN_TYPES);
    return parseGetBinTypesResponse(response.data);
}


/**
 * Updates binType by calling appropriate end point.
 *
 * @param flatBinType
 * @returns {Promise<*>}
 */
export async function updateBinTypes(flatBinType) {
    const binType = createBinTypeObject(flatBinType);
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_BIN_TYPE, {binType: binType});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_BIN_TYPE);
}

/**
 * Adds binType by calling appropriate end point.
 *
 * @param flatBinType
 * @returns {Promise<*>}
 */
export async function addBinType(flatBinType) {
    const binType = createBinTypeObject(flatBinType);
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_ADD_BIN_TYPE, {binType: binType});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_ADD_BIN_TYPE);
}

/**
 * Creates binType object accepted by backend apis from flatBinType object.
 *
 * @param flatBinType binType object with flat structure.
 * @returns binType object
 */
function createBinTypeObject(flatBinType) {
    let binType = {
        dimension: {}
    };
    binType.type = flatBinType.type;
    binType.scope = flatBinType.scope;
    binType.maximumWeight = flatBinType.weight;
    binType.dimension.length = flatBinType.length;
    binType.dimension.width = flatBinType.width;
    binType.dimension.height = flatBinType.height;
    return binType;
}

/**
 * Creates a flat structure binType object expected by React component.
 *
 * @param binType binType object.
 * @returns flat structure binType object
 */
function createFlatBinTypeObject(binType) {
    let flatBinType = {};
    flatBinType.type = binType.type;
    flatBinType.scope = binType.scope;
    flatBinType.weight = binType.maximumWeight;
    if (binType.hasOwnProperty("dimension")) {
        flatBinType.length = binType.dimension.length;
        flatBinType.width = binType.dimension.width;
        flatBinType.height = binType.dimension.height;
    } else {
        flatBinType.length = "";
        flatBinType.width = "";
        flatBinType.height = "";
    }
    return flatBinType;
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{binTypes: []}}
 */
function parseGetBinTypesResponse(responseData) {
    let flatBinTypes = [];
    for (let binType of responseData.binTypes) {
        const flatBinType = createFlatBinTypeObject(binType);
        flatBinTypes.push(flatBinType);
    }
    return {
        binTypes: flatBinTypes
    }
}