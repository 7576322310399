import {AMZL_CORE} from "../Constants";

export const GET_CAPABILITY = "GET_CAPABILITY"

export const CapabilityReducer = (state, action) => {
    switch (action.type) {
        case GET_CAPABILITY:
            const capability = action.capability ? JSON.parse(action.capability.capability) : AMZL_CORE;
            return {
                ...state,
                data: capability
            };
        default:
            return state
    }
}
