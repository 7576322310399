import React, {createContext, useReducer} from "react"
import {EmployeeFormManagementDataReducer} from "../reducers/EmployeeFormManagementReducer";

export const EmployeeFormManagementContext = createContext()

const EmployeeFormManagementContextProvider = (props) => {
    /**
     * Initial state of the employee form.
     */
    const initialState = {
        items: {
            employeeDetails: {},
            roleList: []
        },
        loading: false,
        error: null,
        setEmployeeModalState: false,
        setEmployeeError: null,
        saveButtonDisabled: true,
        selectableStations: []
    };
    const [ state, dispatch ] = useReducer(EmployeeFormManagementDataReducer, initialState)
    return (
        <EmployeeFormManagementContext.Provider value = {{state, dispatch}}>
            {props.children}
        </EmployeeFormManagementContext.Provider>
    )
}

export default EmployeeFormManagementContextProvider
