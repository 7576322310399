import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter } from 'react-router-dom';
import App from './App';
import "@meridian/tokens/base/font/amazon-ember.css";
import {IntlProvider} from "react-intl";
import ErrorBoundary from "./components/util/ErrorBoundary";
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'
import it from 'react-intl/locale-data/it'
import fr from 'react-intl/locale-data/fr'
import es from 'react-intl/locale-data/es'
import {getDirection, getLocale, getMessages} from "./handler/LocaleHandler";
import StationConfigContextProvider from "./context/StationConfigContext";
import UserAuthorizationContextProvider from "./context/UserAuthorizationContext";

addLocaleData(en);
addLocaleData(ja);
addLocaleData(it);
addLocaleData(fr);
addLocaleData(es);

getLocale().then(locale => {
    document.getElementsByTagName(`html`)[0].setAttribute("dir", getDirection(locale));
    ReactDOM.render((
        <IntlProvider locale={locale} messages={getMessages(locale)}>
            <HashRouter>
                <ErrorBoundary>
                    <UserAuthorizationContextProvider>
                    <StationConfigContextProvider>
                        <App/>
                    </StationConfigContextProvider>
                    </UserAuthorizationContextProvider>
                </ErrorBoundary>
            </HashRouter>
        </IntlProvider>
    ), document.getElementById('root'));
})
