import React, {useState, useCallback, useContext, useEffect} from 'react';
import Modal from "@meridian/components/modal";
import {BinTestModalContext} from "../../../context/BinTestModalContext";
import {CLOSE_MODAL, LOAD_CART_RECIPE_FOR_TYPE} from "../../../reducers/BinTestModalReducer";
import Row from "@meridian/components/row";
import Select from "@meridian/components/select";
import SelectOption from "@meridian/components/select/select-option";
import Input from "@meridian/components/input";
import Text from "@meridian/components/text";
import BinTestCart from "../BinTestCart";
import Heading from "@meridian/components/heading";
import {publishBinTestCommand} from "../../../resources/SmartStationResource";
import {getNodeId} from "../../../Utility";
import {getCartRecipe} from "../../../resources/CartRecipeResource";

export default function BinTestModal() {
    const [spotlight, setSpotlight] = useState(undefined);
    const [color, setColor] = useState('Blue');
    const [duration, setDuration] = useState(10);
    const {state, dispatch} = useContext(BinTestModalContext);
    const onClose = useCallback(() => dispatch({type: CLOSE_MODAL, payload: {open:true}}), []);
    const cartRecipes = state.cartRecipes;
    const onClick = useCallback(async (label) => {
        await publishBinTestCommand(getNodeId(), label, color, [spotlight], duration);
    }, [spotlight, color, duration]);
    useEffect(() => {
        if (state.testCartType && !cartRecipes[state.testCartType]) {
            console.log('cart not already present. Fetching it ', state.testCartType);
            getCartRecipe(state.testCartType)
                .then((response) => {
                    dispatch({
                        type: LOAD_CART_RECIPE_FOR_TYPE,
                        payload: {cartType: state.testCartType, cartRecipe: response.data.cartRecipe}
                    });
                })
                .catch((e) => {
                    console.error('error calling fetch ', e)
                    dispatch({
                        type: LOAD_CART_RECIPE_FOR_TYPE, payload: {cartType: state.testCartType, cartRecipe: null}
                    })
                })
        }
    }, [state.testCartType, dispatch]);
    const spotlights = state.spotlights[state.testGroupLabel]? state.spotlights[state.testGroupLabel]: [];
    return <Modal
        title={`Testing Cart Location: ${state.testCartLabel}`}
        open={state.openState}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        describedById="modal-description"
        bodySpacingInset="large"
        width="100%"
        >
        <Row spacing="medium">
            <Text>Spotlight: </Text>
            <Select
                value={spotlight}
                onChange={setSpotlight}>
                {
                    spotlights.map((spotlight) => {
                        return <SelectOption value={spotlight.id} label={spotlight.name} key={spotlight.id}/>;
                    })
                }
            </Select>
            <Text>Color: </Text>
            <Select
                value={color}
                onChange={setColor}>
                <SelectOption value="Blue" label="Blue"/>
                <SelectOption value="Red" label="Red" />
                <SelectOption value="Green" label="Green" />
                <SelectOption value="White" label="White" />
                <SelectOption value="Yellow" label="Yellow" />
                <SelectOption value="Orange" label="Orange" />
                <SelectOption value="Cyan" label="Cyan" />
                <SelectOption value="Pink" label="Pink" />
            </Select>
            <Text>Duration: </Text>
            <Input value={duration} onChange={setDuration}
                   type="number" placeholder="0" size="small" width="100px"/>
        </Row>
        <Row alignmentHorizontal="center" className="margin-top-10px">
            <Heading level={5}> Click the bin below to light up corresponding physical bin</Heading>
        </Row>
        <Row className="margin-top-10px">
            <BinTestCart group={state.testGroupLabel} cartLocation={state.testCartLabel}
                         cartRecipe={cartRecipes[state.testCartType]}
                         binTest={onClick} testEnabled={spotlight&&color&&duration}
            />
        </Row>
    </Modal>;
}
