import React, {Component} from 'react';
import CapabilityContextProvider from "../../context/CapabilityContext";
import NodeResourcesManagerTable from "./NodeResourcesManagerTable";
import NodeResourcesManagerHeader from "./NodeResourcesManagerHeader";
import CreateResourceModal from "./modal/CreateResourceModal";
import DeprecateResourceModal from "./modal/DeprecateResourceModal";
import NodeResourceContextProvider from "../../context/NodeResourceContext";
import SetResourceModal from "./modal/SetResourceModal";
import UpdateResourceModal from "./modal/UpdateResourceModal";
import ValidateAndDownloadResourceModal from "./modal/ValidateAndDownloadResourceModal";

class NodeResourceManager extends Component {
    render() {
        return (
            <CapabilityContextProvider>
                <NodeResourceContextProvider>
                    <NodeResourcesManagerHeader/>
                    <NodeResourcesManagerTable/>
                    <CreateResourceModal/>
                    <SetResourceModal/>
                    <UpdateResourceModal/>
                    <DeprecateResourceModal/>
                    <ValidateAndDownloadResourceModal/>
                </NodeResourceContextProvider>
            </CapabilityContextProvider>
        );
    }
}

export default NodeResourceManager;
