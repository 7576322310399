export const UPDATE_CONFIGS = "UPDATE_CONFIGS"
export const UPDATE_BIN_TYPES = "UPDATE_BIN_TYPES"

export const StationConfigReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_CONFIGS:
            return {
                ...state,
                ...action.data
            };
        case UPDATE_BIN_TYPES:
            return {
                ...state,
                ...action.data
            };
        default:
            return state
    }
}
