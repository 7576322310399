
/**
 * Reducer function that changes state based on the action triggered.
 */

export const EDIT_EMPLOYEE_DETAILS = "EDIT_EMPLOYEE_DETAILS";
export const SET_EMPLOYEE_DETAILS_CALL_BEGIN = "SET_EMPLOYEE_DETAILS_CALL_BEGIN";
export const SET_EMPLOYEE_DETAILS_SUCCESS = "SET_EMPLOYEE_DETAILS_SUCCESS";
export const SET_EMPLOYEE_DETAILS_FAILURE = "SET_EMPLOYEE_DETAILS_FAILURE";
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN"
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS"
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE"
export const SET_EMPLOYEE_SUCCESS_MODAL_STATE = "SET_EMPLOYEE_SUCCESS_MODAL_STATE";

export const EmployeeFormManagementDataReducer = (state,action) => {
    switch (action.type) {

        case GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS:

            return {
                ...state,
                loading: false,
                items: {
                    ...state.items,
                    employeeDetails: action.payload.employeeDetails.employee,
                    roleList: action.payload.employeeDetails.userRoles
                },
                selectableStations: action.payload.employeeDetails.selectableStations
            }

        case GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case SET_EMPLOYEE_DETAILS_CALL_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null,
                setEmployeeError: null,
            };

        case SET_EMPLOYEE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                setEmployeeModalState: true,
                saveButtonDisabled: true
            };

        case SET_EMPLOYEE_SUCCESS_MODAL_STATE:
            return {
                ...state,
                loading: false,
                setEmployeeModalState: action.modalState
            };


        case SET_EMPLOYEE_DETAILS_FAILURE:
            // The request failed.
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                loading: false,
                setEmployeeModalState: true,
                setEmployeeError: action.payload.error,
                items: {
                    ...state.items,
                    employeeDetails: action.payload.employeeDetails.employee,
                    roleList: action.payload.employeeDetails.userRoles
                }
            };

        case EDIT_EMPLOYEE_DETAILS:
            let value;
            if (action.key === "active" || action.key === "deliveryAssociate") {
                value = action.value === "true";
            } else {
                value = action.value;
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    employeeDetails: {
                        ...state.items.employeeDetails,
                        [action.key]: value
                    }
                },
                saveButtonDisabled: false
            }
        default:
            // ALWAYS have a default case in a reducer
            return state;

    }
}