import React, {Component} from "react";
import StationManagementContextProvider from "../../context/StationManagementContext";
import StationManagement from "../StationManagement";

class StationManagementManager extends Component {
    render() {
        return (
            <StationManagementContextProvider>
                <StationManagement/>
            </StationManagementContextProvider>
        )
    }
}

export default StationManagementManager
