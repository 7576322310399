/**
 * Resources file for Employee form Management.
 */
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_USER,
    ENDPOINT_GET_EMPLOYEE_BY_ID,
    ENDPOINT_SET_EMPLOYEE
} from "../Constants";
import {createRequest} from "../Utility";
export const EDIT_EMPLOYEE_DETAILS = "EDIT_EMPLOYEE_DETAILS";
export const SET_EMPLOYEE_DETAILS_CALL_BEGIN = "SET_EMPLOYEE_DETAILS_CALL_BEGIN";
export const SET_EMPLOYEE_DETAILS_SUCCESS = "SET_EMPLOYEE_DETAILS_SUCCESS";
export const SET_EMPLOYEE_DETAILS_FAILURE = "SET_EMPLOYEE_DETAILS_FAILURE";
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN"
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS"
export const GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE = "GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE"
export const SET_EMPLOYEE_SUCCESS_MODAL_STATE = "SET_EMPLOYEE_SUCCESS_MODAL_STATE";

/**
 * Gets the Employee By Employee Id via API Call.
 */
async function getEmployeeByEmployeeId(employeeId) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_EMPLOYEE_BY_ID, employeeId);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_EMPLOYEE_BY_ID);
    return parseEmployee(response.data);
}


/**
 * Gets the Employee By employeeCode via backend call and trigger actions based when call is started and
 * when it ended.
 */
export function fetchEmployeeDetailsByEmployeeId(dispatch, employeeId) {
        dispatch(getEmployeeDetailsByEmployeeIdCallBegin());
        getEmployeeByEmployeeId(employeeId).then(employeeJson =>
            dispatch(getEmployeeDetailsByEmployeeIdSuccess(employeeJson))
        ).catch (error =>
        dispatch(getEmployeeDetailsByEmployeeIdCallFailure(error.response)));
}


/**
 * Creates a flat structure station object expected by React component.
 *
 * @param responseData object.
 * @returns flat structure object
 */
function parseEmployee(responseData) {
    let employeeDetails = {};

    let employee = responseData.employee;
    let flatEmployee = {};
    flatEmployee.station = employee.station;
    flatEmployee.active = employee.active;
    flatEmployee.phone = employee.phone;
    flatEmployee.userRole = employee.userRole;
    flatEmployee.deliveryAssociate = employee.deliveryAssociate;
    flatEmployee.name = employee.name;
    flatEmployee.code = employee.code;
    flatEmployee.customerId = employee.customerId;
    flatEmployee.id = employee.id;
    flatEmployee.externalCode = employee.externalCode;

    employeeDetails.employee = flatEmployee;

    let flatUserRolesList = [];
    for (let userRole of responseData.userRoles) {
        flatUserRolesList.push(userRole);
    }
    employeeDetails.userRoles = flatUserRolesList;

    employeeDetails.selectableStations = getFlatListOfStations(responseData.selectableStations);

    return employeeDetails;
}

function getFlatListOfStations(selectableStations) {
    let flatSelectableStations = [];
    for (let station of selectableStations) {
        let flatStation = {};
        flatStation.id = station.id;
        flatStation.code = station.code;
        flatSelectableStations.push(flatStation)
    }
    return flatSelectableStations;
}
/**
 * Sets the Employee Details Data via backend call.
 */
async function setEmployeeDetails(employeeDetails) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_SET_EMPLOYEE, employeeDetails);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_SET_EMPLOYEE);
    return response.data;
}

/**
 * Sets the Employee Details Data via backend call and trigger actions based when call is started and
 * when it ended.
 */
export function setCurrentEmployeeDetails(dispatch, employeeDetails) {
        dispatch(setEmployeeDetailsCallBegin());
            setEmployeeDetails(employeeDetails).then(() =>
                getEmployeeByEmployeeId(employeeDetails.id).then(employeeJson => {
                    dispatch(getEmployeeDetailsByEmployeeIdSuccess(employeeJson));
                    dispatch(setEmployeeDetailsSuccess());
                }
            )
        ).catch (error => {
            getEmployeeByEmployeeId(employeeDetails.id).then(employeeJson =>
                dispatch(setEmployeeDetailsFailure(error.response, employeeJson)));
        });
}

/**
 * Action for Get Employee By employeeCode call begin
 */
export const getEmployeeDetailsByEmployeeIdCallBegin = () => ({
    type: GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_BEGIN
});

/**
 * Action for Get Employee By employeeCode call Success
 */
export const getEmployeeDetailsByEmployeeIdSuccess = (employeeDetails) => ({
    type: GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_SUCCESS,
    payload: {
        employeeDetails
    }
});

/**
 * Action for Get Employee By employeeCode call failure
 */
export const getEmployeeDetailsByEmployeeIdCallFailure = error => ({
    type: GET_EMPLOYEE_DETAILS_BY_EMPLOYEE_ID_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Action for SetEmployee Details Data call begin
 */
export const setEmployeeDetailsCallBegin = () => ({
    type: SET_EMPLOYEE_DETAILS_CALL_BEGIN
});

/**
 * Action for set Employee Performance Data call Success
 */
export const setEmployeeDetailsSuccess = () => ({
    type: SET_EMPLOYEE_DETAILS_SUCCESS
});

/**
 * Action for Set Employee Data call failure
 */
export const setEmployeeDetailsFailure = (error,employeeDetails) => ({
    type: SET_EMPLOYEE_DETAILS_FAILURE,
    payload: {
        error,
        employeeDetails
    }
});

/**
 * Edit Employee Details
 */

export const editEmployeeDetails = (key, value) => ({
    type: EDIT_EMPLOYEE_DETAILS,
    key,
    value

});

/**
 * Toggle Set Employee Success Modal
 */
export const setEmployeeSuccessModalState = (modalState) => ({
    type: SET_EMPLOYEE_SUCCESS_MODAL_STATE,
    modalState
});

