import {
    GET_STATION_BY_STATION_CODE_CALL_BEGIN,
    GET_STATION_BY_STATION_CODE_CALL_FAILURE,
    GET_STATION_BY_STATION_CODE_SUCCESS,
    UPDATE_PARENT_STATION,
    UPDATE_STATION_INFO_FAILURE,
    UPDATE_STATION_INFO_SUCCESS,
    EMPLOYEE_VERIFICATION_FAILURE,
    EMPLOYEE_VERIFICATION_SUCCESS,
    SET_STATION_UPDATE_MODAL_STATE,
    UPDATE_CITY,
    UPDATE_STATE,
    UPDATE_ADDRESS_LINE1, UPDATE_ADDRESS_LINE2, UPDATE_ADDRESS_LINE3,
    UPDATE_POSTAL_CODE, UPDATE_LATITUDE, UPDATE_LONGITUDE
} from "../Constants";

/**
 * Reducer function that changes state based on the action triggered.
 */
export const StationViewManagementDataReducer = (state,action) => {
    switch (action.type) {
        case GET_STATION_BY_STATION_CODE_CALL_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_STATION_BY_STATION_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: {
                    ...state.items,
                    currentStation: action.payload.station.currentStation,
                    stationList: action.payload.station.stationList,
                    stationActiveCapabilities: action.payload.station.stationActiveCapabilities
                }
            };


        case GET_STATION_BY_STATION_CODE_CALL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_STATION_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                items: {
                    ...state.items,
                    currentStation: action.payload.station,
                },
                stationUpdateModalState: false
            };
        
        case UPDATE_STATION_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: {
                    ...state.items,
                    currentStation: action.payload.station.currentStation
                }
            };

        case UPDATE_CITY:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        city: action.payload
                    }
                }
            }

        case UPDATE_STATE:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            state: action.payload
                        }
                    }
                }
            }

        case UPDATE_ADDRESS_LINE1:
            return{
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            address1: action.payload
                        }
                    }
                }
            }

        case UPDATE_ADDRESS_LINE2:
            return{
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            address2: action.payload
                        }
                    }
                }
            }

        case UPDATE_ADDRESS_LINE3:
            return{
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            address3: action.payload
                        }
                    }
                }
            }

        case UPDATE_POSTAL_CODE:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            postalCode: action.payload
                        }
                    }
                }
            }

        case UPDATE_LATITUDE:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            geocode: {
                                ...state.items.currentStation.address.geocode,
                                latitude: action.payload
                            }
                        }
                    }
                }
            }

        case UPDATE_LONGITUDE:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        address: {
                            ...state.items.currentStation.address,
                            geocode: {
                                ...state.items.currentStation.address.geocode,
                                longitude: action.payload
                            }
                        }
                    }
                }
            }

        case UPDATE_PARENT_STATION:
            return {
                ...state,
                items: {
                    ...state.items,
                    currentStation: {
                        ...state.items.currentStation,
                        parentStation: action.payload
                    }
                }
            };

        case EMPLOYEE_VERIFICATION_SUCCESS:
            return {
                ...state,
                updateButtonEnabled: true,
                stationAllowlistedForConfigUpdate: action.payload.stationAllowed
            };

        case EMPLOYEE_VERIFICATION_FAILURE:
            return {
                ...state,
                updateButtonEnabled: false
            };

        case SET_STATION_UPDATE_MODAL_STATE:
            return {
                ...state,
                stationUpdateModalState: action.payload.value
            }

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}
