import {
    STRINGS
} from "../Constants";
import readXlsxFile from "read-excel-file";

export const ERROR_MAP = {
    INVALID_FILE_INPUT: "Invalid File Input",
    EMPTY_FILE_INPUT: "Uploaded file is empty"
};

/**
 * fetch the Resource Data Map
 */
async function getSortPathResources(stationCode, event) {
    const file = event.target.files[0];
    const sheetIndexMapList = await readXlsxFile(file, { getSheets : true} );

    let sheetRows = [];

    if(sheetIndexMapList === undefined) {
        return sheetRows;
    }

    const sheet = sheetIndexMapList[0];
    const sheetName = sheet.name;
    sheetRows = await readXlsxFile(file, { sheet: sheetName });
    return sheetRows
}

/**
 * Action for uploading the sort path file
 */
export async function readSortPathFile(stationCode, event) {
    const sheetRows = await getSortPathResources(stationCode, event);
    if(Object.keys(sheetRows).length === 0) {
        return {
            sortPathResources: [],
            error: true,
            errorMessage: ERROR_MAP.EMPTY_FILE_INPUT
        }
    } else {
        let cellValue = sheetRows[0][0];
        if ((cellValue.toString().trim().toLowerCase() !== 'label') || (sheetRows.length === 1))  {
            return {
                sortPathResources: [],
                error: true,
                errorMessage: ERROR_MAP.INVALID_FILE_INPUT
            }
        }
        let sortPathResources = [];
        for (let i = 1; i < sheetRows.length; ++i) {
            cellValue = sheetRows[i][0];
            if(cellValue !== null && cellValue !== STRINGS.EMPTY) {
                // create a resource out of it.
                let resource = {};
                resource.resourceType = "BIN";
                resource.stationCode = stationCode;
                resource.label = cellValue.toString().trim();

                // create the sort path resource
                let sortPathResource = {};
                sortPathResource.resource = resource;
                sortPathResource.sequenceNumber = i;

                // add in the list
                sortPathResources.push(sortPathResource);
            }
        }

        return {
            sortPathResources: sortPathResources
        }
    }
}

