import React, {useContext} from 'react';
import Text from "@meridian/components/text";
import Modal from "@meridian/components/modal";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import {submitUpdatedResource} from "../../../resources/NodeResourceManagerResource";
import {FormattedMessage} from "react-intl";

import {
    BEGIN_CALL,
    TOGGLE_VIEW_RESOURCE_MODAL,
    UPDATE_RESOURCE_DATA,
    UPDATE_RESOURCE_FAILURE,
    UPDATE_RESOURCE_RELATIONSHIP_SUCCESS,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_RELATIONSHIP_FAILURE,
    SET_IS_VALID_EDIT_MODAL
} from "../../../reducers/NodeManagerReducer";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import Column from "@meridian/components/column";
import Input from "@meridian/components/input";
import Select from "@meridian/components/select";
import SelectOption from "@meridian/components/select/select-option";
import SetResourcesAndAssociationsModal from './SetResourcesAndAssociationsModal';
import { EDITABLE_RESOURCE_TYPES, TYPE } from '../../../Constants';

export default function SetResourceModal() {
    const {state, dispatch} = useContext(NodeResourceContext);
    let {viewResourceModalState, currentResource, currentNodeId, isValidEditModal} = state;
    
    const updateResourceData = (fieldName, fieldValue) => {
        dispatch( {
            type: UPDATE_RESOURCE_DATA,
            fieldName,
            fieldValue});
    };

    const toggleViewResourceModal = (status) => {
        dispatch( {
            type: TOGGLE_VIEW_RESOURCE_MODAL,
            status});
    };

    return (
        <Modal
            id="modal-viewResource"
            open={viewResourceModalState}
            scrollContainer="viewport"
            describedBy="modal-viewResource_desc"
        >
            { (EDITABLE_RESOURCE_TYPES.includes(currentResource.resourceType))
                ? 
                    <SetResourcesAndAssociationsModal/>
                :
            <div id="viewResource-modal_desc">
                <Row
                    widths={["grid-6", "grid-6"]}
                    wrap="down"
                    height={"100px"}
                    spacingInset="small"
                    alignment="top left"
                >
                    <Column spacing="small" >
                        <Text type="h100"><FormattedMessage id="resource.Label" defaultMessage="Label" /></Text>
                        <Input
                            size="small"
                            width={"200px"}
                            value={currentResource.label}
                            disabled={true}
                        >
                        </Input>
                    </Column>
                    <Column spacing="small">
                        <Text type="h100"><FormattedMessage id="resource.status" defaultMessage="Status" /></Text>
                        <Select
                            value={currentResource.Status}
                            size="small"
                            popoverPosition="adjacent"
                            width={"200px"}
                            onChange={(status) => updateResourceData("Status", status)}
                        >
                            <SelectOption value="Enabled" label="Enabled" />
                            <SelectOption value="Disabled" label="Disabled" />
                        </Select>
                    </Column>
                </Row>
                <Row
                    widths={["grid-6", "grid-6"]}
                    wrap="down"
                    height={"100px"}
                    spacingInset="small"
                    alignment="top left"
                >
                    <Column spacing="small" >
                        <Text type="h100"><FormattedMessage id="resource.Preference" defaultMessage="Preference" /></Text>
                        <Input
                            size="small"
                            type="number"
                            width={"200px"}
                            value={currentResource.Preference ? currentResource.Preference : ""}
                            onChange={(Preference) => updateResourceData("Preference", Preference)}
                        >
                        </Input>
                    </Column>
                    <Column spacing="small">
                        <Text type="h100"><FormattedMessage id="resource.PrintableLabel" defaultMessage="PrintableLabel" /></Text>
                        <Input
                            size="small"
                            width={"200px"}
                            value={currentResource.PrintableLabel ? currentResource.PrintableLabel : ""}
                            onChange={(PrintableLabel) => updateResourceData("PrintableLabel", PrintableLabel)}
                        >
                        </Input>
                    </Column>
                </Row>
            </div>
            }

            <Row alignment="center right" widths="fit">
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                    if(isValidEditModal){
                        toggleViewResourceModal(false);
                        submitUpdatedResource(currentResource, currentNodeId, state).then(result => {
                            dispatch({type: BEGIN_CALL});
                            if(result.type === TYPE.RESOURCE){
                                if(result.success) {
                                    dispatch({
                                        type: UPDATE_RESOURCE_SUCCESS,
                                        resource: result.resource
                                    });
                                } else {
                                    dispatch({
                                        type: UPDATE_RESOURCE_FAILURE,
                                        payload: {
                                            error: result.error
                                        }
                                    });
                                }
                        } else {
                            if(result.success) {
                                dispatch({
                                    type: UPDATE_RESOURCE_RELATIONSHIP_SUCCESS,
                                    resource: (result.resource.data.addResourceRelationshipsList)[0]
                                });
                            } else {
                                dispatch({
                                    type: UPDATE_RESOURCE_RELATIONSHIP_FAILURE,
                                    payload: {
                                        error: result.error
                                    }
                                });
                            }}
                              })}
                            }
                    }
                >
                    <FormattedMessage id="generic.submit" defaultMessage="Submit">
                    </FormattedMessage>
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                        toggleViewResourceModal(false);
                        dispatch({type: SET_IS_VALID_EDIT_MODAL, payload: {
                            isValidEditModal: true,
                            isValidEditModalMessage: ""}});
                    }}
                >
                    <FormattedMessage id="generic.close" defaultMessage="Close">
                    </FormattedMessage>
                </Button>
            </Row>
        </Modal>
    )
}
