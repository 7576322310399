import React from 'react';
import Box from "@meridian/components/box";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import Text from "@meridian/components/text";

function makeArray(row, col) {
    var arr = [];
    for(let i = 0; i < row; i++) {
        arr.push(new Array(col).fill(undefined));
    }
    return arr;
}

function getButtonGroupFromRecipe(group, cartLocation, cartRecipe, onClickCallBack, testEnabled){
    const cartButtonGroups = [];
    const bins = makeArray(cartRecipe.numberOfRows, cartRecipe.numberOfColumns);
    for(let binDetails of cartRecipe.binDetailsList){
        // row and column start at 1 in NARC
        bins[binDetails.row-1][binDetails.column-1] = cartLocation+'.'+binDetails.labelSuffix;
    }
    for(let i=0; i<bins.length; ++i) {
        cartButtonGroups.push(
            <Row minWidth="medium" className="bin-button-row" wrap="none" spacing="100" widths={["fill"]} key={i}>
                {
                    bins[i].filter(x => x !== undefined)
                        .map(label => {
                                return <Button size="large" type="tertiary" className="bin-button" key={label}
                                        onClick={()=>onClickCallBack(label)} disabled={!testEnabled}>
                                    {label}
                                </Button>;
                            }
                        )
                }
            </Row>
        );
    }
    return cartButtonGroups;
}

function BinTestCart(props) {
    if (!props.cartRecipe) {
        return <Text>Unknown Cart Type</Text>;
    } else {
        return (
            <Row alignmentHorizontal="center" width="100%">
                <Box>
                    {
                        getButtonGroupFromRecipe(props.group, props.cartLocation,
                            props.cartRecipe, props.binTest, props.testEnabled)
                    }
                </Box>
            </Row>
        );
    }
}

export default BinTestCart;
