import React from 'react';
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import RadioButton from "@amzn/meridian/radio-button";
import {addToObject, renderVerticalDivider} from "../util/CommonUtils";
import SortGridAllocationResourceSequenceForm from "./SortGridAllocationResourceSequenceForm";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import get from "lodash/get";
import {
    SortGridAllocationPickFormStates,
} from "../../Constants";
import Tooltip from "@amzn/meridian/tooltip";

const SortGridAllocationPickForm = (props) => {
    const { pickFormInputs, pickFormData, addToPickFormData } = props;


    const setResourceSequences = (value) => {
        addToPickFormData(SortGridAllocationPickFormStates.RESOURCE_SEQUENCES, value);
    }

    const addToParameters = (key, value) => {
        const parameters = addToObject(pickFormData[SortGridAllocationPickFormStates.PARAMETERS], key, value);
        addToPickFormData(SortGridAllocationPickFormStates.PARAMETERS, parameters);
    }



    const renderAlgorithmInputs = () => {
        return (
            <Column width={"50%"}>
                <Row>
                    <Text type={"h100"}>
                        <FormattedMessage id={"sortPaths.editPickType"} defaultMessage={"Edit pick sequencing type"}/>
                    </Text>
                </Row>
                { pickFormInputs.algorithmInputs.map(input => {
                    if(input !== undefined && input.isDisabled === undefined) {
                        input.isDisabled = false
                    }
                    return (
                        <RadioButton key={input.id}
                                     checked={get(pickFormData, [SortGridAllocationPickFormStates.ALGORITHM]) === input.value}
                                     onChange={value => addToPickFormData(SortGridAllocationPickFormStates.ALGORITHM, value)}
                                     name={input.id}
                                     value={input.value}
                                     disabled={!props.isAuthorized || input.isDisabled}
                        >
                        <Tooltip position={"right"} title={input.tooltipMessage}>
                            <FormattedMessage id={input.formattedId} defaultMessage={input.defaultLabel} />
                        </Tooltip>
                        </RadioButton>
                    );
                })}
            </Column>
        );
    }

    const renderParameterInputForm = () => {
        return (
            <Column spacing={"large"} width={"50%"} alignmentVertical={"top"}>
                <SortGridAllocationResourceSequenceForm algorithm={pickFormData.algorithm}
                                                        resourceSequenceInputs={pickFormInputs.resourceSequenceInputs}
                                                        resourceSequences={pickFormData.resourceSequences}
                                                        setResourceSequences={setResourceSequences}
                                                        isAuthorized={props.isAuthorized}
                />
                <Row>
                    <Column spacing={"large"}>
                        { pickFormInputs.parameterInputs.map(parameter => {
                            return (
                                <Row key={parameter.id}>
                                    <Column>
                                        <Text type={"h100"}>
                                            <FormattedMessage id={parameter.formattedId} defaultMessage={parameter.defaultLabel} />:
                                        </Text>
                                    </Column>
                                    <Column>
                                        <Row spacing={"large"}>
                                            { parameter.inputOptions.map(inputOption => {
                                                return (
                                                    <Column key={inputOption.id}>
                                                        <RadioButton checked={get(pickFormData, [SortGridAllocationPickFormStates.PARAMETERS, parameter.id]) === inputOption.value}
                                                                     onChange={value => addToParameters(parameter.id, value)}
                                                                     name={inputOption.id}
                                                                     value={inputOption.value}
                                                                     disabled={!props.isAuthorized || !parameter.ofPickPathTypes.includes(pickFormData.algorithm)} >
                                                            { inputOption.defaultLabel }
                                                        </RadioButton>
                                                    </Column>
                                                );
                                            })}
                                        </Row>
                                    </Column>
                                </Row>
                            );
                        })}
                    </Column>
                </Row>
            </Column>
        );
    }

    return (
        <Row>
            <Column width={"100%"}>
                <Row>
                    <Text type={"h200"}>
                        <FormattedMessage id={"sortPaths.pickPathInputFormTitle"} defaultMessage={"Edit grid sequencing options (pick path options)"}/>
                    </Text>
                </Row>

                <Row spacingInset={"none none none large"}>
                    <Column width={"100%"}>
                        <Row spacing={"large"} alignmentVertical={"stretch"}>
                            { renderAlgorithmInputs() }
                            { renderVerticalDivider("fill") }
                            { renderParameterInputForm()}
                        </Row>
                    </Column>
                </Row>
            </Column>
        </Row>
    );
}

export default SortGridAllocationPickForm;