import {inputType, outputType} from "../../../enums";
import {
    CYCLE,
    INCLUDED_LOCATION_OPTIONS,
    SORT_PATH_FILL_ALGORITHMS,
    SORT_PATH_PICK_ALGORITHMS,
    SortGridAllocationFillFormStates,
    SortGridAllocationCommonFormStates,
    SortGridAllocationFormStates,
    SortGridAllocationPickFormStates,
    SortingOrder, ENABLED_FOR_ALL_ALGORITHMS, SSD3_CYCLE, SSD3_NAME
} from "../../../Constants";
import {isDynamicCycle, isSideline} from "./CommonUtils";
import {getNodeId} from "../../../Utility";
import {FeatureConfigUtils} from "../FeatureConfigUtils";

export const getAvailableCycles = (cycleList, nodeId) => {
    let availableCycles = [];
    // Use an already available list of cycles for the site or else use the fallback list of pre-defined cycles
    if (cycleList && Array.isArray(cycleList) && cycleList.length) {
        availableCycles = availableCycles.concat(cycleList
            .filter(stationCycle => stationCycle && stationCycle.cycle)
            .filter(stationCycle => !nodeId || nodeId === stationCycle.stationCode)
            .map(stationCycle => ({
                id: stationCycle.cycle.cycleId,
                formattedId: stationCycle.cycle.cycleId,
                defaultLabel: stationCycle.cycle.cycleName,
                cycleType: stationCycle.cycle.cycleType,
                value: stationCycle.cycle.cycleName
            })));
    } else {
        availableCycles = availableCycles.concat([
            {
                id: "cycle_0",
                formattedId: "sortPaths.cycle_0",
                defaultLabel: "CYCLE 0",
                cycleType: "CORE",
                value: CYCLE.CYCLE_0,
            },
            {
                id: "cycle_1",
                formattedId: "sortPaths.cycle_1",
                defaultLabel: "CYCLE 1",
                cycleType: "CORE",
                value: CYCLE.CYCLE_1,
            },
            {
                id: "cycle_2",
                formattedId: "sortPaths.cycle_2",
                defaultLabel: "CYCLE 2",
                cycleType: "CORE",
                value: CYCLE.CYCLE_2,
            }]);
    }
    return availableCycles;
}

export const getAvailableNodes = (cycleList) => {
    let availableNodes = [getNodeId()];

    // Add to the list the nodes coming from the cycles for the site
    if (cycleList && Array.isArray(cycleList) && cycleList.length) {
        availableNodes = availableNodes.concat(cycleList
            .filter(stationCycle => stationCycle && stationCycle.stationCode && stationCycle.stationCode!==getNodeId())
            .map(stationCycle => stationCycle.stationCode));
    }

    return [...new Set(availableNodes.filter(nodeId => !!nodeId))];
}

export const getCoreCycleIncludedLocationInputs = () => {
    return [
        {
            id: "aislesRange",
            formattedId: "sortPaths.aislesRange",
            defaultLabel: "Aisles Range",
            inputOptions: [
                {
                    id: "aisleRangeUseAll",
                    formattedId: "sortPaths.includedLocations.useAll",
                    defaultLabel: "Use all",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.AISLES.USE_ALL
                },
                {
                    id: "aisleRangeCustom",
                    formattedId: "sortPaths.includedLocations.custom",
                    defaultLabel: "Custom",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                },
                {
                    id: "specifyAisleRange",
                    formattedId: "sortPaths.includedLocations.specifyAisleRange",
                    defaultLabel: "Specify aisle range",
                    inputType: inputType.TEXT,
                    placeholder: "e.g. A-1:A-10, B-1:B-10",
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                }
            ]
        }
    ];
}

export const getSSD3IncludedLocationInputs = () => {
    return [
        {
            id: "sortLocationsRange",
            formattedId: "sortPaths.sortLocationsRange",
            defaultLabel: "Sort Locations Range",
            inputOptions: [
                {
                    id: "sortLocationsRangeUseAll",
                    formattedId: "sortPaths.includedLocations.useAll",
                    defaultLabel: "Use all",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.SORT_LOCATIONS.USE_ALL
                },
                {
                    id: "sortLocationsRangeCustom",
                    formattedId: "sortPaths.includedLocations.custom",
                    defaultLabel: "Custom",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                },
                {
                    id: "specifySortLocationsRange",
                    formattedId: "sortPaths.includedLocations.specifySortLocationsRange",
                    defaultLabel: "Specify Included Locations range",
                    inputType: inputType.TEXT,
                    placeholder: "e.g. A.L01.OV:A.L02.OV , B.L01.OV:B.L02.OV",
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                }
            ]
        }
    ];
}

export const getExcludedLocationInputs = () => {
    return [
        {
            id: "excludedClusters",
            formattedId: "sortPaths.excludedClusters",
            defaultLabel: "Excluded clusters",
        },
        {
            id: "excludedAisles",
            formattedId: "sortPaths.excludedAisles",
            defaultLabel: "Excluded aisles",
        },
        {
            id: "excludedBins",
            formattedId: "sortPaths.excludedSortZones",
            defaultLabel: "Excluded sort-zones",
        },
    ]
}

export const getExcludedLocationInputsSSD3 = () => {
    return [
        {
            id: "excludedClusters",
            formattedId: "sortPaths.excludedZones",
            defaultLabel: "Excluded Zones",
            placeholder: "e.g. A,B "
        },
        {
            id: "excludedBins",
            formattedId: "sortPaths.excludedSortLocations",
            defaultLabel: "Excluded SortLocations",
            placeholder: "e.g. A.L01.OV, A.L12.OV"
        }
    ]
}

export const getIncludedLocationInputs = () => {
    return [
        {
            id: "aislesRange",
            formattedId: "sortPaths.aislesRange",
            defaultLabel: "Aisles Range",
            inputOptions: [
                {
                    id: "aisleRangeUseAll",
                    formattedId: "sortPaths.includedLocations.useAll",
                    defaultLabel: "Use all",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.AISLES.USE_ALL
                },
                {
                    id: "aisleRangeCustom",
                    formattedId: "sortPaths.includedLocations.custom",
                    defaultLabel: "Custom",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                },
                {
                    id: "specifyAisleRange",
                    formattedId: "sortPaths.includedLocations.specifyAisleRange",
                    defaultLabel: "Specify aisle range",
                    inputType: inputType.TEXT,
                    placeholder: "e.g. A-1:A-10, B-1:B-10",
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                }
            ]
        },
        {
            id: "columns",
            formattedId: "sortPaths.columns",
            defaultLabel: "Columns",
            inputOptions: [
                {
                    id: "columnsUseABC",
                    formattedId: "sortPaths.includedLocations.abc",
                    defaultLabel: "A,B,C",
                    customValue: "A,B,C",
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.COLUMNS.ABC,
                },
                {
                    id: "columnsCustom",
                    formattedId: "sortPaths.includedLocations.custom",
                    defaultLabel: "Custom",
                    customValue: null,
                    inputType: inputType.RADIO,
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM,
                },
                {
                    id: "specifyColumns",
                    formattedId: "sortPaths.includedLocations.specifyColumns",
                    defaultLabel: "Specify columns",
                    inputType: inputType.TEXT,
                    placeholder: "e.g. A,C",
                    value: INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM
                }
            ]
        }
    ]
}

export const getFillAlgorithmInputs = () => {
    return [
        {
            id: "standard",
            formattedId: "sortPaths.standardFill",
            defaultLabel: "Use fewer fingers to reduce the number of diverters (Standard fill)",
            value: SORT_PATH_FILL_ALGORITHMS.STANDARD,
        },
        {
            id: "combinatrix",
            formattedId: "sortPaths.combinatrixFill",
            defaultLabel: "Use more fingers when volume is high, to improve pick productivity (Combinatrix fill)",
            value: SORT_PATH_FILL_ALGORITHMS.COMBINATRIX,
        },
        {
            id: "block",
            formattedId: "sortPaths.blockFill",
            defaultLabel: "Allocate aisles in blocks to optimize pick productivity (Standard Block fill)",
            value: SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK
        },
    ];
}

export const getFillResourceSequenceInputs = (cycle, nodeId, cycleList) => {
    const fingerSequence = {
            id: "fingerSequence",
            formattedId: "sortPaths.editFingerSequence",
            defaultLabel: "Edit finger sequence (for non-standard layouts)",
            ofSortPathAlgorithms: [SORT_PATH_FILL_ALGORITHMS.COMBINATRIX],
            inputOptions: [
                {
                    id: "increasing",
                    formattedId: "sortPaths.editFingerSequence.increasing",
                    defaultLabel: "Increasing",
                    value: "INCREASING",
                },
                {
                    id: "decreasing",
                    formattedId: "sortPaths.editFingerSequence.decreasing",
                    defaultLabel: "Decreasing",
                    value: "DECREASING",
                },
                {
                    id: "custom",
                    formattedId: "sortPaths.editFingerSequence.custom",
                    defaultLabel: "Custom",
                    value: "CUSTOM",
                }
            ]
    };

    const clusterSequence = {
            id: "clusterSequence",
            formattedId: "sortPaths.editClusterSequence",
            defaultLabel: "Edit cluster sequence (for non-standard layouts)",
            ofSortPathAlgorithms: [
                SORT_PATH_FILL_ALGORITHMS.STANDARD,
                SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK,
                SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD
            ],
            isHiddenByDefault: true,
            inputOptions: [
                {
                    id: "a2z",
                    formattedId: "sortPaths.editClusterSequence.a2z",
                    defaultLabel: "A to Z",
                    value: "INCREASING",
                },
                {
                    id: "z2a",
                    formattedId: "sortPaths.editClusterSequence.z2a",
                    defaultLabel: "Z to A",
                    value: "DECREASING",
                },
                {
                    id: "diverter",
                    formattedId: "sortPaths.editClusterSequence.diverters",
                    defaultLabel: "Diverters",
                    value: "DIVERTER_COUNT",
                },
                {
                    id: "custom",
                    formattedId: "sortPaths.editClusterSequence.custom",
                    defaultLabel: "Custom",
                    value: "CUSTOM",
                }
            ]
    };

    const aisleSequence = {
            id: "aisleSequence",
            formattedId: "sortPaths.editAisleSequence",
            defaultTitle: "Edit aisle sequence(for non-standard layouts)",
            ofSortPathAlgorithms: [
                SORT_PATH_FILL_ALGORITHMS.STANDARD,
                SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK,
                SORT_PATH_FILL_ALGORITHMS.COMBINATRIX,
                SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD
            ],
            isDisabled: false,
            inputOptions: [
                {
                    id: "increasing",
                    formattedId: "sortPaths.editAisleSequence.increasing",
                    defaultLabel: "Increasing (low to high aisles)",
                    value: "INCREASING",
                },
                {
                    id: "decreasing",
                    formattedId: "sortPaths.editAisleSequence.decreasing",
                    defaultLabel: "Decreasing (high to low aisles)",
                    value: "DECREASING",
                }
            ]
    };

    const adtaPrioritySequence = {
        id: "adtaPrioritySequence",
        formattedId: "sortPaths.editADTAPrioritySequence",
        defaultTitle: "Edit ADTA clusters priority sequence in fill paths",
        ofSortPathAlgorithms: [
            SORT_PATH_FILL_ALGORITHMS.STANDARD,
            SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK,
            SORT_PATH_FILL_ALGORITHMS.COMBINATRIX,
            SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD
        ],
        isDisabled: false,
        inputOptions: [
            {
                id: "topPriority",
                formattedId: "sortPaths.editADTAPrioritySequence.topPriority",
                defaultLabel: "Use deprioritized tiers in ADTA clusters before using manual clusters",
                value: "TOP_PRIORITY",
            },
            {
                id: "mixedPriority",
                formattedId: "sortPaths.editADTAPrioritySequence.mixedPriority",
                defaultLabel: "Use prioritized tiers in manual clusters before using deprioritized tiers in ADTA clusters",
                value: "MIXED_PRIORITY",
            }
        ]
    };
    return isDynamicCycle(cycle, nodeId, cycleList) ? [clusterSequence, aisleSequence] :
        [fingerSequence, clusterSequence, aisleSequence, adtaPrioritySequence];
}

export const getFillParameterInputs = (cycle, nodeId, cycleList) => {
    const splitPercentage =   {
            id: "splitPercentage",
            formattedId: "sortPaths.splitPercentage",
            defaultLabel: "Edit % finger by finger fill",
            inputType: inputType.RADIO,
            ofFillTypes: [SORT_PATH_FILL_ALGORITHMS.COMBINATRIX],
            inputOptions: [
                {
                    id: "0",
                    defaultLabel: "0%",
                    value: "0",
                },
                {
                    id: "35",
                    defaultLabel: "35%",
                    value: "0.35",
                },
                {
                    id: "60",
                    defaultLabel: "60%",
                    value: "0.6",
                },
                {
                    id: "75",
                    defaultLabel: "75%",
                    value: "0.75",
                },
                {
                    id: "100",
                    defaultLabel: "100%",
                    value: "1",
                }
            ],
    };
    return isDynamicCycle(cycle, nodeId, cycleList) ? [] : [splitPercentage];
}

export const getSSD3GenericResourceSequenceInputs = () => {
    return [
        {
            id: "sortLocationSequence",
            formattedId: "sortPaths.editSortLocationSequence",
            defaultLabel: "Edit Location sequence",
            ofSortPathAlgorithms: [ENABLED_FOR_ALL_ALGORITHMS],
            inputOptions: [
                {
                    id: "increasing",
                    formattedId: "sortPaths.editSortLocationSequence.increasing",
                    defaultLabel: "Increasing Numbered order",
                    value: "INCREASING",
                },
                {
                    id: "decreasing",
                    formattedId: "sortPaths.editSortLocationSequence.decreasing",
                    defaultLabel: "Decreasing Numbered order",
                    value: "DECREASING",
                }
            ]
        }
    ]
};

export const getScienceModelOptimizationParameterInputs = (cycle, nodeId, cycleList) => {
    const optimizationParameters = {
        id: "scienceModelOptimizationParameter",
        formattedId: "sortPaths.optimizationParameters",
        defaultLabel: "Edit optimization parameters",
        inputType: inputType.CHECKBOX,
        inputOptions: [
            {
                id: "optimiseClusterUsage",
                formattedId: "sortPaths.optimizationParameters.optimiseClusterUsage",
                defaultLabel: "Minimize the number of diverters needed (recommended)",
                defaultValue: true,
            },
            {
                id: "balancingSortZonesInClusters",
                formattedId: "sortPaths.optimizationParameters.balancingSortZonesInClusters",
                defaultLabel: "Balance the sort zones used across open clusters",
                defaultValue: false,
            }
        ]
    };
    return isDynamicCycle(cycle, nodeId, cycleList) ? [] : [optimizationParameters];
};

export const getFillCommonParameterInputs = (cycle, nodeId, cycleList) => {
    const deprioritizedTiers = {
        id: "deprioritizedTiers",
        formattedId: "sortPaths.deprioritizeTiers",
        defaultLabel: "Deprioritize tiers for non ADTA clusters (for health and safety reasons)",
        inputType: inputType.RADIO,
        outputType: outputType.ARRAY,
        inputOptions: [
            {
                id: "one",
                formattedId: "sortPaths.deprioritizeTiers.one",
                defaultLabel: "1 (to minimize bending down during stow and pick)",
                value: "1",
            },
            {
                id: "four",
                formattedId: "sortPaths.deprioritizeTiers.four",
                defaultLabel: "4 (to minimize reaching high up during stow and pick)",
                value: "4",
            },
            {
                id: "none",
                formattedId: "sortPaths.deprioritizeTiers.none",
                defaultLabel: "None (all the tiers will be used in the sort path)",
                value: "none",
            },
        ]
    };

    const deprioritizedTiersForADTAClusters = {
        id: "deprioritizedTiersForADTAClusters",
        formattedId: "sortPaths.deprioritizedTiersForADTAClusters",
        defaultLabel: "Deprioritize tiers for ADTA clusters (for health and safety reasons)",
        inputType: inputType.RADIO,
        outputType: outputType.ARRAY,
        inputOptions: [
            {
                id: "one",
                formattedId: "sortPaths.deprioritizeTiers.one",
                defaultLabel: "1 (to minimize bending down during stow and pick)",
                value: "1",
            },
            {
                id: "four",
                formattedId: "sortPaths.deprioritizeTiers.four",
                defaultLabel: "4 (to minimize reaching high up during stow and pick)",
                value: "4",
            },
            {
                id: "none",
                formattedId: "sortPaths.deprioritizeTiers.none",
                defaultLabel: "None (all the tiers will be used in the sort path)",
                value: "none",
            },
        ]
    };

    // const prioritizeEvenAisles = {
    //     id: "prioritizeEvenAisles",
    //     formattedId: "sortPaths.prioritizeEvenAisles",
    //     defaultLabel: "Prioritize even aisles (for new stations with low volume)",
    //     inputType: inputType.RADIO,
    //     isDisabled: true,
    //     inputOptions: [
    //         {
    //             id: "no",
    //             formattedId: "sortPaths.prioritizeEvenAisles.no",
    //             defaultLabel: "No",
    //             value: false,
    //         },
    //         {
    //             id: "yes",
    //             formattedId: "sortPaths.prioritizeEvenAisles.yes",
    //             defaultLabel: "Yes",
    //             value: true
    //         }
    //     ]
    // };
    return isDynamicCycle(cycle, nodeId, cycleList) ? [deprioritizedTiers] : [deprioritizedTiers, deprioritizedTiersForADTAClusters];
}

export const getPickAlgorithmInputs = () => {
    return [
        {
            id: "noZebraType",
            formattedId: "sortPaths.zebraPatternType.noZebraType",
            defaultLabel: "No Zebra Type",
            tooltipMessage: "Traverse sort grid in a sequential manner",
            value: SORT_PATH_PICK_ALGORITHMS.NO_ZEBRA_PATTERN,
        },
        {
            id: "alternateAisleZebraType",
            formattedId: "sortPaths.zebraPatternType.alternateAisleZebraType",
            defaultLabel: "Alternate Aisle Zebra Type",
            tooltipMessage: "Traverse sort grid filling half aisle, and then skipping the next aisle",
            value: SORT_PATH_PICK_ALGORITHMS.ALTERNATE_AISLE_ZEBRA_PATTERN,
        },
        {
            id: "oneAisleSkippingZebraType",
            formattedId: "sortPaths.zebraPatternType.oneAisleSkippingZebraType",
            defaultLabel: "One Aisle Skipping Zebra Type",
            tooltipMessage: "Traverse sort grid skipping the next aisle",
            value: SORT_PATH_PICK_ALGORITHMS.ONE_AISLE_SKIPPING_ZEBRA_PATTERN,
        },
        {
            id: "twoAisleSkippingZebraType",
            formattedId: "sortPaths.zebraPatternType.twoAisleSkippingZebraType",
            defaultLabel: "Two Aisle Skipping Zebra Type",
            tooltipMessage: "Traverse sort grid skipping the next 2 aisles",
            value: SORT_PATH_PICK_ALGORITHMS.TWO_AISLE_SKIPPING_ZEBRA_PATTERN,
        },
        {
            id: "partialColumnZebraType",
            formattedId: "sortPaths.zebraPatternType.partialColumnZebraType",
            defaultLabel: "Partial Column Zebra Type",
            tooltipMessage: "Traverse sort grid filling an aisle column by column",
            value: SORT_PATH_PICK_ALGORITHMS.PARTIAL_COLUMN_ZEBRA_PATTERN,
        },
    ];
};

export const getClusterConstraintParametersForNode = () => {
    return {
        maxClusterCapacityForNode: {
            id: "maxClusterCapacityForNode",
            formattedId: "sortPaths.maxClusterCapacityForNode",
            defaultLabel: "Enter Max Cluster Capacity (As percentage eg: 90 or 80)",
            placeholder: "percentage capacity",
            inputRegularExpression: new RegExp(/^(([0-9]{0,2}(\.[0-9]{0,3})?)?|100?)$/)
        },
        adtaClusterIdentifierForNode: {
            id: "adtaClusterIdentifierForNode",
            formattedId: "sortPaths.adtaClusterIdentifierForNode",
            defaultLabel: "Enter ADTA Cluster Identifier (As true or false)",
            placeholder:"true/false",
        },
        blockLength: {
            id: "blockLength",
            formattedId: "sortPaths.blockLength",
            defaultLabel: "Enter block length for clusters (As no. of sort zones)",
            placeholder: "no. of sort zones",
            inputRegularExpression: new RegExp(/^[0-9]{0,4}$/)
        }
    };
};

export const getPickResourceSequenceInputs = () => {
    return [
        {
            id: "clusterSequence",
            formattedId: "sortPaths.editClusterSequence",
            defaultLabel: "Edit cluster sequence (for non-standard layouts)",
            ofSortPathAlgorithms: [ENABLED_FOR_ALL_ALGORITHMS],
            inputOptions: [
                {
                    id: "a2z",
                    formattedId: "sortPaths.editClusterSequence.a2z",
                    defaultLabel: "A to Z",
                    value: "INCREASING",
                },
                {
                    id: "z2a",
                    formattedId: "sortPaths.editClusterSequence.z2a",
                    defaultLabel: "Z to A",
                    value: "DECREASING",
                },
                {
                    id: "custom",
                    formattedId: "sortPaths.editClusterSequence.custom",
                    defaultLabel: "Custom",
                    value: "CUSTOM",
                }
            ]
        },
        {
            id: "aisleSequence",
            formattedId: "sortPaths.editAisleSequence",
            defaultLabel: "Edit aisle sequence(for non-standard layouts)",
            ofSortPathAlgorithms: [ENABLED_FOR_ALL_ALGORITHMS],
            inputOptions: [
                {
                    id: "increasing",
                    formattedId: "sortPaths.editAisleSequence.increasing",
                    defaultLabel: "Increasing (low to high aisles)",
                    value: "INCREASING",
                },
                {
                    id: "decreasing",
                    formattedId: "sortPaths.editAisleSequence.decreasing",
                    defaultLabel: "Decreasing (high to low aisles)",
                    value: "DECREASING",
                }
            ]
        },
    ]
};

export const getPickParameterInputs = () => {
    return [
        {
            id: "columnLengthForZebraPath",
            formattedId: "sortPaths.columnLengthForZebraPath",
            defaultLabel: "Edit Column length for Partial Column Zebra Path",
            ofPickPathTypes: [SORT_PATH_PICK_ALGORITHMS.PARTIAL_COLUMN_ZEBRA_PATTERN],
            inputOptions: [
                {
                    id: "3",
                    defaultLabel: "3",
                    value: "3",
                },
                {
                    id: "4",
                    defaultLabel: "4",
                    value: "4",
                },
                {
                    id: "5",
                    defaultLabel: "5",
                    value: "5",
                }
            ]
        }
    ]
};

export const getInitialFormInputState = (cycle, nodeId, cycleList) => {
    return {
        commonFormInputs: {
            availableCycles: getAvailableCycles(cycleList, nodeId),
            availableNodes: getAvailableNodes(cycleList),
            includedLocationInputs: FeatureConfigUtils.getIsSSD3EnabledStation() ? getSSD3IncludedLocationInputs() :
                isDynamicCycle(cycle, nodeId, cycleList) ? getIncludedLocationInputs() : getCoreCycleIncludedLocationInputs(),
            excludedLocationInputs: FeatureConfigUtils.getIsSSD3EnabledStation() ? getExcludedLocationInputsSSD3() :getExcludedLocationInputs()
        },
        fillFormInputs: {
            algorithmInputs: getFillAlgorithmInputs(),
            resourceSequenceInputs: FeatureConfigUtils.getIsSSD3EnabledStation() ? getSSD3GenericResourceSequenceInputs() :
                getFillResourceSequenceInputs(cycle, nodeId, cycleList),
            parameterInputs: getFillParameterInputs(cycle, nodeId, cycleList),
            commonParameterInputs: getFillCommonParameterInputs(cycle, nodeId, cycleList),
            clusterConstraintParametersForNode: getClusterConstraintParametersForNode(),
            scienceModelOptimizationParameterInputs:getScienceModelOptimizationParameterInputs(cycle, nodeId, cycleList),
        },
        pickFormInputs: {
            algorithmInputs: getPickAlgorithmInputs(),
            resourceSequenceInputs: FeatureConfigUtils.getIsSSD3EnabledStation() ? getSSD3GenericResourceSequenceInputs():
                getPickResourceSequenceInputs(),
            parameterInputs: getPickParameterInputs(),
        },
    };
};

/**
 * Method to return the initial form data to be prefilled in the form
 *
 * @param cycle selected cycle
 * @param nodeId selected node id
 * @param cycleList selected cycle list
 * @param availableCycles list of all available cycles for the resource station
 * @returns form data
 */

export const getInitialFormDataState = (cycle, nodeId, cycleList, availableCycles) => {
    let initialFormData = {commonFormData: {}, fillFormData: {}, pickFormData: {}};
    // We do not provide all options for Dynamic Cycles, hence keeping default values for some parameters
    if (isDynamicCycle(cycle, nodeId, availableCycles)) {
        initialFormData = {
            [SortGridAllocationFormStates.COMMON_FORM_DATA]: {},
            [SortGridAllocationFormStates.FILL_FORM_DATA]: {
                [SortGridAllocationFillFormStates.ALGORITHM]: SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD,
                [SortGridAllocationFillFormStates.RESOURCE_SEQUENCES]: {
                    clusterSequence: [SortingOrder.INCREASING, null]
                }
            },
            [SortGridAllocationFormStates.PICK_FORM_DATA]: {
                [SortGridAllocationPickFormStates.ALGORITHM]: SORT_PATH_PICK_ALGORITHMS.ALTERNATE_AISLE_ZEBRA_PATTERN,
                [SortGridAllocationPickFormStates.RESOURCE_SEQUENCES]: {
                    clusterSequence: [SortingOrder.INCREASING, null]
                }
            }
        };
    } else if (isSideline(cycle, nodeId, availableCycles)) {
        initialFormData = {
            [SortGridAllocationFormStates.COMMON_FORM_DATA]: {},
            [SortGridAllocationFormStates.FILL_FORM_DATA]: {
                [SortGridAllocationFillFormStates.ALGORITHM]: SORT_PATH_FILL_ALGORITHMS.SIDELINE_BLOCK_FILL,
                [SortGridAllocationFillFormStates.RESOURCE_SEQUENCES]: {
                    clusterSequence: [SortingOrder.INCREASING, null]
                }
            },
            [SortGridAllocationFormStates.PICK_FORM_DATA]: {
                [SortGridAllocationPickFormStates.ALGORITHM]: SORT_PATH_PICK_ALGORITHMS.SIDELINE_ZEBRA_PATH,
                [SortGridAllocationPickFormStates.RESOURCE_SEQUENCES]: {
                    clusterSequence: [SortingOrder.INCREASING, null]
                }
            }
        };
    } else if (FeatureConfigUtils.getIsSSD3EnabledStation()) {
        initialFormData = {
            [SortGridAllocationFormStates.COMMON_FORM_DATA]: {},
            [SortGridAllocationFormStates.FILL_FORM_DATA]: {
                [SortGridAllocationFillFormStates.ALGORITHM]: SORT_PATH_FILL_ALGORITHMS.SAME_DAY_STANDARD_FILL,
                [SortGridAllocationFillFormStates.RESOURCE_SEQUENCES]: {
                    sortLocationSequence: [SortingOrder.INCREASING, null]
                }
            },
            [SortGridAllocationFormStates.PICK_FORM_DATA]: {
                [SortGridAllocationPickFormStates.ALGORITHM]: SORT_PATH_PICK_ALGORITHMS.SAME_DAY_STANDARD_PATH,
                [SortGridAllocationPickFormStates.RESOURCE_SEQUENCES]: {
                    sortLocationSequence: [SortingOrder.INCREASING, null]
                }
            }
        };
    }


        if (nodeId) {
            initialFormData.commonFormData[SortGridAllocationCommonFormStates.NODE_ID] = nodeId;
        }

        if (cycle) {
            initialFormData.commonFormData[SortGridAllocationCommonFormStates.CYCLE] = cycle;
        }

        if (cycleList) {
            initialFormData.commonFormData[SortGridAllocationCommonFormStates.CYCLE_LIST] = cycleList;
        }

        //Backend services (SPG) requires cycle input. Using a constant name for SSD cycles instead
        //of getting it from cycle list. (SSD stations do not use the concept of cycles during operations, only a single
        // cycle exists)
        if (FeatureConfigUtils.getIsSSD3EnabledStation()) {
            initialFormData.commonFormData[SortGridAllocationCommonFormStates.CYCLE] = SSD3_CYCLE;
            initialFormData.commonFormData[SortGridAllocationCommonFormStates.NAME] = SSD3_NAME;
        }

        return initialFormData;


};

