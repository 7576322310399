import React, { Component } from 'react';
import Column from "@meridian/components/column";
import Loader from "@meridian/components/loader";

class Loading extends Component {
    render() {
        return (        
          <Column height={this.props.height || "300px"} alignment="bottom center">
            <Loader />
          </Column>
        )
    };
}

export default Loading;