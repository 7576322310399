import React, {createContext, useReducer} from "react"
import {CapabilityReducer} from "../reducers/CapabilityReducer";
import {STRINGS} from "../Constants";

export const CapabilityContext = createContext()

const CapabilityContextProvider = (props) => {
    const [ capability, dispatch ] = useReducer(CapabilityReducer, {data:STRINGS.EMPTY})
    return (
        <CapabilityContext.Provider value = {{capability, dispatch}}>
            {props.children}
        </CapabilityContext.Provider>
    )
}

export default CapabilityContextProvider
