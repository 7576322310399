export const metricType = {
    COUNT: "count",
    TIME: "time"
};

export const inputType = {
    CHECKBOX: "checkbox",
    RADIO: "radio",
    TEXT: "text"
}

export const outputType = {
    ARRAY: "array"
}