import React from "react";
import {FormattedMessage} from "react-intl";
import Text from "@amzn/meridian/text";
import {addToObject, renderVerticalDivider} from "../util/CommonUtils";
import RadioButton from "@amzn/meridian/radio-button";
import SortGridAllocationResourceSequenceForm from "./SortGridAllocationResourceSequenceForm";
import Checkbox from "@amzn/meridian/checkbox";
import get from "lodash/get";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import {inputType, outputType} from "../../enums";
import Divider from "@amzn/meridian/divider";
import {
    SortGridAllocationCommonFormStates,
    SortGridAllocationFillFormStates,
} from "../../Constants";
import {isDynamicCycle} from "../util/sort-paths/CommonUtils";
import Input from "@amzn/meridian/input";

const SortGridAllocationFillForm = (props) => {
    const { fillFormInputs, formData: { commonFormData, fillFormData }, clusterResources, addToFillFormData, cycleList } = props;


    const setResourceSequences = (value) => {
        addToFillFormData(SortGridAllocationFillFormStates.RESOURCE_SEQUENCES, value);
    }

    const addToParameters = (key, value) => {
        const parameters = addToObject(fillFormData[SortGridAllocationFillFormStates.PARAMETERS], key, value);
        addToFillFormData(SortGridAllocationFillFormStates.PARAMETERS, parameters);
    }

    const addToCommonParameters = (key, value, parameterOutputType) => {
        let commonParameters;
        switch (parameterOutputType) {
            case outputType.ARRAY:
                commonParameters = addToObject(fillFormData[SortGridAllocationFillFormStates.COMMON_PARAMETERS], key, value==='none' ? [] : [value]);
                break;
            default:
                commonParameters = addToObject(fillFormData[SortGridAllocationFillFormStates.COMMON_PARAMETERS], key, value)
        }
        addToFillFormData(SortGridAllocationFillFormStates.COMMON_PARAMETERS, commonParameters);
    }

    const getCommonParameters = (key, parameterOutputType) => {
        switch (parameterOutputType) {
            case outputType.ARRAY:
                return get(fillFormData, [SortGridAllocationFillFormStates.COMMON_PARAMETERS, key, 0]);
            default:
                return get(fillFormData, [SortGridAllocationFillFormStates.COMMON_PARAMETERS, key]);
        }
    }

    const isKeyPresentInCommonParameters = (key) => {
        if (fillFormData && fillFormData.commonParameters && Object.keys(fillFormData.commonParameters).length !==0) {
            return fillFormData.commonParameters[key] !== undefined;
        }
        return false;
    };

    const renderAlgorithmInputForm = () => {
        return (
            <Column width={"50%"}>
                <Row>
                    <Text type={"h100"}>
                        <FormattedMessage id={"sortPaths.editFillType"} defaultMessage={"Edit fill type"}/>
                    </Text>
                </Row>
                { fillFormInputs.algorithmInputs.map(input => {
                    return (
                        <RadioButton key={input.id}
                                     checked={get(fillFormData, [SortGridAllocationFillFormStates.ALGORITHM]) === input.value}
                                     onChange={value => { addToFillFormData(SortGridAllocationFillFormStates.ALGORITHM, value) }}
                                     name={input.id}
                                     value={input.value}
                                     disabled={!props.isAuthorized || input.isDisabled} >
                            <FormattedMessage id={input.formattedId} defaultMessage={input.defaultLabel} />
                        </RadioButton>
                    );
                })}
            </Column>
        );
    }

    const renderParameterInputForm = () => {
        const COMMON_PARAMETER_WIDTH = fillFormInputs.commonParameterInputs.length === 1 ? "100%" : "50%";
        return (
            <Column spacing={"large"} width={COMMON_PARAMETER_WIDTH} alignmentVertical={"top"}>
                <SortGridAllocationResourceSequenceForm algorithm={fillFormData.algorithm}
                                                        resourceSequenceInputs={fillFormInputs.resourceSequenceInputs}
                                                        resourceSequences={fillFormData.resourceSequences}
                                                        setResourceSequences={setResourceSequences}
                                                        isAuthorized={props.isAuthorized}
                />
                <Row>
                    <Column spacing={"large"}>
                        { fillFormInputs.parameterInputs.map(parameter => {
                            return (
                                <Row key={parameter.id}>
                                    <Column>
                                        <Text type={"h100"}>
                                            <FormattedMessage id={parameter.formattedId} defaultMessage={parameter.defaultLabel} />:
                                        </Text>
                                    </Column>
                                    <Column>
                                        <Row spacing={"large"}>
                                            { parameter.inputOptions.map(inputOption => {
                                                return (
                                                    <Column key={inputOption.id}>
                                                        <RadioButton checked={get(fillFormData, [SortGridAllocationFillFormStates.PARAMETERS, parameter.id]) === inputOption.value}
                                                                     onChange={value => addToParameters(parameter.id, value)}
                                                                     name={inputOption.id}
                                                                     value={inputOption.value}
                                                                     disabled={!props.isAuthorized || !parameter.ofFillTypes.includes(fillFormData.algorithm)} >
                                                            { inputOption.defaultLabel }
                                                        </RadioButton>
                                                    </Column>
                                                );
                                            })}
                                        </Row>
                                    </Column>
                                </Row>
                            );
                        })}
                    </Column>
                </Row>
            </Column>
        );
    }

    const renderCommonParameterInput = (parameter) => {
        switch (parameter.inputType) {
            case inputType.CHECKBOX:
                return (
                    parameter.inputOptions.map(inputOption => {
                        return (
                            <Row key={inputOption.id}>
                                <Checkbox checked={isKeyPresentInCommonParameters(inputOption.id) ? getCommonParameters(inputOption.id) : inputOption.defaultValue}
                                          onChange={flag => {
                                              flag ? addToCommonParameters(inputOption.id, true) : addToCommonParameters(inputOption.id, false);
                                          }}
                                          disabled={!props.isAuthorized || parameter.isDisabled || inputOption.isDisabled}>
                                    <FormattedMessage id={inputOption.id} defaultMessage={inputOption.defaultLabel} />
                                </Checkbox>
                            </Row>
                        );
                    })
                );
            case inputType.RADIO:
                return (
                    parameter.inputOptions.map(inputOption => {
                        return (
                            <Row key={inputOption.id}>
                                <RadioButton checked={getCommonParameters(parameter.id, parameter.outputType) ?
                                    getCommonParameters(parameter.id, parameter.outputType) === inputOption.value : inputOption.value === 'none'}
                                             onChange={value => {
                                                 addToCommonParameters(parameter.id, value, parameter.outputType)
                                             }}
                                             name={inputOption.id}
                                             value={inputOption.value}
                                             disabled={!props.isAuthorized || parameter.isDisabled || inputOption.isDisabled} >
                                    <FormattedMessage id={inputOption.formattedId} defaultMessage={inputOption.defaultLabel} />
                                </RadioButton>
                            </Row>
                        )
                    })
                );
            default:
                return null;
        }
    }

    const renderTextFieldFormBasedOnClusterResources = (parameter) => {
        const regExPatten = parameter.inputRegularExpression;
        return (
            <Column key={parameter.id} width={"50%"}>
                <Row>
                    <Text type={"h100"}>
                        <FormattedMessage id={parameter.formattedId}
                                          defaultMessage={parameter.defaultLabel}/>
                    </Text>
                </Row>
                {clusterResources.map(resourceData => {
                    return (
                        <Row key={resourceData.label}>
                            <Text type={"b300"}>
                                {resourceData.label + " :"}
                            </Text>
                            <Column maxWidth={"200px"}>
                                <Input value={get(get(fillFormData, [SortGridAllocationFillFormStates.COMMON_PARAMETERS,
                                    parameter.id], []), resourceData.label)}
                                       onChange={value => {
                                           let values = get(fillFormData, [SortGridAllocationFillFormStates.COMMON_PARAMETERS,
                                               parameter.id], []);
                                           value ? values = addToObject(values, resourceData.label, value.trim()) :
                                               delete values[resourceData.label];
                                           addToCommonParameters(parameter.id, values)
                                       }}
                                       placeholder={parameter.placeholder}
                                       pattern={regExPatten}
                                       type="text"
                                       disabled={!props.isAuthorized || parameter.isDisabled}
                                       data-testid={parameter.id + resourceData.label}/>
                            </Column>
                        </Row>
                    );
                })}
            </Column>
        )
    };

    const renderScienceModelOptimizationParameterInputForm = () => {
        const parameter = fillFormInputs.scienceModelOptimizationParameterInputs[0];
        return (
            <Column key={parameter.id} width={"100%"}>
                <Row>
                    <Text type={"h200"}>
                        <FormattedMessage id={parameter.formattedId} defaultMessage={parameter.defaultLabel} />
                    </Text>
                </Row>
                { renderCommonParameterInput(parameter) }
            </Column>
        );
    };

    const renderClusterConstraintParametersInputForm = () => {
        if (Array.isArray(clusterResources) && clusterResources.length) {
            return (
                <Column width={"100%"}>
                    <Row width={"100%"}>
                        <Divider size={"small"}/>
                    </Row>
                    <Row spacing={"large"} width={"100%"} alignmentVertical={"stretch"}>
                        {renderTextFieldFormBasedOnClusterResources(fillFormInputs.clusterConstraintParametersForNode.maxClusterCapacityForNode)}
                        {renderVerticalDivider(fillFormInputs.clusterConstraintParametersForNode.maxClusterCapacityForNode.formattedId)}
                        {renderTextFieldFormBasedOnClusterResources(fillFormInputs.clusterConstraintParametersForNode.blockLength)}
                    </Row>
                    <Row width={"100%"}>
                        <Divider size={"small"}/>
                    </Row>
                    <Row spacing={"large"} width={"100%"} alignmentVertical={"stretch"}>
                        {renderTextFieldFormBasedOnClusterResources(fillFormInputs.clusterConstraintParametersForNode.adtaClusterIdentifierForNode)}
                    </Row>

                </Column>
            )
        } else {
            return null;
        }
    };

    const renderCommonParameterInputForm = () => {
        return (
            <Row spacing={"large"} width={"100%"} alignmentVertical={"stretch"}>
                { [...Array(2 * fillFormInputs.commonParameterInputs.length).keys()].map((index) => {
                    if (index % 2 === 0) {
                        const parameter = fillFormInputs.commonParameterInputs[index/2];
                        const COMMON_PARAMETER_WIDTH = fillFormInputs.commonParameterInputs.length === 1 ? "100%" : "50%";
                        return (
                            <Column key={parameter.id} width={COMMON_PARAMETER_WIDTH}>
                                <Row>
                                    <Text type={"h100"}>
                                        <FormattedMessage id={parameter.formattedId} defaultMessage={parameter.defaultLabel} />
                                    </Text>
                                </Row>
                                { renderCommonParameterInput(parameter) }
                            </Column>
                        );
                    } else if (index % 4 === 1 && index < 2 * fillFormInputs.commonParameterInputs.length - 1) {
                        return renderVerticalDivider(index);
                    }

                    return null;
                })}
            </Row>
        )
    }

    return (
        <Row>
            <Column width={"100%"}>
                {!isDynamicCycle(get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE]),
                    get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]), cycleList) &&
                    <>
                        <Row spacingInset={"none none none none"}>
                            <Column width={"100%"}>
                                {renderScienceModelOptimizationParameterInputForm()}
                            </Column>
                        </Row>
                        <Row width={"100%"}>
                            <Divider size={"small"}/>
                        </Row>
                    </>
                }
                <Row>
                    <Text type={"h200"}>
                        <FormattedMessage id={"sortPaths.fillPathInputFormTitle"} defaultMessage={"Edit grid allocation options (fill path options)"}/>
                    </Text>
                </Row>
                <Row spacingInset={"none none none large"}>
                    <Column width={"100%"}>
                        {!isDynamicCycle(get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE]),
                            get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]), cycleList) &&
                            <>
                                <Row spacing={"large"} alignmentVertical={"stretch"}>
                                    {renderAlgorithmInputForm()}
                                    {renderVerticalDivider("fill")}
                                    {renderParameterInputForm()}
                                </Row>
                                <Row width={"100%"}>
                                    <Divider size={"small"}/>
                                </Row>
                            </>
                        }
                        {isDynamicCycle(get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE]),
                                get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]), cycleList) &&
                            <>
                                {renderParameterInputForm()}
                                <Row width={"100%"}>
                                    <Divider size={"small"}/>
                                </Row>
                            </>
                        }
                        { renderCommonParameterInputForm() }
                        {!isDynamicCycle(get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE]),
                                get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]), cycleList) &&
                            <>
                                {renderClusterConstraintParametersInputForm()}
                            </>
                        }
                    </Column>
                </Row>
            </Column>
        </Row>
    );
};

export default SortGridAllocationFillForm;