import React, {useEffect, useState, useCallback, useRef, createRef} from 'react'
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Table, {TableCell, TableRow} from "@amzn/meridian/table"
import Loader from "@amzn/meridian/loader";
import Pagination from "@amzn/meridian/pagination";
import {FormattedMessage} from "react-intl";
import Button from "@amzn/meridian/button";
import SearchField from "@amzn/meridian/search-field"
import Menu, { MenuItem } from "@amzn/meridian/menu"
import Modal from "@amzn/meridian/modal";
import InputGroup from "@amzn/meridian/input-group";
import Input from "@amzn/meridian/input";
import ModalFooter from "@amzn/meridian/modal/modal-footer";
import Select from "@amzn/meridian/select";
import SelectOption from "@amzn/meridian/select/select-option";
import {addBinType, fetchBinTypes, updateBinTypes} from "../resources/BinTypesResource";
import Alert from "@amzn/meridian/alert";
import ErrorPage from "./util/ErrorPage";
import Box from "@amzn/meridian/box";

/**
 * React component for bin types page in SCC.
 */
const BinTypes = (props) => {

    const PAGE_LENGTH = 25;
    const NUMBER_OF_INPUTS = 6;
    const ALERT_TIMEOUT = 4000;
    const [sortState, setSortState] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [binTypesList, setBinTypesList] = useState([]);
    const [originalBinTypesList, setOriginalBinTypesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [openActionsMenu, setOpenActionsMenu] = useState({});
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const actionButtonRefs = useRef([]);
    const [openModal, setOpenModal] = useState(false);
    const [dataVersion, setDataVersion] = useState(0);
    const [formValues, setFormValues] = useState([]);
    const [formValueValid, setFormValueValid] = useState(Array(6).fill(true));
    const [disableTypeFormValue, setDisableTypeFormValue] = useState(false);
    const [disableScopeFormValue, setDisableScopeFormValue] = useState(false);
    const [isShowErrorAlert, setIsShowErrorAlert] = useState(false);
    const [isAddBinType, setIsAddBinType] = useState(false);
    const [isFetchError, setIsFetchError] = useState(undefined);
    const formValueValidators = Array(2).fill(textValidator).concat(Array(4).fill(numberValidator));

    useEffect(() => {
        let response = fetchBinTypes();
        response.then(response => {
            setIsLoading(false);
            setOriginalBinTypesList(response.binTypes);
            setBinTypesList(response.binTypes);
        }, error => {
            setIsFetchError(error);
        });
    }, [dataVersion]);

    useEffect(() => {
        if (searchQuery === "") {
            setBinTypesList(originalBinTypesList);
        }
        matchSearchQuery(searchQuery)
    }, [searchQuery]);

    const onClickCancelButton = useCallback(() => {
        setOpenModal(false);
        clearFormValues();
    }, []);

    const onClickSaveButton = useCallback((formValues, currentDataVersion, isAddNewBinType) => {
        const binType = getBinTypeFromFormValues(formValues);
        modifyBinTypes(binType, currentDataVersion, isAddNewBinType);
        setOpenModal(false);
        setIsLoading(true);
        setCurrentPage(1);
        clearFormValues();
    }, []);

    const onFormValuesChanged = useCallback((formValues) => {
        setFormValues(formValues);
        const valid = validateFormValues(formValues);
        let isAllValid = valid.every(e => e === true);
        const filteredFormValues = formValues.filter(value => value && value.toString().length > 0);
        if (filteredFormValues.length === NUMBER_OF_INPUTS && isAllValid === true) {
            setDisableSaveButton(false);
        } else {
            setDisableSaveButton(true);
        }
    }, []);

    const onClickActionsButton = useCallback((index) => {
        let _open = {...openActionsMenu};
        _open[index] = true;
        setOpenActionsMenu(_open);
    }, []);

    const onClickAddBinTypes = useCallback((index) => {
        setOpenModal(true);
        setIsAddBinType(true);
    }, []);

    const onClickUpdateBinTypes = useCallback((binType) => {
        setDisableTypeFormValue(true);
        setDisableScopeFormValue(true);
        setIsAddBinType(false);
        let newFormValues = getFormValuesFromBinType(binType);
        setFormValues(newFormValues);
        setOpenModal(true);
    }, []);

    const onCloseActions = useCallback((index) => {
        let _open = {...openActionsMenu};
        _open[index] = false;
        setOpenActionsMenu(_open)
    }, []);

    const sortData = (sortState)  => {

        setSortState(sortState);
        switch(sortState.sortDirection) {
            case 'ascending': setBinTypesList(binTypesList.sort((a, b) => a[sortState.sortColumn].toString().localeCompare(b[sortState.sortColumn].toString(), 'en', { numeric: true }))); break;
            case 'descending' : setBinTypesList(binTypesList.sort((a,b)=> b[sortState.sortColumn].toString().localeCompare(a[sortState.sortColumn].toString(), 'en', { numeric: true }))); break;
            default : break;
        }
    };

    function textValidator(text) {
        return /^[0-9a-zA-Z_\s]+$/.test(text);
    }

    function numberValidator(number) {
        return /^[0-9]+(\.[0-9]+)?([eE][+-]?[0-9]+)?$/.test(number);
    }

    function clearFormValues() {
        setFormValues([]);
        setFormValueValid(Array(6).fill(true));
        setDisableTypeFormValue(false);
        setDisableScopeFormValue(false);
    }

    function modifyBinTypes(binType, currentDataVersion, isAddNewBinType) {
        let promise;
        promise = isAddNewBinType ? addBinType(binType) : updateBinTypes(binType);
        promise.then(response => {
        }, error => {
            console.log(error);
            setIsShowErrorAlert(true);
            setTimeout(() => {setIsShowErrorAlert(false)}, ALERT_TIMEOUT);
        }).finally(() => reFetchData(currentDataVersion));
    }

    function reFetchData(currentDataVersion) {
        setDataVersion(currentDataVersion + 1);
    }

    function validateFormValues(formValues) {
        const valid = Array(6).fill(true);
        for (let i = 0; i < formValues.length; i++) {
            if (formValues[i] && formValues[i] !== "") {
                valid[i] = formValueValidators[i](formValues[i])
            }
        }
        setFormValueValid(valid);
        return valid;
    }

    function getFormValuesFromBinType(binTypeObject) {
        let newFormValues = [];
        newFormValues.push(binTypeObject.type);
        newFormValues.push(binTypeObject.scope);
        newFormValues.push(binTypeObject.length);
        newFormValues.push(binTypeObject.width);
        newFormValues.push(binTypeObject.height);
        newFormValues.push(binTypeObject.weight);
        return newFormValues;
    }

    function getBinTypeFromFormValues(formValues) {
        let binTypeObject = {};
        binTypeObject.type = formValues[0];
        binTypeObject.scope = formValues[1];
        binTypeObject.length = formValues[2];
        binTypeObject.width = formValues[3];
        binTypeObject.height = formValues[4];
        binTypeObject.weight = formValues[5];
        return binTypeObject;
    }

    function matchSearchQuery(query) {
        query = query.toLowerCase();
        let searchResults = [];
        if (query.length > 0 && originalBinTypesList && originalBinTypesList.length > 0) {
            originalBinTypesList.forEach((binType) => {
                if (isElementMatches(binType, query) === true) {
                    searchResults.push(binType);
                }
            });
            setBinTypesList(searchResults);
        }
        function isElementMatches(binType, query) {
            const values = Object.values(binType).map(value => value.toString().toLowerCase());
            let result = false;
            values.forEach(value => {
                if (value.indexOf(query) !== -1) {
                    result = true;
                }
            });
            return result;
        }
    }

    function renderInputForm() {
        return (
            <InputGroup value={formValues} onChange={(values) => onFormValuesChanged(values)} direction="column">
                <Input size="medium" error={!formValueValid[0]} disabled={disableTypeFormValue} label={<FormattedMessage id="binTypes.type" defaultMessage="Type"/>}/>
                <Select disabled={disableScopeFormValue} label={<FormattedMessage id="binTypes.scope" defaultMessage="Scope"/>}>
                    <SelectOption value="AMZL" label="AMZL"/>
                    <SelectOption value="GSF" label="GSF"/>
                </Select>
                <Input size="medium" error={!formValueValid[2]} label={<FormattedMessage id="binTypes.length" defaultMessage="Length (in inches)"/>}/>
                <Input size="medium" error={!formValueValid[3]} label={<FormattedMessage id="binTypes.width" defaultMessage="Width (in inches)"/>}/>
                <Input size="medium" error={!formValueValid[4]} label={<FormattedMessage id="binTypes.height" defaultMessage="Height (in inches)"/>}/>
                <Input size="medium" error={!formValueValid[5]} label={<FormattedMessage id="binTypes.weight" defaultMessage="Maximum Weight(in pounds)"/>}/>
            </InputGroup>
        );
    }

    function renderModalForInputForm() {
        return (
            <Modal title={isAddBinType ? <FormattedMessage id="binTypes.addBinType" defaultMessage="Add bin type"/> :
                <FormattedMessage id="binTypes.updateBinType" defaultMessage="Update bin type"/>}
                   open={openModal}
                   onClose={onClickCancelButton}
                   scrollContainer="modal"
                   describedById="modal-description"
                   width="800px">
                {renderInputForm()}
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="secondary" size="small" onClick={onClickCancelButton}>
                            <FormattedMessage id="binTypes.cancel" defaultMessage="Cancel"/>
                        </Button>
                        <Button type="primary" size="small" onClick={() => onClickSaveButton(formValues, dataVersion, isAddBinType)}
                                disabled={disableSaveButton}>
                            <FormattedMessage id="binTypes.save" defaultMessage="Save"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        );
    }

    function renderTableHeaders() {
        return (
            <TableRow>
                <TableCell sortColumn="type" width={150} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.Type" defaultMessage="Type"/></Text>
                </TableCell>
                <TableCell sortColumn="scope" width={200} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.scope" defaultMessage="Scope"/></Text>
                </TableCell>
                <TableCell sortColumn="length" width={150} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.length" defaultMessage="Length(in inches)"/></Text>
                </TableCell>
                <TableCell sortColumn="width" width={200} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.width" defaultMessage="Width(in inches)"/></Text>
                </TableCell>
                <TableCell sortColumn="height" width={150} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.height" defaultMessage="Height(in inches)"/></Text>
                </TableCell>
                <TableCell sortColumn="weight" width={150} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.weight" defaultMessage="Weight"/></Text>
                </TableCell>
                <TableCell width={100} alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="binTypes.actions" defaultMessage="Actions"/></Text>
                </TableCell>
            </TableRow>
        );
    }

    function renderTableRows(arr, result, index) {
        if (actionButtonRefs.current.length !== arr.length) {
            actionButtonRefs.current = Array(arr.length).fill().map((_, i) => actionButtonRefs.current[i] || createRef());
        }
        return (
            <TableRow>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.type}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.scope}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.length}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.width}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.height}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.weight}</Text></TableCell>
                <TableCell alignmentHorizontal="left">
                    <Text type="b300">
                        <Button ref={actionButtonRefs.current[index]} type="icon" size="medium"
                                onClick={() => onClickActionsButton(index)}>
                            ...
                        </Button>
                        <Menu anchorNode={actionButtonRefs.current[index].current}
                              open={openActionsMenu[index] ? openActionsMenu[index] : false} position="left"
                              onClose={() => onCloseActions(index)}>
                            <MenuItem onClick={() => onClickUpdateBinTypes(result)}>
                                <FormattedMessage id="binTypes.update" defaultMessage="Update"/>
                            </MenuItem>
                        </Menu>
                    </Text>
                </TableCell>
            </TableRow>
        )
    }

    function renderSearchBarAndAddBinTypeButton() {
        return (
            <Box type="fill" spacingInset="medium">
                <div style={{display: "flex"}}>
                        <FormattedMessage id="binTypes.searchAnyField">
                            {
                                (placeholder) => (
                                    <SearchField value={searchQuery} onChange={setSearchQuery} onSubmit={setSearchQuery} size={"medium"} width={"400px"} placeholder={placeholder}/>
                                )
                            }
                        </FormattedMessage>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{ marginLeft: "auto" }}>
                        <Button onClick={() => onClickAddBinTypes()}><FormattedMessage id="binTypes.addBinType" defaultMessage="Add bin type"/></Button>
                    </div>
                </div>
            </Box>
        );
    }

    function renderPagination() {
        return (
            <Row spacingInset="large" alignmentHorizontal="center">
                <Pagination numberOfPages={Math.ceil(binTypesList.length / PAGE_LENGTH)} onChange={setCurrentPage} currentPage={currentPage}/>
            </Row>
        );
    }

    function renderPageContent() {
        return (
            <Row widths={["grid-12"]} spacingInset="large large large large">
                <div style={{"overflowX": "scroll"}}>
                    {renderSearchBarAndAddBinTypeButton()}
                    <Table sortColumn={sortState.sortColumn} sortDirection={sortState.sortDirection} headerRows={1} spacing={"small"}
                           showDividers={true} showStripes={true} onSort={(data) => {
                        data.sortDirection = sortState.sortDirection === "ascending" ? "descending" : "ascending";
                        sortData(data)
                    }}>
                        {renderTableHeaders()}
                        {isLoading ? <TableRow><TableCell columnSpan={7} alignmentHorizontal="center"><Loader/></TableCell></TableRow> :
                            binTypesList.slice((currentPage - 1) * PAGE_LENGTH, currentPage * PAGE_LENGTH).map((result, index, arr) => {
                                return renderTableRows(arr, result, index);
                            })
                        }
                    </Table>
                </div>
            </Row>
        );
    }

    if (isFetchError) {
        return <ErrorPage error = {isFetchError.data && isFetchError.data.message ? isFetchError.data.message : "generic.error_try_again"} />
    } else {
        return (
            <div>
                {isShowErrorAlert ? <Alert toast={true} size="medium" type="error"><FormattedMessage id="binTypes.updateFailed" defaultMessage="Update failed"/></Alert> : null}
                {renderModalForInputForm()}
                {renderPageContent()}
                {renderPagination()}
            </div>
        );
    }
};

export default BinTypes;
