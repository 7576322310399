import { StationCycleState} from "../../../Constants";
import get from "lodash/get";
import {CYCLE} from "../../../Constants";

/**
 * Check if cycle + node is dynamic or zoned
 * by their corresponding Cycle Type
 * @param cycleName Name of cycle to be checked.
 * @param nodeId Identifier of the node
 * @param cycleList List of Cycles
 * @returns {boolean}
 */
export const isDynamicCycle = (cycleName, nodeId, cycleList) => {
    if(cycleList)
        for(let cycles of cycleList){
            if((cycles.cycle && cycles.cycle.cycleName && cycles.cycle.cycleName === cycleName)
                && (cycles.stationCode && cycles.stationCode === nodeId)){
                return cycles.cycle.cycleType === "DRS";
            }
        }
    return false;
}

export const isSideline = (cycleName, nodeId, cycleList) => {
    if(cycleList) {
        for(let cycles of cycleList) {
            if((cycles.cycle && cycles.cycle.cycleName === cycleName)
                && (cycles.stationCode && cycles.stationCode === nodeId)
                && (cycles.cycle.cycleType === CYCLE.SIDELINE)){
                return true;
            }
        }
    }
    return false;
}

/**
 * Checks if a cycle is dynamic for a given cycle name and node id.
 *
 * @param cycleToCheck
 * @param cycleList
 * @returns {boolean}
 */
export const isDynamicStationCycle = (cycleToCheck, cycleList) => {
    if(cycleList) {
        for (let stationCycle of cycleList) {
            if (areSameCycles(stationCycle, cycleToCheck)) {
                return stationCycle.cycle.cycleType === "DRS";
            }
        }
    }
    return false;
}

export const isSidelineStationCycle = (cycleToCheck, cycleList) => {
    if(cycleList) {
        for (let stationCycle of cycleList) {
            if (areSameCycles(stationCycle, cycleToCheck)) {
                console.log(stationCycle.cycle.cycleType === CYCLE.SIDELINE)
                return stationCycle.cycle.cycleType === CYCLE.SIDELINE;
            }
        }
    }
    return false;
}

/**
 * Checks if two cycles defined by the format StationCycle are same
 * @param cycle1
 * @param cycle2
 * @returns {boolean}
 */
export const areSameCycles = (cycle1, cycle2) => {
    return cycle1[StationCycleState.STATION_CODE] === cycle2[StationCycleState.STATION_CODE]
        && get(cycle1, [StationCycleState.CYCLE, StationCycleState.CYCLE_NAME])
            === get(cycle2, [StationCycleState.CYCLE, StationCycleState.CYCLE_NAME]);
}
