export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN";
export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS";
export const GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE = "GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE";
export const GET_ALL_CHILD_STATION_CALL_SUCCESS = "GET_ALL_CHILD_STATION_CALL_SUCCESS";
export const GET_ALL_CHILD_STATION_CALL_FAILURE = "GET_ALL_CHILD_STATION_CALL_FAILURE";
export const SET_SELECTED_EMPLOYEE_ID = "SET_SELECTED_EMPLOYEE_ID";
export const UPDATE_EMPLOYEE_SEARCH_FILTER = "UPDATE_EMPLOYEE_SEARCH_FILTER";
export const CLEAR_STATE = "CLEAR_STATE";

/**
 * Reducer function that changes state based on the action triggered.
 */
export const EmployeeManagementDataReducer = (state,action) => {
    switch (action.type) {

        case GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_BEGIN:
            // Mark the state as "loading" so we can show a spinner or something
            // Also, reset any errors. We're starting fresh.
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_SUCCESS:
            // Replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                items: {
                    ...state.items,
                    employeeList: action.payload.employeeDetails.employeeList,
                    roleList: action.payload.employeeDetails.roleList
                },
                employeeSearchFilters: {
                    ...state.employeeSearchFilters,
                    nextToken: action.payload.employeeDetails.nextToken
                },
                selectedEmployeeId: "",
                realm: action.payload.employeeDetails.realm,
                employeeRoles: action.payload.employeeDetails.employeeRoles
            };

        case GET_EMPLOYEE_DETAILS_BY_CRITERIA_CALL_FAILURE:
            // The request failed.
            // Save the error, and we can display it somewhere
            // Since it failed, we don't have items to display anymore, so set it empty.
            // This is up to you and your app though: maybe you want to keep the items
            // around! Do whatever seems right.
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case GET_ALL_CHILD_STATION_CALL_SUCCESS:
            return {
                ...state,
                childStationList: action.payload.childStationList
            };


        case GET_ALL_CHILD_STATION_CALL_FAILURE:
            // The request failed.
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case UPDATE_EMPLOYEE_SEARCH_FILTER:

            return {
                ...state,
                employeeSearchFilters: {
                    ...state.employeeSearchFilters,
                    [action.key]: action.value,
                    nextToken: null
                }
            }

        case SET_SELECTED_EMPLOYEE_ID:
            return {
                ...state,
                selectedEmployeeId: action.employeeId
            };

        case CLEAR_STATE:
            return {
                ...state,
                items: {
                    employeeList: [],
                    roleList: []
                },
                employeeSearchFilters: {
                    employeeName: "",
                    station: "",
                    isDa: "false",
                    role: "",
                    login: "",
                    storeId: "",
                    isActive: "true",
                    nextToken: null
                },
                selectedEmployeeId: "",
                childStationList: [],
                loading: false,
                error: null
            };

        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}