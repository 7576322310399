import React, {createContext, useReducer} from "react"
import {StationManagementDataReducer} from "../reducers/StationManagementReducer";
import {
    STRINGS
} from "../Constants";

export const StationManagementContext = createContext()

const StationManagementContextProvider = (props) => {
    /**
     * Initial state of the station management.
     */
    const initialState = {
        items: {
            stationList : [],
            updatedStationListWithFilters : [],
            visibleStationListForPage : []
        },
        stationSearchCriteria : {},
        pageSize : 30,
        currentPageNumber : 1,
        selectedStationCode : STRINGS.EMPTY,
        stationFilters : {
            stationCode : STRINGS.EMPTY,
            stationName : STRINGS.EMPTY,
            city : STRINGS.EMPTY,
            active : STRINGS.EMPTY,
            parentStation : STRINGS.EMPTY,
            timeZone : STRINGS.EMPTY,
        },
        loading: false,
        error: null
    };
    const [ state, dispatch ] = useReducer(StationManagementDataReducer, initialState)
    return (
        <StationManagementContext.Provider value = {{state, dispatch}}>
            {props.children}
        </StationManagementContext.Provider>
    )
}

export default StationManagementContextProvider
