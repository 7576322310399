import React, {useContext} from 'react';
import Box from "@meridian/components/box";
import Text from "@meridian/components/text";
import {FormattedMessage} from "react-intl";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import {CapabilityContext} from "../../context/CapabilityContext";
import {NodeResourceContext} from "../../context/NodeResourceContext";
import {
    UPDATE_BULK_RESOURCE_MODAL_STATE, VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE
} from "../../reducers/NodeManagerReducer";
import {AR_NEXT_GEN, SSD} from "../../Constants";
import { renderHeaderMessage } from '../../helper/NodeResourceManagerHelper';

export default function NodeResourcesManagerHeader() {
    const {capability} = useContext(CapabilityContext);
    const {state, dispatch} = useContext(NodeResourceContext);

    //Fetch flag for NARC rollout for SLS resources from state
    const {narc_sls_intergration_enabled, isAuthorizedUser, narc_ssd_authorization_enabled } = state;

    const handleShowResourceCreationModal = (status) => {
        dispatch({type: UPDATE_BULK_RESOURCE_MODAL_STATE, payload: {status: status}})
    }

    const handleValidateAndDownloadResourceModal = (status) => {
        dispatch({type: VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE, payload: {status: status}})
    };

    const renderResourceCreationRow = () => {
        const ssdAuthCheck = (capability.data === SSD  ? ( narc_ssd_authorization_enabled ? isAuthorizedUser : true) : false);
        return (
            //Enable Header for Resource Creation based on narc_sls_intergration_enabled flag as well
            <Row>
                {(capability.data === AR_NEXT_GEN
                        || ssdAuthCheck
                        || state.countryCode === "IN"
                        || (narc_sls_intergration_enabled && isAuthorizedUser) ) &&
                    <Button type="primary" size="small" onClick={() => {handleShowResourceCreationModal(true);}}>
                        {renderHeaderMessage(narc_sls_intergration_enabled)}
                    </Button>
                }
                {(narc_sls_intergration_enabled) &&
                    <Button type="primary" size="small" onClick={() => {handleValidateAndDownloadResourceModal(true);}}>
                        <FormattedMessage id="node_resources.validate_and_download_resource" defaultMessage="Validate And Download Resources"/>
                    </Button>
                }
            </Row>
        )
    }

    return (
        <Row alignment="top left" backgroundColor="secondary" wrap="down" spacingInset="small" height="fill" spacing="none">
            <Box type="outline" width="100%" height="fill" spacingInset="medium">
                <Row alignment="center justify">
                    <Text type="b300"><FormattedMessage id="node_resources.station_capability" defaultMessage="{capability} Station" values={{capability: capability.data}}/></Text>
                    <Row>
                        {renderResourceCreationRow()}
                    </Row>
                </Row>
            </Box>
        </Row>
    );
}
