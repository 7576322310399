import React, {useEffect, useContext} from 'react';
import Row from "@meridian/components/row";
import Column from "@meridian/components/column";
import Box from "@meridian/components/box";
import Button from "@meridian/components/button"
import { FormattedMessage } from 'react-intl';
import Text from "@meridian/components/text";
import '../css/StationManagement.css';
import Select, { SelectOption } from "@meridian/components/select";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ErrorPage from '../components/util/ErrorPage';
import Loading from '../components/util/Loading';
import {EmployeeFormManagementContext} from "../context/EmployeeFormManagementContext";
import Banner from "./Banner";
import {

} from "../resources/EmployeeFormManagementResource";
import {
    fetchEmployeeDetailsByEmployeeId,
    setEmployeeSuccessModalState,
    editEmployeeDetails,
    setCurrentEmployeeDetails
} from "../resources/EmployeeFormManagementResource";
import Modal from "@meridian/components/modal";
import Alert from "@meridian/components/alert";
import Input from "@meridian/components/input";
import {getRegion} from "../Utility";
import {REGION} from "../Constants";

const EmployeeFormManagement = () => {

    const {state, dispatch} = useContext(EmployeeFormManagementContext);

    const location = useLocation();
    const region = getRegion();

    let employeeRoles = [];
    let employeeId = "";
    if (location.state) {
        employeeRoles = location.state.employeeRoles;
        employeeId = location.state.employeeId;
    }
    const isReadOnly = !(employeeRoles.includes("ADMIN"));

    useEffect(() => {
        fetchEmployeeDetailsByEmployeeId(dispatch, employeeId);
    },[]);

    const { error, loading, setEmployeeModalState, saveButtonDisabled,
        setEmployeeError} = state;

    if (error) {
        return <ErrorPage error={error.data && error.data.message ? error.data.message : "generic.error"} />
    }
    if (loading) {
        return (
            <div className="EmployeeForm">
                <Loading />
            </div>
        );
    }
    let history = useHistory();
    let employee = state.items.employeeDetails;
    let roleList = state.items.roleList;
    if (employee) {
        return (
            <div className="EmployeeForm">
                <Banner message="banner.employee_page"/>
                <Modal
                    id="modal-setEmployeeModalSuccess"
                    open={setEmployeeModalState}
                    onOpen={() => { }}
                    describedBy="modal-setEmployeeModalSuccess_desc"
                >
                    {setEmployeeError ?
                        <div id="setEmployeeModalSuccess-modal_desc">
                            <Alert
                                type="error"
                                size="medium"
                            >
                                <FormattedMessage id={setEmployeeError.data && setEmployeeError.data.message ? setEmployeeError.data.message : "generic.error"} defaultMessage="Some Error Occured">
                                </FormattedMessage>
                            </Alert>
                        </div> :
                        <div id="setEmployeeModalSuccess-modal_desc">
                            <Alert
                                type="success"
                                size="medium"
                            >
                                <FormattedMessage id="employee_form.set_employee_success" defaultMessage="Employee has been edited successfully">
                                </FormattedMessage>
                            </Alert>
                        </div>
                    }
                    <Row alignment="center right" widths="fit">

                        <Button
                            type="primary"
                            size="small"
                            onClick={() => dispatch(setEmployeeSuccessModalState(false))}
                        >
                            <FormattedMessage id="generic.close" defaultMessage="Close">
                            </FormattedMessage>
                        </Button>
                    </Row>
                </Modal>

                <Row widths={["grid-9", "grid-3"]}
                     wrap="down"
                     alignment="top right"
                     spacingInset="large">

                    <Box
                        type="outline"
                        spacingInset="small"
                        height={600}
                        className="OverflowYAuto"
                    >
                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Text
                                type="h200"
                            >
                                <FormattedMessage id="employee.edit_employee" defaultMessage="Edit Employee">
                                </FormattedMessage>
                            </Text>

                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >

                            <Column>
                                <Text type="h100"><FormattedMessage id="employee_form.station" defaultMessage="Station" /></Text>
                                <Select
                                    size="small"
                                    width={"150px"}
                                    value={employee.station ? employee.station : ""}
                                    popoverPosition="adjacent"
                                    disabled={isReadOnly}
                                    onChange={(stationId) => { dispatch(editEmployeeDetails("station", stationId)) }}
                                >
                                    {state.selectableStations.map(station =>
                                        <SelectOption value={station.id} label={station.code} />
                                    )}
                                </Select>
                            </Column>

                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.status" defaultMessage="Status" /></Text>
                                <Select
                                    value={employee.active ? "true" : "false"}
                                    size="small"
                                    popoverPosition="adjacent"
                                    width={"150px"}
                                    disabled={isReadOnly}
                                    onChange={(status) => { dispatch(editEmployeeDetails("active", status)) }}
                                >
                                    <SelectOption value="true" label="Active" />
                                    <SelectOption value="false" label="InActive" />
                                </Select>

                            </Column>

                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.contact" defaultMessage="Contact" /></Text>
                                <Input
                                    value={employee.phone ? employee.phone : ""}
                                    size="small"
                                    width={"150px"}
                                    type="text"
                                    disabled={isReadOnly}
                                    onChange={(phone) => { dispatch(editEmployeeDetails("phone", phone)) }}
                                />
                            </Column>

                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.user_role" defaultMessage="User Role" /></Text>
                                <Select
                                    value={employee.userRole ? employee.userRole : ""}
                                    size="small"
                                    width={"150px"}
                                    popoverPosition="adjacent"
                                    disabled={isReadOnly}
                                    onChange={(userRole) => { dispatch(editEmployeeDetails("userRole", userRole)) }}
                                >
                                    <SelectOption value = {""} label = "" />
                                    {roleList.map(role =>
                                        <SelectOption value={role} label={role} />
                                    )}
                                </Select>
                            </Column>

                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >

                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.is_da" defaultMessage="Is DA" /></Text>
                                <Select
                                    value={employee.deliveryAssociate ? "true" : "false"}
                                    size="small"
                                    popoverPosition="adjacent"
                                    disabled={isReadOnly}
                                    width={"150px"}
                                    onChange={(isDA) => { dispatch(editEmployeeDetails("deliveryAssociate", isDA)) }}
                                >
                                    <SelectOption value="true" label="Yes" />
                                    <SelectOption value="false" label="No" />
                                </Select>
                            </Column>

                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.employee_name" defaultMessage="Employee Name" /></Text>
                                <Input
                                    value={employee.name ? employee.name : ""}
                                    size="small"
                                    width={"150px"}
                                    type="text"
                                    disabled={isReadOnly}
                                    onChange={(name) => { dispatch(editEmployeeDetails("name", name)) }}
                                />

                            </Column>

                        </Row>


                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >

                            <Column>

                                <Text type="h100"><FormattedMessage id="employee_form.employee_login" defaultMessage="Employee Login" /></Text>
                                <Input
                                    value={employee.code ? employee.code : ""}
                                    disabled={isReadOnly || (region !== REGION.IN)}
                                    size="small"
                                    width={"150px"}
                                    type="text"
                                    onChange={(code) => { dispatch(editEmployeeDetails("code", code)) }}
                                />

                            </Column>
                        </Row>
                    </Box>

                    <Box
                        type="outline"
                        spacingInset="small"
                        height={600}
                    >
                        { !isReadOnly ?
                            <div id="Modify_details_button">
                                <Row
                                    widths={"fill"}
                                    wrap="down"
                                    alignment="center left"
                                    spacingInset="medium"
                                >

                                    <Button disabled={saveButtonDisabled}
                                            onClick={() => {
                                                setCurrentEmployeeDetails(dispatch, employee)
                                            }}>
                                        <FormattedMessage id="employee_form.modify_details" defaultMessage="Modify Details" />
                                    </Button>

                                </Row>
                            </div> : ""
                        }

                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center left"
                            spacingInset="medium"
                        >

                            <Button type="tertiary" onClick={() => {
                                history.push('/employee')
                            }}>
                                <FormattedMessage id="employee_form.back" defaultMessage="Back" />
                            </Button>
                        </Row>
                    </Box>
                </Row>
            </div>
        )
    }
};

export default EmployeeFormManagement