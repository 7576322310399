/**
 * Resources file for Station Layout.
 */
import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {getNodeId} from "../Utility";
import {ENDPOINT_GET_RESOURCES, PROCESS_NODE_RESOURCE} from "../Constants";

const INDUCT_AREA = "INDUCT_AREA";
const INDUCT_LOCATION = "inductlocation";
const PROPERTY_ATTRIBUTES = "attributes";
const PROPERTY_TYPE = "Type";

/**
 * Gets the Induct Location Data via backend call.
 */
async function getInductLocationData() {
  let args = getInductLocationsRequest(getNodeId());
  const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_GET_RESOURCES);
  return parseInductLocationsResponse(response.data);
}

/**
 * Returns induct locations request.
 *
 * @param nodeId
 * @returns {{stationCode: *, resourceType: string}}
 */
function getInductLocationsRequest(nodeId) {
  let requestBody = {
    stationCode: nodeId,
    resourceType: INDUCT_AREA
  };
  return {
    processName: PROCESS_NODE_RESOURCE,
    endPoint: ENDPOINT_GET_RESOURCES,
    request: JSON.stringify(requestBody)
  }
}

/**
 * Gets the Induct Location Data via backend call and trigger actions based when call is started and
 * when it ended.
 */
export function fetchInductLocationData() {
  return getInductLocationData();
}

/**
 * Sanitizes the response by 1) Converting nodeResource.attributes.Type to lower case and removes any special characters 2) Adds
 * inductlocation as nodeResource.attributes.Type if absent
 */
function sanitizeResponse(response) {
  for (let nodeResource of response) {
    if (nodeResource.hasOwnProperty(PROPERTY_ATTRIBUTES) && nodeResource.attributes.hasOwnProperty(PROPERTY_TYPE)) {
      nodeResource.attributes.Type = nodeResource.attributes.Type.toLowerCase();
    } else {
      nodeResource.attributes = {};
      nodeResource.attributes.Type = INDUCT_LOCATION;
    }
    nodeResource.attributes.Type = nodeResource.attributes.Type.replace(/[^a-zA-Z ]/g, "");
  }
}

/**
 * Creates mapping between nodeResource.attributes.Type and location, scannable data.
 *
 * @param response
 * @returns {{}}
 */
function createNameToLocationMapping(response) {
  let nameToLocations = {};
  for (let nodeResource of response) {
    let name = nodeResource.attributes.Type;
    if (!nameToLocations[name]) {
      nameToLocations[name] = [];
    }
    nameToLocations[name].push({
      locationArea: nodeResource.label,
      scannableId: nodeResource.scannableId
    });
  }
  return nameToLocations;
}

/**
 * Creates the response in the expected format required by the view.
 *
 * @param nameToLocationMapping Name to location, scannable mapping.
 * @returns Expected response array.
 */
function createExpectedResponse(nameToLocationMapping) {
  let names = Object.keys(nameToLocationMapping);
  let expectedResponse = [];
  for (let name of names) {
    expectedResponse.push({
      name: name,
      inductLocation: nameToLocationMapping[name]
    })
  }
  return expectedResponse;
}

/**
 * Parses the response data retrieved from backend and converts to the format expected by UI.
 *
 * @param response Response data fetched from backend.
 * @returns Response expected by view layer.
 */
function parseInductLocationsResponse(response) {
  sanitizeResponse(response);
  let nameToLocationMapping = createNameToLocationMapping(response);
  return createExpectedResponse(nameToLocationMapping);
}