import React from "react";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import Input from "@amzn/meridian/input";
import get from "lodash/get";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import {addToObject} from "../util/CommonUtils";
import {
    INCLUDED_LOCATION_OPTIONS,
    SortGridAllocationCommonFormStates
} from "../../Constants";
import RadioButton from "@amzn/meridian/radio-button";
import {inputType} from "../../enums";


const SortGridAllocationPartitionInputForm = (props) => {
    const {
        commonFormInputs: {includedLocationInputs, excludedLocationInputs}, commonFormData, addToCommonFormData
    } = props;

    const includedLocations = get(commonFormData, [SortGridAllocationCommonFormStates.INCLUDED_LOCATIONS]);
    const excludedLocations = get(commonFormData, [SortGridAllocationCommonFormStates.EXCLUDED_LOCATIONS]);


    const renderExcludedLocationsInputForm = () => {
        return (
            <Row>
                { excludedLocationInputs.map(input => {
                    return (
                        <Row key={input.id}>
                            <Column spacing={"small"}>
                                <Row>
                                    <Text type={"h100"}>
                                        <FormattedMessage id={input.formattedId} defaultMessage={input.defaultLabel}/>:
                                    </Text>
                                </Row>
                                <Row>
                                    <Input value={get(excludedLocations, [input.id])}
                                           data-testid={input.id}
                                           disabled={!props.isAuthorized}
                                           onChange={ value => addToCommonFormData(SortGridAllocationCommonFormStates.EXCLUDED_LOCATIONS,
                                               addToObject(excludedLocations, input.id, value)
                                           )}
                                           placeholder={input.placeholder}
                                           type="text" />
                                </Row>
                            </Column>
                        </Row>
                    )
                })}
            </Row>
        );
    }

    const renderIncludedLocationInputOption = (includedLocationOptions, inputOption) => {
        switch (inputOption.inputType) {
            case inputType.RADIO:
                return (<Row key={inputOption.id}>
                    <RadioButton checked={get(includedLocations, [includedLocationOptions.id, 0]) === inputOption.value}
                                 onChange={value => addToCommonFormData(
                                     SortGridAllocationCommonFormStates.INCLUDED_LOCATIONS,
                                     addToObject(includedLocations, includedLocationOptions.id, [value, inputOption.customValue])
                                 )}
                                 name={inputOption.id}
                                 disabled={!props.isAuthorized}
                                 value={inputOption.value}>
                        <FormattedMessage id={inputOption.formattedId} defaultMessage={inputOption.defaultLabel} />
                    </RadioButton>
                </Row>);
            case inputType.TEXT:
                return (<Row key={inputOption.id}>
                    <Text type={"h100"}>
                        <FormattedMessage id={inputOption.formattedId} defaultMessage={inputOption.defaultLabel} />
                    </Text>
                    <Input value={get(includedLocations, [includedLocationOptions.id, 1])}
                           onChange={value => addToCommonFormData(
                               SortGridAllocationCommonFormStates.INCLUDED_LOCATIONS,
                               addToObject(includedLocations, includedLocationOptions.id, [inputOption.value, value])
                           )}
                           placeholder={inputOption.placeholder}
                           type="text"
                           disabled={!props.isAuthorized || get(includedLocations, [includedLocationOptions.id, 0]) !== INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM}
                    />
                </Row>);
            default:
                return null;
        }
    }

    const renderIncludedLocationInput = (includedLocationOptions) => {
        return (
            <Row key={includedLocationOptions.id}>
                <Column spacing={"small"}>
                    <Row>
                        <Text type={"h100"}>
                            <FormattedMessage id={includedLocationOptions.formattedId}
                                              defaultMessage={includedLocationOptions.defaultLabel}/>
                        </Text>
                    </Row>
                    <Row spacing={"large"}>
                        { includedLocationOptions.inputOptions
                            .map(inputOption => renderIncludedLocationInputOption(includedLocationOptions, inputOption))
                        }
                    </Row>
                </Column>
            </Row>
        )
    }

    return (
        <Row>
            <Column>
                <Row>
                    <Text type={"h200"}>
                        <FormattedMessage id="sortPaths.concurrentCycleInput"
                                          defaultMessage="Allocate a part of the sort grid" />
                    </Text>
                </Row>
                {includedLocationInputs.map(input => renderIncludedLocationInput(input))}
                {renderExcludedLocationsInputForm() }
            </Column>
        </Row>
    );
}

export default SortGridAllocationPartitionInputForm;
