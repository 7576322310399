/**
 * Resources file for Station View Management.
 */
import {getCoralServiceDataFromParent, getUserNameFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_USER,
    ENDPOINT_GET_STATION_BY_CODE,
    ENDPOINT_GET_STATIONS_BY_REGION,
    ENDPOINT_SET_STATION_ACTIVE,
    ENDPOINT_GET_TCP_ROLES,
    AMAZON_SUFFIX,
    ENDPOINT_GET_STATION_LIST,
    UPDATE_STATION_INFO_SUCCESS,
    UPDATE_STATION_INFO_FAILURE,
    GET_STATION_BY_STATION_CODE_CALL_BEGIN,
    GET_STATION_BY_STATION_CODE_SUCCESS,
    GET_STATION_BY_STATION_CODE_CALL_FAILURE,
    SET_STATION_UPDATE_MODAL_STATE,
    ENDPOINT_NODE_CAPABILITY,
    ACTIVE,
    ENDPOINT_GET_ACTIVE_NODE_CAPABILITY,
    EMPLOYEE_VERIFICATION_SUCCESS, EMPLOYEE_VERIFICATION_FAILURE,
    UTR_TECH_TEAM_ACCOUNT_EU,
    UTR_TECH_TEAM_ACCOUNT_NA, UTR_TECH_TEAM_ACCOUNT_FE,
    REGION, GET_ROLES_USING_ACCOUNT_ID, GET_USER_ROLES_USING_ACCOUNT_ID
} from "../Constants";
import {createRequest, getNodeId, getRegion} from "../Utility";

/**
 * Gets the Station By stationCode via API Call.
 */
export async function getStationByStationCode(stationCode) {
    if (stationCode === "") {
        stationCode = getNodeId();
    }
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CODE, stationCode);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_STATION_BY_CODE);
    return parseGetStationByStationCodeResponse(response.data);
}

/**
 * Gets the Stations of a region via API Call.
 */
export async function getStationsByRegion(stationId) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_STATIONS_BY_REGION, stationId);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_STATIONS_BY_REGION);
    return parseGetStationByRegionResponse(response.data);
}

/**
 * Gets the Active Capabilities of the Station via API Call.
 */
export async function getStationActiveCapability(stationId) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_ACTIVE_NODE_CAPABILITY, stationId);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_ACTIVE_NODE_CAPABILITY);
    return parseGetStationActiveCapabilityResponse(response.data);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param station
 * @returns {{flatStation: []}}
 */
function parseGetStationByStationCodeResponse(station) {
    let flatStation = {};
    flatStation.code = station.code;
    flatStation.name = station.name;
    flatStation.city = station.city;
    flatStation.description = station.description;
    flatStation.active = station.active;
    flatStation.parentStation = station.parentStation;
    flatStation.timeZone = station.timeZone;
    flatStation.address = (station.address || {});
    flatStation.address.geocode = (flatStation.address.geocode || {});
    flatStation.countryCode = station.countryCode;
    flatStation.scac = station.scac;
    flatStation.id = station.id;
    return flatStation;
}

/**
 * Parses response to convert to a format expected by UI.
 * @param response
 */
function parseGetStationActiveCapabilityResponse(response) {
    if (response && Object.keys(response).length !== 0) {
        return response.join(', ');
    }
    return "";
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @returns {{flatStation: []}}
 */
function parseGetStationByRegionResponse(responseData) {
    let flatStationsList = [];
    for (let stationData of responseData) {
        const flatStationData = createFlatStationDataObject(stationData);
        flatStationsList.push(flatStationData);
    }
    return flatStationsList;
}

/**
 * Parses response to convert to a format expected by UI.
 * @param station
 * @returns {{binTypes: []}}
 */
function createFlatStationDataObject(station) {
    let flatStationData = {};
    flatStationData.code = station.code;
    flatStationData.name = station.name;
    flatStationData.id = station.id;
    return flatStationData;
}

export async function verifyStationAllowlistedForConfigUpdate(stationCode) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_STATION_LIST, null);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_STATION_LIST);
    const stationAllowed =  parseStationListFromResponse(stationCode, response.data.value);
    return stationAllowed ? stationAllowed : Promise.reject(new Error("Station Validation Failed"));
}

function parseStationListFromResponse(stationCode, response) {
    if (Object.keys(response).length === 0) {
        return false;
    }
    if (response.allEnabled === true) {
        return true;
    } else {
        return response.values.includes(stationCode);
    }
}

/**
 * Gets the Employee TCP Roles via backend call and check if user has correct roles from TCP.
 */
export async function getEmployeeTCPRoles(login, tcpRole) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_GET_TCP_ROLES, login);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_GET_TCP_ROLES);
    return parseGetEmployeeDetailsFromTCPResponse(response.data, tcpRole);
}

/**
 * Gets the Employee TCP Roles of a given chloe Account via backend call and check if user has correct roles from TCP.
 */
export async function getEmployeeTCPRolesUsingAccountId(request, tcpRole) {
    let args = createRequest(PROCESS_NODE_USER, GET_ROLES_USING_ACCOUNT_ID, request);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, GET_ROLES_USING_ACCOUNT_ID);
    return parseGetEmployeeDetailsFromTCPResponse(response.data, tcpRole);
}

/**
 * Gets the Employee TCP Roles of a given chloe Account via backend call and check if user has correct roles from TCP.
 */
 export async function getUserEmployeeTCPRolesUsingAccountId(request, tcpRoles) {
    let args = createRequest(PROCESS_NODE_USER, GET_USER_ROLES_USING_ACCOUNT_ID, request);
    const response = await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, GET_USER_ROLES_USING_ACCOUNT_ID);
    return parseGetEmployeeDetailsFromTCPResponseList(response.data, tcpRoles);
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @param tcpRole
 */
function parseGetEmployeeDetailsFromTCPResponse(responseData, tcpRole) {
    if (tcpRole && !responseData.includes(tcpRole)) {
        return Promise.reject(new Error("Employee Validation Failed"));
    }
}

/**
 * Parses response to convert to a format expected by UI.
 * @param responseData
 * @param tcpRole
 */
 function parseGetEmployeeDetailsFromTCPResponseList(responseData, validTcpRoles) {
    if (validTcpRoles && !responseData.some( role => validTcpRoles.includes(role))) {
        return Promise.reject(new Error("Employee Validation Failed"));
    }
}

/**
 * Action for Get Station By stationCode call begin
 */
export const getStationByStationCodeCallBegin = () => ({
    type: GET_STATION_BY_STATION_CODE_CALL_BEGIN
});

/**
 * Action for get station By stationCode call Success
 */
export const getStationByStationCodeSuccess = (station) => ({
    type: GET_STATION_BY_STATION_CODE_SUCCESS,
    payload: {
        station
    }
});

/**
 * Action for get Station By stationCode call failure
 */
export const getStationByStationCodeCallFailure = (error) => ({
    type: GET_STATION_BY_STATION_CODE_CALL_FAILURE,
    payload: {
        error
    }
});

/**
 * Sets the Station By stationCode via API Call. 
 */
export async function setStation(stationCode) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_SET_STATION_ACTIVE, stationCode);
    await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_SET_STATION_ACTIVE);
}

/**
 * Sets the Station Capability Active via API Call.
 */
export async function setCapability(setCapabilityRequest) {
    let args = createRequest(PROCESS_NODE_USER, ENDPOINT_NODE_CAPABILITY, setCapabilityRequest);
    await getCoralServiceDataFromParent(args, PROCESS_NODE_USER, ENDPOINT_NODE_CAPABILITY);
}

function createSetCapabilityRequest(updatedStation, capability, userId) {
    let setCapabilityRequest = {}
    setCapabilityRequest.nodeId = updatedStation.code;
    setCapabilityRequest.capabilityConfiguration = {};
    setCapabilityRequest.capabilityConfiguration.capability = capability;
    setCapabilityRequest.capabilityConfiguration.status = ACTIVE;
    setCapabilityRequest.userIdentifier = userId;

    return setCapabilityRequest;
}

/**
 * Sets the Station via backend call and trigger actions based when call is started and 
 * when it ended. 
 */
export async function updateStationInfo(stationInfoToUpdate, userId, dispatch, capability) {
    let updatedStation = stationInfoToUpdate;
    if (stationInfoToUpdate.address && stationInfoToUpdate.address.geocode){
        updatedStation.address.geocode.latitude = parseFloat(stationInfoToUpdate.address.geocode.latitude);
        updatedStation.address.geocode.longitude = parseFloat(stationInfoToUpdate.address.geocode.longitude);
    }
    if (userId) {
        updatedStation.userId = userId;
    }
    dispatch(setStationCallBegin());
    try {
        await setStation(updatedStation);
        if (capability) {
            const setCapabilityRequest = createSetCapabilityRequest(updatedStation, capability, userId)
            await setCapability(setCapabilityRequest);
        }
        dispatch(setStationSuccess(updatedStation));
    }
    catch (error) {
        const stationJson = await getStationByStationCode(stationInfoToUpdate.code);
        dispatch(setStationCallFailure(error.response, stationJson));
    }
}

/**
 * Action for Set Station By stationCode call begin 
 */
export const setStationCallBegin = () => ({
    type: GET_STATION_BY_STATION_CODE_CALL_BEGIN
});

/**
 * Action for set station By stationCode call Success
 */
export const setStationSuccess = (station) => ({
    type: UPDATE_STATION_INFO_SUCCESS,
    payload: {
      station
    }
});

/**
* Action for set Station By stationCode call failure
*/
export const setStationCallFailure = (error, station) => ({
    type: UPDATE_STATION_INFO_FAILURE,
    payload: {
      error,
      station
    }
});

export function verifyEmployeeTCPRoles(stationCode, dispatch, login, tcpRole) {
    if (!login.endsWith(AMAZON_SUFFIX)) {
        login += AMAZON_SUFFIX;
    }

    verifyStationAllowlistedForConfigUpdate(stationCode).then((stationAllowed) => {
        let accountID = UTR_TECH_TEAM_ACCOUNT_EU;
        const region = getRegion();
        if (region === REGION.NA) {
            accountID = UTR_TECH_TEAM_ACCOUNT_NA;
        } else if (region === REGION.FE) {
            accountID = UTR_TECH_TEAM_ACCOUNT_FE;
        }

        let requestBody = {
            userId: login,
            accountId: accountID
        }

        getEmployeeTCPRolesUsingAccountId(requestBody, tcpRole).then(() => {
            dispatch(verifyEmployeeSuccess(stationAllowed))
        }).catch(error => dispatch(verifyEmployeeFailure('employee.fetching_tcp_error')))
    }).catch (error =>
        dispatch(verifyEmployeeFailure('employee.station_validation_error'))
    );
}
/**
 * Action for verify TCP By verifyEmployeeTCPRoles call Success
 */
export const verifyEmployeeSuccess = (stationAllowed) => ({
    type: EMPLOYEE_VERIFICATION_SUCCESS,
    payload: {
        stationAllowed
    }
});

export const setStationUpdateModalState = (value) => ({
    type: SET_STATION_UPDATE_MODAL_STATE,
    payload: {
        value
    }
});

/**
* Action for verify TCP By verifyEmployeeTCPRoles call failure
*/
export const verifyEmployeeFailure = (error) => ({
    type: EMPLOYEE_VERIFICATION_FAILURE,
    payload: {
        error
    }
});

export async function getUserName() {
    let response = await getUserNameFromParent();
    let userName = JSON.parse(response.name)
    return userName;
}

