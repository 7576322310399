import React, {useState, useCallback} from "react";
import CartLocations from "./CartLocations";
import SpotlightGroup from "./SpotlightGroup";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Select from "@amzn/meridian/select";
import SelectOption from "@amzn/meridian/select/select-option";
import Text from "@amzn/meridian/text";
import Expander from "@amzn/meridian/expander";
import BinTestModalContextProvider from "../../context/BinTestModalContext";
import BinTestModal from "./modal/BinTestModal";
import ErrorPage from "../util/ErrorPage";
import {getResourceData} from "../../resources/NodeResourceManagerResource";
import {RESOURCE_TYPES} from "../../Constants";
import {getNodeId, useFetch, strcmp} from "../../Utility";
import Loading from "../util/Loading";
import Button from "@amzn/meridian/button";
import {triggerConfigRefresh} from "../../resources/SmartStationResource";

const DEFAULT_ASSIGNMENT_STRATEGY = "default";
const ControlledExpander = props => {
    const [open, setOpen] = useState(true);
    return <Box className="ar-group">
        <Expander open={open} onChange={setOpen} alwaysRenderChildren={false} {...props}/>
    </Box>;
}

function Group(props){
    let [assignmentStrategy, setAssignmentStrategy] = useState(DEFAULT_ASSIGNMENT_STRATEGY);
    return <Row widths={["50%", "50%"]} alignmentVertical="top">
            <Column type="outline" spacingInset="medium" height="100%" alignmentVertical="top">
                <CartLocations groupResourceId={props.resourceId} groupLabel={props.label}/>
            </Column>
            <Column spacing="medium" height="100%" widths={["fit","fill"]}>
                <Box type="outline" spacingInset="medium">
                    <Row width="100%">
                        <Text>Assignment Strategy: </Text>
                        <Select
                            value={assignmentStrategy}
                            onChange={setAssignmentStrategy}
                            width={300}
                            disabled={true}>
                            <SelectOption value={assignmentStrategy} label={assignmentStrategy} />
                        </Select>
                    </Row>
                    <Row width="100%" className="margin-top-10px">
                        <Text>Positions are relative from a single point in the Work Cell </Text>
                    </Row>
                </Box>
                <Column type="outline" spacingInset="medium"
                        alignmentVertical="top">
                    <SpotlightGroup groupResourceId={props.resourceId} groupLabel={props.label}/>
                </Column>
            </Column>
        </Row>;
}

export default function GroupAccordion() {
    const fetchGroups = useCallback(() => {
        return getResourceData({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.AR_GROUP});
    },[]);
    const [response, loading] = useFetch(fetchGroups);
    const triggerNarcPuller = useCallback(async () => {
        await triggerConfigRefresh(getNodeId());
    },[]);
    if (loading) {
        return <Loading/>;
    } else if(response) {
        const resourceList = response.resources
            .filter((resource) => !resource.isDeprecated)
            .sort((group1, group2) => strcmp(group1.label, group2.label));
        return <BinTestModalContextProvider>
            <>
                <Row widths={["fill", "fit"]}>
                    <Box/>
                    <Button size="large" type="tertiary" onClick={triggerNarcPuller}>
                        Deploy
                    </Button>
                </Row>
            </>
            <>
            <div>
                {
                    resourceList
                        .map((resource, index)=> {
                            return (
                                <ControlledExpander title={resource.label + ' - ' + resource.PrintableLabel} type="box" key={index}>
                                    <Group resourceId={resource.resourceId} scannableId={resource.scannableId}
                                            label={resource.label}/>
                                </ControlledExpander>
                            );
                        })
                }
            </div>
            </>
            <BinTestModal/>
        </BinTestModalContextProvider>;
    } else {
        return <ErrorPage error = "error.getResourceGroups" />;
    }
}
