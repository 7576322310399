import React, {useCallback, useContext, useState, useRef} from "react";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import PositionInput from "./PositionInput";
import Row from "@amzn/meridian/row";

import {BinTestModalContext} from "../../context/BinTestModalContext";
import {LOAD_TO_CART_CLIPBOARD, OPEN_MODAL} from "../../reducers/BinTestModalReducer";
import {
    getImmediateChildForResources,
    updateResource
} from "../../resources/NodeResourceManagerResource";
import {getNodeId, strcmp, useFetch} from "../../Utility";
import {POSITION_TYPES, RESOURCE_TYPES} from "../../Constants";
import ErrorPage from "../util/ErrorPage";
import Loading from "../util/Loading";

function getUpdatedResource(resource, leftPositionXyz, rightPositionXyz, groupLabel) {
    return {
        ...resource,
        relativePositions: [
            {
                sourceResourceLabel: groupLabel,
                position: {
                    x:parseInt(leftPositionXyz.x),
                    y:parseInt(leftPositionXyz.y),
                    z:parseInt(leftPositionXyz.z),
                    unit: 'INCHES'
                },
                fromPositionType: POSITION_TYPES.CENTER,
                toPositionType: POSITION_TYPES.BOTTOM_LEFT
            }, {
                sourceResourceLabel: groupLabel,
                position: {
                    x:parseInt(rightPositionXyz.x),
                    y:parseInt(rightPositionXyz.y),
                    z:parseInt(rightPositionXyz.z),
                    unit: 'INCHES'
                },
                fromPositionType: POSITION_TYPES.CENTER,
                toPositionType: POSITION_TYPES.BOTTOM_RIGHT
            }
        ]
    };
}

function getInitialRelativePositions(resource) {
    let leftPosition = {x:"0", y:"0", z: "0"};
    let rightPosition = {x:"0", y:"0", z: "0"};
    if (resource.relativePositions && resource.relativePositions.length === 2){
        if (resource.relativePositions[0].toPositionType === POSITION_TYPES.BOTTOM_LEFT) {
            leftPosition = resource.relativePositions[0].position;
            rightPosition = resource.relativePositions[1].position;
        } else {
            rightPosition = resource.relativePositions[0].position;
            leftPosition = resource.relativePositions[1].position;
        }
    }
    return [leftPosition, rightPosition]
}

function CartLocation(props) {
    const [saveEnabled, setSaveEnabled] = useState(true);
    const {state, dispatch} = useContext(BinTestModalContext);
    const refClipboard = useRef(null);
    refClipboard.current = state.cartLocationClipboard;
    const [leftInitialPosition, rightInitialPosition] = getInitialRelativePositions(props.resource);
    const [leftPositionXyz, setLeftPositionXyz] = useState(leftInitialPosition);
    const [rightPositionXyz, setRightPositionXyz] = useState(rightInitialPosition);
    const savePositions = useCallback(() => {
        const previousValue = saveEnabled;
        setSaveEnabled(false);
        const updatedResource = getUpdatedResource(props.resource, leftPositionXyz, rightPositionXyz, props.groupLabel);
        updateResource(updatedResource).then(()=>{
            console.log('completed');
        }).catch((error) => {
            console.error(error);
        }).finally(()=>{
            setSaveEnabled(previousValue);
        });
    }, [saveEnabled, leftPositionXyz, rightPositionXyz]);
    const pasteFromClipBoard = useCallback(() => {
        const clipboard = refClipboard.current;
        if (clipboard) {
            setLeftPositionXyz(clipboard.leftPositionXyz);
            setRightPositionXyz(clipboard.rightPositionXyz);
        }
    }, [refClipboard, setLeftPositionXyz, setRightPositionXyz]);

    const saveToClipboard = useCallback(() => {
        dispatch({
            type: LOAD_TO_CART_CLIPBOARD,
            payload: {
                cartLocation: {
                    leftPositionXyz: leftPositionXyz,
                    rightPositionXyz: rightPositionXyz
                }
            }
        });
    }, [dispatch, leftPositionXyz, rightPositionXyz]);
    return <Box type="outline" spacingInset="small" className="margin-top-10px">
        <Row width="100%" widths={["fit","fill","fit"]}>
            <Box><Text>Cart Location: {props.name}</Text></Box>
            <Box/>
            <Box>
                <Button className="margin-right-10px" size="small" label={'Copy Cart'} onClick={saveToClipboard}>Copy</Button>
                <Button size="small" label={'Paste Cart'} onClick={pasteFromClipBoard}> Paste</Button>
            </Box>
        </Row>
        <Row className="margin-top-10px">
            <PositionInput label="Bottom Left Position"  xyz={leftPositionXyz} setXyz={setLeftPositionXyz}/>
            <PositionInput label="Bottom Right Position" xyz={rightPositionXyz} setXyz={setRightPositionXyz}/>
        </Row>
        <Row className="margin-top-10px" width="100%" widths={["fit","fill","fit"]}>
            <Button type="tertiary"
                    onClick={() => dispatch(
                        {
                            type: OPEN_MODAL,
                            payload: {
                                open:true, groupLabel: props.groupLabel, cartLabel: props.name,
                                cartType: props.resource.resourceAttributes.Type
                            }
                        })}>
                Test
            </Button>
            <Box/>
            <Button type="primary" disabled={!saveEnabled} onClick={savePositions}>Save</Button>
        </Row>
    </Box>;
}

export default function CartLocations(props){
    const fetchCartLocations = useCallback(() => {
        return getImmediateChildForResources(getNodeId(), props.groupLabel);
    },[]);
    const [response, loading] = useFetch(fetchCartLocations);
    if (loading) {
        return <Loading/>;
    } else if (response && response.status === 200) {
        return <>
            <Heading level={5}>Cart Locations</Heading>
            {
                response.data.nodeResources.filter((resource) => resource.resourceType === RESOURCE_TYPES.CART_LOCATION)
                    .sort((cl1, cl2) => strcmp(cl1.label, cl2.label))
                    .map((resource) => {
                        return (
                            <CartLocation name={resource.label} groupLabel={props.groupLabel}
                                          resourceId={resource.resourceId} key={resource.label}
                                          resource={resource}
                            />
                        );
                    })
            }
        </>;
    } else {
        return <ErrorPage error={"error.getCartLocations"}/>
    }
}
