import React, {useContext} from 'react';
import Modal from "@meridian/components/modal";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import {FormattedMessage} from "react-intl";

import {
    TOGGLE_UPDATE_RESOURCE_MODAL
} from "../../../reducers/NodeManagerReducer";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import Alert from "@meridian/components/alert";
import { TYPE } from '../../../Constants';

export default function UpdateResourceModal() {
    const {state, dispatch} = useContext(NodeResourceContext);
    let {updateResourceModalState, updateResourceError, updateType} = state;

    const toggleUpdateResourceModal = (status) => {
        dispatch( {
            type: TOGGLE_UPDATE_RESOURCE_MODAL,
            status});
    };

    return (
        <Modal
            id="modal-updateResourceNotification"
            open={updateResourceModalState}
            scrollContainer="modal"
            describedBy="modal-updateResourceNotification_desc"
        >
            {updateResourceError ?
                <div id="updateResourceNotification-modal_desc">
                    <Alert
                        type="error"
                        size="large"
                    >
                        <FormattedMessage id={updateResourceError.data && updateResourceError.data.message ? updateResourceError.data.message : "generic.error"} defaultMessage="Some Error Occured">
                        </FormattedMessage>
                    </Alert>
                </div> :
                <div id="updateResource-modal_desc">
                    <Alert
                        type="success"
                        size="large"
                    >
                        {updateType === TYPE.RESOURCE 
                        ?   <FormattedMessage id="updateResourceNotification.update_resource_success" defaultMessage="Resource has been updated Successfully">
                            </FormattedMessage>
                        :   <FormattedMessage id="updateResourceNotification.update_association_success" defaultMessage="Resource Association has been updated Successfully">
                            </FormattedMessage>
                        }
                    </Alert>
                </div>
            }
            <Row alignment="center right" widths="fit">
                <Button
                    type="primary"
                    size="medium"
                    onClick={() => toggleUpdateResourceModal(false)}
                >
                    <FormattedMessage id="generic.close" defaultMessage="Close">
                    </FormattedMessage>
                </Button>
            </Row>
        </Modal>
    )
}
