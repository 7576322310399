import React, {createRef, useCallback, useEffect, useRef, useState} from 'react'
import Text from "@amzn/meridian/text"
import Row from "@amzn/meridian/row"
import Table, {TableCell, TableRow} from "@amzn/meridian/table"
import Loader from "@amzn/meridian/loader";
import Pagination from "@amzn/meridian/pagination";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@amzn/meridian/button";
import Menu, {MenuItem} from "@amzn/meridian/menu"
import Modal from "@amzn/meridian/modal";
import ModalFooter from "@amzn/meridian/modal/modal-footer";
import Select from "@amzn/meridian/select";
import SelectOption from "@amzn/meridian/select/select-option";
import {
    deprecateSortPath,
    fetchSortPathResources,
    fetchSortPaths,
    fetchBinTypes,
    addSortPath
} from "../../resources/SortPathsResource";
import ErrorPage from "../util/ErrorPage";
import {getNodeId} from "../../Utility";
import Box from "@amzn/meridian/box";
import Link from "@amzn/meridian/link";
import Input from "@amzn/meridian/input";
import InputGroup from "@amzn/meridian/input-group";
import Column from "@meridian/components/column";
import Divider from "@meridian/components/divider";
import {readSortPathFile} from "../../helper/SortPathHelper";
import Alert from "@meridian/components/alert";
import {CAPABILITY, FEATURES, SPG_TAB, STRINGS} from "../../Constants";
import {getFeaturesFromParent, getNodeCapabilityFromParent} from "../../handler/ParentDataHandler";
import {isTCPAuthorizedUser} from "../../helper/NodeResourceAuthorizationHelper";
/**
 * React component for bin types page in SCC.
 */
const UploadedSortPaths = ({intl}) => {

    const PAGE_LENGTH = 25;
    const ALERT_TIMEOUT = 4000;
    const [sortState, setSortState] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortPathsList, setSortPathsList] = useState([]);
    const [originalSortPathsList, setOriginalSortPathsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openActionsMenu, setOpenActionsMenu] = useState({});
    const actionButtonRefs = useRef([]);
    const [openViewSortPathModal, setOpenViewSortPathModal] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [dataVersion, setDataVersion] = useState(0);
    const [isFetchError, setIsFetchError] = useState(undefined);
    const [tagFilterValue, setTagFilterValue] = useState("ALL");
    const [sortPathView, setSortPathView] = useState("");
    const [isLoadingSortPathView, setIsLoadingSortPathView] = useState(false);
    const [isErrorSortPathView, setIsErrorSortPathView] = useState(false);
    const [toBeDeprecated, setToBeDeprecated] = useState();
    const [hideClearAllButton, setHideClearAllButton] = useState(true);
    const [nameFilter, setNameFilter] = useState();
    const [idFilter, setIdFilter] = useState();
    const [tagFilter, setTagFilter] = useState();
    const [binTypeFilter, setBinTypeFilter] = useState();

    const NUMBER_OF_INPUTS = 3;
    const [openModal, setOpenModal] = useState(false);
    const [binTypesList, setBinTypesList] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [isAddSortPathError, setIsAddSortPathError] = useState(undefined);
    const [sortPathUploadResultModalState, setSortPathUploadResultModalState] = useState(false);
    const [sortPathUploadRefreshModalState, setSortPathUploadRefreshModalState] = useState(false);
    const [sortPathResources, setSortPathResources] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [isSsdAuthorized, setIsSsdAuthorized] = useState(true);

    useEffect(() => {
        getNodeCapabilityFromParent().then(capability => {
            getFeaturesFromParent([FEATURES.NARC_SSD_AUTHORIZATION_CHECK]).then(featureStatus => {
                const parsedCapability = JSON.parse(capability.capability);
                const ssd_authorization_enabled_response = featureStatus.data[FEATURES.NARC_SSD_AUTHORIZATION_CHECK] && parsedCapability===CAPABILITY.SSD;
                isTCPAuthorizedUser(ssd_authorization_enabled_response, SPG_TAB).then( (isAuthorized) => {
                    setIsSsdAuthorized(isAuthorized)
                });
            });
        });

    }, []);

    const onClickAddSortPath = useCallback(() => {
        setOpenModal(true);
        let binTypesResponse = fetchBinTypes();
        binTypesResponse.then(data => {
            setBinTypesList(data.binTypes);
        }, error => {
            setIsFetchError(error);
        });
    }, []);

    const onClickCancelButton = useCallback(() => {
        setOpenModal(false);
        clearFormValues();
    }, []);

    function clearFormValues() {
        setFormValues([]);
        setSortPathResources([]);
        setSelectedFileName("");
    }

    const handleFileUpload = useCallback(uploadEvent => {
        if(uploadEvent.target.files[0] && uploadEvent.target.files[0].name) {
            setSelectedFileName(uploadEvent.target.files[0].name);
            setSortPathResources([]);
            readSortPathFile(getNodeId(), uploadEvent).then(results => {
                if(results.error) {
                    setOpenModal(false);
                    clearFormValues();
                    setIsAddSortPathError(results.errorMessage);
                    setSortPathUploadResultModalState(true);
                } else {
                    setSortPathResources(results.sortPathResources);
                }
            });
        }
    },[]);

    const onClickSaveButton = useCallback((formValues, sortPathResources, currentDataVersion) => {
        let sortPath = {};
        sortPath.stationCode = getNodeId();
        sortPath.name = formValues[0];
        sortPath.attributes = {
            "binType": formValues[1]
        };
        sortPath.tag = formValues[2];
        setIsLoading(true);
        setOpenModal(false);
        addSortPath(sortPath, sortPathResources).then(response => {
            clearFormValues();
            if (response !== "") {
                setIsLoading(false);
                if (!response) {
                    response = STRINGS.REFRESH_LATER;
                    setIsAddSortPathError(response);
                    setSortPathUploadRefreshModalState(true);
                } else {
                    setIsAddSortPathError(response);
                    setSortPathUploadResultModalState(true);
                }

            } else {
                setIsAddSortPathError(undefined);
                setDataVersion(currentDataVersion + 1)
            }
        }, error => {
            setTimeout(() => {
                setSortPathResources([]);
                setIsFetchError(undefined);
                setDataVersion(currentDataVersion + 1)
            }, ALERT_TIMEOUT);
        });
    }, []);

    const onClose = useCallback(() => {
        setSortPathUploadResultModalState(false);
        setSortPathUploadRefreshModalState(false);
        setSortPathResources([]);
        setSelectedFileName("");
        setIsAddSortPathError(undefined);
    }, []);

    const onFormValuesChanged = useCallback((formValues) => {
        setFormValues(formValues);
    }, []);

    useEffect(() => {
        const filteredFormValues = formValues.filter(value => value && value.toString().length > 0);
        if ((filteredFormValues.length === NUMBER_OF_INPUTS) && (selectedFileName) && (sortPathResources && sortPathResources.length > 0)) {
            setDisableSaveButton(false);
        } else {
            setDisableSaveButton(true);
        }

    }, [selectedFileName, sortPathResources, formValues]);

    useEffect(() => {
        let response = fetchSortPaths(getNodeId());
        response.then(sortPaths => {
            setIsLoading(false);
            setOriginalSortPathsList(sortPaths);
            setSortPathsList(sortPaths);
            setCurrentPage(1);
        }, error => {
            setIsFetchError(error);
        });
    }, [dataVersion]);

    useEffect(() => {
        setCurrentPage(1);
        setHideClearAllButton(tagFilterValue === "ALL");
        let filteredResults = originalSortPathsList;
        if (tagFilterValue !== "ALL") {
            filteredResults = matchSearchQuery(tagFilterValue, originalSortPathsList, "tag", true);
        }
        filteredResults = matchSearchQuery(nameFilter, filteredResults, "name", false);
        filteredResults = matchSearchQuery(tagFilter, filteredResults, "tag", false);
        filteredResults = matchSearchQuery(idFilter, filteredResults, "id", false);
        filteredResults = matchSearchQuery(binTypeFilter, filteredResults, "binType", false);
        setSortPathsList(filteredResults);
    }, [nameFilter, tagFilter, idFilter, binTypeFilter, tagFilterValue]);

    const onClickCloseButton = useCallback(() => {
        setOpenViewSortPathModal(false);
        setIsErrorSortPathView(false);
    }, []);

    const onClickClearAllButton = useCallback(() => {
        setTagFilterValue("ALL");
    }, []);

    const onClickNoButton = useCallback(() => {
        setOpenConfirmationModal(false);
    }, []);

    const onClickActionsButton = useCallback((index) => {
        let _open = {...openActionsMenu};
        _open[index] = true;
        setOpenActionsMenu(_open);
    }, []);

    const onClickConfirmDeprecate = useCallback((sortPath, currentDataVersion) => {
        setIsLoading(true);
        setOpenConfirmationModal(false);
        deprecateSortPath(getNodeId(), sortPath.id).then(success => {
            setDataVersion(currentDataVersion + 1)
        }, error => {
            setIsFetchError(error);
            setTimeout(() => {
                setIsFetchError(undefined);
                setDataVersion(currentDataVersion + 1)
            }, ALERT_TIMEOUT);
        })
    }, []);

    const onClickDeprecate = useCallback((sortPath, index) => {
        setOpenConfirmationModal(true);
        setToBeDeprecated(sortPath);
        closeActionsPopUp(index);
    }, []);

    const onClickViewSortPath = useCallback((sortPath, currentDataVersion) => {
        setIsLoadingSortPathView(true);
        setOpenViewSortPathModal(true);
        fetchSortPathResources(getNodeId(), sortPath.id).then(sortPathData => {
            setSortPathView(sortPathData);
        }, error => {
            setIsErrorSortPathView(true);
        }).finally(() => {
            setIsLoadingSortPathView(false)
        });
    }, []);

    function closeActionsPopUp(index) {
        let _open = {...openActionsMenu};
        _open[index] = false;
        setOpenActionsMenu(_open)
    }

    const onCloseActions = useCallback((index) => {
        closeActionsPopUp(index);
    }, []);

    const sortData = (data, sortState)  => {
        data.sortDirection = sortState.sortDirection === "ascending" ? "descending" : "ascending";
        setSortState(data);
        switch(data.sortDirection) {
            case 'ascending': setSortPathsList(sortPathsList.sort((a, b) => a[data.sortColumn].toString().localeCompare(b[data.sortColumn].toString(), 'en', { numeric: true }))); break;
            case 'descending' : setSortPathsList(sortPathsList.sort((a,b)=> b[data.sortColumn].toString().localeCompare(a[data.sortColumn].toString(), 'en', { numeric: true }))); break;
            default : break;
        }
    };

    function matchSearchQuery(query, originalList, key, isAbsoluteMatch) {
        if (query && query.length > 0) {
            let searchResults = [];
            if (originalList && originalList.length > 0) {
                originalList.forEach((sortPath) => {
                    if (isMatchesSearchQuery(sortPath, key, query, isAbsoluteMatch)) {
                        searchResults.push(sortPath);
                    }
                });
                return searchResults;
            }
        }
        return originalList;
    }

    function isMatchesSearchQuery(sortPath, key, query, absoluteMatch) {
        return absoluteMatch === true ? sortPath[key] === query : sortPath[key].toLowerCase().indexOf(query.toLowerCase()) !== -1;
    }

    function renderModalForViewSortPath() {
        return (
            <Modal title={intl.formatMessage({id: "sortPaths.viewSortPath", defaultMessage: "View Sort Path"})}
                          open={openViewSortPathModal}
                          scrollContainer="modal"
                          describedById="modal-description"
                          onClose={onClickCloseButton}
                          width="800px"
                          height="600px">
                {isLoadingSortPathView ? <Loader/> : (isErrorSortPathView ? <ErrorPage error="generic.error_try_again"/> : <Text id="modal-description">{sortPathView}</Text>)}
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="primary" size="small" onClick={onClickCloseButton}>
                            <FormattedMessage id="sortPaths.close" defaultMessage="Close"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        );
    }

    function renderModalForDeprecateConfirmation() {
        return (
            <Modal title={intl.formatMessage({id: "sortPaths.deprecateConfirmation"}) + " " + (toBeDeprecated ? toBeDeprecated.name : "")} describedById="modal-confirm-description" width="800px" open={openConfirmationModal} onClose={onClickNoButton}>
                <Text id="modal-confirm-description"><FormattedMessage id="sortPaths.deprecateContent" defaultMessage="This sort path will be removed and cannot be undone."/></Text>
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="primary" size="small" onClick={() => onClickConfirmDeprecate(toBeDeprecated, dataVersion)}>
                            <FormattedMessage id="sortPaths.yes" defaultMessage="Yes"/>
                        </Button>
                        <Button type="secondary" size="small" onClick={onClickNoButton}>
                            <FormattedMessage id="sortPaths.no" defaultMessage="No"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        );
    }

    function renderFilter() {
        return (
            <div>
                <Box type="fill" spacingInset="medium">
                    <div style={{ display: "flex"}}>
                        <div style={{ display: "flex" }}>
                            <Text><FormattedMessage id="sortPaths.filters" defaultMessage="Filters:"/></Text>
                            &nbsp;&nbsp;&nbsp;
                            <Select size="small" value={tagFilterValue} onChange={setTagFilterValue} placeholder={intl.formatMessage({id: "sortPaths.tag"})}>
                                <SelectOption value="SORT_PATH" label="SORT_PATH"/>
                                <SelectOption value="REVERSE_SORT_PATH" label="REVERSE_SORT_PATH"/>
                            </Select>
                        </div>
                        { isSsdAuthorized && <div style={{ marginLeft: "auto" }}>
                            <Button onClick={() => onClickAddSortPath()}><FormattedMessage id="sortPaths.addSortPath" defaultMessage="Add Sort Path"/></Button>
                        </div>}
                    </div>
                </Box>
                {hideClearAllButton ? null :
                    <div style={{display: "flex"}}>
                        <div>
                            <Link type="secondary" onClick={() => onClickClearAllButton()}>Clear all</Link>
                        </div>
                    </div>
                }
            </div>
        );
    }

    function renderTableHeaders() {
        return (
            <TableRow>
                <TableCell sortColumn="name" alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="sortPaths.name" defaultMessage="Name"/></Text>
                </TableCell>
                <TableCell sortColumn="id" alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="sortPaths.id" defaultMessage="Id"/></Text>
                </TableCell>
                <TableCell sortColumn="tag" alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="sortPaths.tag" defaultMessage="Tag"/></Text>
                </TableCell>
                <TableCell sortColumn="binType" alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="sortPaths.binType" defaultMessage="Bin Type"/></Text>
                </TableCell>
                <TableCell alignmentHorizontal="left">
                    <Text type="h100"><FormattedMessage id="sortPaths.actions" defaultMessage="Actions"/></Text>
                </TableCell>
            </TableRow>
        );
    }

    function renderTableSearchBars() {
        return (
            <TableRow>
                <TableCell>
                    <Input alignmentHorizontal="right" type="text" size="small" value={nameFilter} onChange={setNameFilter}/>
                </TableCell>
                <TableCell>
                    <Input type="text" size="small" value={idFilter} onChange={setIdFilter}/>
                </TableCell>
                <TableCell>
                    <Input type="text" size="small" value={tagFilter} onChange={setTagFilter}/>
                </TableCell>
                <TableCell>
                    <Input type="text" size="small" value={binTypeFilter} onChange={setBinTypeFilter}/>
                </TableCell>
            </TableRow>
        );
    }

    function renderTableRows(arr, result, index) {
        if (actionButtonRefs.current.length !== arr.length) {
            actionButtonRefs.current = Array(arr.length).fill().map((_, i) => actionButtonRefs.current[i] || createRef());
        }
        return (
            <TableRow key={result.id}>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.name}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.id}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.tag}</Text></TableCell>
                <TableCell alignmentHorizontal="left"><Text type="b300">{result.binType}</Text></TableCell>
                <TableCell alignmentHorizontal="left">
                    <Text type="b300">
                        <Button ref={actionButtonRefs.current[index]} type="icon" size="medium"
                                onClick={() => onClickActionsButton(index)}>
                            ...
                        </Button>
                        <Menu anchorNode={actionButtonRefs.current[index].current}
                              open={openActionsMenu[index] ? openActionsMenu[index] : false} position="left"
                              onClose={() => onCloseActions(index)}>
                            <MenuItem onClick={() => onClickViewSortPath(result, dataVersion)}>
                                <FormattedMessage id="sortPaths.viewSortPath" defaultMessage="View Sort Path"/>
                            </MenuItem>
                            { isSsdAuthorized && <MenuItem onClick={() => onClickDeprecate(result, index)}>
                                <FormattedMessage id="sortPaths.deprecate" defaultMessage="Deprecate"/>
                            </MenuItem>}
                        </Menu>
                    </Text>
                </TableCell>
            </TableRow>
        )
    }

    function renderPagination() {
        return (
            <Row spacingInset="large" alignmentHorizontal="center">
                <Pagination numberOfPages={Math.ceil(sortPathsList.length / PAGE_LENGTH)} onChange={setCurrentPage} currentPage={currentPage}/>
            </Row>
        );
    }

    function renderPageContent() {
        return (
            <Row widths={["grid-12"]} spacingInset="large large large large">
                <div style={{"overflowX": "scroll"}}>
                    {renderFilter()}
                    <br/>
                    <Table sortColumn={sortState.sortColumn} sortDirection={sortState.sortDirection} headerRows={1} spacing={"small"}
                           showDividers={true} showStripes={true} onSort={(data) => {
                        sortData(data, sortState)
                    }}>
                        {renderTableHeaders()}
                        {renderTableSearchBars()}
                        {
                            isLoading ? <TableRow><TableCell columnSpan={7} alignmentHorizontal="center"><Loader/></TableCell></TableRow> :
                                sortPathsList.slice((currentPage - 1) * PAGE_LENGTH, currentPage * PAGE_LENGTH).map((result, index, arr) => {
                                    return renderTableRows(arr, result, index);
                                })
                        }
                    </Table>
                </div>
            </Row>
        );
    }

    function renderAddSortPathInputForm() {
        return (

            <InputGroup value={formValues} onChange={(values) => onFormValuesChanged(values)} direction="column">
                <Input size="medium" label={intl.formatMessage({id: "sortPaths.name", defaultMessage: "Name"})}/>
                <Select label={intl.formatMessage({id: "sortPaths.binType", defaultMessage: "Bin Type"})}>
                    {binTypesList.map((binTypeObject, idx) =>
                        <SelectOption key={idx} value={binTypeObject.type} label={binTypeObject.type} />
                    )}
                </Select>
                <Select label={intl.formatMessage({id: "sortPaths.tag", defaultMessage: "Tag"})}>
                    <SelectOption value="SORT_PATH" label="SORT_PATH"/>
                    <SelectOption value="REVERSE_SORT_PATH" label="REVERSE_SORT_PATH"/>
                </Select>
                <Divider/>
                <Divider/>
                <Column>
                    <Row alignment="center justify">
                        <input ref={createSortPathFileRef} style={{ "display": "none" }} type="file" size="medium"
                               id="input-bulkfileupload"  data-testid="input-bulkfileupload" onChange={(event) => {
                            handleFileUpload(event);
                        }}
                        />
                        <Button type="primary" size="medium" onClick={() => {createSortPathFileRef.current.click()}}>
                            <FormattedMessage id="sortPaths.uploadSortPath" defaultMessage="Select File"/>
                        </Button>
                        <Text>{selectedFileName}</Text>
                    </Row>
                </Column>
            </InputGroup>
        );
    }

    let createSortPathFileRef = React.createRef();

    function renderModalForAddSortPath() {
        return (
            <Modal title={intl.formatMessage({id: "sortPaths.addSortPath", defaultMessage: "Add Sort Path"})}
                   open={openModal}
                   onClose={onClickCancelButton}
                   scrollContainer="modal"
                   describedById="modal-description"
                   width="800px">
                {renderAddSortPathInputForm()}
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="secondary" size="small" onClick={onClickCancelButton}>
                            <FormattedMessage id="binTypes.cancel" defaultMessage="Cancel"/>
                        </Button>
                        <Button type="primary" size="small" onClick={() => onClickSaveButton(formValues, sortPathResources, dataVersion)}
                                disabled={disableSaveButton}>
                            <FormattedMessage id="binTypes.save" defaultMessage="Save"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        );
    }

    function renderModalForSortPathUploadResult() {
        return (
            <div>
                <Modal
                    title="Error"
                    id="modal-sortPathUploadResult"
                    open={sortPathUploadResultModalState}
                    onClose={onClose}
                    scrollContainer="modal"
                    closeLabel="Close"
                    describedBy="modal-sortPathUploadResult_desc"
                >
                    {isAddSortPathError ?
                        <div id="sortPathUploadResult-modal_desc">
                            <Alert
                                type="error"
                                size="large"
                            >
                                <Text>{isAddSortPathError}</Text>
                            </Alert>
                        </div> : null
                    }
                </Modal>
                <Modal
                title="Message"
                id="modal-sortPathUploadResultMessage"
                open={sortPathUploadRefreshModalState}
                onClose={onClose}
                scrollContainer="modal"
                closeLabel="Close"
                describedBy="modal-sortPathUploadResultMessage_desc"
                    >
                    {isAddSortPathError ?
                            <div id="sortPathUploadResultMessage-modal_desc">
                                <Alert
                                    type="warning"
                                    size="large"
                                >
                                    <Text>{isAddSortPathError}</Text>
                                </Alert>
                            </div> : null
                    }
                </Modal>
                </div>
            );
    }

    if (isFetchError) {
        return <ErrorPage error = {isFetchError.data && isFetchError.data.message ? isFetchError.data.message : "generic.error_try_again"} />
    } else {
        return (
            <div>
                {renderModalForSortPathUploadResult()}
                {renderModalForAddSortPath()}
                {renderModalForViewSortPath()}
                {renderModalForDeprecateConfirmation()}
                {renderPageContent()}
                {renderPagination()}
            </div>
        );
    }
};

export default injectIntl(UploadedSortPaths);
