import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import Divider from "@amzn/meridian/divider";
import Loading from "../util/Loading";
import React, {createRef, useCallback, useContext, useEffect, useRef, useState} from "react";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Pagination from "@amzn/meridian/pagination";
import SortPathConfigurationActions from "./SortPathConfigurationActions";
import {fetchSortPathConfigurations} from "../../resources/SortPathSettingsResource";
import {getNodeId, isConfigEnabled} from "../../Utility";
import {SORT_GRID_OVERLAP_DETAILS_ENABLED, STRINGS} from "../../Constants";
import get from "lodash/get";
import {StationConfigContext} from "../../context/StationConfigContext";
import {getGridAllocationFormData} from "../util/sort-paths/SortPathConfigurationData";


const SortGridAllocationTable = (props) => {
    const PAGE_LENGTH = 15;
    const actionButtonRefs = useRef([]);
    const fillPathCSVRefs = useRef([]);
    const pickPathCSVRefs = useRef([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [isFetchSortPathConfigurationsLoading, setIsFetchSortPathConfigurationsLoading] = useState(true);
    const [fetchSortPathConfigurationsError, setFetchSortPathConfigurationsError] = useState(undefined);

    const {state} = useContext(StationConfigContext);

    const { dataVersion, updateDataVersion, setGridAllocationInputForm, setFormData, sortPathConfigurationList, setSortPathConfigurationList  } = props;

    const onClickEditGridAllocation = useCallback((sortPathConfiguration) => {
        setGridAllocationInputForm(false, sortPathConfiguration.nodeId, sortPathConfiguration.cycle);
        setFormData(getGridAllocationFormData(sortPathConfiguration, state.cycleList));
    }, []);

    useEffect(() => {
        const sortPathConfigurations = fetchSortPathConfigurations(getNodeId());
        sortPathConfigurations.then(data => {
            if (!data) {
                setFetchSortPathConfigurationsError(STRINGS.RETRY_LATER);
            } else {
                const { sortPathSettings } = data;
                if (sortPathSettings) {
                    setSortPathConfigurationList(sortPathSettings);
                } else {
                    setFetchSortPathConfigurationsError(STRINGS.RETRY_LATER);
                }
            }
        }, error => {
            setFetchSortPathConfigurationsError(error || STRINGS.RETRY_LATER);
        }).finally(() => setIsFetchSortPathConfigurationsLoading(false));
    }, [dataVersion]);


    const renderPagination = () => {
        return (
            <Row spacingInset="large" alignmentHorizontal="center" width={"75%"}>
                <Pagination numberOfPages={Math.ceil(sortPathConfigurationList.length / PAGE_LENGTH)}
                            onChange={setCurrentPage} currentPage={currentPage}/>
            </Row>
        );
    }

    const renderCurrentSortPathConfigurationItem = (sortPathConfiguration, index, sortPathConfigurationList) => {
        [actionButtonRefs, fillPathCSVRefs, pickPathCSVRefs].forEach(refs => {
            if (refs.current.length !== sortPathConfigurationList.length) {
                refs.current = Array(sortPathConfigurationList.length)
                    .fill(null).map((_, i) => refs.current[i] || createRef());
            }
        });

        const isOverlapDetailsEnabled = isConfigEnabled(SORT_GRID_OVERLAP_DETAILS_ENABLED, state.stationConfigs)
        const overlapList = get(sortPathConfiguration, ["overlapList"], [])
        const cycleList = get(sortPathConfiguration, ["cycleList"])

        return (
            <Row key={index} width={"100%"}>
                <Column width={"100%"}>
                    <Box type="fill">
                        <Row spacingInset={"small medium"}>
                            <Column width={"10%"} spacingInset={"none xxsmall"}>
                                <Text type="b400"> { cycleList ? getNodeId() :
                                    get(sortPathConfiguration, ["nodeId"], getNodeId()) }
                                </Text>
                            </Column>
                            <Column width={"20%"} spacingInset={"none xxsmall"}>
                                <Text type="b400"> { cycleList ? cycleList
                                    .map(function(stationCycle) {
                                        return stationCycle.stationCode + " " + stationCycle.cycle.cycleName;
                                    }).join(", ")
                                    : sortPathConfiguration.cycle }
                                </Text>
                            </Column>
                            <Column width={isOverlapDetailsEnabled ? "30%" : "50%"} spacingInset={"none xxsmall"}>
                                <Text type="b400"> { sortPathConfiguration.name }</Text>
                            </Column>
                            { isOverlapDetailsEnabled && (
                            <Column width={"30%"} spacingInset={"none xxsmall"}>
                                <Text type="b400"> {
                                    overlapList ? overlapList
                                        .map(function(overlap) {return overlap.overlappingGridName; }).join(", ")  : ""
                                }
                                </Text>
                            </Column>
                            )}
                            <Column width={isOverlapDetailsEnabled ? "10%" : "20%"} alignmentHorizontal={"right"} spacingInset={"none medium"}>
                                <SortPathConfigurationActions updateDataVersion={updateDataVersion}
                                                              actionButtonRef={actionButtonRefs.current[index]}
                                                              fillPathCSVRef={fillPathCSVRefs.current[index]}
                                                              pickPathCSVRef={pickPathCSVRefs.current[index]}
                                                              sortPathConfiguration={sortPathConfiguration}
                                                              onClickEditGridAllocation={onClickEditGridAllocation} />
                            </Column>
                        </Row>
                    </Box>
                </Column>
            </Row>
        )
    }

    const renderSortPathConfigurations = () => {
        const isOverlapDetailsEnabled = isConfigEnabled(SORT_GRID_OVERLAP_DETAILS_ENABLED, state.stationConfigs)

        return (
            <Column width={"75%"} spacing={"none"}>
                <Row spacingInset={"xsmall medium"}>
                    <Column width={"10%"}>
                        <Text type="h300">
                            <FormattedMessage id="header.station" defaultMessage="Station"/>
                        </Text>
                    </Column>
                    <Column width={"20%"}>
                        <Text type="h300">
                            <FormattedMessage id="sortPaths.cycleHeader" defaultMessage="Cycle (CPT)"/>
                        </Text>
                    </Column>
                    <Column width={isOverlapDetailsEnabled ? "30%" : "50%"}>
                        <Text type="h300">
                            <FormattedMessage id="sortPaths.nameHeader" defaultMessage="Partition Name"/>
                        </Text>
                    </Column>
                    { isOverlapDetailsEnabled && (
                    <Column width={"30%"}>
                        <Text type="h300">
                            <FormattedMessage id="sortPaths.overlapHeader" defaultMessage="Grid Overlaps"/>
                        </Text>
                    </Column>
                    )}
                    <Column width={isOverlapDetailsEnabled ? "10%" : "20%"} alignmentHorizontal={"right"}>
                        <Text type="h300">
                            <FormattedMessage id="sortPaths.actionsHeader" defaultMessage="Actions"/>
                        </Text>
                    </Column>
                </Row>
                <Divider/>
                <Row alignmentHorizontal={"center"} widths={"fill"}>
                    <Column width={"100%"} spacing={"small"} spacingInset={"medium none"}>
                        { isFetchSortPathConfigurationsLoading ? <Loading height={"100px"}/> :
                            fetchSortPathConfigurationsError ?
                                <Row alignmentHorizontal={"center"} alignmentVertical={"center"} spacingInset={"medium"}>
                                    { fetchSortPathConfigurationsError.toString() }
                                </Row> :
                                sortPathConfigurationList.slice((currentPage - 1) * PAGE_LENGTH, currentPage * PAGE_LENGTH)
                                    .map((sortPathConfiguration, index, sortPathConfigurationListSlice) => {
                                        return (
                                            renderCurrentSortPathConfigurationItem(sortPathConfiguration, index, sortPathConfigurationListSlice)
                                        );
                                    })
                        }
                    </Column>
                </Row>
            </Column>
        );
    }

    return (
        <div>
            { renderSortPathConfigurations() }
            { renderPagination() }
        </div>
    )
}

export default SortGridAllocationTable;
