import {
    CUSTOM_FILED_IN_SETTING,
    INCLUDED_LOCATION_OPTIONS,
    SORT_PATH_FILL_ALGORITHMS, SORT_PATH_PICK_ALGORITHMS, SSD3_CYCLE, SSD3_NAME,
} from "../../../Constants";
import {
    commaSeperatedStringToArray
} from "../../../Utility";
import {chainWalk} from "../CommonUtils";


export const validateCustomSettingForResource = (resourceSequences) => {
    return (Object.keys(resourceSequences || {}).length >= 1 &&
        Object.values(resourceSequences || {}).filter(x => x[0] === CUSTOM_FILED_IN_SETTING).every(x => x[1] && (x[1]).trim().length > 0));
};

const validateResourceSequenceBasedOnFillFormAlgorithm = (fillFormData) => {
    return ((fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD && fillFormData.resourceSequences.clusterSequence &&
        fillFormData.resourceSequences.clusterSequence[0]) ||
        (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK && fillFormData.resourceSequences.clusterSequence &&
            fillFormData.resourceSequences.clusterSequence[0]) ||
        (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD && fillFormData.resourceSequences.clusterSequence &&
            fillFormData.resourceSequences.clusterSequence[0]) ||
        (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.COMBINATRIX && fillFormData.resourceSequences.fingerSequence &&
            fillFormData.resourceSequences.fingerSequence[0]))
};

export const validatePickPathPattern = (pickFormData) => {
    return (pickFormData.algorithm && pickFormData.resourceSequences && pickFormData.resourceSequences.clusterSequence &&
        pickFormData.resourceSequences.clusterSequence[0]);
};

export const validateColumnLengthForPartialColumnZebraType = (pickFormData) => {
    return pickFormData.algorithm && (pickFormData.algorithm !== SORT_PATH_PICK_ALGORITHMS.PARTIAL_COLUMN_ZEBRA_PATTERN ||
        (pickFormData.algorithm === SORT_PATH_PICK_ALGORITHMS.PARTIAL_COLUMN_ZEBRA_PATTERN && pickFormData.parameters
            && pickFormData.parameters.columnLengthForZebraPath));
}

export const validateMaxClusterProximitySettings = (fillFormData) => {
    if (fillFormData.commonParameters
        && (Object.keys(fillFormData.commonParameters.maxClusterCapacityForNode || {}).length > 0)) {
        return Object.values(fillFormData.commonParameters.maxClusterCapacityForNode || {}).every(x => x <= 100);
    }
    return true;
};

export const validateAdtaClusterIdentifierSettings = (fillFormData) => {
    if (fillFormData.commonParameters
        && (Object.keys(fillFormData.commonParameters.adtaClusterIdentifierForNode || {}).length > 0)) {
        return Object.values(fillFormData.commonParameters.adtaClusterIdentifierForNode || {}).every(x => x==='true' ||x==='false');
    }
    return true;
};

export const validateIncludedSortZonesForSideline = (commonFormData) => {
    return !!(commonFormData.includedLocations && commonFormData.includedLocations.includedSortZones
        && (commaSeperatedStringToArray(chainWalk(() => commonFormData.includedLocations.includedSortZones, null)).length <= 4));
};

export const validateSidelineSortZonesNomenclature = (commonFormData) => {
    return !!(commonFormData.includedLocations && commonFormData.includedLocations.includedSortZones
        && (commaSeperatedStringToArray(chainWalk(() => commonFormData.includedLocations.includedSortZones, null)).every(x => /^(S-?1[-.][1-4][A-D])$/.test(x))))
}

export const validateCommonFormDataForSSD3 = (commonFormData) => {
    return ((commonFormData.cycle === SSD3_CYCLE && commonFormData.name === SSD3_NAME &&
            commonFormData.includedLocations && commonFormData.includedLocations.sortLocationsRange) &&
            (commonFormData.includedLocations.sortLocationsRange[0] === INCLUDED_LOCATION_OPTIONS.SORT_LOCATIONS.USE_ALL ||
            (commonFormData.includedLocations.sortLocationsRange[0] === INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM &&
                (commaSeperatedStringToArray(chainWalk(() => commonFormData.includedLocations.sortLocationsRange[1], null))
                    .every(x => /^([A-B]\.L[0-9][0-9]\.OV):([A-B]\.L[0-9][0-9]\.OV)$/.test(x)))))
    )
};

export const validateFillFormDataForSSD3 = (fillFormData) => {
    return (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.SAME_DAY_STANDARD_FILL && fillFormData.resourceSequences &&
            fillFormData.resourceSequences.sortLocationSequence)
};

export const validatePickFormDataForSSD3 = (pickFormData) => {
    return (pickFormData.algorithm === SORT_PATH_PICK_ALGORITHMS.SAME_DAY_STANDARD_PATH && pickFormData.resourceSequences &&
            pickFormData.resourceSequences.sortLocationSequence)
};

export const validateFillFormAlgorithm = (fillFormData) => {
    return ((fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD && Object.keys(fillFormData.parameters || {}).length === 0) ||
            (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.STANDARD_BLOCK && Object.keys(fillFormData.parameters || {}).length === 0) ||
            (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.COMBINATRIX && Object.keys(fillFormData.parameters || {}).length === 1) ||
            (fillFormData.algorithm === SORT_PATH_FILL_ALGORITHMS.CLUSTER_WISE_STANDARD && Object.keys(fillFormData.parameters || {}).length === 0)) &&
        validateResourceSequenceBasedOnFillFormAlgorithm(fillFormData);
};

export const validateIncludedLocationsForDynamicCycle = (commonFormData) => {
    const includedLocationOptions = Object.values(commonFormData.includedLocations || {})
        .filter((option) => Array.isArray(option) && option.length === 2);

    const isCustomIncluded = includedLocationOptions
        .filter((includedLocationOption) => includedLocationOption[0] === INCLUDED_LOCATION_OPTIONS.COMMON.CUSTOM)
        .every((includedLocationOption) => includedLocationOption[1] && includedLocationOption[1].trim().length > 0);

    return includedLocationOptions.length === 2 && isCustomIncluded;
};
