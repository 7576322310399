import React, {useCallback, useContext, useEffect, useState} from "react";
import SortGridAllocationForm from "./SortGridAllocationForm";
import {getInitialFormDataState, getInitialFormInputState} from "../util/sort-paths/SortPathConfigurationInputs";
import SortGridAllocationTable from "./SortGridAllocationTable";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import get from "lodash/get";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import {
    FEATURES,
    SortGridAllocationCommonFormStates,
    SortGridAllocationFormStates,
    ACTION_TYPE,
    SPG_TAB,
    CAPABILITY, SORT_PATH_SETTING_STATUS, SORT_PATH_INFO_UPDATE_STATUS
} from "../../Constants";
import {StationConfigContext} from "../../context/StationConfigContext";
import {getNodeId} from "../../Utility";
import {fetchBinTypes} from "../../resources/SortPathsResource";
import {UPDATE_BIN_TYPES} from "../../reducers/StationConfigReducer";
import { UserAuthorizationContext } from "../../context/UserAuthorizationContext";
import { SET_IS_AUTHORIZED_USER } from "../../reducers/UserAuthorizationReducer";
import {getFeaturesFromParent, getNodeCapabilityFromParent} from "../../handler/ParentDataHandler";
import {isTCPAuthorizedUser} from "../../helper/NodeResourceAuthorizationHelper";
import Loading from "../util/Loading";
import {refreshSortPathsForNode} from "../../resources/SortPathSettingsResource";

const SortGridAllocationView = (props) => {
    const [dataVersion, setDataVersion] = useState(false);
    const [isGridAllocationFormOpen, setIsGridAllocationFormOpen] = useState(false);
    const [isNewGridAllocation, setIsNewGridAllocation] = useState(undefined);
    const [formInputs, setFormInputs] = useState({});
    const [formData, setFormData] = useState({ commonFormData: {}, fillFormData: {}, pickFormData: {} });
    const [sortPathConfigurationList, setSortPathConfigurationList] = useState([]);
    const [isSortPathsForNodeRefreshed, setIsSortPathsForNodeRefreshed] = useState(false);
    const [isRefreshSortPathsForNodeLoading, setIsRefreshSortPathsForNodeLoading] = useState(false);
    const [isRefreshSortPathsForNodeSuccess, setIsRefreshSortPathsForNodeSuccess] = useState(false);
    const [refreshSortPathsForNodeNotification, setRefreshSortPathsForNodeNotification] = useState(undefined);

    const {state, dispatch} = useContext(StationConfigContext);
    const { userAuthorizationState, userAuthorizationDispatch } = useContext(UserAuthorizationContext);

    const onClickCreateGridAllocation = useCallback(() => {
        setGridAllocationInputForm(true, getNodeId(),
            get(formData, [SortGridAllocationFormStates.COMMON_FORM_DATA, SortGridAllocationCommonFormStates.CYCLE]));
        setFormData(getInitialFormDataState(undefined, getNodeId(), undefined, state.cycleList));
    }, []);

    const setGridAllocationInputForm = useCallback((isNewGridAllocation, nodeId, cycle) => {
        setIsGridAllocationFormOpen(true);
        setIsNewGridAllocation(isNewGridAllocation);
        setFormInputs(getInitialFormInputState(cycle, nodeId, state.cycleList));
    }, []);

    // Flip dataVersion value to refresh data from backend
    const updateDataVersion = useCallback(() => setDataVersion(!dataVersion), [dataVersion]);

    useEffect(() => {
        fetchBinTypes().then(data => {
            dispatch({type: UPDATE_BIN_TYPES, data: {binTypes: data.binTypes}});
        });
        getNodeCapabilityFromParent().then(capability => {
            getFeaturesFromParent([FEATURES.NARC_AUTHORIZATION_CHECK, FEATURES.NARC_SSD_AUTHORIZATION_CHECK]).then(featureStatus => {
                const parsedCapability = JSON.parse(capability.capability);
                const ssd_authorization_enabled_response = featureStatus.data[FEATURES.NARC_SSD_AUTHORIZATION_CHECK]&& parsedCapability===CAPABILITY.SSD;
                const tcp_auth_check_enabled_response = (featureStatus.data[FEATURES.NARC_AUTHORIZATION_CHECK] && parsedCapability === CAPABILITY.AMZL_CORE)
                                                        || ssd_authorization_enabled_response;
                dispatch({type: ACTION_TYPE.GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK, isNARCTCPAuthorizationCheckEnabled: tcp_auth_check_enabled_response});
                isTCPAuthorizedUser(tcp_auth_check_enabled_response, SPG_TAB).then( (isAuthorized) => {
                    userAuthorizationDispatch({type: SET_IS_AUTHORIZED_USER, payload: {
                            isAuthorized:isAuthorized
                        }})
                });
            });
        });

    }, []);

    useEffect(() => {
        if ((!isSortPathsForNodeRefreshed || isRefreshSortPathsForNodeSuccess) &&
            Array.isArray(sortPathConfigurationList) && sortPathConfigurationList.length) {
            sortPathConfigurationList.forEach(sortPathSetting => {
                if (get(sortPathSetting, ["status"]) === SORT_PATH_SETTING_STATUS.PENDING_UPDATE) {
                    setRefreshSortPathsForNodeNotification(props.intl.formatMessage(
                        {id: "refresh_sort_path_for_node_required"}))
                }
            });
        }
    }, [sortPathConfigurationList]);

    const onClickRefreshSortPaths = () => {
        setIsRefreshSortPathsForNodeLoading(true);
        setIsSortPathsForNodeRefreshed(true);
        const sortPathUpdateInfos  = refreshSortPathsForNode(getNodeId());
        sortPathUpdateInfos.then(data => {
            if (!data) {
                setRefreshSortPathsForNodeNotification(props.intl.formatMessage(
                    {id: "refresh_sort_path_for_node_failed"}));
            } else {
                const { sortPathUpdateInfoList } = data;
                if (sortPathUpdateInfoList) {
                    let failedCycleList = new Set([]);
                    sortPathUpdateInfoList.forEach(sortPathUpdateInfo => {
                        if (get(sortPathUpdateInfo, ["updateStatus"]) === SORT_PATH_INFO_UPDATE_STATUS.FAILURE) {
                            failedCycleList.add(get(sortPathUpdateInfo, ["cycle"]))
                        }
                    });
                    if (failedCycleList.size) {
                        setRefreshSortPathsForNodeNotification(props.intl.formatMessage(
                            {id: "refresh_sort_path_for_node_partial_failed"},
                            {cycleList: new Array(...failedCycleList).join(", ")}));
                    } else {
                        setIsRefreshSortPathsForNodeSuccess(true);
                        setRefreshSortPathsForNodeNotification(props.intl.formatMessage(
                            {id: "refresh_sort_path_for_node_successful"}));
                    }
                } else {
                    setRefreshSortPathsForNodeNotification(props.intl.formatMessage(
                        {id: "refresh_sort_path_for_node_failed"}));
                }
            }
        }, error => {
            setRefreshSortPathsForNodeNotification(error || props.intl.formatMessage(
                {id: "refresh_sort_path_for_node_failed"}));
        }).finally(() => setIsRefreshSortPathsForNodeLoading(false));
    };

    return (
        <div>
            { isGridAllocationFormOpen ?
                <SortGridAllocationForm dataVersion={dataVersion}
                                        setDataVersion={setDataVersion}
                                        isNewGridAllocation={isNewGridAllocation} formInputs={formInputs}
                                        formData={formData} setIsGridAllocationFormOpen={setIsGridAllocationFormOpen}
                                        setIsNewGridAllocation={setIsNewGridAllocation} setFormInputs={setFormInputs}
                                        setFormData={setFormData}
                                        sortPathConfigurationList={sortPathConfigurationList}
                                        isAuthorized={userAuthorizationState.isAuthorized}
                />
                :
                <Column spacing={"small"}>
                    {!isRefreshSortPathsForNodeLoading && refreshSortPathsForNodeNotification &&
                    <Alert type={isRefreshSortPathsForNodeSuccess ? "success" : "warning"}
                           onClose={() => setRefreshSortPathsForNodeNotification(undefined)}>
                        {refreshSortPathsForNodeNotification}
                    </Alert>
                    }
                    <Row alignmentHorizontal={"right"}>
                        {userAuthorizationState.isAuthorized &&
                        <Row alignmentHorizontal={"right"} spacingInset={"small none none"}>
                            <Button onClick={() => onClickCreateGridAllocation()}>
                                <FormattedMessage id="sortPaths.addNewGridAllocation" defaultMessage="Add new grid allocation"/>
                            </Button>
                        </Row>}
                        {userAuthorizationState.isAuthorized && refreshSortPathsForNodeNotification &&
                        !isRefreshSortPathsForNodeSuccess && <Row alignmentHorizontal={"right"} spacingInset={"small none none"}>
                            <Button onClick={() => onClickRefreshSortPaths()}>
                                <FormattedMessage id="sortPaths.refreshSortPaths" defaultMessage="Refresh Sort Paths"/>
                            </Button>
                        </Row>}
                    </Row>
                    {isRefreshSortPathsForNodeLoading ? <Loading height={"100px"}/> :
                        <SortGridAllocationTable dataVersion={dataVersion}
                                             updateDataVersion={updateDataVersion}
                                             setGridAllocationInputForm={setGridAllocationInputForm}
                                             setFormData={setFormData}
                                             sortPathConfigurationList={sortPathConfigurationList}
                                             setSortPathConfigurationList={setSortPathConfigurationList}
                    />}
                </Column>
            }
        </div>
    );
};

export default injectIntl(SortGridAllocationView);
