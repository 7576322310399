import React, {Component} from "react";
import RegisterEmployeeContextProvider from "../../context/RegisterEmployeeContext";
import RegisterEmployee from "../RegisterEmployee";

class RegisterEmployeeManager extends Component {
    render() {
        return (
            <RegisterEmployeeContextProvider>
                <RegisterEmployee/>
            </RegisterEmployeeContextProvider>
        )
    }
}

export default RegisterEmployeeManager
