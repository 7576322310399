/**
 * Actions file for Resource Management.
 */

import {getCoralServiceDataFromParent} from "../handler/ParentDataHandler";
import {
    PROCESS_NODE_RESOURCE,
    ENDPOINT_UPDATE_RESOURCE_V2,
    ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES,
    ENDPOINT_DEPRECATE_RESOURCES,
    RESOURCE_TYPE_DISPLAY_FIELDS,
    RESOURCE_TYPES,
    SSP_LANE_FILE_DEFAULT_VALUES,
    SSP_CONFIG_FILE_DEFAULT_VALUES,
    RA_CONFIG_FILE_DEFAULT_VALUES,
    OV_RESOURCE_SUFFIX,
    ENDPOINT_UPDATE_RESOURCE,
    ENDPOINT_DEPRECATE_RESOURCES_ASSOCIATIONS,
    ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS,
    TYPE,
    AISLE_TO_STAGING_ZONE_DISTANCE_MAPPING_FILE_DEFAULT_VALUES,
    ENDPOINT_GET_RESOURCES_RELATIONSHIPS,
    ENDPOINT_GET_RESOURCES_V2,
    ERROR_MAP,
    RESOURCES_FILE_DEFAULT_VALUES,
    INACTIVE_STATUS_TYPE,
    ACTIVE_STATUS_TYPE,
    GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE,
    REVIEW_STATUS_TYPES,
    ENDPOINT_GENERATE_VALIDATION_REPORT
} from "../Constants";
import {createRequest, downloadXLSXFile} from "../Utility";
import {parseResponseData, convertImmediateResourceData, getResourceCreationDataWithNodeResources, fetchRequestObjectForGetResourceData, fetchEndpointForGetResourceData, fetchRequestObjectForDeprecateResourceAssociation, fetchRequestObjectForSubmitUpdatedResource, parseRequestObjectForSubmitUpdatedResource} from "../helper/NodeResourceManagerHelper";

/**
 *
 * @param str - splits resource string on the basis of continuous string and numbers
 * @returns {string[]|*[]}
 */
function splitResourceString(str) {
    const regExp = /([A-Z]*)(\d*)([A-Z]*)/;
    const matches = str.toUpperCase().replace(/[^A-Z\d]+/g, '').match(regExp);
    return matches.slice(1);
}

/**
 * sorts the resource labels on the basis of their consisting alphabet strings and numeric values
 */
function compareResources(firstArr, secondArr) {
    let isString = true;
    for (let index = 0; index < firstArr.length; ++index) {
        if (isString) {
            if (firstArr[index] !== secondArr[index]) {
                return firstArr[index] < secondArr[index] ? -1 : 1;
            }
        } else {
            const numFirst = Number(firstArr[index]);
            const numSecond = Number(secondArr[index]);
            if (numFirst !== numSecond) {
                return numFirst < numSecond ? -1 : 1;
            }
        }
        isString = !isString;
    }
}

/**
 * Fetches layout data corresponding to resources and relationships from Coral APIs
 * @param {*} resourceSearchCriteria 
 * @param {*} endpoint 
 * @returns 
 */
async function fetchLayoutData(resourceSearchCriteria, endpoint, isOVDFeatureEnabled, initialPageToken, isPaginationFixEnabled) {
    let pageToken = initialPageToken;
    let completeResponse = {};
    if(endpoint ===  ENDPOINT_GET_RESOURCES_V2) {
        completeResponse = {data : {nodeResources : []}}
    }
    do {
        // Fetch Request Object
        let requestObject = fetchRequestObjectForGetResourceData(resourceSearchCriteria, pageToken);
        // Make coral api request
        let args = createRequest(PROCESS_NODE_RESOURCE, endpoint, requestObject);
        let response = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, endpoint);
        if(endpoint ===  ENDPOINT_GET_RESOURCES_V2) {
            if (response && response.data && response.data.nodeResources) {
                // Adds paginated response in existing resource list to form complete set of resources data list
                if (isOVDFeatureEnabled) {
                    pageToken = response.data.pageToken;
                }
                completeResponse.data.nodeResources.push(...response.data.nodeResources);
                completeResponse.status = response.status;
                completeResponse.pageToken = response.data.pageToken;
            }
        } else if (endpoint ===  ENDPOINT_GET_RESOURCES_RELATIONSHIPS) {
            // Non paginated query for resource relationships
            completeResponse = response;
        }
    } while (pageToken && !isPaginationFixEnabled);
    return completeResponse;
}

/**
 * Returns layout data
 * @param {*} resourceSearchCriteria 
 * @returns 
 */
export async function getResourceData(resourceSearchCriteria, isOVDFeatureEnabled=false, pageToken=null, isPaginationFixEnabled=false) {
    // Fetch endpoint
    let endpoint = fetchEndpointForGetResourceData(resourceSearchCriteria);
    // Fetch response from paginated query
    let response = await fetchLayoutData(resourceSearchCriteria, endpoint, isOVDFeatureEnabled, pageToken, isPaginationFixEnabled);
    // Sort data in increasing order for better visibility on SCC
    if (endpoint === ENDPOINT_GET_RESOURCES_RELATIONSHIPS) {
        response.data.sort((first, second) => compareResources(splitResourceString(first.resourceLabel), splitResourceString(second.resourceLabel)));
    } else if (endpoint === ENDPOINT_GET_RESOURCES_V2 && response && response.data && response.data.nodeResources) {
        response.data.nodeResources.sort((first, second) => compareResources(splitResourceString(first.label), splitResourceString(second.label)));
    }
    return parseResponseData(response.data, resourceSearchCriteria, isOVDFeatureEnabled, response.pageToken);
}

/**
 * Inits the dialog for deprecating a resource.
 */
export async function deprecateResource(nodeResourceList, deprecationStatus, forceStatusFlag) {
    try {
        if (!nodeResourceList.length) {
            return {
                success: true,
                deprecatedResources: []
            }
        }
        let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_DEPRECATE_RESOURCES, {nodeResources: nodeResourceList, deprecated: deprecationStatus, force: forceStatusFlag});
        const response = await getCoralServiceDataFromParent(args);
        if(response.status === 500){
            return {
                success: false,
                updateResourceError: response.errorResponse.errorMessage
            }
        }
        return {
            success: true,
            deprecatedResources: response.nodeResources
        }
    } catch (error) {
        return {
            success: false,
            updateResourceError: error
        }
    }
}

/**
 * Generates validation report for resource type.
 */
export async function generateValidationReport(resourceSearchCriteria) {
    try {

        let requestObject = {
            nodeId : resourceSearchCriteria.nodeId,
            resourceTypesToValidate : resourceSearchCriteria.resourceType,
            userEmail: "",
            emailCcList: []
        }

        requestObject[GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE] = {
            REVIEW_STATUS : [REVIEW_STATUS_TYPES.REVIEWED, REVIEW_STATUS_TYPES.PENDING_REVIEW]
        };

        let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GENERATE_VALIDATION_REPORT, requestObject);
        const response = await getCoralServiceDataFromParent(args);
        if(response.status === 500){
            return {
                success: false
            }
        }
        return {
            success: true,
            generateValidationReportSuccessMessage: response.data.msg
        }
    } catch (error) {
        return {
            success: false,
            generateValidationReportErrorMessage: error
        }
    }
}

/**
 * Inits the dialog for deleting a resource association.
 */
 export async function deprecateResourceAssociation(resource, nodeId, resourceSearchCriteria, childResource) {
    let resource_association = fetchRequestObjectForDeprecateResourceAssociation(resource, resourceSearchCriteria, childResource);
    try {
        let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_DEPRECATE_RESOURCES_ASSOCIATIONS, {deprecateResourceRelationshipsRequestList: [resource_association]});
        const response = await getCoralServiceDataFromParent(args);
        if(response.status === 500){
            return {
                success: false,
                updateResourceError: ERROR_MAP.GENERIC
            }
        }
        return {
            success: true,
            deprecatedResources: response
        }
    } catch (error) {
        return {
            success: false,
            updateResourceError: error
        }
    }
}

/**
 * Update resource association
 */
export async function updateResourceAssociation(resource, nodeId){
    let resource_association = fetchRequestObjectForSubmitUpdatedResource(resource, nodeId)
                
    let request = {
        addResourceRelationshipsList: [resource_association]
    }

    try{
        const response = await getCoralServiceDataFromParent(
            createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS, request),
                            PROCESS_NODE_RESOURCE, ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS);
                            
        if(response.status === 500){
            return {
                success: false,
                error: "error",
                type: TYPE.ASSOCIATION
            };
        }
        return {
                success: true,
                resource: response,
                type: TYPE.ASSOCIATION
            }
    } catch (error){
        return {
            success: false,
            error: error,
            type: TYPE.ASSOCIATION
        }
    }
}

/**
 * Update the resource Data
 */
export async function submitUpdatedResource(resource, nodeId, state) {
    if([RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE,
            RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE].includes(resource.resourceType)){
            return updateResourceAssociation(resource, nodeId);
    }
    
    let parsedResource = parseRequestObjectForSubmitUpdatedResource(resource, nodeId);
    try {
        let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_RESOURCE_V2, {nodeResource: parsedResource});
        let response  = await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_RESOURCE_V2);
        if(response.status === 500){
            return {
                success: false,
                error: "error",
                type: TYPE.RESOURCE
            };
        }
        return {
            success: true,
            resource: response,
            type: TYPE.RESOURCE
        }
    } catch (error) {
        return {
            success: false,
            error: error,
            type: TYPE.RESOURCE
        }
    }
}

export async function fetchImmediateResourceData(resourceStatus, resourceId, nodeId, resourceType) {
    if(!resourceStatus){
        try {
            const dataRequest = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES, {
                resourceId: resourceId,
                nodeId: nodeId
            });
            const resourceDataJson = await getCoralServiceDataFromParent(dataRequest, PROCESS_NODE_RESOURCE, ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES);
            resourceDataJson.data.nodeResources.sort((first, second) => compareResources(splitResourceString(first.label), splitResourceString(second.label)));
            const resourceData = convertImmediateResourceData(resourceDataJson.data.nodeResources, resourceType);
            return {
                resources: resourceData,
                displayFields: RESOURCE_TYPE_DISPLAY_FIELDS[resourceType]["childResource"]
            }
        }
        catch (error) {
            return {
                resources: null,
                error: error
            }
        }
    }
}

export function getImmediateChildForResources(nodeId, parentLabel){
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES, {
        label: parentLabel,
        nodeId: nodeId
    });
    return getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE,
        ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES);
}

export async function updateResource(nodeResource) {
    let args = createRequest(PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_RESOURCE, {nodeResource: nodeResource});
    return await getCoralServiceDataFromParent(args, PROCESS_NODE_RESOURCE, ENDPOINT_UPDATE_RESOURCE);
}

export async function uploadResources(stationCode, resourceDataMap, capability, endpoint, narc_sls_intergration_enabled, isOVDFeatureEnabled) {
    try {
        if (resourceDataMap && Object.keys(resourceDataMap).length > 0) {
            let preparedJson = {};
            preparedJson.nodeId = stationCode;
            preparedJson.resources = [];
            Object.keys(resourceDataMap).forEach((resourceType) => {
                let resourceObject = {
                    type: resourceType,
                    list: []
                };
                resourceObject.list = resourceObject.list.concat(resourceDataMap[resourceType].sheetMapList);
                preparedJson.resources.push(resourceObject);
            });

            let updatedJson = getResourceCreationDataWithNodeResources(preparedJson, capability, endpoint, isOVDFeatureEnabled);
            const response = await getCoralServiceDataFromParent(createRequest(PROCESS_NODE_RESOURCE, endpoint, updatedJson),
                PROCESS_NODE_RESOURCE, endpoint);
            if(response.status === 500) {
                let responseErrorMsg = ERROR_MAP.GENERIC;
                if(narc_sls_intergration_enabled) {
                    responseErrorMsg = response.errorResponse.errorMessage
                }
                return {
                    uploadBulkResourceSuccess: false,
                    uploadBulkResourceError: responseErrorMsg
                }
            }
            return {
                uploadBulkResourceSuccess: true
            }
        } else {
            return {
                uploadBulkResourceError: ERROR_MAP.NO_RESOURCES_FOUND,
                uploadBulkResourceSuccess: false
            }
        }
    } catch (error) {
        return {
            uploadBulkResourceError: ERROR_MAP.GENERIC,
            uploadBulkResourceSuccess: false
        }
    }
}

function getRADataLocationToOVMapping(nodeId, resources) {
    return  resources.map(
        resourceData => {
            return {
                ...RA_CONFIG_FILE_DEFAULT_VALUES,
                "StackingFilter": resourceData.label + OV_RESOURCE_SUFFIX,
                Resources: resourceData.label
            };
        }
    )
}

function getRADataOVToOVMapping(nodeId, resources) {
    return  resources.map(
        resourceData => {
            return {
                ...RA_CONFIG_FILE_DEFAULT_VALUES,
                "StackingFilter": resourceData.label + OV_RESOURCE_SUFFIX,
                Resources: resourceData.label + OV_RESOURCE_SUFFIX
            };
        }
    )
}

export function getAisleToStagingZoneDistanceMapping(resources) {
    return  resources.map(
        resourceData => {
            return {
                ...AISLE_TO_STAGING_ZONE_DISTANCE_MAPPING_FILE_DEFAULT_VALUES,
                "Aisle_Label_Range": resourceData.Aisle_Label,
                "Staging_Zone_Label": resourceData.Staging_Zone_Label,
                "Distance": resourceData.Distance
            };
        }
    )
}

/**
 * Transforms resources data into excel format
 * @param {*} resources 
 * @returns Resource data in excel format
 */
export function getResourcesMapping(resources) {
    return  resources.map(
        resourceData => {
            return {
                ...RESOURCES_FILE_DEFAULT_VALUES,
                "label": resourceData.label,
                "scannableId": resourceData.scannableId,
                "binResourceCategory": resourceData.binResourceCategory,
                "binResourceSubCategory": resourceData.binResourceSubCategory,
                "binCount" : resourceData.binCount,
                "binTier" : resourceData.binTier,
                "binType" : resourceData.binType, //This is mapped from binDimension in backend
                "reviewStatus" : resourceData.reviewStatus,
                "Status" : resourceData.isDeprecated ? INACTIVE_STATUS_TYPE : ACTIVE_STATUS_TYPE
            };
        }
    )
}

function getSSPLaneData(nodeId, resources) {
    return  resources.map(
        resourceData => {
            return {
                ...SSP_LANE_FILE_DEFAULT_VALUES,
                Node: nodeId,
                Lane: nodeId + "->CUST",
                "Stacking Filter": resourceData.label
            };
        }
    )
}

function getSSPConfigSFMappingData(nodeId, resources) {
    return resources.map(
        resourceData => {
            return {
                ...SSP_CONFIG_FILE_DEFAULT_VALUES,
                Action: "CREATE RULE TO SF MAPPING",
                Node: nodeId,
                "Stacking Filter": resourceData.label,
                "Sort Code": resourceData.label
            };
        }
    )
}

function getSSPConfigSFHierarchyData(nodeId, resources) {
    return resources.map(
        resourceData => {
            return {
                ...SSP_CONFIG_FILE_DEFAULT_VALUES,
                Action: "CREATE SF HIERARCHY MAPPING",
                Node: nodeId,
                "Stacking Filter": resourceData.label
            };
        }
    )
}

export function getSSPLaneDataForSSDStation(nodeId) {
    const SSPLaneSheetName = "SF2Lane"
    const sspLaneFileName = nodeId + "_SSP_Lane";

    const SSPConfigSheetName = "SF2Rule"
    const SSPConfigFileName = nodeId + "_SSP_Config";

    getResourceData({nodeId: nodeId, resourceType: RESOURCE_TYPES.VIRTUAL_LOCATION}).then( result => {
        const resourceList = getSSPLaneData(nodeId, result.resources);
        const sspConfigSFMapping = getSSPConfigSFMappingData(nodeId, result.resources);
        const sspConfigSFHierarchyMapping = getSSPConfigSFHierarchyData(nodeId, result.resources);

        downloadXLSXFile(resourceList, SSPLaneSheetName, sspLaneFileName);
        downloadXLSXFile([...sspConfigSFMapping, ...sspConfigSFHierarchyMapping], SSPConfigSheetName, SSPConfigFileName);
    });
}

export function getRADataForSSDStation(nodeId) {
    const RASheetName = "Allocations"
    const RAFileName = nodeId + "_SSP_RA";

    getResourceData({nodeId: nodeId, resourceType: RESOURCE_TYPES.CART_LOCATION}).then( result => {
        const resourceListLocationToOVMapping = getRADataLocationToOVMapping(nodeId, result.resources);
        const resourceListOVToOVMapping = getRADataOVToOVMapping(nodeId, result.resources);

        downloadXLSXFile([...resourceListLocationToOVMapping, ...resourceListOVToOVMapping], RASheetName, RAFileName);
    });
}
