import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Text from "@amzn/meridian/text";
import Modal from "@amzn/meridian/modal";
import Loading from "../util/Loading";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";

const SortGridAllocationModal = (props) => {
    const {
        intl, isNewGridAllocation, isGridAllocationSubmitModalOpen, isGridAllocationInProgress, gridAllocationError,
        dataVersion, resetToDefaultState
    } = props;


    return (
        <Column>
            <Modal title={intl.formatMessage({
                id: isNewGridAllocation ? "sortPaths.addNewSortGridAllocation" : "sortPaths.editSortGridAllocation",
                defaultMessage: isNewGridAllocation ? "Add new sort grid allocation": "Edit sort grid allocation"
            })}
                   bodySpacingInset="xlarge"
                   describedById="modal-description" width="800px"
                   open={isGridAllocationSubmitModalOpen}
                   onClose={() => resetToDefaultState(dataVersion)} >
                { isGridAllocationInProgress ?
                    <Loading height={"50px"}/> :
                    <Row spacing={"medium"} width={"100%"}>
                        <Alert type={gridAllocationError ? "error" : "success"} size="large" width={"100%"}>
                            { gridAllocationError ?
                                <div>
                                    <Text type={"h100"}>
                                        { gridAllocationError.toString() }
                                    </Text>
                                    <Text type={"h100"}>
                                        <FormattedMessage id={isNewGridAllocation ? "sortPaths.addGridAllocationFailureMessage": "sortPaths.editGridAllocationFailureMessage"}
                                                          defaultMessage={isNewGridAllocation ? "New sort grid allocation was not added" : "Sort grid allocation was not updated"}/>
                                    </Text>
                                </div> :
                                <Text type={"h100"}>
                                    <FormattedMessage id={isNewGridAllocation ? "sortPaths.addGridAllocationSuccessMessage": "sortPaths.editGridAllocationSuccessMessage"}
                                                      defaultMessage={isNewGridAllocation ? "New sort grid allocation was successfully added" : "Sort grid allocation was successfully updated"}/>
                                </Text>
                            }
                        </Alert>
                    </Row>
                }
            </Modal>
        </Column>
    );
}

export default injectIntl(SortGridAllocationModal);
