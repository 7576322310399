import React, {useEffect, useContext, useState} from 'react';
import Row from "@meridian/components/row";
import Column from "@meridian/components/column";
import Box from "@meridian/components/box";
import Button from "@meridian/components/button"
import { FormattedMessage } from 'react-intl';
import Text from "@meridian/components/text";
import Input from "@meridian/components/input";
import '../css/StationManagement.css';
import Select, { SelectOption } from "@meridian/components/select";
import ErrorPage from '../components/util/ErrorPage';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loading from '../components/util/Loading';
import {
    getStationByStationCodeCallBegin,
    getStationByStationCode,
    getStationsByRegion,
    getStationByStationCodeCallFailure,
    getStationByStationCodeSuccess,
    verifyEmployeeTCPRoles,
    setStationUpdateModalState,
    updateStationInfo,
    getUserName, getStationActiveCapability
} from "../resources/StationViewManagementResource";
import {
    UPDATE_ADDRESS_LINE1, UPDATE_ADDRESS_LINE2, UPDATE_ADDRESS_LINE3,
    UPDATE_CITY, UPDATE_LATITUDE, UPDATE_LONGITUDE,
    UPDATE_PARENT_STATION,
    UPDATE_POSTAL_CODE,
    UPDATE_STATE, CAPABILITY, UPDATE_STATION_ROLE
} from "../Constants"
import {StationViewManagementContext} from "../context/StationViewManagementContext";
import Alert from "@meridian/components/alert";
import Modal from "@meridian/components/modal";
import ModalFooter from "@amzn/meridian/modal/modal-footer";
import Banner from "./Banner";

const StationView = () => {

    const {state, dispatch} = useContext(StationViewManagementContext);
    const location = useLocation();
    const [userId, setUserId] = useState("");
    const [capability, setCapability] = useState("")
    const STATION_DATA_CACHE_TIME_IN_HOURS = 6;

    // Filter out Capabilites which already exists as Stations's Active Capability
    function filterActiveCapability(capability) {
        return state.items.stationActiveCapabilities.includes(capability) ? null : capability;
    }

    useEffect(() => {
        let stationCode = "";
        if (location.state) {
            stationCode = location.state.detail;
        }
        dispatch(getStationByStationCodeCallBegin());
        getStationByStationCode(stationCode).then(stationDataJson => {
            getStationsByRegion(stationDataJson.parentStation).then(stationList => {
                getStationActiveCapability(stationCode).then(activeCapabilities => {
                    dispatch(getStationByStationCodeSuccess({
                        currentStation : stationDataJson,
                        stationList : stationList,
                        stationActiveCapabilities : activeCapabilities
                    }));
                })
            })
        }).catch(error => {
            dispatch(getStationByStationCodeCallFailure(error.response));
        });
        getUserName().then(userName => {
            setUserId(userName)
            verifyEmployeeTCPRoles(stationCode, dispatch, userName, UPDATE_STATION_ROLE);
        })
    }, []);
    const isReadOnly = true;
    let history = useHistory();
    if (state.error) {
        return <ErrorPage error={state.error.data && state.error.data.message ? state.error.data.message : "generic.error"}/>
    }

    if (state.loading) {
        return (
            <div className="StationView">
                <Loading/>
            </div>
        );
    }
    let currentStation = state.items.currentStation;
    const updateAllowed = (state.updateButtonEnabled) && (state.stationAllowlistedForConfigUpdate);
    if (currentStation) {
        return (
            <div className="StationView" data-testid="StationViewDiv">
                <Modal
                    id="modal-setStation"
                    closeLabel="Close"
                    open={state.stationUpdateModalState}
                >
                    <Row>
                        <Alert
                            type="warning"
                            size="large"
                        >
                            <FormattedMessage id="station_info_update_modal.critical_message" defaultMessage="You are about to modify critical station configurations"/>
                            <br/>
                            <FormattedMessage id="station_info_update_modal.confirm_message" defaultMessage="Do you want to save the changes ?"/>
                        </Alert>
                    </Row>
                    <ModalFooter>
                        <Row alignment="center right" widths="fit">
                            <Button
                                data-testid="modal-yesButton"
                                type="primary"
                                size="small"
                                onClick={() => updateStationInfo(currentStation, userId, dispatch, capability)}
                            >
                                <FormattedMessage id="station_info_update_modal.confirm" defaultMessage="Yes"/>
                            </Button>
                            <Button
                                data-testid="modal-cancelButton"
                                type="primary"
                                size="small"
                                onClick={() => dispatch(setStationUpdateModalState(false))}
                            >
                                <FormattedMessage id="station_info_update_modal.cancel" defaultMessage="Cancel"/>
                            </Button>
                        </Row>
                    </ModalFooter>
                </Modal>
                {updateAllowed && <Box spacingInset="none large none large">
                    <Banner message="banner.station_edit_page" type="informational" values={{"cache_time": STATION_DATA_CACHE_TIME_IN_HOURS}}/>
                </Box>}
                <Row
                    widths={["grid-9", "grid-3"]}
                    wrap="down"
                    alignment="top right"
                    spacingInset="large"
                >
                    <Box
                        type="outline"
                        spacingInset="small"
                        height={600}
                        className="OverflowYAuto"
                    >
                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Text
                                type="h200"
                            >
                                <FormattedMessage id="station_view.view_station" defaultMessage="View Station"/>
                            </Text>
                        </Row>
                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_code" defaultMessage="Station Code"/> *</Text>
                                </Text>
                                <Input
                                    value={currentStation.code ? currentStation.code : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={true}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_name" defaultMessage="Station Name"/></Text>
                                </Text>
                                <Input
                                    value={currentStation.name ? currentStation.name : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={isReadOnly}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_city" defaultMessage="City"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputCity"
                                    value={currentStation.city ? currentStation.city : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_CITY, payload:value})}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.description" defaultMessage="Description"/></Text>
                                </Text>
                                <Input
                                    value={currentStation.description ? currentStation.description : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={isReadOnly}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_active" defaultMessage="Active"/> *</Text>
                                </Text>
                                <Input
                                    size="small"
                                    popoverPosition="adjacent"
                                    value={currentStation.active ? "true" : "false"}
                                    width={"150px"}
                                    disabled={isReadOnly}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_parent" defaultMessage="Parent"/> *</Text>
                                </Text>
                                <Select
                                    size="small"
                                    popoverPosition="adjacent"
                                    width={"150px"}
                                    value={currentStation.parentStation ? currentStation.parentStation : ""}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_PARENT_STATION, payload:value})}
                                >
                                    {state.items.stationList.map(station => (
                                        <SelectOption key={station.id} value={station.id} label={station.code} />
                                    ))}
                                </Select>
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.station_timezone" defaultMessage="Time Zone"/></Text>
                                </Text>
                                <Input
                                    size="small"
                                    popoverPosition="adjacent"
                                    width={"150px"}
                                    value={currentStation.timeZone ? currentStation.timeZone : ""}
                                    disabled={isReadOnly}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.state" defaultMessage="State"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputState"
                                    value={currentStation.address && currentStation.address.state ? currentStation.address.state : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_STATE, payload:value})}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.address1" defaultMessage="Address Line 1"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputAddressLine1"
                                    value={currentStation.address && currentStation.address.address1 ? currentStation.address.address1 : ""}
                                    type="text"
                                    size="small"
                                    width={"250px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_ADDRESS_LINE1, payload:value})}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.address2" defaultMessage="Address Line 2"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputAddressLine2"
                                    value={currentStation.address && currentStation.address.address2 ? currentStation.address.address2 : ""}
                                    type="text"
                                    size="small"
                                    width={"250px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_ADDRESS_LINE2, payload:value})}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.address3" defaultMessage="Address Line 3"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputAddressLine3"
                                    value={currentStation.address && currentStation.address.address3 ? currentStation.address.address3 : ""}
                                    type="text"
                                    size="small"
                                    width={"250px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_ADDRESS_LINE3, payload:value})}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.postal_code" defaultMessage="Postal Code"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputPostalCode"
                                    value={currentStation.address && currentStation.address.postalCode ? currentStation.address.postalCode : ""}
                                    type="text"
                                    size="small"
                                    width={"150px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_POSTAL_CODE, payload:value})}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="top left"
                        >
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.latitude" defaultMessage="Latitude"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputLatitude"
                                    value={currentStation.address && currentStation.address.geocode && currentStation.address.geocode.latitude ? currentStation.address.geocode.latitude : ""}
                                    type="number"
                                    size="small"
                                    width={"150px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_LATITUDE, payload:value})}
                                />
                            </Column>
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.longitude" defaultMessage="Longitude"/></Text>
                                </Text>
                                <Input
                                    data-testid="inputLongitude"
                                    value={currentStation.address && currentStation.address.geocode && currentStation.address.geocode.longitude ? currentStation.address.geocode.longitude : ""}
                                    type="number"
                                    size="small"
                                    width={"150px"}
                                    disabled={!updateAllowed}
                                    onChange={(value) => dispatch({type: UPDATE_LONGITUDE, payload:value})}
                                />
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="center">
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.activeCapabilities" defaultMessage="Active Capabilities"/></Text>
                                </Text>
                                <Text>
                                    {state.items.stationActiveCapabilities}
                                </Text>
                            </Column>
                        </Row>

                        <Row
                            widths={["grid-6", "grid-6"]}
                            wrap="down"
                            height={"100px"}
                            spacingInset="medium"
                            alignment="center">
                            <Column>
                                <Text>
                                    <Text type="h100"><FormattedMessage id="station_view.addCapability" defaultMessage="Add Capability"/></Text>
                                </Text>
                                <Select
                                    data-testid="modifyCapability"
                                    size="small"
                                    popoverPosition="adjacent"
                                    width={"150px"}
                                    value={capability}
                                    disabled={!updateAllowed}
                                    onChange={(value) => setCapability(value)}
                                >
                                    {Object.values(CAPABILITY).filter(filterActiveCapability).map(value => (
                                        <SelectOption key={value} value={value} label={value} />
                                    ))}
                                </Select>
                            </Column>
                        </Row>

                    </Box>
                    <Box
                        type="outline"
                        spacingInset="small"
                        height={600}
                    >
                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center left"
                            spacingInset="medium"
                        >
                            <Button data-testid="saveButton" disabled = {!updateAllowed} type="tertiary" onClick={() => dispatch(setStationUpdateModalState(true))}>
                                <FormattedMessage id="generic.save" defaultMessage="Save" />
                            </Button>
                        </Row>

                        <Row
                            widths={"fill"}
                            wrap="down"
                            alignment="center left"
                            spacingInset="medium"
                        >
                            <Button type="tertiary" onClick={() => (history.push("/station"))}>
                                <FormattedMessage id="generic.back" defaultMessage="Back">
                                </FormattedMessage>
                            </Button>
                        </Row>
                    </Box>
                </Row>
            </div>

        );
    }

};
export default StationView;