import {
    getAPIGatewayDataFromParent,
    getSmartStationRelayAPIGatewayRequest
} from "../handler/ParentDataHandler";

export const BIN_TEST_ENDPOINT = '/rbl/bintest';
export const NARC_PULLER_REFRESH_ENDPOINT = '/narcpuller/refresh';

export async function publishBinTestCommand(siteId, binId, color, spotlightIds, duration) {
    const request = {
        siteId: siteId,
        binId: binId,
        color: color,
        spotlightIds: spotlightIds,
        duration: duration * 1000,
        command: "target"
    };
    let args = getSmartStationRelayAPIGatewayRequest(BIN_TEST_ENDPOINT, request);
    return await getAPIGatewayDataFromParent(args);
}

export async function triggerConfigRefresh(siteId) {
    const request = {
        siteId: siteId
    }
    let args = getSmartStationRelayAPIGatewayRequest(NARC_PULLER_REFRESH_ENDPOINT, request);
    return await getAPIGatewayDataFromParent(args);
}
