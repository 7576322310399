import {metricType} from "./enums";
import {publishClientSideMetrics} from "./handler/ParentDataHandler";
import {getNodeId} from "./Utility";

export const publishCount = (processName, metricName) => {
    const request = getMetricPublishRequest(processName, metricName, 1, metricType.COUNT);
    publishClientSideMetrics(request);
    publishNodeLevelMetrics(request);
};

export const publishTime = (processName, metricName, startTime) => {
    const metricValue = Date.now() - startTime;
    const request = getMetricPublishRequest(processName, metricName, metricValue, metricType.TIME);
    publishClientSideMetrics(request);
    publishNodeLevelMetrics(request);
};

const getMetricPublishRequest = (processName, metricName, metricValue, metricType) => {
    return {
        processName: processName,
        metricName: metricName,
        metricValue: metricValue,
        metricType: metricType
    }
};

const publishNodeLevelMetrics = (request) => {
    const nodeId = getNodeId();
    const nodeRequest = {
        ...request,
        processName: request.processName + '_' + nodeId
    };
    publishClientSideMetrics(nodeRequest);
};
