import React, {useContext} from 'react';
import Text from "@meridian/components/text";
import Modal, {ModalFooter} from "@meridian/components/modal";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import {deprecateResourceAssociation, deprecateResource} from "../../../resources/NodeResourceManagerResource";
import {FormattedMessage} from "react-intl";

import {
    BEGIN_CALL,
    DEPRECATE_RESOURCE_FAILURE,
    DEPRECATE_RESOURCE_SUCCESS,
    TOGGLE_DEPRECATE_RESOURCE_MODAL
} from "../../../reducers/NodeManagerReducer";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import { CAPABILITY_CONFIG, DEPRECATE_RESOURCE_ASSOCIATION_TYPES, DEPRECATION_OPERATION_PARAMETERS, RESOURCE_TYPES } from '../../../Constants';
import { getDeprecationAlertMessage } from '../../../helper/NodeResourceManagerHelper';

export default function DeprecateResourceModal() {
    const {state, dispatch} = useContext(NodeResourceContext);
    let {deprecateResourceModalState, deprecationResource, currentNodeId, resourceSearchCriteria, narc_sls_intergration_enabled, childResource, isSSDMMOTEnabled} = state;

    const deprecateResourceData = (deprecationResource, currentNodeId, resourceSearchCriteria) => {
        dispatch( {type: BEGIN_CALL});
        if(narc_sls_intergration_enabled || isSSDMMOTEnabled){
            if(DEPRECATE_RESOURCE_ASSOCIATION_TYPES.includes(resourceSearchCriteria.resourceType)){
                deprecateResourceAssociation(deprecationResource, currentNodeId, resourceSearchCriteria, state.childResource).then(result => {
                    if(result.success) {
                        dispatch( {type: DEPRECATE_RESOURCE_SUCCESS});
                    } else {
                        dispatch( {type: DEPRECATE_RESOURCE_FAILURE, payload: {updateResourceError: result.updateResourceError,
                        }});
                    }
                });
                return;
            }
        }
        deprecationResource.nodeId = currentNodeId;
        let forceStatusFlag = true;
	     if(CAPABILITY_CONFIG.Resources.RESOURCE_TYPES.includes(deprecationResource.resourceType) 
                || deprecationResource.resourceType === RESOURCE_TYPES.BIN){
	         deprecationResource.resourceAttributes = {};
	         forceStatusFlag = false;
	     }
        deprecateResource([deprecationResource], DEPRECATION_OPERATION_PARAMETERS.DEPRECATION_FLAG.TRUE, forceStatusFlag).then(result => {
            if(result.success) {
                dispatch({type: DEPRECATE_RESOURCE_SUCCESS});
            } else {
                dispatch({type: DEPRECATE_RESOURCE_FAILURE, payload: {updateResourceError: result.updateResourceError
                    }});
            }
        });
    }

    const toggleDeprecateResourceModal = (resource, status)  => {
        dispatch( {type: TOGGLE_DEPRECATE_RESOURCE_MODAL, payload:{
                deprecateResourceModalState: status,
                deprecationResource: resource}});
    }

    return (
        <Modal title="Resource Deprecation" open={deprecateResourceModalState} scrollContainer="viewport" describedById="modal-description">
            <Text id="modal-description">
                {
                    deprecationResource && 
                    (
                        getDeprecationAlertMessage(deprecationResource, resourceSearchCriteria, childResource)
                    )
                    
                }</Text>
            <ModalFooter>
                <Row alignment="center right" widths="fit">
                    <Button type="secondary" size="small"
                            onClick={() => toggleDeprecateResourceModal(null, false)}>
                        <FormattedMessage id="node_resources.cancel" defaultMessage="Cancel"/>
                    </Button>
                    <Button type="primary" size="small"
                            onClick={() => deprecateResourceData(deprecationResource, currentNodeId, resourceSearchCriteria)}>
                        <FormattedMessage id="node_resources.confirm" defaultMessage="Confirm"/>
                    </Button>
                </Row>
            </ModalFooter>
        </Modal>
    )
}
