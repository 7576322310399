import React from 'react';
import Column from "@amzn/meridian/column";
import { colorGray200 } from "@amzn/meridian-tokens/base/color";

export const chainWalk = (func, defaultValue) => {
    try {
        const value = func()
        return (value === null || value === undefined) ? defaultValue : value;
    } catch (e) {
        return defaultValue
    }
}

export const addToObject = (data, key, value) => {
    return {...(data || {}), [key]: value};
};

export const disableSection = (styles = {}) => {
    return {...styles, pointerEvents: "none", opacity: "0.5"};
}

// Render components
export const renderVerticalDivider = (key) => {
    return (
        <Column key={key} heights={["fit", "fill"]} backgroundColor={colorGray200} width={"1px"} />
    );
};