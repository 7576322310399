import intlMessageEN from '../i18n/locales/en.json';
import intlMessageES from '../i18n/locales/es.json';
import intlMessageFR from '../i18n/locales/fr.json';
import intlMessageIT from '../i18n/locales/it.json';
import intlMessageJP from '../i18n/locales/ja.json';
import intlMessageDE from '../i18n/locales/de.json';
import {STRINGS} from "../Constants";
import {getLocaleFromParent} from "./ParentDataHandler";

export async function getLocale() {
    const locale = await fetchLocale();
    switch (locale) {
        case "de":
        case "de-DE":
        case "de-CH":
        case "de-AT":
        case "de-LU":
        case "de-LI":
            return "de";
        case "es":
        case "es-ES":
        case "es-AR":
        case "es-GT":
        case "es-CR":
        case "es-PA":
        case "es-DO":
        case "es-MX":
        case "es-VE":
        case "es-CO":
        case "es-PE":
        case "es-EC":
        case "es-CL":
        case "es-UY":
        case "es-PY":
        case "es-BO":
        case "es-SV":
        case "es-HN":
        case "es-NI":
        case "es-PR":
            return "es";
        case "ja":
        case "ja-JP":
            return "ja";
        case "it":
        case "it-IT":
        case "it-CH":
            return "it";
        case "fr":
        case "fr-FR":
        case "fr-BE":
        case "fr-CA":
        case "fr-CH":
        case "fr-LU":
            return "fr";
        case "ar":
        case "ar-SA":
        case "ar-IQ":
        case "ar-EG":
        case "ar-LY":
        case "ar-DZ":
        case "ar-MA":
        case "ar-TN":
        case "ar-OM":
        case "ar-YE":
        case "ar-SY":
        case "ar-JO":
        case "ar-LB":
        case "ar-KW":
        case "ar-AE":
        case "ar-BH":
        case "ar-QA":
            return "ar";
        default:
            return "en"
    }
}

async function fetchLocale() {
    const localeResponse = await getLocaleFromParent();
    if (STRINGS.TIMEOUT === localeResponse) {
        /*
         * Fallback when time out
         */
        return navigator.language;
    }
    const locale = JSON.parse(localeResponse.locale);
    if (STRINGS.EMPTY === locale) {
        /*
         * Fallback when backend return empty locale, happens for MLS disabled regions https://weblab.amazon.com/wl/SCC_MLS_349230
         */
        return navigator.language;
    }
    return locale;
}

export function getDirection(locale) {
    return locale === "ar" ? "rtl" : "ltr";
}

export function getMessages(locale) {
    switch (locale) {
        case "en":
            return intlMessageEN;
        case "es":
            return intlMessageES;
        case "fr":
            return intlMessageFR;
        case "it":
            return intlMessageIT;
        case "ja":
            return intlMessageJP;
        case "de":
            return intlMessageDE;
        default:
            return intlMessageEN
    }
}