export const PROCESS_NODE_RESOURCE = "nodeResource";
export const SMART_STATION_PROCESS_NAME = "smartStationRelay";
export const SORT_PATH_GENERATOR_PROCESS_NAME = "sortPathGenerator";
export const ENDPOINT_GET_RESOURCES = "getResources";
export const ENDPOINT_GET_RESOURCES_V2 = "getResourcesV2";
export const ENDPOINT_GET_RESOURCES_RELATIONSHIPS = "getResourceRelationships";
export const ENDPOINT_UPDATE_RESOURCE_V2 = "updateResourceV2";
export const ENDPOINT_DEPRECATE_RESOURCES = "setDeprecationStatusV3";
export const ENDPOINT_DEPRECATE_RESOURCES_ASSOCIATIONS = "deprecateResourceRelationships";
export const ENDPOINT_BULK_CREATE_RESOURCES = "addBulkResources";
export const ENDPOINT_GET_IMMEDIATE_CHILD_RESOURCES = "getImmediateChildForResources";
export const ENDPOINT_UPDATE_RESOURCE = "updateResourceV2";
export const ENDPOINT_GET_BIN_TYPES = "getBinTypes";
export const ENDPOINT_UPDATE_BIN_TYPE = "updateBinType";
export const ENDPOINT_ADD_BIN_TYPE = "addBinType";
export const ENDPOINT_GET_SORT_PATHS = "getSortPaths";
export const ENDPOINT_ADD_SORT_PATH = "addSortPath";
export const ENDPOINT_DEPRECATE_SORT_PATH = "deprecateSortPath";
export const ENDPOINT_GET_SORT_PATH_RESOURCES = "getSortPathResources";
export const ENDPOINT_ADD_ASSET = "addAsset";
export const ENDPOINT_UPDATE_ASSET = "updateAsset";
export const ENDPOINT_SET_ASSET_STATUS = "setAssetStatus";
export const ENDPOINT_GET_ASSETS_BY_MAPPED_RESOURCE = "getAssetsByMappedResource";
export const ENDPOINT_GET_ASSET_BY_ASSET_LABEL = "getAssetByAssetLabel";
export const ENDPOINT_GET_CART_RECIPE = "getCartRecipe";
export const ENDPOINT_BULK_CREATE_RESOURCES_RELATIONSHIPS = "addResourceRelationships";
export const ENDPOINT_GET_TCP_ROLES = "getTcpRoles";
export const GET_ROLES_USING_ACCOUNT_ID = "getTcpRolesUsingAccountId";
export const GET_USER_ROLES_USING_ACCOUNT_ID = "getUserTcpRolesUsingAccountId";
export const ADMIN = "Admin";
export const UPDATE_STATION_ROLE = "AmazonFacility::SCC: UpdateStation";
export const ENDPOINT_GET_STATION_LIST = "getSDCConfig";
export const ENDPOINT_GENERATE_VALIDATION_REPORT = "generateValidationReport";
export const ACTIVE = "ACTIVE";

export const ALL_STATIONS = "ALL_STATIONS"
export const UTR_TECH_TEAM_ACCOUNT_EU = "A2Y51WRDCGFK84";
export const UTR_TECH_TEAM_ACCOUNT_NA = "ASL63PT8UOXWD";
export const UTR_TECH_TEAM_ACCOUNT_FE = "A2QY19F5BNKMBP";

export const PROCESS_NODE_USER = "nodeUser";
export const ENDPOINT_GET_STATION_BY_CRITERIA = "getStationsByCriteria";
export const ENDPOINT_GET_STATIONS_BY_REGION = "getStationsByRegion";
export const ENDPOINT_GET_STATION_BY_CODE = "getStationByStationCode";
export const ENDPOINT_SET_STATION_ACTIVE = "setStationActive";
export const ENDPOINT_GET_ACTIVE_NODE_CAPABILITY = "getActiveNodeCapability";
export const ENDPOINT_NODE_CAPABILITY = "setNodeCapability";
export const ENDPOINT_GET_EMPLOYEES_BY_CRITERIA = "getEmployeesByCriteria";
export const ENDPOINT_GET_EMPLOYEE_BY_ID = "getEmployeeByEmployeeId";
export const ENDPOINT_SET_EMPLOYEE = "setEmployee";
export const ENDPOINT_GET_TOOLS_LISTS = "getToolsList";
export const ENDPOINT_REGISTER_EMPLOYEE = "registerEmployee";
export const ENDPOINT_SEARCH_EMPLOYEES_IN_TCP = "searchEmployee";

export const AMAZON_SUFFIX = "@amazon.com";
export const SORT_PATH = "SORT_PATH";
export const REVERSE_SORT_PATH = "REVERSE_SORT_PATH";
export const STATIC_GRID_ALLOCATION_ENABLED = "staticGridAllocationEnabled";
export const SORT_GRID_OVERLAP_DETAILS_ENABLED = "sortGridOverlapDetailsEnabled";
export const MULTIPLE_CYCLE_PER_PARTITION_ENABLED = "multipleCyclePerPartitionEnabled";
export const IS_DELETE_PARTITION_ENABLED = "isDeletePartitionEnabled"
export const ERROR_METRIC_SUFFFIX = "Error";
export const OV_RESOURCE_SUFFIX = ".OV";
export const GET_STATION_BY_STATION_CODE_CALL_BEGIN = "GET_STATION_BY_STATION_CODE_CALL_BEGIN";
export const GET_STATION_BY_STATION_CODE_SUCCESS = "GET_STATION_BY_STATION_CODE_SUCCESS";
export const GET_STATION_BY_STATION_CODE_CALL_FAILURE = "GET_STATION_BY_STATION_CODE_CALL_FAILURE";
export const UPDATE_STATION_INFO_SUCCESS = "UPDATE_STATION_INFO_SUCCESS"
export const UPDATE_STATION_INFO_FAILURE = "UPDATE_STATION_INFO_FAILURE";
export const UPDATE_PARENT_STATION = "UPDATE_PARENT_STATION"
export const EMPLOYEE_VERIFICATION_SUCCESS = "EMPLOYEE_VERIFICATION_SUCCESS"
export const EMPLOYEE_VERIFICATION_FAILURE = "EMPLOYEE_VERIFICATION_FAILURE"
export const SET_STATION_UPDATE_MODAL_STATE = "SET_STATION_UPDATE_MODAL_STATE"
export const UPDATE_LONGITUDE = "UPDATE_LONGITUDE"
export const UPDATE_LATITUDE = "UPDATE_LATITUDE"
export const UPDATE_POSTAL_CODE = "UPDATE_POSTAL_CODE"
export const UPDATE_ADDRESS_LINE1 = "UPDATE_ADDRESS_LINE1"
export const UPDATE_ADDRESS_LINE2 = "UPDATE_ADDRESS_LINE2"
export const UPDATE_ADDRESS_LINE3 = "UPDATE_ADDRESS_LINE3"
export const UPDATE_STATE = "UPDATE_STATE"
export const UPDATE_CITY = "UPDATE_CITY"
export const TEST_CITY = "TEST_CITY"
export const TEST_STATE = "TEST_STATE"
export const TEST_POSTAL_CODE = "TEST_POSTAL_CODE"
export const TEST_ADDRESS_LINE_1 = "TEST_ADDRESS_LINE_1"
export const TEST_ADDRESS_LINE_2 = "TEST_ADDRESS_LINE_2"
export const TEST_ADDRESS_LINE_3 = "TEST_ADDRESS_LINE_3"
export const TEST_LATITUDE = "11"
export const TEST_LONGITUDE = "-22"
export const TEST_CAPABILITY = "TEST_CAPABILITY"

export const NARC_TAB = "NARC"
export const SPG_TAB = "SPG"
export const CAPABILITY = {
    AR: "AR",
    SSD: "SSD",
    AMZL_CORE: "AMZL_CORE",
    GSF: "GSF",
    GSF_FREE_BATCHING: "GSF_FREE_BATCHING",
    DOOR_DELIVERY: "DOOR_DELIVERY",
    HANDOVER: "HANDOVER",
    OVERNIGHT_STORAGE: "OVERNIGHT_STORAGE",
    CUSTOMER_PICKUP: "CUSTOMER_PICKUP",
    AR_NEXT_GEN: "AR_NEXT_GEN",
    HANDOVER_TO_ROBOT: "HANDOVER_TO_ROBOT"
}
export const REGION = {
    NA: "NA",
    EU: "EU",
    FE: "FE",
    IN: "IN"
}
export const STAGE = {
    BETA: "BETA",
    GAMMA: "GAMMA",
    PROD: "PROD"
}
export const VINYAAS = {
    NA: {
        BETA: "https://vinyaas-ui-beta.integ.amazon.com/mn/sortcenter/vinyaas",
        GAMMA: "https://vinyaas-ui-gamma.amazon.com/mn/sortcenter/vinyaas",
        PROD: "https://vinyaas-ui-na.amazon.com/mn/sortcenter/vinyaas"
    },
    EU: {
        BETA: "https://vinyaas-ui-gamma-eu.amazon.com/mn/sortcenter/vinyaas",
        GAMMA: "https://vinyaas-ui-gamma-eu.amazon.com/mn/sortcenter/vinyaas",
        PROD: "https://vinyaas-ui-eu.amazon.com/mn/sortcenter/vinyaas"
    },
    FE: {
        BETA: "http://vinyaas-ui-gamma-nrt.nrt.proxy.amazon.com/mn/sortcenter/vinyaas",
        GAMMA: "http://vinyaas-ui-gamma-nrt.nrt.proxy.amazon.com/mn/sortcenter/vinyaas",
        PROD: "https://vinyaas-ui-jp-nrt.nrt.proxy.amazon.com/mn/sortcenter/vinyaas"
    },
    IN: {
        BETA: "https://vinyaas-ui-gamma-eu.amazon.com/mn/sortcenter/vinyaas",
        GAMMA: "https://vinyaas-ui-gamma-eu.amazon.com/mn/sortcenter/vinyaas",
        PROD: "https://vinyaas-ui-eu.amazon.com/mn/sortcenter/vinyaas"
    }
}

export const STEM = {
    NA: {
        BETA: "https://stem-na-preprod.corp.amazon.com/node/stationCode/sortationRules",
        GAMMA: "https://stem-na-preprod.corp.amazon.com/node/stationCode/sortationRules",
        PROD: "https://stem-na.corp.amazon.com/node/stationCode/sortationRules"
    },
    EU: {
        BETA: "https://stem-eu-preprod.corp.amazon.com/node/stationCode/sortationRules",
        GAMMA: "https://stem-eu-preprod.corp.amazon.com/node/stationCode/sortationRules",
        PROD: "https://stem-eu.corp.amazon.com/node/stationCode/sortationRules"
    },
    FE: {
        BETA: "https://stem-fe-preprod.corp.amazon.com/node/stationCode/sortationRules",
        GAMMA: "https://stem-fe-preprod.corp.amazon.com/node/stationCode/sortationRules",
        PROD: "https://stem-fe.corp.amazon.com/node/stationCode/sortationRules"
    },
    IN: {
        BETA: "https://stem-eu-preprod.corp.amazon.com/node/stationCode/sortationRules",
        GAMMA: "https://stem-eu-preprod.corp.amazon.com/node/stationCode/sortationRules",
        PROD: "https://stem-eu.corp.amazon.com/node/stationCode/sortationRules"
    }
}

export const NodeResources_excel = "NodeResources";
export const NodeResourcesOVDFeatureEnabled_excel = "NodeResourcesOVDFeatureEnabled";
export const NodeResourcesAssociation_excel = "NodeResourcesAssociation";
export const SSD_NodeResourcesAssociation_excel = "SSD_NodeResourcesAssociation";
export const CUSTOM_FILED_IN_SETTING = "CUSTOM";

export const FILE_PATH = {
    SSD: "/resources/CREATE_RESOURCE_FILE_SSD.xlsx",
    NodeResources: "/resources/SLS_NodeResources.xlsx",
    NodeResourcesOVDFeatureEnabled: "/resources/NodeResources.xlsx",
    NodeResourcesAssociation: "/resources/SLS_NodeResourcesAssociation.xlsx",
    SSD_NodeResourcesAssociation: "/resources/SSD_NodeResourcesAssociation.xlsx",
    DEFAULT: "/resources/CREATE_RESOURCE_FILE_v2.0.xlsx",
}

export const STRINGS = {
    EMPTY: "",
    TIMEOUT:  "timeout",
    REFRESH_LATER : "The sort path creation is taking some time. This happens with large files. Please refresh page after 5-10 mins to search for the new sort path",
    RETRY_LATER: "Something went wrong! Please retry after some time",
}

export const RESOURCE_TYPES = {
    AR_FLOOR: "AR_FLOOR",
    AR_STATION: "AR_STATION",
    AR_GROUP: "AR_GROUP",
    CART_LOCATION: "CART_LOCATION",
    INDUCT_AREA: "INDUCT_AREA",
    STAGING_AREA: "STAGING_AREA",
    CART: "CART",
    GENERAL_AREA: "GENERAL_AREA",
    PROBLEM_SOLVE_AREA: "PROBLEM_SOLVE_AREA",
    CHUTE: "CHUTE",
    ZONE: "ZONE",
    AISLE: "AISLE",
    BIN: "BIN",
    DOCK_DOOR: "DOCK_DOOR",
    PRE_STAGING_AREA: "PRE_STAGING_AREA",
    FLOOR_LOCATION: "FLOOR_LOCATION",
    LAUNCHPAD: "LAUNCHPAD",
    STAGING_ZONE: "STAGING_ZONE",
    CLUSTER: "CLUSTER",
    FINGER: "FINGER",
    PICK_STATION: "PICK_STATION",
    OV_BIN: "OV_BIN",
    VIRTUAL_LOCATION: "VIRTUAL_LOCATION",
    VL: "VL",
    OV_VL: "OV_VL",
    STAGING_ZONE_STAGING_AREA_ASSOCIATION: "STAGING_ZONE_STAGING_AREA_ASSOCIATION",
    CLUSTER_AISLE_ASSOCIATION: "CLUSTER_AISLE_ASSOCIATION",
    AISLE_STAGING_ZONE_DISTANCE: "AISLE_STAGING_ZONE_DISTANCE",
    LAUNCHPAD_STAGING_ZONE_DISTANCE: "LAUNCHPAD_STAGING_ZONE_DISTANCE",
    LAUNCHPAD_STAGING_ZONE_ASSOCIATION: "LAUNCHPAD_STAGING_ZONE_ASSOCIATION",
    AISLE_BIN_ASSOCIATION: "AISLE_BIN_ASSOCIATION",
    TESSERACT_BIN_ASSOCIATION: "TESSERACT_BIN_ASSOCIATION",
    PICK_STATION_CLUSTER_MAP: "PICK_STATION_CLUSTER_MAP",
    PICK_STATION_STAGING_AREA_MAP: "PICK_STATION_STAGING_AREA_MAP",
    PICK_STN_STG_ZONE_DISTANCE: "PICK_STN_STG_ZONE_DISTANCE",
    DOCK_DOOR_STAGING_AREA_MAP: "DOCK_DOOR_STAGING_AREA_MAP"
}

export const RESOURCE_ASSOCIATION_TO_CHILD_RESOURCE_TYPE_MAP = {
    STAGING_ZONE_STAGING_AREA_ASSOCIATION: [RESOURCE_TYPES.STAGING_AREA],
    CLUSTER_AISLE_ASSOCIATION: [RESOURCE_TYPES.AISLE],
    LAUNCHPAD_STAGING_ZONE_ASSOCIATION: [RESOURCE_TYPES.STAGING_ZONE],
    AISLE_BIN_ASSOCIATION: [RESOURCE_TYPES.BIN],
    TESSERACT_BIN_ASSOCIATION: [RESOURCE_TYPES.BIN],
    PICK_STATION_CLUSTER_MAP: [RESOURCE_TYPES.CLUSTER],
    PICK_STATION_STAGING_AREA_MAP: [RESOURCE_TYPES.STAGING_AREA],
    DOCK_DOOR_STAGING_AREA_MAP : [RESOURCE_TYPES.STAGING_AREA]
}

export const CART_TYPE = {
    CART_TYPE1: "CART_TYPE1",
    CART_TYPE2: "CART_TYPE2",
    CART_TYPE3: "CART_TYPE3",
    CART_TYPE4: "CART_TYPE4",
    SIX_BIN_CART: "CART_TYPE_SIX_BIN_ZERO_OV_BIN"
}

export const SSD = "SSD";
export const AR_NEXT_GEN = "AR_NEXT_GEN";
export const AMZL_CORE = "AMZL_CORE";
export const DEFAULT = "DEFAULT";

export const CART_TYPE_1_COUNT = 6;
export const CART_TYPE_3_COUNT = 4;
export const CART_TYPE_4_COUNT = 4;
export const SIX_BIN_CART_BINS_COUNT = 6;
export const SIX_BIN_CART_SUFFIX_LABEL = 'X';
export const SIX_BIN_CART_TIERS = 3;


export const OVLABEL = "OV";

export const ELIGIBLE_PARENTS = ["AR Floor", "AR Station", "AR Group", "zone"];

export const ALL_ATTRIBUTES = {
    Capacity_Of_Vehicles: "Capacity_Of_Vehicles",
    Cart_Capacity_of_Staging_Areas: "Cart_Capacity_of_Staging_Areas",
    Column_Order_of_Aisles: "Column_Order_of_Aisles",
    Cluster_Pairs: "Cluster_Pairs",
    DISTANCE: "Distance",
    DivertersCount: "No of Diverters",
    Type: "Type",
    Capacity: "Capacity",
    binCount: "binCount",
    Label: "label",
    Status: "Status",
    Launchpad_Label: "Launchpad_Label",
    Staging_Zone_Label: "Staging_Zone_Label",
    Staging_Area_Label: "Staging_Area_Label",
    Cluster_Label: "Cluster_Label",
    Aisle_Label: "Aisle_Label",
    Bin_Label: "Bin_Label",
    Pick_Station_Label: "Pick_Station_Label",
    Dock_Door_Label: 'Dock_Door_Label',
    Aisle_Label_Range: "Aisle_Label_Range",
    scannableId: "scannableId",
    reviewStatus: "reviewStatus",
    binResourceCategory: "binResourceCategory",
    binTier: "binTier",
    binType: "binType",
    binResourceSubCategory: "binResourceSubCategory",
    AnchorPoint: "AnchorPoint",
    DockDoor: "DockDoor"
}

export const VISIBLE_ATTRIBUTES = {
    CHUTE: ["PrintableLabel", "Preference", "Group", "Type", "Status", "Tags"],
    AR_STATION: ["PrintableLabel"],
    AR_GROUP: ["PrintableLabel"],
    LAUNCHPAD: [ALL_ATTRIBUTES.Capacity_Of_Vehicles],
    STAGING_ZONE: [ALL_ATTRIBUTES.Cart_Capacity_of_Staging_Areas],
    STAGING_AREA: [ALL_ATTRIBUTES.Capacity, ALL_ATTRIBUTES.Type],
    CLUSTER: [ALL_ATTRIBUTES.Column_Order_of_Aisles, ALL_ATTRIBUTES.DivertersCount, ALL_ATTRIBUTES.Type],
    FINGER: [ALL_ATTRIBUTES.Cluster_Pairs],
    BIN_OVD_FIX_ENABLED: [ALL_ATTRIBUTES.binResourceCategory, ALL_ATTRIBUTES.binResourceSubCategory, ALL_ATTRIBUTES.binTier, ALL_ATTRIBUTES.binType, ALL_ATTRIBUTES.reviewStatus, ALL_ATTRIBUTES.binCount],
    AISLE_STAGING_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE],
    LAUNCHPAD_STAGING_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE],
    PICK_STN_STG_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE],
    DOCK_DOOR: [ALL_ATTRIBUTES.AnchorPoint]
};

export const DISABLED_ATTRIBUTES = {
    LAUNCHPAD: ['label', 'scannableId'],
    STAGING_ZONE: ['label', 'scannableId'],
    CLUSTER: ['label', 'scannableId'],
    FINGER: ['label', 'scannableId'],
    DOCK_DOOR: ['label', 'scannableId'],
    LAUNCHPAD_STAGING_ZONE_DISTANCE: ['Launchpad_Label', 'Staging_Zone_Label'],
    DOCK_DOOR_STAGING_AREA_MAP: ['Dock_Door_Label', 'Staging_Area_Label'],
    PICK_STN_STG_ZONE_DISTANCE: ['Pick_Station_Label', 'Staging_Zone_Label'],
    AISLE_STAGING_ZONE_DISTANCE: ['Aisle_Label', 'Staging_Zone_Label']
};

export const DEPRECATE_ASSOCIATION_MODAL_ATTRIBUTES = {
    AISLE_STAGING_ZONE_DISTANCE: ['Aisle_Label', 'Staging_Zone_Label'],
    LAUNCHPAD_STAGING_ZONE_DISTANCE: ['Launchpad_Label', 'Staging_Zone_Label'],
    PICK_STN_STG_ZONE_DISTANCE: ['Pick_Station_Label', 'Staging_Zone_Label'],
    CLUSTER_AISLE_ASSOCIATION: ['Cluster_Label', 'Aisle_Label'],
    TESSERACT_BIN_ASSOCIATION: ['Cluster_Label', 'Bin_Label'],
    PICK_STATION_CLUSTER_MAP: ['Pick_Station_Label', 'Cluster_Label'],
    PICK_STATION_STAGING_AREA_MAP: ['Pick_Station_Label', 'Staging_Area_Label'],
    STAGING_ZONE_STAGING_AREA_ASSOCIATION: ['Staging_Zone_Label', 'Staging_Area_Label'],
    LAUNCHPAD_STAGING_ZONE_ASSOCIATION: ['Launchpad_Label', 'Staging_Zone_Label'],
    AISLE_BIN_ASSOCIATION: ['Aisle_Label', 'Bin_Label']
}

export const LAUNCHPAD_STAGING_ZONE_MAP = "LAUNCHPAD_STAGING_ZONE_MAP"

export const RESOURCE_TYPE_DISPLAY_FIELDS = {
    AR_FLOOR: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"}, {fieldName:"scannableId"}, {fieldName:"PrintableLabel"}],
    },
    AR_STATION: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"PrintableLabel"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"PrintableLabel"}]
    },
    AR_GROUP:  {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"PrintableLabel"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"PrintableLabel"}]
    },
    CART_LOCATION: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    VIRTUAL_LOCATION: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    INDUCT_AREA: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    STAGING_AREA: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"Capacity"},{fieldName:"Type"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    CART: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    GENERAL_AREA: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    PROBLEM_SOLVE_AREA: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    CHUTE: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"PrintableLabel"},{fieldName:"Preference"},{fieldName:"Group"},
        {fieldName:"Type"},{fieldName:"Tags"},{fieldName:"Status"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    ZONE: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    AISLE: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    BIN: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    BIN_OVD_FIX_ENABLED: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"binResourceCategory"},{fieldName: "binResourceSubCategory"},{fieldName:"binTier"},{fieldName:"binType"},{fieldName:"reviewStatus"},{fieldName:"binCount"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"binResourceCategory"},{fieldName: "binResourceSubCategory"},{fieldName:"binTier"},{fieldName:"binType"},{fieldName:"reviewStatus"},{fieldName:"binCount"}]
    },
    PRE_STAGING_AREA: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    FLOOR_LOCATION: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    LAUNCHPAD: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"Capacity_Of_Vehicles"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    STAGING_ZONE: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"Cart_Capacity_of_Staging_Areas"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    CLUSTER: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"Column_Order_of_Aisles"},{fieldName:"No of Diverters"},{fieldName:"Type"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    FINGER: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"Cluster_Pairs"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    PICK_STATION: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    STAGING_ZONE_STAGING_AREA_ASSOCIATION: {
        resource: [{fieldName:"Staging_Zone_Label"},{fieldName:"Staging_Zone_Scannable"}],
        childResource: [{fieldName:"Staging_Area_Label"},{fieldName:"Staging_Area_Scannable"}]
    },
    LAUNCHPAD_STAGING_ZONE_ASSOCIATION: {
        resource: [{fieldName:"Launchpad_Label"},{fieldName:"Launchpad_Scannable"}],
        childResource: [{fieldName:"Staging_Zone_Label"},{fieldName:"Staging_Zone_Scannable"}]
    },
    CLUSTER_AISLE_ASSOCIATION: {
        resource: [{fieldName:"Cluster_Label"},{fieldName:"Cluster_Scannable"}],
        childResource: [{fieldName:"Aisle_Label"},{fieldName:"Aisle_Scannable"}]
    },
    AISLE_STAGING_ZONE_DISTANCE: {
        resource: [{fieldName:"Aisle_Label"},{fieldName:"Staging_Zone_Label"},{fieldName:"Distance"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    LAUNCHPAD_STAGING_ZONE_DISTANCE: {
        resource: [{fieldName:"Launchpad_Label"},{fieldName:"Staging_Zone_Label"},{fieldName:"Distance"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    PICK_STN_STG_ZONE_DISTANCE: {
        resource: [{fieldName:"Pick_Station_Label"},{fieldName:"Staging_Zone_Label"},{fieldName:"Distance"}],
        childResource: [{fieldName:"label"},{fieldName:"scannableId"}]
    },
    AISLE_BIN_ASSOCIATION: {
        resource: [{fieldName:"Aisle_Label"},{fieldName:"Aisle_Scannable"}],
        childResource: [{fieldName:"Bin_Label"},{fieldName:"Bin_Scannable"}]
    },
    DOCK_DOOR: {
        resource: [{fieldName:"label"},{fieldName:"scannableId"},{fieldName:"AnchorPoint"}],
        childResource: [{fieldName:"Staging_Area_Label"},{fieldName:"Staging_Area_Scannable"}]
    },
    TESSERACT_BIN_ASSOCIATION: {
        resource: [{fieldName:"Cluster_Label"},{fieldName:"Cluster_Scannable"}],
        childResource: [{fieldName:"Bin_Label"},{fieldName:"Bin_Scannable"}]
    },
    PICK_STATION_CLUSTER_MAP: {
        resource: [{fieldName:"Pick_Station_Label"},{fieldName:"Pick_Station_Scannable"}],
        childResource: [{fieldName:"Cluster_Label"},{fieldName:"Cluster_Scannable"}]
    },
    PICK_STATION_STAGING_AREA_MAP: {
        resource: [{fieldName:"Pick_Station_Label"},{fieldName:"Pick_Station_Scannable"}],
        childResource: [{fieldName:"Staging_Area_Label"},{fieldName:"Staging_Area_Scannable"}]
    }
};

export const Resources = "Resources";
export const ResourcesAssociation = "ResourcesAssociation";
export const Resources_Associations = "Resources_Associations";
export const AMZL_CORE_RESOURCES = "AMZL_CORE_RESOURCES";
export const AMZL_CORE_Associations = "AMZL_CORE_Associations";

export const CAPABILITY_CONFIG = {
    AR_NEXT_GEN: {
        RESOURCE_TYPES: ["AR_FLOOR", "AR_STATION", "AR_GROUP", "CART_LOCATION", "INDUCT_AREA", "STAGING_AREA", "CART", "GENERAL_AREA", "PROBLEM_SOLVE_AREA", "FLOOR_LOCATION"]
    },
    SSD: {
        RESOURCE_TYPES: ["ZONE", "CART_LOCATION", "INDUCT_AREA", "PROBLEM_SOLVE_AREA", "STAGING_AREA", "CART", "GENERAL_AREA", "DOCK_DOOR"]
    },
    AR: {
        RESOURCE_TYPES: ["AR_FLOOR", "CHUTE", "CART_LOCATION", "INDUCT_AREA", "PROBLEM_SOLVE_AREA", "STAGING_AREA", "CART"]
    },
    AMZL_CORE: {
        RESOURCE_TYPES: ["AISLE", "CART", "BIN", "PRE_STAGING_AREA", "FLOOR_LOCATION", "STAGING_AREA", "PROBLEM_SOLVE_AREA", "INDUCT_AREA"]
    },
    AMZL_CORE_RESOURCES: {
        RESOURCE_TYPES: ["AISLE", "CART", "BIN", "PRE_STAGING_AREA", "FLOOR_LOCATION", "STAGING_AREA", "PROBLEM_SOLVE_AREA", "INDUCT_AREA", "LAUNCHPAD", "STAGING_ZONE", "CLUSTER", "FINGER", "PICK_STATION"]
    },
    AMZL_CORE_Associations: {

        RESOURCE_TYPES: ["LAUNCHPAD_STAGING_ZONE_DISTANCE", "STAGING_ZONE_STAGING_AREA_ASSOCIATION", "LAUNCHPAD_STAGING_ZONE_ASSOCIATION", "CLUSTER_AISLE_ASSOCIATION", "AISLE_STAGING_ZONE_DISTANCE",
    "AISLE_BIN_ASSOCIATION", "TESSERACT_BIN_ASSOCIATION", "PICK_STATION_CLUSTER_MAP", "PICK_STATION_STAGING_AREA_MAP", "PICK_STN_STG_ZONE_DISTANCE"]
    },
    SSD_Associations: {
        RESOURCE_TYPES: ["DOCK_DOOR_STAGING_AREA_MAP"]
    },
    Resources: {
        RESOURCE_TYPES: ["LAUNCHPAD", "STAGING_ZONE", "CLUSTER", "FINGER"]
    },
    Resources_Associations: {
        RESOURCE_TYPES: ["LAUNCHPAD_STAGING_ZONE_DISTANCE", "STAGING_ZONE_STAGING_AREA_ASSOCIATION", "LAUNCHPAD_STAGING_ZONE_ASSOCIATION", "CLUSTER_AISLE_ASSOCIATION", "AISLE_STAGING_ZONE_DISTANCE",
        "AISLE_BIN_ASSOCIATION", "TESSERACT_BIN_ASSOCIATION", "PICK_STATION_CLUSTER_MAP", "PICK_STATION_STAGING_AREA_MAP", "PICK_STN_STG_ZONE_DISTANCE"]
    },
    GSF: {
        RESOURCE_TYPES: ["STAGING_AREA", "CART"]
    },
    DEFAULT: {
        RESOURCE_TYPES: ["AISLE", "CART", "BIN", "PRE_STAGING_AREA", "FLOOR_LOCATION", "STAGING_AREA", "PROBLEM_SOLVE_AREA", "INDUCT_AREA"],
    }
}

export const XLSX_FILE_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const SSP_LANE_FILE_DEFAULT_VALUES = {
    Action: "CREATE STACKING FILTER TO LANE MAPPING",
    Node: "",
    Lane: "",
    "Stacking Filter": "",
    "New Lane": ""
}

export const SSP_CONFIG_FILE_DEFAULT_VALUES = {
    Action: "",
    Node: "",
    "Stacking Filter": "",
    "New Stacking Filter": "",
    "Parent Stacking Filter": "",
    "Remote Node ID": "",
    "Remote Node Sort Code": "",
    "Sort Code": "",
    "Zip Code": "",
    "Zip11": "",
    "Country Code": "",
    "Secondary Sort": "",
    "Delivery Start Time": "",
    "Delivery End Time": "",
    "Min Length": "",
    "Max Length": "",
    "Min Height": "",
    "Max Height": "",
    "Min Width": "",
    "Max Width": "",
    "Min Weight": "",
    "Max Weight": "",
    "Time Zone": "",
    UAID: "",
    "Ship Option": "",
    "Transportation Mode": "",
    "Container Type": "",
    "Shipment status": ""
}

export const RA_CONFIG_FILE_DEFAULT_VALUES = {
    "StackingFilter": "",
    Resources: ""
}

export const AISLE_TO_STAGING_ZONE_DISTANCE_MAPPING_FILE_DEFAULT_VALUES = {
    "Aisle_Label_Range": "",
    "Staging_Zone_Label": "",
    "Distance": ""
}

export const RESOURCES_FILE_DEFAULT_VALUES = {
    "label": "",
    "scannableId": "",
    "binResourceCategory": "",
    "binResourceSubCategory": "",
    "binTier" : "",
    "binCount" : "",
    "binType" : "",
    "reviewStatus" : "",
    "Status" : ""
}

export const ASSET_TYPES = {
    SPOTLIGHT: "SPOTLIGHT"
}

export const POSITION_TYPES = {
    CENTER: "CENTER",
    BOTTOM_LEFT: "BOTTOM_LEFT",
    BOTTOM_RIGHT: "BOTTOM_RIGHT"
}

export const ASSET_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}

export const FEATURES = {
    NARC_VISIBILITY: "isNARCSLSResourcesEnabled",
    NARC_AUTHORIZATION_CHECK: "isNARCTCPAuthorizationCheckEnabled",
    IS_SSD3_ENABLED: "isSSD3Enabled",
    IS_SSD_MMOT_ENABLED: "isSSDMMOTEnabled",
    OVD_FEATURE_ENABLED: "isOVDFeatureEnabled",
    PAGINATION_FIX_ENABLED: "isPaginationFixEnabled",
    BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED: "isBulkUploadReaderUsingXLSXReaderEnabled",
    RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED: "isResourceRevivalUsingAddBulkResourcesAPIEnabled",
    NARC_SSD_AUTHORIZATION_CHECK: "isNARCSSDAuthorizationCheckEnabled"
}

export const ACTION_TYPE = {
    GET_FEATURES_STATUS: "GET_FEATURES_STATUS",
    GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK: "GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK",
    GET_FEATURES_STATUS_FOR_SSD_AUTHORIZATION: "GET_FEATURES_STATUS_FOR_SSD_AUTHORIZATION",
    IS_SSD_MMOT_ENABLED : "IS_SSD_MMOT_ENABLED"
}

export const NODE_RESOURCES_TABS = {
    RESOURCES_TAB: "RESOURCES_TAB",
    RESOURCES_ASSOCIATIONS_TAB: "RESOURCES_ASSOCIATIONS_TAB"
}
export const NODE_RESOURCES_TABS_NAMES = {
    RESOURCES: "Resources",
    RESOURCES_ASSOCIATIONS: "Resources Associations"
}

export const EXCEL_TYPES = {
    RESOURCES_EXCEL: "RESOURCES_EXCEL",
    RESOURCES_ASSOCIATIONS_EXCEL: "RESOURCE_ASSOCIATIONS_EXCEL"
}

export const DEPRECATE_HIERARCHY_RESOURCE_ASSOCIATION_TYPES = [RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION, RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION, RESOURCE_TYPES.AISLE_BIN_ASSOCIATION,
    RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION, RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP, RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP]
export const DEPRECATE_NON_HIERARCHY_RESOURCE_ASSOCIATION_TYPES = [RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE]

export const DEPRECATE_RESOURCE_ASSOCIATION_TYPES = [RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION, RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION, RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.AISLE_BIN_ASSOCIATION,
    RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION, RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP, RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP, RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE];
export const DEPRECATE_RESOURCE_TYPES = [RESOURCE_TYPES.LAUNCHPAD, RESOURCE_TYPES.STAGING_ZONE, RESOURCE_TYPES.FINGER, RESOURCE_TYPES.AISLE, RESOURCE_TYPES.BIN, RESOURCE_TYPES.CLUSTER, RESOURCE_TYPES.STAGING_AREA]

export const RESOURCE_ATTRIBUTES = {
    LAUNCHPAD:  [ALL_ATTRIBUTES.Capacity_Of_Vehicles],
    STAGING_ZONE: [ALL_ATTRIBUTES.Cart_Capacity_of_Staging_Areas],
    STAGING_AREA: [ALL_ATTRIBUTES.Capacity, ALL_ATTRIBUTES.Type],
    CLUSTER: [ALL_ATTRIBUTES.Column_Order_of_Aisles, ALL_ATTRIBUTES.DivertersCount, ALL_ATTRIBUTES.Type],
    FINGER: [ALL_ATTRIBUTES.Cluster_Pairs]
}

export const TYPE = {
    RESOURCE: "resource",
    ASSOCIATION: "association"
}

export const RESOURCE_ASSOCIATION_ATTRIBUTES = {
    AISLE_STAGING_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE],
    LAUNCHPAD_STAGING_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE],
    PICK_STN_STG_ZONE_DISTANCE: [ALL_ATTRIBUTES.DISTANCE]
}

export const RESOURCE_ASSOCIATION_DISTANCE_TYPES = [
    RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE,
    RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE,
    RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE
]
export const RESOURCE_ASSOCIATION_PARENT_CHILD_TYPES = [
    RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION,
    RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION,
    RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION,
    RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION,
    RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP,
    RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP,
    RESOURCE_TYPES.DOCK_DOOR_STAGING_AREA_MAP
]

export const VALID_SHEET_NAMES = {
    RESOURCES_EXCEL: [RESOURCE_TYPES.BIN, RESOURCE_TYPES.AISLE, RESOURCE_TYPES.LAUNCHPAD, RESOURCE_TYPES.CLUSTER, RESOURCE_TYPES.FINGER, RESOURCE_TYPES.STAGING_ZONE, RESOURCE_TYPES.PICK_STATION, RESOURCE_TYPES.STAGING_AREA],
    RESOURCE_ASSOCIATIONS_EXCEL: [RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, "STAGING_ZONE_STAGING_AREA_MAP", RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION,
        "LAUNCHPAD_STAGING_ZONE_MAP", RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION, RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP, RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP, RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE,
        RESOURCE_TYPES.DOCK_DOOR_STAGING_AREA_MAP]
}

export const FRONTEND_TO_BACKEND_ATTRIBUTES_MAPPING = {
    LAUNCHPAD : {
        "Capacity_Of_Vehicles" :  "NumStagingSpots",
        "label" : "label",
        "scannableId" : "scannableId",
        "resourceId": "resourceId"
    },
    STAGING_ZONE : {
        "Cart_Capacity_of_Staging_Areas" : "Depth",
        "label" : "label",
        "scannableId" : "scannableId",
        "resourceId": "resourceId"
    },
    STAGING_AREA : {
        "Capacity" : "Depth",
        "Type" : "Type",
        "label" : "label",
        "scannableId" : "scannableId",
        "resourceId": "resourceId"
    },
    CLUSTER : {
        "Column_Order_of_Aisles" : "ColumnOrder",
        "No of Diverters": "DivertersCount",
        "Type": "Type",
        "label" : "label",
        "scannableId" : "scannableId",
        "resourceId": "resourceId"
    },
    FINGER : {
        "Cluster_Pairs" : "ClusterPairs",
        "label" : "label",
        "scannableId" : "scannableId",
        "resourceId": "resourceId"
    },
    PICK_STATION : {
        "label" : "label",
        "scannableId" : "scannableId"
    },
    BIN_OVD_FIX_ENABLED : {
        "label" : "label",
        "scannableId" : "scannableId",
        "reviewStatus" : "ReviewStatus",
        "binResourceCategory" : "BinResourceCategory",
        "binResourceSubCategory": "BinResourceSubCategory",
        "binTier" : "BinTier",
        "binCount" : "Count",
        "binType" : "BinDimension" //This is binDimension in backend as binType in frontend for better user experience
    },
    LAUNCHPAD_STAGING_ZONE_DISTANCE : {
        "Distance" : "Distance",
        "Launchpad_Label" : "resourceLabel",
        "Launchpad_Scannable" : "resourceScannable",
        "Staging_Zone_Label" : "relatedResourceLabel",
        "Staging_Zone_Scannable" : "relatedResourceScannable",
    },
    PICK_STN_STG_ZONE_DISTANCE : {
        "Distance" : "Distance",
        "Pick_Station_Label" : "resourceLabel",
        "Pick_Station_Scannable" : "resourceScannable",
        "Staging_Zone_Label" : "relatedResourceLabel",
        "Staging_Zone_Scannable" : "relatedResourceScannable",
    },
    AISLE_STAGING_ZONE_DISTANCE : {
        "Distance" : "Distance",
        "Aisle_Label" : "resourceLabel",
        "Aisle_Scannable" : "resourceScannable",
        "Staging_Zone_Label" : "relatedResourceLabel",
        "Staging_Zone_Scannable" : "relatedResourceScannable",
    },
    STAGING_ZONE_STAGING_AREA_ASSOCIATION : {
        "Staging_Zone_Label" : "label",
        "Staging_Zone_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    LAUNCHPAD_STAGING_ZONE_ASSOCIATION : {
        "Launchpad_Label" : "label",
        "Launchpad_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    CLUSTER_AISLE_ASSOCIATION : {
        "Cluster_Label" : "label",
        "Cluster_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    AISLE_BIN_ASSOCIATION: {
        "Aisle_Label" : "label",
        "Aisle_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    TESSERACT_BIN_ASSOCIATION : {
        "Cluster_Label" : "label",
        "Cluster_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    PICK_STATION_CLUSTER_MAP : {
        "Pick_Station_Label" : "label",
        "Pick_Station_Scannable" : "scannableId",
        "resourceId": "resourceId"
    },
    PICK_STATION_STAGING_AREA_MAP : {
        "Pick_Station_Label" : "label",
        "Pick_Station_Scannable" : "scannableId",
        "resourceId": "resourceId"
    }
}

export const RELATIONSHIP_TYPES = {
    DISTANCE : "DISTANCE",
    PARENT_CHILD : "PARENT_CHILD"
}

export const FRONTEND_TO_BACKEND_RESOURCEATTRIBUTES_MAPPING = {
    LAUNCHPAD : {
        "Capacity_Of_Vehicles" :  "NumStagingSpots",
    },
    STAGING_ZONE : {
        "Cart_Capacity_of_Staging_Areas" : "Depth",
    },
    STAGING_AREA : {
        "Capacity" : "Depth",
        "Type" : "Type",
    },
    CLUSTER : {
        "Column_Order_of_Aisles" : "ColumnOrder",
        "No of Diverters": "DivertersCount",
        "Type": "Type"
    },
    FINGER : {
        "Cluster_Pairs" : "ClusterPairs",
    },
   BIN : {
        "binResourceCategory" : "BinResourceCategory",
        "reviewStatus" : "ReviewStatus",
        "binTier" : "BinTier",
        "binType" : "BinDimension", //This is binDimension in backend as binType in frontend for better user experience
        "binCount" : "Count",
        "binResourceSubCategory": "BinResourceSubCategory"
    },
    DOCK_DOOR : {
        "AnchorPoint": "AnchorPoint"
    }
}

export const EDITABLE_HIERARCHIAL_RESOURCE_TYPES = {
    RESOURCES: {
        LAUNCHPAD: {IDENTIFIER : "resourceId"},
        STAGING_ZONE: {IDENTIFIER : "resourceId"},
        FINGER: {IDENTIFIER : "resourceId"},
        CLUSTER: {IDENTIFIER : "resourceId"},
        DOCK_DOOR: {IDENTIFIER : "resourceId"}
    },
        ASSOCIATIONS: {
            AISLE_STAGING_ZONE_DISTANCE: {IDENTIFIER : ['Aisle_Label', 'Staging_Zone_Label']},
            LAUNCHPAD_STAGING_ZONE_DISTANCE: {IDENTIFIER : ['Launchpad_Label', 'Staging_Zone_Label']},
            PICK_STN_STG_ZONE_DISTANCE: {IDENTIFIER : ['Pick_Station_Label', 'Staging_Zone_Label']}
    }
}

export const EDITABLE_RESOURCE_TYPES = [RESOURCE_TYPES.LAUNCHPAD, RESOURCE_TYPES.CLUSTER, RESOURCE_TYPES.FINGER, RESOURCE_TYPES.PICK_STATION, RESOURCE_TYPES.STAGING_AREA, RESOURCE_TYPES.STAGING_ZONE, RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE, RESOURCE_TYPES.DOCK_DOOR];

export const RESOURCE_TYPES_WITH_ADDITION_OPERATION_NOT_SUPPORTED = [RESOURCE_TYPES.BIN, RESOURCE_TYPES.AISLE];
export const ITEMS_PER_PAGE_IN_PREVIEW = 20;
export const ACTIVE_STATUS_TYPE = "ACTIVE";
export const INACTIVE_STATUS_TYPE = "INACTIVE";
export const VALID_STATUS_TYPES = [ACTIVE_STATUS_TYPE, INACTIVE_STATUS_TYPE];

export const VALID_HEADER_ROWS = {
    BIN : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label,
        ALL_ATTRIBUTES.binResourceCategory,
        ALL_ATTRIBUTES.scannableId,
        ALL_ATTRIBUTES.reviewStatus,
        ALL_ATTRIBUTES.binCount,
        ALL_ATTRIBUTES.binTier,
        ALL_ATTRIBUTES.binType,
        ALL_ATTRIBUTES.binResourceSubCategory
    ],
    AISLE : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label
    ],
    CLUSTER : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Column_Order_of_Aisles,
        ALL_ATTRIBUTES.DivertersCount,
        ALL_ATTRIBUTES.Type,
        ALL_ATTRIBUTES.Label,
    ],
    FINGER : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Cluster_Pairs,
        ALL_ATTRIBUTES.Label
    ],
    PICK_STATION : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.scannableId,
        ALL_ATTRIBUTES.Label
    ],
    STAGING_ZONE : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Cart_Capacity_of_Staging_Areas,
        ALL_ATTRIBUTES.Label,
    ],
    STAGING_AREA : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Capacity,
        ALL_ATTRIBUTES.Type,
        ALL_ATTRIBUTES.Label
    ],
    DOCK_DOOR : [
        ALL_ATTRIBUTES.Label,
        ALL_ATTRIBUTES.AnchorPoint
    ],
    LAUNCHPAD : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Capacity_Of_Vehicles,
        ALL_ATTRIBUTES.Label
    ],
    LAUNCHPAD_STAGING_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Launchpad_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label,
    ],
    PICK_STN_STG_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label,
    ],
    AISLE_STAGING_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Aisle_Label_Range,
        ALL_ATTRIBUTES.Staging_Zone_Label
    ],
    STAGING_ZONE_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Staging_Zone_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
    LAUNCHPAD_STAGING_ZONE_MAP : [
        ALL_ATTRIBUTES.Launchpad_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label
    ],
    CLUSTER_AISLE_ASSOCIATION : [
        ALL_ATTRIBUTES.Cluster_Label,
        ALL_ATTRIBUTES.Aisle_Label
    ],
    TESSERACT_BIN_ASSOCIATION : [
        ALL_ATTRIBUTES.Cluster_Label,
        ALL_ATTRIBUTES.Bin_Label
    ],
    PICK_STATION_CLUSTER_MAP : [
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Cluster_Label
    ],
    PICK_STATION_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
    DOCK_DOOR_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Dock_Door_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
}

export const REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL_GENERIC = [
    ALL_ATTRIBUTES.Status,
    ALL_ATTRIBUTES.Label
];

export const REQUIRED_HEADER_ROWS_IN_BULK_UPLOAD_EXCEL = {
    BIN : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label
    ],
    AISLE : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label
    ],
    CLUSTER : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.DivertersCount,
        ALL_ATTRIBUTES.Label,
    ],
    FINGER : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Cluster_Pairs,
        ALL_ATTRIBUTES.Label
    ],
    STAGING_ZONE : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label
    ],
    LAUNCHPAD : [
        ALL_ATTRIBUTES.Status,
        ALL_ATTRIBUTES.Label
    ],
    LAUNCHPAD_STAGING_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Launchpad_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label,
    ],
    PICK_STN_STG_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label,
    ],
    AISLE_STAGING_ZONE_DISTANCE : [
        ALL_ATTRIBUTES.DISTANCE,
        ALL_ATTRIBUTES.Aisle_Label_Range,
        ALL_ATTRIBUTES.Staging_Zone_Label
    ],
    STAGING_ZONE_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Staging_Zone_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
    LAUNCHPAD_STAGING_ZONE_MAP : [
        ALL_ATTRIBUTES.Launchpad_Label,
        ALL_ATTRIBUTES.Staging_Zone_Label
    ],
    CLUSTER_AISLE_ASSOCIATION : [
        ALL_ATTRIBUTES.Cluster_Label,
        ALL_ATTRIBUTES.Aisle_Label
    ],
    TESSERACT_BIN_ASSOCIATION : [
        ALL_ATTRIBUTES.Cluster_Label,
        ALL_ATTRIBUTES.Bin_Label
    ],
    PICK_STATION_CLUSTER_MAP : [
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Cluster_Label
    ],
    PICK_STATION_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Pick_Station_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
    DOCK_DOOR_STAGING_AREA_MAP : [
        ALL_ATTRIBUTES.Dock_Door_Label,
        ALL_ATTRIBUTES.Staging_Area_Label
    ],
}

export const SHEET_NAME_TO_RESOURCE_TYPE_MAP = {
    BIN : RESOURCE_TYPES.BIN,
    AISLE : RESOURCE_TYPES.AISLE,
    CLUSTER : RESOURCE_TYPES.CLUSTER,
    FINGER : RESOURCE_TYPES.FINGER,
    PICK_STATION : RESOURCE_TYPES.PICK_STATION,
    STAGING_ZONE : RESOURCE_TYPES.STAGING_ZONE,
    STAGING_AREA : RESOURCE_TYPES.STAGING_AREA,
    LAUNCHPAD : RESOURCE_TYPES.LAUNCHPAD,
    CLUSTER_AISLE_ASSOCIATION : RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION,
    AISLE_TO_STAGING_ZONE_DISTANCE : RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE,
    STAGING_ZONE_STAGING_AREA_MAP : RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION,
    LAUNCHPAD_STAGING_ZONE_DISTANCE : RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_DISTANCE,
    PICK_STN_STG_ZONE_DISTANCE: RESOURCE_TYPES.PICK_STN_STG_ZONE_DISTANCE,
    LAUNCHPAD_STAGING_ZONE_MAP : RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION,
    TESSERACT_BIN_ASSOCIATION : RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION,
    PICK_STATION_CLUSTER_MAP : RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP,
    PICK_STATION_STAGING_AREA_MAP : RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP,
    DOCK_DOOR_STAGING_AREA_MAP : RESOURCE_TYPES.DOCK_DOOR_STAGING_AREA_MAP
}

export const SORT_PATH_FILL_ALGORITHMS = {
    STANDARD: "STANDARD",
    COMBINATRIX: "COMBINATRIX",
    FULL_AISLE_CLUSTER_WISE_ZEBRA: "FULL_AISLE_CLUSTER_WISE_ZEBRA",
    CLUSTER_WISE_STANDARD: "CLUSTER_WISE_STANDARD",
    STANDARD_BLOCK: "STANDARD_BLOCK",
    SIDELINE_BLOCK_FILL: "SIDELINE_BLOCK_FILL",
    SAME_DAY_STANDARD_FILL: "SAME_DAY_STANDARD_FILL"
}

export const SORT_PATH_PICK_ALGORITHMS = {
    NO_ZEBRA_PATTERN: "NO_ZEBRA_PATTERN",
    ALTERNATE_AISLE_ZEBRA_PATTERN: "ALTERNATE_AISLE_ZEBRA_PATTERN",
    ONE_AISLE_SKIPPING_ZEBRA_PATTERN: "ONE_AISLE_SKIPPING_ZEBRA_PATTERN",
    TWO_AISLE_SKIPPING_ZEBRA_PATTERN: "TWO_AISLE_SKIPPING_ZEBRA_PATTERN",
    SIDELINE_ZEBRA_PATH: "SIDELINE_ZEBRA_PATH",
    PARTIAL_COLUMN_ZEBRA_PATTERN: "PARTIAL_COLUMN_ZEBRA_PATTERN",
    SAME_DAY_STANDARD_PATH: "SAME_DAY_STANDARD_PATH"
}

export const INCLUDED_LOCATION_OPTIONS = {
    AISLES: { USE_ALL: "USE_ALL" },
    COLUMNS: { ABC: "ABC" },
    COMMON: { CUSTOM: "CUSTOM" },
    SORT_LOCATIONS: {
        USE_ALL: "USE_ALL",
    }
}

export const CYCLE = {
    CYCLE_0: "CYCLE_0",
    CYCLE_1: "CYCLE_1",
    CYCLE_2: "CYCLE_2",
    DRS_SORT_TO_ZONE: "DRS_SORT_TO_ZONE",
    SIDELINE: "SIDELINE"
}

export const ZEBRA_SORT_PATH_PATTEN = {
    NO_ZEBRA_PATTERN: "NO_ZEBRA_PATTERN",
    ALTERNATE_AISLE_ZEBRA_PATTERN: "ALTERNATE_AISLE_ZEBRA_PATTERN",
    ONE_AISLE_SKIPPING_ZEBRA_PATTERN: "ONE_AISLE_SKIPPING_ZEBRA_PATTERN",
    TWO_AISLE_SKIPPING_ZEBRA_PATTERN: "TWO_AISLE_SKIPPING_ZEBRA_PATTERN",
    PARTIAL_COLUMN_ZEBRA_PATTERN: "PARTIAL_COLUMN_ZEBRA_PATTERN"
}

export const SORT_PATH_SETTING_STATUS = {
    PENDING_UPDATE: "PENDING_UPDATE",
    UPDATED: "UPDATED"
};

export const SORT_PATH_INFO_UPDATE_STATUS = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    SKIPPED_UPDATE: "SKIPPED_UPDATE"
};

export const SortGridAllocationFormStates = {
    COMMON_FORM_DATA: "commonFormData",
    FILL_FORM_DATA: "fillFormData",
    PICK_FORM_DATA: "pickFormData",
}

export const SortGridAllocationCommonFormStates = {
    CYCLE: "cycle",
    CYCLE_LIST: "cycleList",
    BIN_TYPE: "binType",
    NODE_ID: "nodeId",
    NAME: "name",
    EXCLUDED_LOCATIONS: "excludedLocations",
    INCLUDED_LOCATIONS: "includedLocations",
    INCLUDED_SORT_ZONES: "includedSortZones",
    OVERLAP_LIST: "overlapList"
}

export const StationCycleState = {
    STATION_CODE: "stationCode",
    CYCLE: "cycle",
    CYCLE_NAME : "cycleName",
    CYCLE_ID : "cycleId"
}

export const SortGridAllocationFillFormStates = {
    ALGORITHM: "algorithm",
    RESOURCE_SEQUENCES: "resourceSequences",
    PARAMETERS: "parameters",
    COMMON_PARAMETERS: "commonParameters",
}

export const SortGridAllocationPickFormStates = {
    ALGORITHM: "algorithm",
    RESOURCE_SEQUENCES: "resourceSequences",
    PARAMETERS: "parameters",
}

export const SortingOrder = {
    DEFAULT: "DEFAULT",
    PARENT: "PARENT",
    INCREASING: "INCREASING",
    DECREASING: "DECREASING",
    CUSTOM: "CUSTOM"
}

export const SSD3_CYCLE = "SSD"
export const SSD3_NAME = "SSD"
//TODO: remove
export const SSD3_STATION = "SSD_STATION"
export const AisleToStagingZoneDistancesSheetName = "AISLE_STAGING_ZONE_DISTANCE";
export const StationLayoutFileSuffix = "_Station_Layout";
export const ENABLED_FOR_ALL_ALGORITHMS = "ENABLED_FOR_ALL_ALGORITHMS";


export const AMZL_TCP_ROLES_ACCOUNTS = {
    NARC: {
        NA: ["A1OGQG95VEURM9"],
        EU: ["A1RFMHSF0CZ9W2", "A2ASK6Z15JX7ZP"],
        FE: ["AJGZQ6KPMOFMJ"]
    },
    SPG: {
        NA: ["A1OGQG95VEURM9"],
        EU: ["A1RFMHSF0CZ9W2", "A2ASK6Z15JX7ZP"],
        FE: ["AJGZQ6KPMOFMJ"]
    }
}

export const AMZL_TCP_ROLES =  {
    NARC: {
        NA: ["AmazonFacility::SCC: Station Layout Manage"],
        EU: ["AmazonFacility::SCC: Station Layout Manage"],
        FE: ["AmazonFacility::SCC: Station Layout Manage"]
    },
    SPG: {
        NA: ["AmazonFacility::SCC: Station Layout Manage"],
        EU: ["AmazonFacility::SCC: Station Layout Manage"],
        FE: ["AmazonFacility::SCC: Station Layout Manage"]
    }
 };

export const ERROR_MAP = {
    GENERIC: "Some Error Occured",
    NO_RESOURCES_FOUND: "No Resources found!"
}

export const DEFAULT_FORMATTED_MESSAGES = {
    "download_template_file_SLS_resources" : "STEP 1 : Download the template file to update Resources",
    "sls_resources_operations" : "Create/Edit/Deprecate/Revive : (CLUSTER, FINGER, STAGING ZONE, LAUNCHPAD)",
    "stem_resources_operations_in_narc" : "Deprecate/Revive : (AISLE/BIN)",
    "stem_resources_operations_in_narc_editable" : "Edit/Deprecate/Revive : (BIN)",
    "stem_resources_operations_in_narc_non_editable" : "Deprecate/Revive : (AISLE)",
    "download_template" : "Get Template",
    "upload_file_SLS_resources" : "STEP 2 : Upload the file containing Resources",
    "upload_file_SLS_resources_associations" :  "STEP 4 : Upload the file containing Resources Associations",
    "download_template_file_SLS_resources_associations" : "STEP 3 : Download the template file to upload Resources Associations",
    "stem_service_resources" : "(BIN/CART/PRE_STAGING_AREA/STAGING_AREA/INDUCT_AREA/FLOOR_LOCATION/PROBLEM_SOLVE_AREA)",
    "upload_resource" : "Select File",
    "confirm" : "Confirm",
    "redirect_stem_SLS" : "STEP 5: Upload Data in STEM",
    "redirect_stem_button" : "Redirect",
    "bulk_resource_success" : "Resources have been created Successfully",
    "bulk_sls_resource_success" : "Resources have been updated Successfully",
    "bulk_resource_association_success" : "Resources Associations have been updated Successfully",
    "manage_resources" : "Manage Resources",
    "create_resource" : "Create Resources",
    "preview_loader" : "Please wait while we are generating preview...",
    "file_upload_loader" : "Please wait while we are uploading file...",
    "sls_resource_confirmation_view" : "Confirm that the following resources will be updated",
    "sls_resource_confirmation_view_notes" : "Note : Please be careful while uploading bins, Review Status of all bins will be marked as “REVIEWED”, so please make sure you align with their resource category values.",
    "resource_confirmation_view" : "Confirm that the following resources will be created",
    "confirm_deprecate_resources_alert_force_flag_true" : "Are you sure you want to deprecate {resourceLabel}? Please remember if you deprecate this resource, its child resources also get deprecated.",
    "confirm_deprecate_resources_alert_aisle" : "Are you sure you want to deprecate {resourceLabel}? Please remember if you deprecate an aisle, all bins associated with that aisle also get deprecated.",
    "confirm_deprecate_resources_alert_force_flag_false" : "Are you sure you want to deprecate {resourceLabel}? Please remember you can't deprecate this resource until all its child relationships get deprecated.",
    "confirm_deprecate_resources_alert_generic" : "Are you sure you want to deprecate {resourceLabel}?"
}

export const ROW_LIMIT_FOR_EACH_SHEET = 5000;
export const DEFAULT_NUM_PAGES = 1;
export const DEFAULT_CURRENT_PAGE_INDEX = 1;
export const LOADER_TYPE = {
    PREVIEW : "PREVIEW",
    FILE_UPLOAD : "FILE_UPLOAD"
};
export const DEPRECATION_OPERATION_PARAMETERS = {
    DEPRECATION_FLAG : {
        TRUE : true,
        FALSE : false
    },
    FORCE_FLAG : {
        TRUE : true,
        FALSE : false
    }
};

export const DOWNLOADABLE_RESOURCE_TYPES = [RESOURCE_TYPES.BIN];
export const RESOURCE_TYPE_DROPDOWN_SELECTOR_LABEL_DEFAULT_VALUE = "Select Resource Type";
export const DOWNLOAD_LABEL_FOR_RESOURCES_IN_VALIDATE_AND_DOWNLOAD_DEFAULT_VALUE = "3. Download";
export const GENERATE_VALIDATION_REPORT_DEFAULT_VALUE = "4. Generate Validation Report for OV resources"
export const DOWNLOAD_RESOURCE_DEFAULT_VALUE = "Download Resource";
export const OVD_FIX_ENABLED_SUFFIX = "_OVD_FIX_ENABLED";
export const SAVE_OPERATION_TYPES = {
    CREATE : "CREATE",
    UPDATE : "UPDATE"
}
export const REVIEW_STATUS_TYPES = {
    REVIEWED : "REVIEWED",
    PENDING_REVIEW : "PENDING_REVIEW"
}

export const CLUSTER_TYPES = {
    TESSERACT : "TESSERACT"
}

export const GET_RESOURCES_V2_FILTER_REQUEST_ATTRIBUTE = "filters";

export const GET_RESOURCES_V2_RESOURCE_TYPE_REQUEST_ATTRIBUTE = "resourceType";
export const REDUNDANT_HEADER_ROWS = {
    BIN : [ALL_ATTRIBUTES.scannableId, ALL_ATTRIBUTES.reviewStatus]
}

export const RESOURCE_TYPES_WITH_PAGINATION_SUPPORT = [RESOURCE_TYPES.BIN];
export const PREVIOUS_PAGE_NAVIGATION = "Prev";
export const NEXT_PAGE_NAVIGATION = "Next";