import React from 'react';
import Input from "@amzn/meridian/input";
import InputGroup from "@amzn/meridian/input-group";
import Text from "@amzn/meridian/text";

export default function PositionInput(props) {
    const {xyz, setXyz} = props;
    return <div>
        <Text>{props.label}</Text>
        <InputGroup>
            <Input value={xyz.x} onChange={(x)=>setXyz({...xyz, x})} label="X"
                   type="number" />
            <Input value={xyz.y} onChange={(y)=>setXyz({...xyz, y})} label="Y"
                       type="number" />
            <Input value={xyz.z} onChange={(z)=>setXyz({...xyz, z})} label="Z"
                       type="number"/>
        </InputGroup>
    </div>;
}