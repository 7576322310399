import Modal from "@meridian/components/modal";
import React, {Fragment, useContext, useState} from "react";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import {
    BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE,
    CONCLUDE_VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE,
    DOWNLOAD_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS,
    DOWNLOAD_RESOURCES_SUCCESS,
    GENERATE_VALIDATION_REPORT_FAILURE,
    GENERATE_VALIDATION_REPORT_START,
    GENERATE_VALIDATION_REPORT_SUCCESS,
    VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS,
    VALIDATE_RESOURCE_FAILURE
} from "../../../reducers/NodeManagerReducer";
import Column from "@meridian/components/column";
import Row from "@meridian/components/row";
import Text from "@meridian/components/text";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "@meridian/components/button";
import Select from "@amzn/meridian/select";
import SelectOption from "@amzn/meridian/select/select-option";
import {
    AisleToStagingZoneDistancesSheetName,
    DOWNLOADABLE_RESOURCE_TYPES,
    DOWNLOAD_LABEL_FOR_RESOURCES_IN_VALIDATE_AND_DOWNLOAD_DEFAULT_VALUE,
    DOWNLOAD_RESOURCE_DEFAULT_VALUE,
    RESOURCE_TYPES,
    RESOURCE_TYPE_DROPDOWN_SELECTOR_LABEL_DEFAULT_VALUE,
    Resources,
    StationLayoutFileSuffix,
    GENERATE_VALIDATION_REPORT_DEFAULT_VALUE
} from "../../../Constants";
import Divider from "@meridian/components/divider";
import {downloadXLSXFile, getNodeId} from "../../../Utility";
import {
    generateValidationReport,
    getAisleToStagingZoneDistanceMapping,
    getResourceData,
    getResourcesMapping
} from "../../../resources/NodeResourceManagerResource";
import Alert from "@meridian/components/alert";
import Loading from "../../util/Loading";


function ValidateAndDownloadResourceModal(props) {

    const {state, dispatch} = useContext(NodeResourceContext);
    const [resourceTypeDropdown, setResourceTypeDropdown] = useState(RESOURCE_TYPES.BIN);
    
    const {validateAndDownloadResourceModalState, validateAndDownloadResourceError, aisleResources, aisleToStagingZoneDistanceValidationEnabled,
        aisleToStagingZoneDistanceResources, loadingValidateAndDownloadResourceModal, isOVDFeatureEnabled,
        generateValidationReportErrorMessage, generateValidationReportSuccessMessage} = state;

    const concludeResourceValidationAndDownload = (status) => {
        dispatch({type: CONCLUDE_VALIDATE_AND_DOWNLOAD_RESOURCE_MODAL_STATE, payload: {status: status}})
    };

    const validateAisleToStagingZoneDistance = () => {
        if (!(Array.isArray(aisleResources) && aisleResources.length)) {
            dispatch({type: BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE});
            getResourceData({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.AISLE}).then(aisleResourceResult => {
                if (aisleResourceResult.error) {
                    dispatch({type: VALIDATE_RESOURCE_FAILURE, payload: {validateAndDownloadResourceError:
                                props.intl.formatMessage({id: "fetch_aisle_resources_error"})}});
                } else {
                    if (!(Array.isArray(aisleToStagingZoneDistanceResources) && aisleToStagingZoneDistanceResources.length)) {
                        getResourceData({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE})
                            .then(aisleToStagingZoneDistanceResourcesResult => {
                                if (aisleToStagingZoneDistanceResourcesResult.error) {
                                    dispatch({type: VALIDATE_RESOURCE_FAILURE, payload: {validateAndDownloadResourceError:
                                                props.intl.formatMessage({id: "fetch_aisle_to_staging_zone_distance_error"})}})
                                } else {
                                    dispatch({type: VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS, payload: {aisleResources: aisleResourceResult.resources,
                                            aisleToStagingZoneDistanceResources: aisleToStagingZoneDistanceResourcesResult.resources}})
                                }
                            });
                    } else {
                        dispatch({type: VALIDATE_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS, payload: {aisleResources: aisleResourceResult.resources,
                                aisleToStagingZoneDistanceResources: aisleToStagingZoneDistanceResources}})
                    }
                }
            });
        }
    };

    const showAisleToStagingZoneValidationData = () => {
        let aisleToStagingZoneDistanceValidationMessage = "";
        let aisleWithoutStagingZoneDistanceList = new Set([]);
        if ((Array.isArray(aisleResources) && aisleResources.length) &&
            (Array.isArray(aisleToStagingZoneDistanceResources) && aisleToStagingZoneDistanceResources.length)) {
            let aisleWithStagingZoneDistanceList = new Set([]);
            aisleToStagingZoneDistanceResources.forEach(resourceData => {
                aisleWithStagingZoneDistanceList.add(resourceData.Aisle_Label);
            });
            let isAislesToStagingZoneDistancesValid = true;
            aisleResources.forEach(validAisleResource => {
                if (!aisleWithStagingZoneDistanceList.has(validAisleResource.label)) {
                    aisleWithoutStagingZoneDistanceList.add(validAisleResource.label);
                    isAislesToStagingZoneDistancesValid = false;
                }
            });
            aisleToStagingZoneDistanceValidationMessage = isAislesToStagingZoneDistancesValid ?
                props.intl.formatMessage({id: "aisle_to_staging_zone_distance_validation_success_message"}) :
                props.intl.formatMessage({id: "aisle_to_staging_zone_distance_validation_failure_message"});
        } else {
            aisleToStagingZoneDistanceValidationMessage = props.intl.formatMessage({id: "invalid_aisle_to_staging_zone_distance_message"});
        }
        if (aisleToStagingZoneDistanceValidationEnabled) {
            return (
                <Column>
                    <Row alignment="center justify" widths="fit">
                        <Text>
                            {aisleToStagingZoneDistanceValidationMessage}
                        </Text>
                    </Row>
                    {(aisleWithoutStagingZoneDistanceList.size) ?
                        <Row alignment="center justify" widths="fit">
                            <Text>
                                {new Array(...aisleWithoutStagingZoneDistanceList).join(", ")}
                            </Text>
                        </Row> : null
                    }
                </Column>
            );
        } else {
            return null;
        }
    };

    const downloadAisleToStagingZoneDistance = () => {
        const StationLayoutFileName = getNodeId() + StationLayoutFileSuffix;
        if (!(Array.isArray(aisleToStagingZoneDistanceResources) && aisleToStagingZoneDistanceResources.length)) {
            getResourceData({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.AISLE_STAGING_ZONE_DISTANCE})
                .then(aisleToStagingZoneDistanceResourcesResult => {
                if (aisleToStagingZoneDistanceResourcesResult.error) {
                    dispatch({type: VALIDATE_RESOURCE_FAILURE, payload: {validateAndDownloadResourceError:
                                props.intl.formatMessage({id: "fetch_aisle_to_staging_zone_distance_error"})}})
                } else {
                    const aisleToStagingZoneDistanceMapping = getAisleToStagingZoneDistanceMapping(aisleToStagingZoneDistanceResourcesResult.resources);
                    downloadXLSXFile(aisleToStagingZoneDistanceMapping, AisleToStagingZoneDistancesSheetName, StationLayoutFileName);
                    dispatch({type: DOWNLOAD_AISLE_TO_STAGING_ZONE_DISTANCE_RESOURCE_SUCCESS, payload: {aisleResources: aisleResources,
                            aisleToStagingZoneDistanceResources: aisleToStagingZoneDistanceResourcesResult.resources}})
                }
            });
        } else {
            const aisleToStagingZoneDistanceMapping = getAisleToStagingZoneDistanceMapping(aisleToStagingZoneDistanceResources);
            downloadXLSXFile(aisleToStagingZoneDistanceMapping, AisleToStagingZoneDistancesSheetName, StationLayoutFileName);
        }
    };


    const generateValidationReportResource = () => {
        dispatch({type: GENERATE_VALIDATION_REPORT_START})
        generateValidationReport({nodeId: getNodeId(), resourceType: RESOURCE_TYPES.BIN})
            .then(response => {
                if (response.success === false) {
                    dispatch({type: GENERATE_VALIDATION_REPORT_FAILURE, payload: {generateValidationReportErrorMessage:
                                            props.intl.formatMessage({id: "node_resources.generate_validation_report_error"})}})
                } else {
                    // Successfully generated event for report generation
                    dispatch({type: GENERATE_VALIDATION_REPORT_SUCCESS, payload: {generateValidationReportSuccessMessage:
                            response.generateValidationReportSuccessMessage}})
                }
            });
    };

    /**
     * This method downloads the resources in an excel file belonging to inputted resource type
     * @param {*} resourceType 
     */
    const downloadResources = (resourceType) => {
        dispatch({type: BEGIN_CALL_FOR_VALIDATE_AND_DOWNLOAD_RESOURCE});
        const stationLayoutFileName = getNodeId() + "_" + resourceType + "_" + Resources;
        // Fetch resources
        getResourceData({nodeId: getNodeId(), resourceType: resourceType}, isOVDFeatureEnabled)
                .then(response => {
                    if (response.error) {
                        dispatch({type: VALIDATE_RESOURCE_FAILURE, payload: {
                                validateAndDownloadResourceError: response.error}});
                    } else {
                        // Transform resources data into required excel format
                        const resourceMapping = getResourcesMapping(response.resources);
                        // Download resources in excel file
                        downloadXLSXFile(resourceMapping, RESOURCE_TYPES.BIN, stationLayoutFileName);
                        dispatch({type: DOWNLOAD_RESOURCES_SUCCESS})
                    }
            });
    };

    const validateAndDownloadAisleToStagingZoneDistances = () => {
        return (
            <Column>
                <Row alignment="center justify">
                    <Text>
                        <FormattedMessage id="node_resources.validate_aisle_staging_zone_distances"
                                          defaultMessage="1. Validate Aisles to Staging Zone distances"/>
                    </Text>
                    <Button type="primary" size="small" onClick={() => {validateAisleToStagingZoneDistance()}}>
                        <FormattedMessage id="node_resources.validate_resource" defaultMessage="Validate Resource"/>
                    </Button>
                </Row>
                {showAisleToStagingZoneValidationData()}
                <Divider />
                <Row alignment="center justify">
                    <Text>
                        <FormattedMessage id="node_resources.download_aisle_staging_zone_distances"
                                          defaultMessage="2. Download Aisles to Staging Zone distances"/>
                    </Text>
                    <Button type="primary" size="small" onClick={() => {downloadAisleToStagingZoneDistance()}}>
                        <FormattedMessage id="node_resources.download_resource" defaultMessage="Download Resource"/>
                    </Button>
                </Row>
                <Divider />
                {
                    /**
                     *  Display feature to download resources, resource type is selected from dropdown, 
                     *  currently only "BIN" resource type download is supported, 
                     *  this feature can be extended for other resource types
                     */
                    isOVDFeatureEnabled 
                        &&
                    <div>
                        <Row alignment="center justify"
                             spacingInset="small">
                            <Text>
                                <FormattedMessage id="node_resources.download_label_for_resources_in_validate_and_download"
                                                defaultMessage={DOWNLOAD_LABEL_FOR_RESOURCES_IN_VALIDATE_AND_DOWNLOAD_DEFAULT_VALUE}
                                />
                            </Text>
                            <Select
                                label={<FormattedMessage id="node_resources.resource_type_dropdown_selector_label" defaultMessage={RESOURCE_TYPE_DROPDOWN_SELECTOR_LABEL_DEFAULT_VALUE}/>}
                                size="small"
                                width={210}
                                popoverPosition="adjacent"
                                value={resourceTypeDropdown}
                                onChange={setResourceTypeDropdown}
                            >
                                {DOWNLOADABLE_RESOURCE_TYPES.map(resourceType => 
                                    <SelectOption key={resourceType} value={resourceType} label={resourceType} />
                                )}
                            </Select>
                            <Button type="primary" size="small" onClick={() => {downloadResources(resourceTypeDropdown)}}>
                                <FormattedMessage id="node_resources.download_resource" defaultMessage={DOWNLOAD_RESOURCE_DEFAULT_VALUE}/>
                            </Button> 
                        </Row>
                        <Divider />

                        <Row alignment="center justify"
                             spacingInset="small">
                            <Text>
                                <FormattedMessage id="node_resources.generate_validation_report_text"
                                                  defaultMessage={GENERATE_VALIDATION_REPORT_DEFAULT_VALUE}/>
                            </Text>
                            <Button type="primary" size="small" onClick={() => {generateValidationReportResource()}}>
                                <FormattedMessage id="node_resources.generate_validation_report_button" defaultMessage="Generate Report"/>
                            </Button>
                        </Row>
                        <Divider />

                    </div>
                }
            </Column>
        )
    };

    const errorAlert = () => {
        return (<Fragment>
            <Alert type="error" size="large">
                <FormattedMessage id="bulk_resource_failure" defaultMessage={validateAndDownloadResourceError}/>
            </Alert>
            <br/>
        </Fragment>)
    };

    const reportGenerationError = () => {
        return (<Fragment>
            <Alert type="error" size="large">
                <FormattedMessage id="generateValidationReportErrorMessage" defaultMessage={generateValidationReportErrorMessage}/>
            </Alert>
            <br/>
        </Fragment>)
    };

    const reportGenerationSuccess = () => {
        return (<Fragment>
            <Alert type="success" size="large">
                <FormattedMessage id="generateValidationReportSuccessMessage" defaultMessage={generateValidationReportSuccessMessage}/>
            </Alert>
            <br/>
        </Fragment>)
    };

    return (
        <Modal title={props.intl.formatMessage({id: "node_resources.validate_and_download_resource"})}
               id="modal-validateAndDownloadResource"
               scrollContainer="modal"
               describedById="resource_validate_and_download_preview"
               open={validateAndDownloadResourceModalState}
               onClose={() => { concludeResourceValidationAndDownload(false); }}
        >
            {validateAndDownloadResourceError && errorAlert()}
            {generateValidationReportErrorMessage && reportGenerationError()}
            {generateValidationReportSuccessMessage && reportGenerationSuccess()}
            {loadingValidateAndDownloadResourceModal ?
                <Loading height={"100px"}/> :
                <Column>
                    {validateAndDownloadAisleToStagingZoneDistances()}
                </Column>
            }
        </Modal>
    );
}

export default injectIntl(ValidateAndDownloadResourceModal);