import React, {useEffect, useContext, useState, useRef} from 'react';
import {getNodeId} from "../../Utility";
import {getFeaturesFromParent, getNodeCapabilityFromParent} from "../../handler/ParentDataHandler";
import {getResourceTypes} from "../../helper/NodeResourceManagerHelper";
import {fetchImmediateResourceData, getResourceData} from "../../resources/NodeResourceManagerResource";
import {
    getStationByStationCode
} from "../../resources/StationViewManagementResource";
import {FormattedMessage} from "react-intl";
import Row from "@meridian/components/row";
import Button from "@meridian/components/button";
import Text from "@meridian/components/text";
import Column from "@meridian/components/column";
import Select, {SelectOption} from "@meridian/components/select";
import Box from "@meridian/components/box";
import Table, {TableCell, TableRow} from "@meridian/components/table";
import ErrorPage from "../util/ErrorPage";
import Loading from "../util/Loading";
import {CapabilityContext} from "../../context/CapabilityContext";
import {NodeResourceContext} from "../../context/NodeResourceContext";
import {GET_CAPABILITY} from "../../reducers/CapabilityReducer";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import Icon from "@amzn/meridian/icon";
import chevronRightLargeTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import chevronLeftLargeTokens from "@amzn/meridian-tokens/base/icon/chevron-left-small"

import {
    CLEAR_RESOURCE_DATA,
    CONTRACT_RESOURCE, ERROR_CALL, GET_IMMEDIATE_CHILDREN_DATA_SUCCESS,
    GET_RESOURCE_DATA, GET_RESOURCE_DATA_SUCCESS,
    TOGGLE_DEPRECATE_RESOURCE_MODAL, UPDATE_RESOURCE_SEARCH_CRITERIA,
    SET_CURRENT_RESOURCE, UPDATE_COUNTRY_CODE, SET_CURRENT_RESOURCE_TAB, 
    CLEAR_RESOURCE_DATAMAP, CLEAR_RESOURCE_TYPE, TOGGLE_DEPRECATE_RESOURCE_ASSOCIATION_MODAL, 
    SET_CURRENT_RESOURCE_ASSOCIATION,
    SET_IS_AUTHORIZED_USER,
    SET_OVD_FEATURE_ENABLED_FLAG,
    SET_PAGINATION_FIX_ENABLED_FLAG,
    SET_BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED_FLAG,
    SET_RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED_FLAG
} from "../../reducers/NodeManagerReducer";
import {
    ACTION_TYPE,
    CAPABILITY_CONFIG,
    DEPRECATE_NON_HIERARCHY_RESOURCE_ASSOCIATION_TYPES,
    DEPRECATE_RESOURCE_TYPES,
    EDITABLE_HIERARCHIAL_RESOURCE_TYPES,
    FEATURES,
    NODE_RESOURCES_TABS,
    NODE_RESOURCES_TABS_NAMES,
    RESOURCE_TYPES,
    Resources,
    STRINGS,
    DEPRECATE_HIERARCHY_RESOURCE_ASSOCIATION_TYPES,
    NARC_TAB,
    CAPABILITY,
    RESOURCE_TYPES_WITH_PAGINATION_SUPPORT,
    DEFAULT_CURRENT_PAGE_INDEX,
    PREVIOUS_PAGE_NAVIGATION,
    NEXT_PAGE_NAVIGATION
} from "../../Constants";
import { isTCPAuthorizedUser } from '../../helper/NodeResourceAuthorizationHelper';

export default function NodeResourcesManagerTable() {
    const {capability, dispatch:capabilityDispatch} = useContext(CapabilityContext);
    const {state, dispatch} = useContext(NodeResourceContext);
    // currentPageIndex determines the current page index set at the time of pagination, it is set to default index of 1 when page loads initially
    const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_CURRENT_PAGE_INDEX);
    // resourceDataPageTokens contains map of pageIndex with pageToken required to load page at given pageIndex
    const [resourceDataPageTokens, setResourceDataPageTokens] = useState({});

    // These references are added to ensure useEffect hooks which trigger on state changes don't render on initial page load render
    const isFirstRenderForResourceDataFetchInUseEffect = useRef(true);
    const isFirstRenderForResourceTypeAndDataFetchInUseEffect = useRef(true);

    const resourcesWithHierarchy = [ RESOURCE_TYPES.AR_FLOOR, RESOURCE_TYPES.CHUTE, RESOURCE_TYPES.CART_LOCATION, RESOURCE_TYPES.CART, RESOURCE_TYPES.AR_GROUP,
        RESOURCE_TYPES.AR_STATION, RESOURCE_TYPES.STAGING_ZONE_STAGING_AREA_ASSOCIATION, RESOURCE_TYPES.CLUSTER_AISLE_ASSOCIATION,
        RESOURCE_TYPES.AISLE_BIN_ASSOCIATION, RESOURCE_TYPES.LAUNCHPAD_STAGING_ZONE_ASSOCIATION, RESOURCE_TYPES.TESSERACT_BIN_ASSOCIATION, RESOURCE_TYPES.PICK_STATION_CLUSTER_MAP,
        RESOURCE_TYPES.PICK_STATION_STAGING_AREA_MAP, RESOURCE_TYPES.DOCK_DOOR];
    let loading, error, resourceList, displayFields, resourceSearchCriteria, resourceTypes, deprecateResourceModalState, currentNodeId, narc_sls_intergration_enabled, currentTab, isAuthorizedUser, isOVDFeatureEnabled, isPaginationFixEnabled, narc_ssd_authorization_enabled;
    //Set states based on flag for NARC rollout for SLS resources
    state.narc_sls_intergration_enabled ?
    {loading, error, resourceList, displayFields, resourceSearchCriteria, resourceTypes, deprecateResourceModalState, currentNodeId, currentTab, narc_sls_intergration_enabled, isAuthorizedUser, isOVDFeatureEnabled, isPaginationFixEnabled} = state
      : {loading, error, resourceList, displayFields, resourceSearchCriteria, resourceTypes, deprecateResourceModalState, currentNodeId, narc_ssd_authorization_enabled, isAuthorizedUser} = state;

    useEffect(() => {
        getNodeCapabilityFromParent().then(capability => {
            capabilityDispatch({ type: GET_CAPABILITY, capability});
            const nodeId = getNodeId();
            getStationByStationCode(nodeId).then(station => {
                const countryCode = station.countryCode;
                dispatch({type: UPDATE_COUNTRY_CODE, countryCode});
                //Check if a station is valid based on flag for NARC rollout for SLS resources and update state
                getFeaturesFromParent([FEATURES.NARC_VISIBILITY, FEATURES.IS_SSD_MMOT_ENABLED]).then(featureStatus => {
                    const parsedCapability = JSON.parse(capability.capability);
                    const narc_sls_intergration_enabled_response = featureStatus.data[FEATURES.NARC_VISIBILITY] && parsedCapability===CAPABILITY.AMZL_CORE;
                    const isSSDMMOTEnabled_response = featureStatus.data[FEATURES.IS_SSD_MMOT_ENABLED] && parsedCapability===CAPABILITY.SSD;
                    dispatch({type: ACTION_TYPE.GET_FEATURES_STATUS, narc_sls_intergration_enabled: narc_sls_intergration_enabled_response});
                    dispatch({type: ACTION_TYPE.IS_SSD_MMOT_ENABLED, isSSDMMOTEnabled: isSSDMMOTEnabled_response});
                    getFeaturesFromParent([FEATURES.NARC_AUTHORIZATION_CHECK, FEATURES.NARC_SSD_AUTHORIZATION_CHECK]).then(authFeatureStatus => {
                        const narc_ssd_authorization_enabled_response = authFeatureStatus.data[FEATURES.NARC_SSD_AUTHORIZATION_CHECK] && parsedCapability===CAPABILITY.SSD;
                        const tcp_auth_check_enabled_response = (authFeatureStatus.data[FEATURES.NARC_AUTHORIZATION_CHECK] && parsedCapability===CAPABILITY.AMZL_CORE)
                                                                || narc_ssd_authorization_enabled_response;
                        dispatch({type: ACTION_TYPE.GET_FEATURES_STATUS_FOR_SSD_AUTHORIZATION, narc_ssd_authorization_enabled: narc_ssd_authorization_enabled_response});
                        dispatch({type: ACTION_TYPE.GET_FEATURES_STATUS_FOR_NARC_AUTHORIZATION_CHECK, isNARCTCPAuthorizationCheckEnabled: tcp_auth_check_enabled_response});
                        isTCPAuthorizedUser(tcp_auth_check_enabled_response, NARC_TAB).then( (isAuthorizedUser) => {
                            dispatch({type: SET_IS_AUTHORIZED_USER, payload: {
                                isAuthorizedUser: isAuthorizedUser
                            }})
                        });
                    });

                    // OVD Fixes Enabled flag is used for launching ovd related changes and other bug fixes for better user experience
                    getFeaturesFromParent([FEATURES.OVD_FEATURE_ENABLED]).then(ovdFeatureStatus => {
                        const ovd_feature_enabled_response = ovdFeatureStatus.data[FEATURES.OVD_FEATURE_ENABLED] && parsedCapability===CAPABILITY.AMZL_CORE;
                        dispatch({type: SET_OVD_FEATURE_ENABLED_FLAG, payload : { isOVDFeatureEnabled: ovd_feature_enabled_response}});
                    });
                    // Pagination fix flag is used to determine if pagination support for rendering resources in UI is enabled
                    getFeaturesFromParent([FEATURES.PAGINATION_FIX_ENABLED]).then(paginationFixEnabledFeatureStatus => {
                        const pagination_fix_enabled_response = paginationFixEnabledFeatureStatus.data[FEATURES.PAGINATION_FIX_ENABLED] && parsedCapability===CAPABILITY.AMZL_CORE;
                        dispatch({type: SET_PAGINATION_FIX_ENABLED_FLAG, payload : { isPaginationFixEnabled: pagination_fix_enabled_response}});
                    });
                    /** Resource Revival using AddBulkResources API flag is used to determine the API to use for deprecation/revival functionalities
                     * Currently, deprecation/revival functionalities are handled by "SetDeprecationStatusV3" API
                    */ 
                    getFeaturesFromParent([FEATURES.RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED]).then(revivalFeatureStatus => {
                        const revival_feature_response = revivalFeatureStatus.data[FEATURES.RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED] && parsedCapability===CAPABILITY.AMZL_CORE;
                        dispatch({type: SET_RESOURCE_REVIVAL_USING_ADD_BULK_RESOURCES_API_ENABLED_FLAG, payload : { isResourceRevivalUsingAddBulkResourcesAPIEnabled: revival_feature_response}});
                    });
                    /** Bulk Upload File Reader using XLSX Reader Enabled flag is used to determine if SheetJs XLSX reader is used for parsing excel file data instead of npm "read-excel-file" reader
                     * SheetJS XLSX reader improves latency of parsing excel data from ~25sec to ~2-3sec
                    */
                    getFeaturesFromParent([FEATURES.BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED]).then(xlsxReaderEnabledFeatureStatus => {
                        const xlsx_reader_enabled_response = xlsxReaderEnabledFeatureStatus.data[FEATURES.BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED] && parsedCapability===CAPABILITY.AMZL_CORE;
                        dispatch({type: SET_BULK_UPLOAD_FILE_READER_USING_XLSX_READER_ENABLED_FLAG, payload : { isBulkUploadReaderUsingXLSXReaderEnabled: xlsx_reader_enabled_response}});
                    });
                    //Fetch Resource Types based on narc_sls_intergration_enabled flag and capability
                    const resourceTypes = getResourceTypes(narc_sls_intergration_enabled_response, JSON.parse(capability.capability), NODE_RESOURCES_TABS.RESOURCES_TAB);
                    const resourceSearchCriteria = {nodeId: nodeId, resourceType: resourceTypes[0]};
                    dispatch({type: GET_RESOURCE_DATA, payload: {
                            resourceSearchCriteria: resourceSearchCriteria,
                            resourceTypes: resourceTypes,
                            currentNodeId: nodeId,
                            loading: false
                        }
                    })
                    fetchResourceData(resourceSearchCriteria);
                })
            });
        })

    }, []);
  
    useEffect(() => {
        // Check if deprecateResourceModalState or resourceSearchCriteria states are properly set
        if(typeof deprecateResourceModalState === 'undefined' || typeof resourceSearchCriteria === 'undefined') {
            return;
        }
        /** 👈️ Return early if initial render, 
         * because fetchResourceData API is already invoked in above useEffect method,
         * This is done to avoid unnecessay API invocations
         */
        if (isFirstRenderForResourceDataFetchInUseEffect.current) {
            isFirstRenderForResourceDataFetchInUseEffect.current = false;
            return;
          } 
        if(!deprecateResourceModalState && capability.data) {
            dispatch( {type: CLEAR_RESOURCE_DATA});
            fetchResourceData(resourceSearchCriteria);
        }
    }, [deprecateResourceModalState, resourceSearchCriteria]);

    //Fetch data when current tab is reset
    useEffect(() => {
        // Check if currentTab state is properly set
        if(typeof currentTab === 'undefined') {
            return;
        }
        /** 👈️ Return early if initial render, 
         * because getResourceTypes method and fetchResourceData API are already invoked in above useEffect method,
         * This is done to avoid unnecessay API invocations
         */
        if (isFirstRenderForResourceTypeAndDataFetchInUseEffect.current) {
            isFirstRenderForResourceTypeAndDataFetchInUseEffect.current = false;
            return;
        } 
                
        if(narc_sls_intergration_enabled && !deprecateResourceModalState && capability.data) {
            dispatch( {type: CLEAR_RESOURCE_DATA});
            dispatch( {type: CLEAR_RESOURCE_DATAMAP});
            dispatch( {type: CLEAR_RESOURCE_TYPE});

            const resourceTypes =  getResourceTypes(narc_sls_intergration_enabled, capability.data, currentTab);
            const resourceSearchCriteria = {nodeId: currentNodeId, resourceType: resourceTypes[0]};

            dispatch({type: GET_RESOURCE_DATA, payload: {
                    resourceSearchCriteria: resourceSearchCriteria,
                    resourceTypes: resourceTypes,
                    currentNodeId: currentNodeId,
                    loading: false
                }
            })
             fetchResourceData(resourceSearchCriteria);
        }
    }, [currentTab]);

    const setCurrentResource = (resourceId) => ( dispatch({
        type: SET_CURRENT_RESOURCE,
        resourceId
    }));

    //Set current value of resource association when association is edited
    const setCurrentResourceAssociation = (resourceLabel, relatedResourceLabel) => ( dispatch({
        type: SET_CURRENT_RESOURCE_ASSOCIATION,
        resourceLabel: resourceLabel,
        relatedResourceLabel: relatedResourceLabel
    }));


    const toggleDeprecateResourceModal = (resource, status)  => {
        dispatch( {type: TOGGLE_DEPRECATE_RESOURCE_MODAL, payload:{
            deprecateResourceModalState: status,
            deprecationResource: resource}});
    };

    // toggle modal state when an association is deprecated
    const toggleDeprecateResourceAssociationModal = (resource, status, childResource)  => {
        dispatch( {type: TOGGLE_DEPRECATE_RESOURCE_ASSOCIATION_MODAL, payload:{
            deprecateResourceModalState: status,
            deprecationResource: resource,
            childResource: childResource
        }});
    };

    const fetchImmediateResource = (resourceStatus, resourceId, nodeId) => {
        // To update and mark resource close on clicking a resource twice
        dispatch( {type: CONTRACT_RESOURCE, payload:{resourceList: resourceList, resourceId: resourceId, loading: false}});
        if(!resourceStatus) {
            fetchImmediateResourceData(resourceStatus, resourceId, nodeId, resourceSearchCriteria.resourceType).then(result => {
                if (result.resources) {
                    dispatch({type: GET_IMMEDIATE_CHILDREN_DATA_SUCCESS, payload:
                            {resourceList: resourceList, resourceId: resourceId, result:result}});
                } else {
                    dispatch( {type: ERROR_CALL, payload:{error: result.error}});
                }
            });
        }
    }

    const updateAndFetchDataByResourceType = (resourceType) => {
        dispatch( {type: UPDATE_RESOURCE_SEARCH_CRITERIA, payload:{resourceSearchCriteria: {nodeId: currentNodeId, resourceType: resourceType}}});
    }


    // Fetch resource data for the given search criteria
    const fetchResourceData = (resourceSearchCriteria) => {
        getResourceData(resourceSearchCriteria, isOVDFeatureEnabled, null, isPaginationFixEnabled).then( result => {
            if(result.error) {
                dispatch( {type: ERROR_CALL, payload:{error: result.error}});
            } else {
                dispatch( {type: GET_RESOURCE_DATA_SUCCESS, payload:{result: result, resourceSearchCriteria: resourceSearchCriteria}});
                // Page Token to load first page is always null
                resourceDataPageTokens[DEFAULT_CURRENT_PAGE_INDEX] = null;
                // Set page token to load next page based on response
                if (result.pageToken)
                    resourceDataPageTokens[DEFAULT_CURRENT_PAGE_INDEX+1] = result.pageToken;
                setResourceDataPageTokens(resourceDataPageTokens => ({...resourceDataPageTokens}));
            }
        });
    }

    const resourceHeader = () => {
        return (
            <div style={{whiteSpace: 'pre-wrap'}}>
                <Row spacing="medium" style={{whiteSpace: 'pre-wrap'}}>
                    
                     <Text width="150" type="h100">
                     { 
                        (currentTab === NODE_RESOURCES_TABS.RESOURCES_TAB || !narc_sls_intergration_enabled)
                        ? <FormattedMessage id="node_resources.resources_type" defaultMessage="Resource Type:" />
                        : <FormattedMessage id="node_resources.resources_association_type" defaultMessage="Resource Association Type:" />
                    }
                    </Text>
                    <Select
                        id="resourceTypeDropdown" size="small" width="200" placeholder="resourceTypeDropdown"
                        value={resourceSearchCriteria.resourceType} popoverPosition="adjacent"
                        onChange={(resourceType) => updateAndFetchDataByResourceType(resourceType)}
                    >
                        {resourceTypes.map(resourceType =>
                            <SelectOption key={resourceType} value={resourceType} label={resourceType} />
                        )}
                    </Select>
                    {
                       (
                            (CAPABILITY_CONFIG[Resources].RESOURCE_TYPES).includes(resourceSearchCriteria.resourceType)
                            || (isPaginationFixEnabled && resourceSearchCriteria.resourceType === RESOURCE_TYPES.BIN)
                        )
                            &&
                            <Text type="b300">
                                <i>
                                    <FormattedMessage id={'description_resources.' + resourceSearchCriteria.resourceType} defaultMessage=""/>
                                </i>
                            </Text>
                    }
                </Row>
            </div>
        )
    }

    const tableHeader = () => {
        return (
            <TableRow>
                {displayFields && displayFields.map(
                    displayField =>
                        <TableCell key={"resource." + displayField.fieldName}>
                            <Text alignment="center" type="h100">
                                <FormattedMessage id={"resource." + displayField.fieldName} defaultMessage={displayField.fieldName}/>
                            </Text>
                        </TableCell>
                )}
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        )
    }

    const resourceHierarchyHeader = (resource) => {
        return (
            <TableRow>
                {resource.immediateChilds.displayFields && resource.immediateChilds.displayFields.map(displayField =>
                    <TableCell key={"resource." + displayField.fieldName}>
                        <Text alignment="center" type="h100">
                            <FormattedMessage id={"node_resources." + displayField.fieldName}
                                              defaultMessage={displayField.fieldName}/>
                        </Text>
                    </TableCell>
                )}
            </TableRow>
        )
    }

    const resourceHierarchyData = (resource) => {
        return (resource.immediateChilds.resourceList.map(childResource =>
            <TableRow highlightOnHover={true} key={"childResource " + childResource.label}>
                {resource.immediateChilds.displayFields && resource.immediateChilds.displayFields.map(displayField =>
                    <TableCell key={"child " + displayField.fieldName + " " + childResource[displayField.fieldName]}>
                        <Text alignment="center" type="b100">
                            {childResource[displayField.fieldName] ? childResource[displayField.fieldName] : STRINGS.EMPTY}
                        </Text>
                    </TableCell>
                )}
                {narc_sls_intergration_enabled && isAuthorizedUser && DEPRECATE_HIERARCHY_RESOURCE_ASSOCIATION_TYPES.includes(resource.resourceType) && 
	                     deprecateResourceAssociationButton(resource, childResource)}
            </TableRow>)
        )
    }

    const deprecateResourceButton = (resource) => {
        return (
            <TableCell>
                <div onClick={e => { e.stopPropagation(); toggleDeprecateResourceModal(resource, true) }}>
                    <Button size="small" type="link">
                        <FormattedMessage id="node_resources.deprecate_button" defaultMessage="Deprecate"/>
                    </Button>
                </div>
            </TableCell>
        )
    }

    //Button to deprecate resource associations
    const deprecateResourceAssociationButton = (resource, childResource={}) => {
        return (
            <TableCell>
                <div onClick={e => { e.stopPropagation(); toggleDeprecateResourceAssociationModal(resource, true, childResource) }}>
                    <Button size="small" type="link">
                        <FormattedMessage id="node_resources.deprecate_association_button" defaultMessage="Deprecate Association"/>
                    </Button>
                </div>
            </TableCell>
        )
    }

    const tableDataWhenHierarchyExists = (resourceList) => {
        // checking if we should show deprecate button based on narc_ssd_authorization_enabled feature and user authorized or not
        // if flag is disabled then button will be shown, if flag enabled then user permission will used to show button
        // Currently check is added for SSD as deprecate button is to be removed from SSD nodes based on permissions.
        const shouldShowCartLocationDeprecateButton = (CAPABILITY.SSD === capability.data && narc_ssd_authorization_enabled ? isAuthorizedUser : true);
        return (
            resourceList.map(resource =>
                <TableRow open={resource.open} key={resource.label}
                          onClick={() => { fetchImmediateResource(resource.open, resource.resourceId, resourceSearchCriteria.nodeId); }}
                          highlightOnHover={true}>
                    {displayFields && displayFields.map(displayField =>
                        <TableCell key={displayField.fieldName + " " + resource[displayField.fieldName]}>
                            <Text alignment="center" type="b100">
                                {resource[displayField.fieldName] && resource[displayField.fieldName]}
                            </Text>

                        </TableCell>
                    )}
                    {(resource.resourceType === "CHUTE" || resource.resourceType === RESOURCE_TYPES.DOCK_DOOR)?
                        <TableCell>
                            <div onClick={e => { e.stopPropagation(); setCurrentResource(resource.resourceId) }}>
                                <Button
                                    size="small"
                                    type="link"
                                >
                                    Edit
                                </Button>
                            </div>
                        </TableCell>
                        : ""}
                    {resource.resourceType === RESOURCE_TYPES.CART_LOCATION && shouldShowCartLocationDeprecateButton && deprecateResourceButton(resource)}
                    {resourceHierarchyHeader(resource)}
                    {resourceHierarchyData(resource)}
                </TableRow>
            )
        )
    }

    // Custom component for rendering pagination on resources tab
    const renderPagination = () => {
        return isPaginationFixEnabled 
            && RESOURCE_TYPES_WITH_PAGINATION_SUPPORT.includes(resourceSearchCriteria.resourceType)
            && 
                <Row alignment="top right">

                    { /** Previous Page Navigation */ }
                    <Button disabled={currentPageIndex<=1} type="link" onClick={() => {
                        handleOnChangeEvent(currentPageIndex-1);
                    }}>
                        <Icon tokens={chevronLeftLargeTokens} /> {PREVIOUS_PAGE_NAVIGATION} 
                    </Button>

                    { /** Current Page Index */ }
                    <Button type="link">
                        {currentPageIndex}
                    </Button>

                    { /** Next Page Navigation */ }
                    <Button disabled={ !resourceDataPageTokens.hasOwnProperty(currentPageIndex+1) } type="link" onClick={() => {
                        handleOnChangeEvent(currentPageIndex+1);
                    }}>
                        {NEXT_PAGE_NAVIGATION} <Icon tokens={chevronRightLargeTokens} />
                    </Button>

                </Row>
    }

    // Handles Page Navigation events
    const handleOnChangeEvent = (selectedPageIndex) => {
        dispatch( {type: CLEAR_RESOURCE_DATA});
        /* resourcePageTokens state contains the list of page tokens required for page navigation
        */
        let pageToken = resourceDataPageTokens[selectedPageIndex];
        getResourceData(resourceSearchCriteria, isOVDFeatureEnabled, pageToken, isPaginationFixEnabled).then( result => {
            if(result.error) {
                dispatch( {type: ERROR_CALL, payload:{error: result.error}});
            } else {
                // Display data in UI
                dispatch( {type: GET_RESOURCE_DATA_SUCCESS, payload:{result: result, resourceSearchCriteria: resourceSearchCriteria}});
                /**
                 * Check if resourcePageTokens state contains the required page token to load the next page
                 * If current index of page is x, and "Next" page has index x+1, 
                 * then we need to set the token to load x+2 page in this call
                 * */ 
                if (selectedPageIndex > currentPageIndex
                    && !resourceDataPageTokens.hasOwnProperty(selectedPageIndex+1)
                    && result.pageToken !== null) {
                        resourceDataPageTokens[selectedPageIndex+1] = result.pageToken;
                        setResourceDataPageTokens(resourceDataPageTokens => ({...resourceDataPageTokens}));
                }
            }
        });
        // Set currentPageIndex based on selected event
        setCurrentPageIndex(selectedPageIndex);
    }
    
    const tableDataWhenHierarchyDoesntExists = (resourceList) => {
        return (
            resourceList.map(resource =>
                <TableRow key={resource.resourceId + " " + resource.resourceType} highlightOnHover={true}>
                    {displayFields && displayFields.map(displayField =>
                        <TableCell key={displayField.fieldName}>
                            <Text alignment="center" type="b100">
                                {(() => {
                                     if(resource[displayField.fieldName]){
                                       return resource[displayField.fieldName]
                                     } else{
                                        return STRINGS.EMPTY
                                     }
                                }
                                )()}
                            </Text>
                        </TableCell>
                    )}
                    {/* Enable edit option for valid resources and associations */}
                    { narc_sls_intergration_enabled && isAuthorizedUser &&
                    (resourceSearchCriteria.resourceType in EDITABLE_HIERARCHIAL_RESOURCE_TYPES.RESOURCES
                    || resourceSearchCriteria.resourceType in EDITABLE_HIERARCHIAL_RESOURCE_TYPES.ASSOCIATIONS)
                    ?
                        <TableCell>
                            <div onClick={e => { 
                                    e.stopPropagation(); 
                                    if (resourceSearchCriteria.resourceType in EDITABLE_HIERARCHIAL_RESOURCE_TYPES.RESOURCES)
                                        setCurrentResource(resource.resourceId) 
                                    else{
                                        const identifiers = (EDITABLE_HIERARCHIAL_RESOURCE_TYPES.ASSOCIATIONS)[resourceSearchCriteria.resourceType].IDENTIFIER;
                                        setCurrentResourceAssociation(resource[identifiers[0]], resource[identifiers[1]]) 
                                    }
                                }}>
                                <Button
                                    size="small"
                                    type="link"
                                >
                                    Edit
                                </Button>
                            </div>
                        </TableCell>
                        : ""}
                    {narc_sls_intergration_enabled && isAuthorizedUser && DEPRECATE_RESOURCE_TYPES.includes(resource.resourceType) && deprecateResourceButton(resource)}
	                {narc_sls_intergration_enabled && isAuthorizedUser && DEPRECATE_NON_HIERARCHY_RESOURCE_ASSOCIATION_TYPES.includes(resource.resourceType) && deprecateResourceAssociationButton(resource)}
                </TableRow>
            )
        )
    }

    if (error) {
        return <ErrorPage error={error.data && error.data.message ? error.data.message : "generic.error"} />
    }

    if (loading) {
        return (
            <div className="ResourceManagement">
                <Loading />
            </div>
        );
    }
    
    //Method to change tabs
    const setCurrentTab = (tab) => {
        dispatch({
            type: SET_CURRENT_RESOURCE_TAB,
            tab: tab
        })
    };

    if (resourceList) {
        return (<div className="NodeResourceManager">
            {
                narc_sls_intergration_enabled
                    &&
                (   <>
                        <TabGroup value={currentTab} onChange={setCurrentTab} fill="tabs">
                                <Tab value={NODE_RESOURCES_TABS.RESOURCES_TAB}>{NODE_RESOURCES_TABS_NAMES.RESOURCES}</Tab>
                                <Tab value={NODE_RESOURCES_TABS.RESOURCES_ASSOCIATIONS_TAB}>{NODE_RESOURCES_TABS_NAMES.RESOURCES_ASSOCIATIONS}</Tab>
                        </TabGroup>
                        <br/>
                    </>
                )
            }
            <Column widths="fill" heights={["fill", "85vh"]} wrap="down" spacing="none">
                <Row alignment="top left" backgroundColor="secondary" wrap="down" spacingInset="medium" height="fill" spacing="none">
                    {resourceTypes && resourceTypes.length > 0 &&
                        <Column widths="fill" spacing="medium" width="100%">
                            <br/>
                            {resourceHeader()}
                            {renderPagination()}
                            <Row width="100%" spacing="none" alignment="top left">
                                <Box type="outline" spacingInset="medium" className="OverflowYAuto" width="100%">
                                    <Table headerRows={1} width="100%" showDividers={true} fixHeaderRows={true} spacing="small">
                                        {tableHeader()}
                                        {resourcesWithHierarchy.includes(resourceSearchCriteria.resourceType) ?
                                            tableDataWhenHierarchyExists(resourceList) :
                                            tableDataWhenHierarchyDoesntExists(resourceList)}
                                    </Table>
                                </Box>
                            </Row>
                            </Column>
                        }
                </Row>
            </Column>
            
            </div>);
    } else {
        return STRINGS.EMPTY
    }
}
