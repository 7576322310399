import { AMZL_TCP_ROLES_ACCOUNTS, AMZL_TCP_ROLES } from '../Constants';
import { getUserEmployeeTCPRolesUsingAccountId } from '../resources/StationViewManagementResource'
import { getRegion } from '../Utility';

/**
 * 
 * @param {*} region 
 * @returns Root AccountIds
 */
 export const getRootAccountId = (tab, region) => {
    return AMZL_TCP_ROLES_ACCOUNTS[tab][region];
}

/**
 * 
 * @param {*} region 
 * @returns Root AccountIds
 */
 export const getValidTCPRolesList = (tab, region) => {
    return AMZL_TCP_ROLES[tab][region];
}

/**
 * 
 * @param {*} requestBody 
 * @param {*} tcpRole 
 * @returns if user has given tcp rol
 */
export const getTCPRoles = async (requestBody, tab, region) => {
    let isValidUser = false;
    await getUserEmployeeTCPRolesUsingAccountId(requestBody, getValidTCPRolesList(tab, region)).then( () => {
        isValidUser = true;
    }).catch( () => {
        isValidUser = false;
    })
    return isValidUser;
}

/**
 * 
 * @param {*} loginId 
 * @param {*} tcpRole 
 * @returns Boolean value based on whether user with given loginId has given TCP Role in given accountId
 */
 export const isValidRole = async (tab, region) => {

    let isValidUser = false;
    let accountIds = getRootAccountId(tab, region);
	 
    for (let accountId in accountIds) {
        let requestBody = {
            accountId: accountIds[accountId]
        }
        isValidUser = await getTCPRoles(requestBody, tab, region) || isValidUser;
    }
    return isValidUser;
}

/**
 * 
 * @returns Boolean value based on whether user is authorized to have write access in NodeResources tab
 */
export const isTCPAuthorizedUser = async (isNARCTCPAuthorizationCheckEnabled, tab) => {
    if (!isNARCTCPAuthorizationCheckEnabled) {
        return true;
    }

    let isValidUser = false;
    await isValidRole(tab ,getRegion()).then(
            (isValidRoleResponse) => {
                isValidUser = isValidRoleResponse;
    })

    return isValidUser;
}