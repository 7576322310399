import React, {Component} from "react";
import EmployeeManagementContextProvider from "../../context/EmployeeManagementContext";
import EmployeeManagement from "../EmployeeManagement";

class EmployeeManagementManager extends Component {
    render() {
        return (
            <EmployeeManagementContextProvider>
                <EmployeeManagement/>
            </EmployeeManagementContextProvider>
        )
    }
}

export default EmployeeManagementManager
