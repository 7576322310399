import React from 'react';
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import RadioButton from "@amzn/meridian/radio-button";
import get from "lodash/get";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import {addToObject} from "../util/CommonUtils";
import {ENABLED_FOR_ALL_ALGORITHMS} from "../../Constants";

const SortGridAllocationResourceSequenceForm = (props) => {
    const { algorithm, resourceSequenceInputs, resourceSequences, setResourceSequences } = props;


    const renderResourceSequenceInputOption = (sequenceOptions, sequenceInput) => {
        return (
            <Column key={sequenceInput.id}>
                <Row spacing={"small"}>
                    <Column>
                        <RadioButton
                            checked={get(resourceSequences, [sequenceOptions.id, 0]) === sequenceInput.value}
                            onChange={value => setResourceSequences(addToObject(
                                resourceSequences,
                                sequenceOptions.id,
                                [value, null]
                            ))}
                            name={sequenceInput.id}
                            value={sequenceInput.value}
                            disabled={!props.isAuthorized || !algorithm || sequenceOptions.isDisabled || sequenceInput.isDisabled}
                        >
                            <FormattedMessage id={sequenceInput.formattedId} defaultMessage={sequenceInput.defaultLabel} />
                        </RadioButton>
                    </Column>
                    { sequenceInput.id === "custom" ?
                        <Column maxWidth={"150px"}>
                            <Input value={get(resourceSequences, [sequenceOptions.id, 1])}
                                   onChange={value => setResourceSequences(addToObject(
                                       resourceSequences,
                                       sequenceOptions.id,
                                       [sequenceInput.value, value]
                                   ))}
                                   placeholder={sequenceInput.placeholder}
                                   type="text"
                                   disabled={!props.isAuthorized || get(resourceSequences, [sequenceOptions.id, 0]) !== sequenceInput.value}
                                   data-testid={sequenceInput.id}/>
                        </Column> : null
                    }
                </Row>
            </Column>
        );
    }

    const renderResourceSequenceInput = (sequenceOptions) => {
        return (
            <Row key={sequenceOptions.id}>
                <Column spacing={"small"}>
                    <Row>
                        <Text type={"h100"}>
                            <FormattedMessage id={sequenceOptions.formattedId}
                                              defaultMessage={sequenceOptions.defaultLabel}/>
                        </Text>
                    </Row>
                    <Row spacing={"large"}>
                        { sequenceOptions.inputOptions
                            .map(sequenceInput => renderResourceSequenceInputOption(sequenceOptions, sequenceInput))
                        }
                    </Row>
                </Column>
            </Row>
        )
    }

    return (
        <Row>
            <Column spacing={"medium"}>
                { resourceSequenceInputs
                    .filter(resourceSequenceInput => algorithm ?
                        resourceSequenceInput.ofSortPathAlgorithms.includes(algorithm) ||
                        resourceSequenceInput.ofSortPathAlgorithms.includes(ENABLED_FOR_ALL_ALGORITHMS) :
                        !resourceSequenceInput.isHiddenByDefault).map(sequenceOptions => renderResourceSequenceInput(sequenceOptions)) }
            </Column>
        </Row>
    );
}

export default SortGridAllocationResourceSequenceForm;
