export const GET_STATION_DATA_CALL_BEGIN = "GET_STATION_DATA_CALL_BEGIN"
export const GET_STATION_DATA_SUCCESS = "GET_STATION_DATA_SUCCESS"
export const GET_STATION_DATA_CALL_FAILURE = "GET_STATION_DATA_CALL_FAILURE"
export const UPDATE_SEARCH_FILTERS = "UPDATE_SEARCH_FILTERS"
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER"
export const SET_SELECTED_STATION = "SET_SELECTED_STATION"
  
  /**
   * Reducer function that changes state based on the action triggered. 
   */
  export const StationManagementDataReducer = (state,action) => {
    switch (action.type) {
      case GET_STATION_DATA_CALL_BEGIN:
        // Mark the state as "loading" so we can show a spinner or something
        // Also, reset any errors. We're starting fresh.
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case GET_STATION_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          items: {
             ...state.items,
             stationList: action.payload.stationData, 
             updatedStationListWithFilters: action.payload.stationData,
             visibleStationListForPage: action.payload.stationData.slice(0,state.pageSize),
          },
          selectedStationCode : "",
          currentPageNumber : 1,
          stationFilters : {
            stationCode : "",
            stationName : "", 
            city : "",
            active : "",
            parentStation : "",
            timeZone : ""
          }
        };

      case SET_PAGE_NUMBER:
      const indexOfLastVisibleStation = action.pageNumber * state.pageSize;
      const indexOfFirstVisibleStation = indexOfLastVisibleStation - state.pageSize;
      const currentVisibleStations = state.items.updatedStationListWithFilters.slice(indexOfFirstVisibleStation, indexOfLastVisibleStation);
        return {
          ...state,
          loading: false,
          currentPageNumber: action.pageNumber,
          items: {
             ...state.items,
             visibleStationListForPage : currentVisibleStations
          },
          selectedStationCode : ""
        };  
  
      case GET_STATION_DATA_CALL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };

      case SET_SELECTED_STATION:
        return {
          ...state,
          selectedStationCode : action.stationCode
        };
  
      case UPDATE_SEARCH_FILTERS:
        let stationFilters = state.stationFilters;
        stationFilters[action.key] = action.value;
        // Todo : This if else is to be simplified.
        const updatedStationList = state.items.stationList.filter(station => isStationMatchesCriteria(station, stationFilters));
        return {
          ...state,
          loading: false,
          error: null,
          items : {
            ...state.items, 
            updatedStationListWithFilters : updatedStationList,
            visibleStationListForPage : updatedStationList.slice(0,state.pageSize)
          },
          selectedStationCode : "",
          stationFilters : stationFilters,
          currentPageNumber : 1

        };  
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }

const isStationMatchesCriteria = (station, stationFilters) => {
  return station.stationName
      && station.stationName.toString().toUpperCase().indexOf(stationFilters.stationName.toUpperCase()) >= 0
      && station.stationCode
      && station.stationCode.toString().toUpperCase().indexOf(stationFilters.stationCode.toUpperCase()) >= 0
      && station.city
      && station.city.toString().toUpperCase().indexOf(stationFilters.city.toUpperCase()) >= 0
      && station.active !== null
      && station.active.toString().toUpperCase().indexOf(stationFilters.active.toUpperCase()) >= 0
      && station.parentStation
      && station.parentStation.toString().toUpperCase().indexOf(stationFilters.parentStation.toUpperCase()) >= 0
      && station.timeZone
      && station.timeZone.toString().toUpperCase().indexOf(stationFilters.timeZone.toUpperCase()) >= 0;
}
