import {
    getAPIGatewayDataFromParent,
    getSortPathGeneratorAPIGatewayRequest
} from "../handler/ParentDataHandler";

export const CONFIGURE_SORT_PATH_SETTINGS_ENDPOINT = '/spg/sortpathsettings/configure';
export const GET_SORT_PATH_SETTINGS_ENDPOINT = '/spg/sortpathsettings/get';
export const GET_SORT_PATH_ENDPOINT = '/spg/sortpath/get';
export const GET_SPG_STATION_CONFIGS = '/spg/stationconfig/get';
export const GET_SORT_GRID_OVERLAP = '/spg/sortgrid/overlap';
export const DELETE_SORT_PATH_SETTING_ENDPOINT = '/spg/sortpathsettings/delete';
export const REFRESH_SORT_PATHS_FOR_NODE = '/spg/sortpath/refresh';

/**
 * Creates sort path setting by calling the appropriate endpoint.
 *
 * @param sortPathSettings
 * @returns {Promise<*>}
 */
export async function createSortPathConfiguration(nodeId, sortPathSetting) {
    const requestBody = {
        stationCode: nodeId,
        sortPathSetting: sortPathSetting,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(CONFIGURE_SORT_PATH_SETTINGS_ENDPOINT, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}

/**
 * Fetches sort path settings by calling the appropriate endpoint.
 *
 * @param nodeId
 * @returns {Promise<[*]>}
 */
export async function fetchSortPathConfigurations(nodeId) {
    const requestBody = {
        stationCode: nodeId,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(GET_SORT_PATH_SETTINGS_ENDPOINT, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}


/**
 * Refreshes sort paths for all sort path settings by calling the appropriate endpoint.
 *
 * @param nodeId
 * @returns {Promise<[*]>}
 */
export async function refreshSortPathsForNode(nodeId) {
    const requestBody = {
        stationCode: nodeId,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(REFRESH_SORT_PATHS_FOR_NODE, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}

/**
 * Deletes sort path setting by calling the appropriate endpoint.
 *
 * @param nodeId Station code of the performed operation
 * @param sortPathSettingId Sort Path setting to be deleted
 * @returns {Promise<[*]>}
 */
export async function deleteSortPathSetting(nodeId, sortPathSettingId) {
    const requestBody = {
        stationCode: nodeId,
        sortPathSettingId: sortPathSettingId
    };

    const request = getSortPathGeneratorAPIGatewayRequest(DELETE_SORT_PATH_SETTING_ENDPOINT, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}

/**
 * Fetches sort grid overlap for the given sort path setting
 *
 * @param nodeId
 * @param sortPathSettings settings details
 * @returns {Promise<*>}
 */
export async function getSortGridOverlap(nodeId, sortPathSetting) {
    const requestBody = {
        stationCode: nodeId,
        sortPathSetting: sortPathSetting,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(GET_SORT_GRID_OVERLAP, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}

/**
 * Fetches sort paths by calling the appropriate endpoint.
 *
 * @param nodeId
 * @param sortPathId
 * @returns {Promise<[*]>}
 */
export async function getSortPath(nodeId, sortPathId) {
    const requestBody = {
        stationCode: nodeId,
        sortPathId: sortPathId,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(GET_SORT_PATH_ENDPOINT, requestBody);
    const response = await getAPIGatewayDataFromParent(request);
    return response ? (response.data || response.errorResponse) : response;
}

/**
 * Fetches station configs by calling the appropriate endpoint.
 *
 * @param nodeId
 * @returns {Promise<[*]>}
 */
export async function getStationConfigs(nodeId) {
    const requestBody = {
        stationCode: nodeId,
    };

    const request = getSortPathGeneratorAPIGatewayRequest(GET_SPG_STATION_CONFIGS, requestBody);
    const response = await getAPIGatewayDataFromParent(request, 2000);
    return response ? (response.data || response.errorResponse) : response;
}