import React, {useContext, useEffect, useState} from "react";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {FormattedMessage, injectIntl} from "react-intl";
import Select from "@amzn/meridian/select";
import get from "lodash/get";
import {
    MULTIPLE_CYCLE_PER_PARTITION_ENABLED,
    SortGridAllocationCommonFormStates,
    CYCLE,
    StationCycleState
} from "../../Constants";
import {getNodeId, isConfigEnabled} from "../../Utility";
import {
    getAvailableCycles,
    getInitialFormDataState,
    getInitialFormInputState
} from "../util/sort-paths/SortPathConfigurationInputs";
import SelectOption from "@amzn/meridian/select/select-option";
import Button from "@amzn/meridian/button";
import {StationConfigContext} from "../../context/StationConfigContext";
import Tag from "@amzn/meridian/tag";
import {
    areSameCycles,
    isDynamicStationCycle, isSidelineStationCycle
} from "../util/sort-paths/CommonUtils";
import Tooltip from "@amzn/meridian/tooltip";
import Box from "@amzn/meridian/box";

const SortGridAllocationCycleSelectionForm = (props) => {

    const {
        commonFormData, commonFormInputs, addToCommonFormData, addToCommonFormInputs, setFormData, setFormInputs,
        isNewGridAllocation, intl, sortPathConfigurationList
    } = props;

    const {state} = useContext(StationConfigContext);

    const [selectedNodeId, setSelectedNodeId] = useState();
    const [selectedCycleName, setSelectedCycleName] = useState();
    const [toolTipOpen, setToolTipOpen] = useState(false);



    useEffect(() => {
        setSelectedNodeId(get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID], getNodeId()));
        setSelectedCycleName(get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE]));
    }, [get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]),
        get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE])]);

    const onClickAddCycle = () => {
        const cycleList = get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE_LIST], [])
        const cycleToBeAdded = {
            [StationCycleState.STATION_CODE] : selectedNodeId,
            [StationCycleState.CYCLE] : {
                [StationCycleState.CYCLE_NAME]: selectedCycleName,
                [StationCycleState.CYCLE_ID]: selectedCycleName // #TODO : this is not id that we receive from the backend.
            }
        };
        const isAlreadyPresent = cycleList.find(existingCycle => {
            return areSameCycles(existingCycle, cycleToBeAdded);
        })
        !isAlreadyPresent && cycleList.push(cycleToBeAdded);

        saveCycleList(cycleList);
    };

    const onClickRemoveCycle = (removedCycle) => {
        const existingCycleList = get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE_LIST], [])
        const filteredCycleList = existingCycleList.filter(existingCycle => {
            return !areSameCycles(removedCycle, existingCycle);
        })
        saveCycleList(filteredCycleList);
    };

    const saveCycleList = (cycleList) => {
        if(cycleList.length === 1) { // reset the form as per the type of cycle selected
            const cycleName = get(cycleList[0], [StationCycleState.CYCLE, StationCycleState.CYCLE_NAME]);
            const stationCode = get(cycleList[0], [StationCycleState.STATION_CODE]);

            setFormData(getInitialFormDataState(cycleName, stationCode, cycleList, state.cycleList));
            // Reset form inputs as initial inputs might change in case of dynamic cycle
            setFormInputs(getInitialFormInputState(cycleName, stationCode, state.cycleList));
        } else {
            addToCommonFormData(SortGridAllocationCommonFormStates.CYCLE_LIST, cycleList);
        }
    }

    const getValidCycles = () => {
        let validCycles = commonFormInputs.availableCycles;

        // remove the ones for which we already have sort path configurations
        if (isNewGridAllocation) {
            let alreadyConfiguredCycles = [];
            sortPathConfigurationList.forEach(configuration => {
                if(Array.isArray(configuration.cycleList)) {
                    alreadyConfiguredCycles = alreadyConfiguredCycles.concat(configuration.cycleList);
                }
            })
            validCycles = validCycles.filter(cycle => {
                return !alreadyConfiguredCycles.find(element => {
                    return (element.cycle.cycleName === cycle.value && element.stationCode === selectedNodeId);
                });
            });

            let existingSortPathConfigurations = sortPathConfigurationList.filter(element => {
                return element.nodeId === selectedNodeId;
            })
            validCycles = validCycles.filter(cycle => {
                return !existingSortPathConfigurations.find(element => {
                    return element.cycle === cycle.value;
                });
            });
        }
        // show only core cycles if multiple selection is enabled and core cycle is selected
        if (canSelectMultipleCycles() && allCoreCyclesSelected()) {
            validCycles = validCycles.filter(element => {
                return element.cycleType !== "DRS" && element.cycleType !== CYCLE.SIDELINE;
            })
        }

        return validCycles;
    }

    const getCycleListDisplay = () => {
        return (
            <Row>
                <Column spacing={"small"}>
                    <Row>
                        <Text type={"h100"}>
                            { Array.isArray(commonFormData.cycleList) ? commonFormData.cycleList.length : "0"} Cycle(s) selected
                        </Text>
                    </Row>
                    <Row spacing={"small"}>
                        {
                            ((Array.isArray(commonFormData.cycleList) && commonFormData.cycleList.length > 0)) && commonFormData.cycleList.map(cycleInput => {
                                return (isNewGridAllocation) ?
                                    <Tag key={cycleInput.stationCode + "-" + cycleInput.cycle.cycleName}
                                         onClose={() => onClickRemoveCycle(cycleInput)}>
                                        {cycleInput.stationCode + " " + cycleInput.cycle.cycleName}
                                    </Tag>
                                    :
                                    <Box key={cycleInput.stationCode + "-" + cycleInput.cycle.cycleName} type="outline" spacingInset="small">
                                        <Text type={"h100"}>{cycleInput.stationCode} {cycleInput.cycle.cycleName}</Text>
                                    </Box>
                            })
                        }
                    </Row>
                </Column>
            </Row>
        );
    }

    const getNodeSelectionDropdown = () => {
        return (
            <Column spacing={"small"}>
                <Row>
                    <Text type={"h100"}>
                        <FormattedMessage id="header.station" defaultMessage="Station"/>:
                    </Text>
                </Row>
                <Row>
                    <div data-testid="selectNode">
                        <Select
                            value={selectedNodeId}
                            onChange={value => {
                                if (canSelectMultipleCycles()) {
                                    setSelectedNodeId(value);
                                    setSelectedCycleName(undefined)

                                    // update cycle list.
                                    addToCommonFormInputs("availableCycles", getAvailableCycles(state.cycleList, value));
                                } else {
                                    // Reset form data node is switched to avoid preserving filled information
                                    //      across cycles, which might not allow it to be edited
                                    setFormData(getInitialFormDataState(undefined, value, undefined, state.cycleList));

                                    // Reset form inputs as initial inputs might change based on what cycles are available
                                    setFormInputs(getInitialFormInputState(undefined, value, state.cycleList));
                                }
                            }}
                            name="selectNode"
                            minWidth={150}
                            disabled={!props.isAuthorized || !isNewGridAllocation}>
                            {commonFormInputs.availableNodes.map(nodeId => {
                                return (
                                    <SelectOption key={nodeId} value={nodeId} label={nodeId}/>
                                )
                            })}
                        </Select>
                    </div>
                </Row>
            </Column>
        );
    }

    const getCycleSelectionDropdown = () => {
        return (
            <Column spacing={"small"}>
                <Row>
                    <Text type={"h100"}>
                        <FormattedMessage id="sortPaths.selectCycle" defaultMessage="Select Cycle (CPT)"/>:
                    </Text>
                </Row>
                <Row>
                    <div data-testid="selectCycle">
                        <Select value={selectedCycleName}
                                onChange={value => {
                                    if (canSelectMultipleCycles()) {
                                        setSelectedCycleName(value)
                                    } else {
                                        const selectedNodeId = get(commonFormData, [SortGridAllocationCommonFormStates.NODE_ID]);

                                        // Reset form data when cycle is switched to avoid preserving filled information
                                        //      across cycles, which might not allow it to be edited
                                        setFormData(getInitialFormDataState(value, selectedNodeId, undefined, state.cycleList));

                                        // Reset form inputs as initial inputs might change in case of dynamic cycle
                                        setFormInputs(getInitialFormInputState(value, selectedNodeId, state.cycleList));
                                    }
                                }}
                                name="selectCycle"
                                minWidth={150}
                                disabled={!props.isAuthorized || !isNewGridAllocation}>
                            {getValidCycles().map(cycleInput => {
                                return (
                                    <SelectOption key={cycleInput.id} value={cycleInput.value}
                                                  label={intl.formatMessage({
                                                      id: cycleInput.formattedId,
                                                      defaultMessage: cycleInput.defaultLabel
                                                  })}/>
                                )
                            })}
                        </Select>
                    </div>
                </Row>
            </Column>
        );
    }

    const getAddCycleButton = (isAuthorizedUser = true) => {
        return (
            <Column spacing={"small"}>
                <Row>
                    &nbsp;
                </Row>
                <Row>
                    <Tooltip open={toolTipOpen} position={"top"} title={getTooltipMessage()}>
                        <Button
                            disabled={shouldDisableAddButton(isAuthorizedUser)}
                            onClick={() => onClickAddCycle()}
                            onMouseEnter={() => {
                                setToolTipOpen(shouldDisableAddButton(isAuthorizedUser));
                            }}
                            onMouseLeave={() => setToolTipOpen(false)}
                        >Add</Button>
                    </Tooltip>
                </Row>
            </Column>
        );
    }

    const getTooltipMessage = () => {
        const cycleList = get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE_LIST], []);
        if(cycleList.length > 0) {
            return "Select a Core cycle to add more";
        }
        return "Select Cycle";
    }

    const shouldDisableAddButton = (isAuthorizedUser) => {
        if (!isAuthorizedUser) {
            return true
        }
        const cycleList = get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE_LIST], []);
        let isDynamicCycleSelected = false;
        if(cycleList.length === 1) {
            isDynamicCycleSelected = isDynamicStationCycle(cycleList[0], state.cycleList) || isSidelineStationCycle(cycleList[0], state.cycleList);
        }
        return !selectedCycleName || !selectedNodeId || isDynamicCycleSelected;
    }

    const allCoreCyclesSelected = () => {
        const selectedCycleList = get(commonFormData, [SortGridAllocationCommonFormStates.CYCLE_LIST], []);
        return selectedCycleList.length > 0 && !selectedCycleList.find(cycle => {
            return isDynamicStationCycle(cycle, state.cycleList) || isSidelineStationCycle(cycle, state.cycleList);
        })
    }

    const shouldShowCycleSelectionDropdown = () => {
        return !(canSelectMultipleCycles() && !isNewGridAllocation);
    }

    const canSelectMultipleCycles = () => {
        return isConfigEnabled(MULTIPLE_CYCLE_PER_PARTITION_ENABLED, state.stationConfigs);
    }

    return (
        <>
            <Row>
                { shouldShowCycleSelectionDropdown() &&
                    <>
                        {(Array.isArray(commonFormInputs.availableNodes) && commonFormInputs.availableNodes.length > 1)
                            && getNodeSelectionDropdown()}
                        {getCycleSelectionDropdown()}
                        {canSelectMultipleCycles()
                            && getAddCycleButton(props.isAuthorized)}
                    </>
                }
            </Row>
            {canSelectMultipleCycles()
                && getCycleListDisplay()}
        </>
    )
}

export default injectIntl(SortGridAllocationCycleSelectionForm);