import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from "@meridian/components/alert";

class ErrorPage extends Component {
    render() {
        return (        
        <Alert
            type="error"
            size="medium"
          >
            <FormattedMessage id={this.props.error} defaultMessage="Some Error Occured">
            </FormattedMessage>
        </Alert>
        )
    };
}

export default ErrorPage;