import { DISABLED_ATTRIBUTES, RESOURCE_TYPE_DISPLAY_FIELDS, VISIBLE_ATTRIBUTES } from '../../../Constants';
import React, {useContext} from 'react';
import Text from "@meridian/components/text";
import Row from "@meridian/components/row";
import {NodeResourceContext} from "../../../context/NodeResourceContext";
import Column from "@meridian/components/column";
import Input from "@meridian/components/input";
import { SET_IS_VALID_EDIT_MODAL, UPDATE_RESOURCE_DATA } from '../../../reducers/NodeManagerReducer';
import Alert from "@meridian/components/alert";
import { checkIsValidEditModal } from '../../../helper/NodeResourceManagerHelper';

function SetResourcesAndAssociationsModal(props) {
    const {state, dispatch} = useContext(NodeResourceContext);
    let {currentResource, isValidEditModal, isValidEditModalMessage} = state;

    const updateResourceData = (fieldName, fieldValue, isDisabledField) => {
        dispatch( {
            type: UPDATE_RESOURCE_DATA,
            fieldName,
            fieldValue});
        if(!isDisabledField){
            const isValidEditModalResponse = checkIsValidEditModal(fieldName, fieldValue);
            dispatch({type: SET_IS_VALID_EDIT_MODAL, payload: {
                isValidEditModal: isValidEditModalResponse.isValidEditModal,
                isValidEditModalMessage: isValidEditModalResponse.isValidEditModalMessage
            }})
        }
    };
    
    return (
        <div id="viewResource-modal_desc">
                {
                    <>
                    {RESOURCE_TYPE_DISPLAY_FIELDS[currentResource.resourceType].resource.map(
                        (attribute) => {
                            const fieldName = attribute.fieldName;
                            let value;
                            if(currentResource.resourceType && VISIBLE_ATTRIBUTES[currentResource.resourceType].includes(fieldName)){
                                value = (currentResource.resourceAttributes)[fieldName];
                            } else{
                                value = currentResource[fieldName];
                            }
                            let isDisabledField = DISABLED_ATTRIBUTES[currentResource.resourceType].includes(fieldName);
                            return (
                                <Row
                                    widths={["grid-6", "grid-6"]}
                                    wrap="down"
                                    height={"100px"}
                                    spacingInset="small"
                                    alignment="top left"
                                >
                                    <Column spacing="small" >
                                        <Text type="h100">{fieldName}</Text>
                                        <Input
                                            size="small"
                                            width={"200px"}
                                            value={value}
                                            disabled={isDisabledField}
                                            onChange={(attribute) => updateResourceData(fieldName, attribute, isDisabledField)}
                                        >
                                        </Input>
                                    </Column>
                                </Row>  
                            )
                        }
                    )}
                    <Row>
                        {!isValidEditModal && 
                            <Alert size="small" type="error"> {isValidEditModalMessage} </Alert>
                        }
                    </Row>
                </>
                }
            
        </div>
    );
}

export default SetResourcesAndAssociationsModal;