import React, {createContext, useReducer} from "react"

import {StationViewManagementDataReducer} from "../reducers/StationViewManagementReducer";

export const StationViewManagementContext = createContext()

const StationViewManagementContextProvider = (props) => {
    /**
     * Initial state of the station view management.
     */
    const initialState = {
        items: {
            currentStation : {},
            stationList : [],
            stationActiveCapabilities: ""
        },
        loading: true,
        error: null,
        updateButtonEnabled: false,
        stationAllowlistedForConfigUpdate: false,
        stationUpdateModalState: false
    };
    const [ state, dispatch ] = useReducer(StationViewManagementDataReducer, initialState)
    return (
        <StationViewManagementContext.Provider value = {{state, dispatch}}>
            {props.children}
        </StationViewManagementContext.Provider>
    )
}

export default StationViewManagementContextProvider
