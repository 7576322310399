import {publishCount, publishTime} from "../MetricUtility";
import {
    ERROR_METRIC_SUFFFIX,
    SMART_STATION_PROCESS_NAME,
    SORT_PATH_GENERATOR_PROCESS_NAME,
    STRINGS
} from "../Constants";

const postMessagePromises = {};

const subscribeAbleEvents = {
    "sendCoralServiceData": sendCoralServiceData,
    "sendLocaleResponse": sendResponse,
    "sendCapabilityResponse": sendResponse,
    "sendApiGatewayResponse": sendApiGatewayResponse,
    "sendEmployeeNameResponse": sendEmployeeNameResponse,
    "sendFeatureStatus": sendResponseData,
};

function sendApiGatewayResponse(data) {
    if (postMessagePromises[data.guid]) {
        postMessagePromises[data.guid].resolve(data.responseData);
    }
}

window.addEventListener("message", onNewPostMessageFromParent, false);

function onNewPostMessageFromParent(event) {
    if (event.data.method && subscribeAbleEvents.hasOwnProperty(event.data.method)) {
        subscribeAbleEvents[event.data.method](event.data);
    }
}

function sendCoralServiceData(data) {
    console.log("InductLocationsData response from parent:");
    console.log(data);
    if(postMessagePromises[data.guid]) {
        postMessagePromises[data.guid].resolve(data.responseData);
    }
}

function sendEmployeeNameResponse(data) {
    if (postMessagePromises[data.guid]) {
        postMessagePromises[data.guid].resolve(data.childData);
    }
}


function generateGuid(){
    /**
     * @return {string}
     */
    function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

export function publishClientSideMetrics(args) {
    window.parent.postMessage({
        method: 'publishClientSideMetrics',
        args: args
    }, "*");
}

export async function getNodeCapabilityFromParent() {
    return await postMessagePromised(window.parent, {
        method: 'getNodeCapability',
        args: {}
    }, '*');
}

export async function getCoralServiceDataFromParent(args, processName, metricName) {
    let response;
    const startTime = Date.now();
    try {
        response = await postMessagePromised(window.parent, {
            method: 'getCoralServiceDataFromParent',
            args: args
        }, '*');
    } catch (e) {
        console.error('Error ',e);
        publishCount(processName, metricName + ERROR_METRIC_SUFFFIX);
        throw e;
    }
    publishTime(processName, metricName, startTime);
    return response;
}

export async function getAPIGatewayDataFromParent(args, timeout) {
    return await postMessagePromised(window.parent, {
        method: 'getAPIGatewayDataFromParent',
        args: args
    }, '*', timeout);
}

export function getSmartStationRelayAPIGatewayRequest(endpoint, request, httpMethod = "POST") {
    return getAPIGatewayRequest(SMART_STATION_PROCESS_NAME, endpoint, request, httpMethod)
}

export function getSortPathGeneratorAPIGatewayRequest(endpoint, request, httpMethod = "POST") {
    return getAPIGatewayRequest(SORT_PATH_GENERATOR_PROCESS_NAME, endpoint, request, httpMethod)
}

export function getAPIGatewayRequest(processName,endpoint, request, httpMethod = "POST") {
    return {
        resourcePath: endpoint,
        httpMethod: httpMethod,
        processName: processName,
        requestBody: request
    }
}

export async function getFeaturesFromParent(features) {
    return await postMessagePromised(window.parent, {
        method: 'getFeatureStatus',
        args: {features}
    }, '*');
}

export async function getLocaleFromParent() {
    return await postMessagePromised(window.parent, {
        method: 'getLocale',
        args: {}
    }, '*',200);
}

export async function getUserNameFromParent() {
    return await postMessagePromised(window.parent, {
        method: 'getEmployeeName',
        args: {}
    }, '*',200);
}

function postMessagePromised(targetWindow, message, targetOrigin, timeout) {
    const newGuid = generateGuid();
    message.guid = newGuid;
    postMessagePromises[newGuid] = {};
    postMessagePromises[newGuid]['promise'] = new Promise(function (resolve, reject) {
        postMessagePromises[newGuid]['resolve'] = resolve;
        postMessagePromises[newGuid]['reject'] = reject;
        targetWindow.postMessage(message, targetOrigin);
        if (timeout) {
            setTimeout(() => {
                postMessagePromises[newGuid].resolve(STRINGS.TIMEOUT);
            },timeout);
        }
    });
    return postMessagePromises[newGuid].promise;
}

function sendResponse(data) {
    if (postMessagePromises[data.guid]) {
        postMessagePromises[data.guid].resolve(data.childData);
    }
}

function sendResponseData(data) {
    if (postMessagePromises[data.guid]) {
        postMessagePromises[data.guid].resolve(data.responseData);
    }
}
